import * as React from "react";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";
import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  Organization,
  User,
  Workspace,
  WorkspaceUserRole,
} from "../../types/User";
import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Workspaces } from "@mui/icons-material";
import authSlice, { selectAuth, selectCreateUserError, setCreaterUserError } from "../auth/authSlice";

/**
 * Edit the workspace if a workspace is passed in, otherwise create a new one.
 *
 * @export
 * @return {*}
 */
export default function WorkspaceConfigForm(props: {
  closeDialogHandler: any;
  newWorkspaceHandler: any;
  organization: Organization;
  workspace: Workspace | undefined;
  workspaces: Array<Workspace>;
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [name, setName] = React.useState<string>(
    props.workspace?.name ?? ""
  );
  const authState = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const userErrorState= useAppSelector(selectCreateUserError)  
  const [nameError, setNameError] = React.useState("");
  const [dialogError, setDialogError] = React.useState("");

  const [addUser, setAddUser] = React.useState<User | undefined>(
    props.organization.users && props.organization.users.length > 0
      ? props.organization.users[0]
      : undefined
  );
  const [addUserRole, setAddUserRole] = React.useState<string>("READER");
  const [newUserEmail, setNewUserEmail] = React.useState<string>("");
  const [newUserPassword, setNewUserPassword] = React.useState<string>("");

  const handleEditUserEmail = (e: any) => {

    setNewUserEmail(e.target.value);
    dispatch(setCreaterUserError(undefined))
  };

  const handleEditUserPassword = (e: any) => {
    setNewUserPassword(e.target.value);
  };

  const actions = useActions();

  const handleCancel = () => {
    dispatch(setCreaterUserError(undefined));
    props.closeDialogHandler();
  };

  const handleClose = () => {
    if (props.workspace === undefined) {
      //Create the workspace.
      if (props.workspaces.filter((_) => _.name == name).length > 0) {
        enqueueSnackbar("A workspace by that name already exists");
      } else {
        if (name == "FREE CONTENT") {
          enqueueSnackbar(
            "You can't use that name for a workspace because it is a system name"
          );
        } else {
          actions.createWorkspace({
            name: name,
            role_type: "PUBLISHER_CREATOR",
          });
          props.newWorkspaceHandler(name);
        }
      }
    } else {
      props.closeDialogHandler();
    }
  };

  const handleSetName = (name: string) => {
    if (name.trim() === "") {
      setNameError("Invalid name");
    } else setNameError("");
    setName(name);
  };

  const handleSelectUser = (e: SelectChangeEvent) => {
    setAddUser(props.organization.users?.find((u) => u.id === e.target.value));
  };

  const handleSelectRole = (e: SelectChangeEvent) => {
    setAddUserRole(e.target.value);
  };

  const handleSelectRoleChange = (
    e: SelectChangeEvent,
    user_id: number,
    workspace: Workspace | undefined
  ) => {
    //console.log(userRole);
    actions.changeUserRole({
      role: e.target.value,
      user_id: user_id,
      workspace: workspace,
    });
  };

  const handleAddUser = (e: any) => {
    if (addUser == null) {
      setDialogError("Please select a user to add.");
      return;
    }
    //TODO: Check for duplicates.
    actions.attachUser({
      workspace: props.workspace!,
      user: addUser,
      role: addUserRole,
    });
  };



  const handleCreateUser = (e: any) => {
    /*if (addUser == null) {
      setDialogError("Please select a user to add.");
      return;
    }*/
    //TODO: Check for duplicates.

    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!newUserEmail.match(validRegex)) {
      enqueueSnackbar("Not a valid email address",{variant:"error"});
      return;
    }

    actions.createUser({
      workspace: props.workspace!,
      email: newUserEmail,
      password: newUserPassword,
    });

    enqueueSnackbar("Invitation sent successfully!", { variant: "success" });
    setNewUserEmail("")

  };

  const handleDeleteRole = (role: WorkspaceUserRole) => {
    actions.detachUser({ workspace: props.workspace!, user: role.user });
  };

  // The assigned user identities.
  const assignedUsers = new Map<string, User>();
  props.workspace?.user_roles?.forEach((userRole) =>
    assignedUsers.set(userRole.user.id, userRole.user)
  );

  // All users
  const allUsersItems = props.organization.users?.map((user) => (
    <MenuItem key={user.id} value={user.id}>
      {user.email}
    </MenuItem>
  ));
  // Remaining users (not assigned roles)
  const unassignedUsersItems = props.organization.users
    ?.filter((u) => !assignedUsers.has(u.id))
    .map((user) => (
      <MenuItem key={user.id} value={user.id}>
        {user.email}
      </MenuItem>
    ));

  const userEditCard = (
    <Card>
      <CardHeader title="User Roles"></CardHeader>
      <CardContent>
        {/* Existing role types */}
        {props.workspace?.user_roles?.map((userRole) => (
          <Grid container spacing={2} sx={{ py: "10px" }}>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel id={userRole.user.id + "demo-select-small-label"}>
                  User
                </InputLabel>
                <Select
                  label="User"
                  value={userRole.user.id}
                  fullWidth={true}
                  disabled={true}
                >
                  {allUsersItems}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel id={userRole.user.id + "demo-select-small-label"}>
                  Role
                </InputLabel>
                <Select
                  label="Role"
                  value={userRole.role_type}
                  onChange={(event) =>
                    handleSelectRoleChange(
                      event,
                      parseInt(userRole.user.id),
                      props.workspace
                    )
                  }
                  fullWidth={true}
                  sx={{
                    "& label.MuiInputLabel-root": {
                      color: "black",
                    },
                  }}
                >
                  <MenuItem value="READER">Reader</MenuItem>
                  <MenuItem key="READER_CREATOR" value="READER_CREATOR">
                 Creator
                </MenuItem>
                  {/*<MenuItem value="PUBLISHER">Publisher</MenuItem> */}
                  <MenuItem value="CREATOR">Editor</MenuItem>
                  <MenuItem value="PUBLISHER_CREATOR">Publisher</MenuItem>
               
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} textAlign="center">
              <IconButton onClick={(e) => handleDeleteRole(userRole)}>
                <DeleteIcon fontSize="large"></DeleteIcon>
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Divider sx={{ margin: 1 }}></Divider>
        <Grid container spacing={2} sx={{ marginTop: "10px" }}>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <InputLabel id={"demo-select-small-label__user"}>User</InputLabel>
              <Select
                label="User"
                value={addUser?.id}
                onChange={handleSelectUser}
                fullWidth={true}
              >
                {unassignedUsersItems}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <InputLabel id={"demo-select-small-label__"}>Role</InputLabel>
              <Select
                label="Role"
                value={addUserRole}
                onChange={handleSelectRole}
                fullWidth={true}
              >
                <MenuItem key="READER" value="READER">
                  Reader
                </MenuItem>
                <MenuItem key="READER_CREATOR" value="READER_CREATOR">
                 Creator
                </MenuItem>
                {/* <MenuItem key="PUBLISHER" value="PUBLISHER">Publisher</MenuItem> */}
                <MenuItem key="CREATOR" value="CREATOR">
                  Editor
                </MenuItem>
                <MenuItem key="PUBLISHER_CREATOR" value="PUBLISHER_CREATOR">
                  Publisher
                </MenuItem>
               
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button startIcon={<AddIcon />} onClick={handleAddUser}>
              Add User
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <CardHeader
          sx={{ alignItems: "left", pl: 0, ml: 0 }}
          title="Invite a New User"
        ></CardHeader>
        <Divider sx={{ margin: 0, marginBottom:2 }}></Divider>
        <Grid container spacing={2}>
          <Grid item xs={9.5}>
            {/* <InputLabel>Email</InputLabel> */}
            <TextField
              value={newUserEmail}
              onChange={handleEditUserEmail}
              fullWidth={true}
              error={userErrorState!==undefined}
              label={"Email"}
              helperText={userErrorState!==undefined? userErrorState
                //  'A user with this email already exists in this workspace.'
                  : ''}
              
            
            />
          </Grid>
          {/*<Grid item xs={5}>
            <InputLabel>Password</InputLabel>
            <Input
              value={newUserPassword}
              onChange={handleEditUserPassword}
            />
        </Grid> */}
          <Grid item xs={2}>
            <Button
       
              startIcon={<AddIcon />}
              onClick={(event) => handleCreateUser(event)}
            >
              Create User
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <Dialog
      open={true}
      onClose={(e) => {
        props.closeDialogHandler();
      }}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        {props.workspace == undefined ? "Add" : "Edit"} Workspace
      </DialogTitle>
      <DialogContent>
        {dialogError !== "" ? (
          <Alert severity="error">{dialogError}</Alert>
        ) : null}
        <TextField
          autoFocus
          error={nameError ? true : false}
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          variant="outlined"
          value={name}
          disabled={props.workspace !== undefined}
          onChange={(e) => handleSetName(e.target.value)}
        />

        {props.workspace !== undefined ? userEditCard : null}
      </DialogContent>
      <DialogActions>
        {props.workspace === undefined ? (
          <Button onClick={handleCancel}></Button>
        ) : null}
        <Button variant="outlined" disabled={name==""}  onClick={handleClose}>
          {props.workspace == undefined ? "Create" : "Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

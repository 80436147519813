import {
  Box,
  Card,
  CardContent,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useContext, useRef, useState } from "react";
import { truncateHeading } from "../../../../services/utils";
import { useEffect } from "react";
import {
  ContentType,
  DocumentElement,
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplateSummary,
} from "../../../../types/DocumentTemplate";
import useActions, { useAppSelector } from "../../../../app/hooks";
import { ViewPreferences } from "../../header/preferencesSlice";
import { ImageElementEditor } from "../images/ImageElementEditor";
import { getEditorForDocumentElement } from "../utils";
import { selectWorkspaceSummaryState } from "../libraryContent/workspaceSummarySlice";
import { MyObjectContext } from "./LibraryContentFormSubSection";
/**
 * A single DocumentSectionSummary in the LibraryContent form.
 *
 * @export
 * @param {{
 *   selectElementHandler: any;
 *   documentTemplateSummary: DocumentTemplateSummary
 * }} props
 */

export default function LibraryContentElementSubSection(props: {
  selectElementHandler: any;
  documentSectionSummary: DocumentSectionSummary;
  isSelected: boolean;
  organizationId: number;
  workspaceId: number;
  viewPreferences: ViewPreferences;
  documentTemplateSummary: DocumentTemplateSummary;
}) {
  const section = props.documentSectionSummary;
  // only
  const sampleDocumentElements = section.sample_document_elements;
  // .filter((e) => {
  //   return e.content_type !== "PAGEBREAK" && e.content_type !== "SPACER" && e.content_type !== "TABLEOFCONTENTS" && e.content_type !== "LISTOFTABLES" && e.content_type !== "LISTOFFIGURES";
  // });

  const sampleDocumentSubsections = section.sample_document_sub_sections;
  const [searchedIndexes, setSearchedIndexes] = useState<any>([]);
  const workspaceSummaryState = useAppSelector(selectWorkspaceSummaryState);
  const [Matched, setMatched] = useState<number>(0);
  // Context to rapidly parent child data
  const [selectedSubSection, setSelectedSubSection] =
    useContext(MyObjectContext);

  // Select a Section
  const selectElementHandler = () => {
    props.selectElementHandler(props.documentSectionSummary);
  };

  useEffect(() => {
    loop: for (const section of props.documentTemplateSummary
      .document_section_summaries || []) {
      for (const subSection of section.sample_document_sub_sections) {
        if (searchedIndexes.includes(subSection.id)) {
          setMatched(subSection.id);
          break loop; // Exit the labeled loop
        }
      }
    }
  }, [searchedIndexes]);

  useEffect(() => {
    if (workspaceSummaryState.searchedElement) {
      const targetSubSectionId = Matched;
      const boxElement = document.querySelector(
        `[data-subsection-id="${targetSubSectionId}"]`
      );
      if (boxElement) {
        boxElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [workspaceSummaryState.searchedElement, Matched]);

  // Store the Matched Search result.
  const boxRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (workspaceSummaryState.searchedElement) {
      setSearchedIndexes(workspaceSummaryState.searchedElement);
    }
  }, [workspaceSummaryState]);

  // function to select & deSelect a subsection
  const toggleBoxSelection = (index: any) => {
    if (selectedSubSection.includes(index)) {
      setSelectedSubSection(selectedSubSection.filter((i: any) => i !== index));
    } else {
      setSelectedSubSection([...selectedSubSection, index]);
    }
  };
  
  return (
    <ListItem
      key={section.id}
      button
      selected={props.isSelected}
      onClick={selectElementHandler}
      disableRipple
    >
      <ListItemText
        primary={
          <Typography variant="h4">
            {truncateHeading(section.heading.replace(/## |\+\+|\*|_/g, ""))}
          </Typography>
        }
        secondary={
          <Card>
            <CardContent
              style={{
                backgroundColor: "white",
              }}
            >
              {sampleDocumentElements.map((element, index) => (
                <Box
                  sx={{
                    borderRadius: 2,
                    padding: 0.5,
                    border: 1,
                    "&:hover": {
                      backgroundColor: "primary.main",
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                  key={sampleDocumentSubsections[index].id}
                  border={3}
                  borderColor="black"
                  p={1}
                  onClick={(e) => {
                    toggleBoxSelection(sampleDocumentSubsections[index].id);
                  }}
                  style={{
                    backgroundColor: selectedSubSection
                      ? selectedSubSection.includes(
                          sampleDocumentSubsections[index].id
                        )
                        ? "#4C33FF"
                        : searchedIndexes.includes(
                            sampleDocumentSubsections[index].id
                          )
                        ? "#29cfff"
                        : "white"
                      : searchedIndexes.includes(
                          sampleDocumentSubsections[index].id
                        )
                      ? "#29cfff"
                      : "white",
                    boxShadow: searchedIndexes.includes(
                      sampleDocumentSubsections[index].id
                    )
                      ? "0 0 10px rgba(0, 0, 0, 0.3)"
                      : "none",
                    transition:
                      "box-shadow 0.3s ease, background-color 0.3s ease",
                    fontWeight: searchedIndexes.includes(
                      sampleDocumentSubsections[index].id
                    )
                      ? "bold"
                      : "inherit",
                    fontStyle: searchedIndexes.includes(
                      sampleDocumentSubsections[index].id
                    )
                      ? "italic"
                      : "normal",
                    borderRadius: "0px",
                  }}
                >
                  {getEditorForDocumentElement(
                    element,
                    sampleDocumentSubsections[index],
                    section,
                    null,
                    props.documentTemplateSummary,
                    props.workspaceId,
                    props.organizationId,
                    props.viewPreferences,
                    false,
                    "sde_" + section.id + "_" + index,
                    false,
                    -1,
                    true
                  )}
                </Box>
              ))}
            </CardContent>
          </Card>
        }
      ></ListItemText>
    </ListItem>
  );
}

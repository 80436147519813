import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FileUpload, FileUploadProps } from "../../../shared/FileUpload";
import useActions, { useAppDispatch } from "../../../../app/hooks";

import { fabric } from "fabric";
import Grid from "@mui/material/Grid";
import {
  Alert,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputAdornment,
  SelectChangeEvent,
} from "@mui/material";
import {
  DocumentElement,
  DocumentSection,
  DocumentSubsection,
  DocumentTemplate,
} from "../../../../types/DocumentTemplate";
import { Typography } from "@mui/material";
import { host } from "../../../../services/client";
import { useState } from "react";
import { font_Families } from "../../../../globals";
import { findMatchingFont } from "../HelperEditorFunctions";

import { clearSelectedComponent } from "../../editorSlice";
import { attachedSubsectionElement } from "../utils";
/**
 * REF: https://codesandbox.io/s/lgqwn?file=/src/FileUpload/FileUpload.tsx:1869-1877
 *
 * @export
 * @param {{open: boolean}} props
 * @return {*}
 */
export default function ImageUploadForm(props: {
  closeDialogHandler: any;
  section: DocumentSection;
  subSection: DocumentSubsection;
  element: DocumentElement;
  documentTemplate: DocumentTemplate;
  organizationId: number;
  setJustify: any;
  imageFile?: any;
  sendData: (Data: any) => void;
  imageNumber?: any;
}) {
  const documentTemplate = props.documentTemplate as DocumentTemplate;
  const [file, setFile] = React.useState<File | null>(
    props.imageFile === null ? null : props.imageFile
  );
  const [originalFile, setOriginalFile] = React.useState<File | null>(
    props.imageFile === null ? null : props.imageFile
  );
  const actions = useActions();
  const dispatch = useAppDispatch();

  const startElement = React.useMemo(() => {
    return JSON.parse(props.element.content_format);
  }, []);
  const [imageSize, setImageSize] = React.useState<string>(
    startElement.size ? startElement.size : "Original"
  );
  const [customSize, setCustomSize] = React.useState<number>(
    startElement.custom
  );
  const [justify, setJustify] = React.useState<string>(startElement.justify);
  const [caption, setCaption] = React.useState<string>(
    startElement.caption
      ? startElement.caption === "Image"
        ? "Image " + props.imageNumber
        : startElement.caption
      : "No Caption"
  );
  const [isDisplayCaption, setIsDisplayCaption] = React.useState<boolean>(
    startElement.DisplyCaption ? startElement.DisplyCaption : false
  );
  const [captionOrder, setCaptionOrder] = React.useState<string>(
    startElement.captionOrder ? startElement.captionOrder : "below"
  );

  const [font, setFont] = React.useState<string>(
    startElement.font ? startElement.font : "rmfamily"
  );
  const [isNumberFormat, setIsNumberFormat] = React.useState<boolean>(
    startElement.isNumberFormat ? startElement.isNumberFormat : false
  );
  const [captionAlignment, setCaptionAlignment] = React.useState<string>(
    startElement.captionAlignment ? startElement.captionAlignment : "center"
  );
  const [fontSize, setFontsize] = React.useState<string>(
    startElement.fontSize ? startElement.fontSize : "normalsize"
  );
  const [color, setColor] = React.useState<string>(
    startElement.color ? startElement.color : "000000"
  );
  const captionAlignmentList = ["center", "left", "right"];
  const captionOrderList = ["below", "above"];
  const [style, setStyle] = React.useState<string[]>([
    startElement.style ? startElement.style : "bold",
  ]);
  const styleList = ["Standard", "bold", "Italic", "Underline"];
  const [isBold, setIsBold] = React.useState<boolean>(
    startElement.isBold ? startElement.isBold : false
  );
  const [isItalic, setIsItalic] = React.useState<boolean>(
    startElement.isItalic ? startElement.isItalic : false
  );
  const [isUnderline, setIsUnderline] = React.useState<boolean>(
    startElement.isUnderline ? startElement.isUnderline : false
  );

  const [isStandard, setIsStandard] = React.useState<boolean>(
    startElement.isStandard ? startElement.isStandard : false
  );
  const [imageWidth, setImageWidth] = React.useState<number>(
    startElement.image_width ? startElement.image_width : 100
  );
  const [imageHeight, setImageHeight] = React.useState<number>(
    startElement.image_height ? startElement.image_height : 100
  );

  const fontFamilies = font_Families;

  const colorOptions = [
    { name: "Black", code: "000000" },
    { name: "White", code: "FFFFFF" },
    { name: "Red", code: "FF0000" },
    { name: "Blue", code: "0000FF" },
    { name: "Green", code: "008000" },
    { name: "Yellow", code: "FFFF00" },
    { name: "Purple", code: "800080" },
    { name: "Orange", code: "FFA500" },
    { name: "Brown", code: "A52A2A" },
    { name: "Teal", code: "008080" },
  ];

  const handleCustomSizeChange = (e: any) => {
    const inputValue = e.target.value;
    const newValue = inputValue.replace(/\D/g, ""); // Remove non-digit characters
    setCustomSize(parseInt(newValue));
  };

  let countImageElement = 0;

  // Flag variable to track if we need to exit from all loops

  let exitLoop = false; // Flag variable to track if we need to exit from all loops

  // Labeled statement for the outermost loop

  outerLoop: if (documentTemplate.sections) {
    for (const section of documentTemplate.sections) {
      if (section.subsections) {
        for (const subsection of section.subsections) {
          if (
            Array.isArray(subsection.elements) && // Check if elements is an array
            subsection.elements.length > 0 &&
            subsection.elements[0]?.content_type === "IMAGE" && // Added optional chaining
            subsection.pending_deleted_at === null
          ) {
            if (
              attachedSubsectionElement(subsection).id === props.element?.id
            ) {
              countImageElement++;
              exitLoop = true; // Set the flag to true if condition is met
              break outerLoop; // Break out of all loops using the labeled statement
            } else if (
              JSON.parse(attachedSubsectionElement(subsection).content_format)
                .isNumberFormat === true
              // && JSON.parse(subsection.elements[0].content_format).isDisplayCaption==="true"
            ) {
              countImageElement++;
            }
          }
        }
      }
    }
  }

  const fileUploadProp: FileUploadProps = {
    accept: "image/*",
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files !== null && event.target?.files?.length > 0) {
        setFile(event.target.files[0]);
        setOriginalFile(event.target.files[0]);
        const uploadedFile = event.target.files[0];

        const image = new Image();
        image.src = URL.createObjectURL(uploadedFile);
        image.onload = () => {
          const width = image.width;
          const height = image.height;
          console.log(`Image width: ${width}px, height: ${height}px`);
          setImageWidth(width);
          setImageHeight(height);
          // You can set the width and height in your component's state if needed
        };
      }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      setFile(event.dataTransfer.files[0]);
      setOriginalFile(event.dataTransfer.files[0]);
      const uploadedFile = event.dataTransfer.files[0];

      const image = new Image();
      image.src = URL.createObjectURL(uploadedFile);

      image.onload = () => {
        const width = image.width;
        const height = image.height;
        console.log(`Image width: ${width}px, height: ${height}px`);
        setImageWidth(image.width);
        setImageHeight(height);
        // You can set the width and height in your component's state if needed
      };
    },
  };

  const handleUploadImage = () => {
    if (file != null) {
      const newParms = JSON.stringify({
        size: imageSize,
        custom: imageSize === "full page" ? "150" : customSize.toString(),
        justify: justify,
        caption: caption,
        DisplyCaption: isDisplayCaption,
        captionOrder: captionOrder,
        captionAlignment: captionAlignment,
        font: font,
        fontSize: fontSize,
        // style: style,
        color: color,
        isNumberFormat: isDisplayCaption ? isNumberFormat : false,
        isBold: isBold,
        isItalic: isItalic,
        isUnderline: isUnderline,
        isStandard: isStandard,
        image_width: imageWidth,
        image_height: imageHeight,
      });
      actions.uploadDocumentElementImage({
        section: props.section!,
        subSection: props.subSection,
        documentElement: { ...props.element, content_format: newParms },
        file: file,
      });
    }
    if (props.sendData) {
      props.sendData({
        caption,
        imageSize,
        customSize,
        captionAlignment,
        isDisplayCaption,
        captionOrder,
        fontSize,
        font,
        style,
        justify,
        color,
        isNumberFormat,
        isBold,
        isStandard,
        isUnderline,
        isItalic,
      });
    }
    dispatch(clearSelectedComponent());
    props.closeDialogHandler();

    props.setJustify(justify);
  };

  const handleUpdateImage = () => {
    const newParms = JSON.stringify({
      size: imageSize,
      custom: imageSize === "full page" ? "150" : customSize.toString(),
      justify: justify,
      caption: caption,
      DisplyCaption: isDisplayCaption,
      captionOrder: captionOrder,
      captionAlignment: captionAlignment,
      font: font,
      fontSize: fontSize,
      // style: style,
      color: color,
      isNumberFormat: isDisplayCaption ? isNumberFormat : false,
      isBold: isBold,
      isItalic: isItalic,
      isUnderline: isUnderline,
      isStandard: isStandard,
      image_width: imageWidth,
      image_height: imageHeight,
    });

    // actions.updateDocumentElement
    actions.updateDocumentElementContent({
      section: props.section!,
      subSection: props.subSection,
      documentElement: { ...props.element, content_format: newParms },
    });
    if (props.sendData) {
      props.sendData({
        caption,
        imageSize,
        customSize,
        captionAlignment,
        isDisplayCaption,
        captionOrder,
        fontSize,
        font,
        style,
        justify,
        color,
        isNumberFormat,
        isBold,
        isStandard,
        isUnderline,
        isItalic,
      });
    }
    dispatch(clearSelectedComponent());
    props.closeDialogHandler();
    props.setJustify(justify);
  };

  const [selectedStyles, setSelectedStyles] = useState<string[]>(() => {
    const initialSelectedStyles: string[] = [];
    if (isStandard) {
      initialSelectedStyles.push("standard");
    }
    if (isBold) {
      initialSelectedStyles.push("bold");
    }
    if (isItalic) {
      initialSelectedStyles.push("italic");
    }
    if (isUnderline) {
      initialSelectedStyles.push("underline");
    }
    return initialSelectedStyles;
  });
  //  Implemention  for Style Check Boxes
  const handleStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    switch (name) {
      case "bold":
        setIsBold(checked);
        setIsStandard(false);
        setSelectedStyles((prevStyles) =>
          checked
            ? [
                ...prevStyles.slice(prevStyles.includes("standard") ? 1 : 0),
                name,
              ]
            : prevStyles.filter((style) => style !== name)
        );
        break;
      case "italic":
        setIsItalic(checked);
        setIsStandard(false);
        setSelectedStyles((prevStyles) =>
          checked
            ? [
                ...prevStyles.slice(prevStyles.includes("standard") ? 1 : 0),
                name,
              ]
            : prevStyles.filter((style) => style !== name)
        );
        break;
      case "underline":
        setIsUnderline(checked);
        setIsStandard(false);
        setSelectedStyles((prevStyles) =>
          checked
            ? [
                ...prevStyles.slice(prevStyles.includes("standard") ? 1 : 0),
                name,
              ]
            : prevStyles.filter((style) => style !== name)
        );
        break;
      case "standard":
        setIsStandard(checked);
        setIsBold(false);
        setIsItalic(false);
        setIsUnderline(false);
        setSelectedStyles(checked ? ["standard"] : []);
        break;
      default:
        break;
    }
  };

  const filterOptions = [
    { label: "Black and White", value: "grayscale(1)" },
    { label: "Vivid", value: "brightness(1.2) contrast(1.2)" },
    { label: "Sepia", value: "sepia(0.5)" },
    { label: "Invert", value: "invert(1)" },
    { label: "Blur", value: "blur(3px)" },
    { label: "Saturate", value: "saturate(2)" },
    { label: "Hue Rotate", value: "hue-rotate(90deg)" },
    { label: "Reset Filters", value: "Reset Filters" },
    // Add more filter options as needed
  ];

  const [selectedFilter, setSelectedFilter] = useState<string>("");

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setSelectedFilter(event.target.value);
  };

  const handleApplyFilter = () => {
    if (selectedFilter === "Reset Filters") {
      setFile(originalFile);
    } else if (file) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        context?.drawImage(img, 0, 0);

        const filteredCanvas = document.createElement("canvas");
        const filteredContext = filteredCanvas.getContext("2d");
        filteredCanvas.width = img.width;
        filteredCanvas.height = img.height;
        if (filteredContext) {
          filteredContext.filter = selectedFilter;
          filteredContext.drawImage(canvas, 0, 0);
        }

        filteredCanvas.toBlob((filteredBlob) => {
          if (filteredBlob) {
            const filteredFile = new File([filteredBlob], file.name);
            setFile(filteredFile);
          }
        }, file.type);
      };
    }
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={(e) => {
          dispatch(clearSelectedComponent());
          props.closeDialogHandler();
        }}
      >
        <DialogTitle>
          {file === null ? "Update" : "Upload"} Image Form
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FileUpload {...fileUploadProp} />
            </Grid>
            {file && (
              <Grid item xs={12}>
                <div>
                  {file && (
                    <img
                      src={URL.createObjectURL(file)}
                      alt="Filtered Image"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  )}
                  <Box sx={{ paddingTop: 1 }}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="filter-select">
                        Select Filter
                      </InputLabel>
                      <Select
                        value={selectedFilter}
                        onChange={handleFilterChange}
                        label="Select Filter"
                        inputProps={{
                          name: "filter",
                          id: "filter-select",
                        }}
                      >
                        <MenuItem value="">
                          <em>Select Filter</em>
                        </MenuItem>
                        {filterOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <Box sx={{ paddingTop: 1 }}>
                        <Button
                          onClick={handleApplyFilter}
                          disabled={!selectedFilter}
                          variant="contained"
                          color="primary"
                        >
                          Apply Filter
                        </Button>
                      </Box>
                    </FormControl>
                  </Box>
                </div>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="image-size">Image Size</InputLabel>
                <Select
                  value={imageSize}
                  onChange={(e) => setImageSize(e.target.value)}
                  fullWidth
                  labelId="image-size"
                  label="Image Size"
                >
                  <MenuItem value="Original">Original</MenuItem>
                  <MenuItem value="custom">Custom</MenuItem>
                  <MenuItem value="full Page">Full page</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {imageSize === "custom" && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    id="image-size-custom"
                    fullWidth
                    label="Custom Width Size(mm)*"
                    value={Number.isNaN(customSize) ? " " : customSize}
                    onChange={handleCustomSizeChange}
                    variant="outlined"
                    inputProps={{
                      pattern: "[1-9][0-9]*",
                      title: "Please enter a positive number greater than 0",
                    }}
                  />
                  {customSize > 150 && (
                    <Alert severity="warning">
                      The maximum allowed size is 150mm. Exceeding this limit
                      may cause issues.
                    </Alert>
                  )}
                </FormControl>
              </Grid>
            )}

            {imageSize != "full Page" && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="image-just">
                    Image Justification
                  </InputLabel>
                  <Select
                    value={justify}
                    onChange={(e) => setJustify(e.target.value)}
                    fullWidth
                    labelId="image-just"
                    label="Image Justification"
                  >
                    <MenuItem value="left">Left</MenuItem>
                    <MenuItem value="center">Center</MenuItem>
                    <MenuItem value="right">Right</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDisplayCaption}
                    onChange={(e) => setIsDisplayCaption(e.target.checked)}
                  />
                }
                label="Display Caption"
              />
              {isDisplayCaption && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isNumberFormat}
                      onChange={(e) => setIsNumberFormat(e.target.checked)}
                    />
                  }
                  label="Allow Number Format"
                />
              )}
            </Grid>
            {isDisplayCaption && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Image Caption"
                      value={caption}
                      onChange={(e) => setCaption(e.target.value)}
                      variant="outlined"
                      InputProps={{
                        // readOnly: isNumberFormat,
                        startAdornment: isNumberFormat && (
                          <InputAdornment position="start">
                            Figure {countImageElement}:
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      fullWidth
                      label="Placement"
                      value={captionOrder}
                      onChange={(e) => setCaptionOrder(e.target.value)}
                      variant="outlined"
                    >
                      {captionOrderList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      fullWidth
                      label="Alignment"
                      value={captionAlignment}
                      onChange={(e) => setCaptionAlignment(e.target.value)}
                      variant="outlined"
                    >
                      {captionAlignmentList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      value={fontFamilies.find(
                        (option) => option.value === font
                      )}
                      disablePortal
                      options={fontFamilies}
                      onChange={(event: any, newValue: any) => {
                        const selectedValue = newValue ? newValue.value : null;
                        setFont(selectedValue);
                      }}
                      renderInput={(params: any) => (
                        <TextField {...params} label="Font" />
                      )}
                      disableClearable
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{ fontFamily: findMatchingFont(option.value) }}
                        >
                          {option.label}
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      fullWidth
                      label="Color"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                      variant="outlined"
                    >
                      {colorOptions.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="style-select">Style</InputLabel>
                      <Select
                        labelId="style-label"
                        id="style-select"
                        label="Style"
                        variant="outlined"
                        multiple
                        value={selectedStyles}
                        renderValue={(selected) =>
                          (selected as string[]).join(", ")
                        }
                      >
                        <MenuItem>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isStandard}
                                name="standard"
                                onChange={handleStyleChange}
                              />
                            }
                            label="Standard"
                          />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isBold}
                                name="bold"
                                onChange={handleStyleChange}
                              />
                            }
                            label="Bold"
                          />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isItalic}
                                name="italic"
                                onChange={handleStyleChange}
                              />
                            }
                            label="Italic"
                          />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isUnderline}
                                name="underline"
                                onChange={handleStyleChange}
                              />
                            }
                            label="Underline"
                          />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="image-size">Font Size</InputLabel>
                      <Select
                        value={fontSize}
                        onChange={(e) => setFontsize(e.target.value)}
                        fullWidth
                        labelId="FontSize"
                        label="FontSize"
                      >
                        <MenuItem value="tiny">6pt</MenuItem>
                        <MenuItem value="scriptsize">7pt</MenuItem>
                        <MenuItem value="footnotesize">8pt</MenuItem>
                        <MenuItem value="small">9pt</MenuItem>
                        <MenuItem value="normalsize">10pt</MenuItem>
                        <MenuItem value="large">12pt</MenuItem>
                        <MenuItem value="Large">14pt</MenuItem>
                        <MenuItem value="LARGE">16pt</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {file !== null && (
          <Typography sx={{ p: 2 }}> File Name: {file.name}</Typography>
        )}
        <DialogActions>
          <Button
            variant="outlined"
            onClick={(e) => {
              dispatch(clearSelectedComponent());
              props.closeDialogHandler();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={file === null ? handleUpdateImage : handleUploadImage}
          >
            {file === null ? "Update" : "Upload"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React, { useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import TableRowsIcon from "@mui/icons-material/TableRows";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FormatSizeIcon from "@mui/icons-material/FormatSize";

import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import { ChromePicker } from "react-color";
import {
  Box,
  Button,
  Input,
  InputAdornment,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import LowPriorityIcon from "@mui/icons-material/LowPriority";

import FontSizeSelector from "../../../../shared/UtilitesComponents/FontSizeSelector";
import FontSizeDailog from "./FontSizeDialog";
import { getCellStyle } from "../HelperFuctions/TableUtils";

export const NestedContextMenu = (props: {
  options: any[];
  handleContextMenuAction: any;
  contextMenu: any;
  formatting?: any[][];
}) => {
  const cellFormatting = getCellStyle(
    props.contextMenu.cell.row,
    props.contextMenu.cell.column,
    props.formatting
  );

  const [selectedFontSize, setSelectedFontSize] = useState<number>(
    cellFormatting.columnWidth && cellFormatting.columnWidth != "none"
      ? parseInt(cellFormatting.columnWidth) // Assuming the columnWidth is a string
      : 0
  );

  const [selectedColor, setSelectedColor] = useState<string>(
    cellFormatting.backgroundColor
      ? "#" + cellFormatting.backgroundColor
      : "#000000"
  );

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [subHoveredIndex, setSubHoveredIndex] = useState<number | null>(null);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [fontPickerOpen, setFontPickerOpen] = useState(false);

  const [colorPickerAction, setColorPickerAction] = useState<string>("");

  const options: any[] = [
    {
      label: "Insert",
      icon: <AddIcon />,
      options: [
        {
          label: "Insert Row Above",
          icon: <AddIcon />,
          action: () => props.handleContextMenuAction("Insert Row Above"),
        },
        {
          label: "Insert Row Below",
          icon: <AddIcon />,
          action: () => props.handleContextMenuAction("Insert Row Below"),
        },
        {
          label: "Insert Column to the Left",
          icon: <AddIcon />,
          action: () => props.handleContextMenuAction("Insert Column Left"),
        },
        {
          label: "Insert Column to the Right",
          icon: <AddIcon />,
          action: () => props.handleContextMenuAction("Insert Column Right"),
        },
      ],
    },

    {
      label: "Duplicate",
      icon: <ContentCopyIcon />,
      options: [
        {
          label: "Duplicate Row Above",
          icon: <ContentCopyIcon />,
          action: () => props.handleContextMenuAction("Duplicate Row Above"),
        },
        {
          label: "Duplicate Row Below",
          icon: <ContentCopyIcon />,
          action: () => props.handleContextMenuAction("Duplicate Row Below"),
        },
        {
          label: "Duplicate Column to the Left",
          icon: <ContentCopyIcon />,
          action: () => props.handleContextMenuAction("Duplicate Column Left"),
        },
        {
          label: "Duplicate Column to the Right",
          icon: <ContentCopyIcon />,
          action: () => props.handleContextMenuAction("Duplicate Column Right"),
        },
      ],
    },
    {
      label: "Move",
      icon: <LowPriorityIcon />,
      options: [
        {
          label: "Move Row Above",
          icon: <LowPriorityIcon />,
          action: () => props.handleContextMenuAction("Move Row Above"),
        },
        {
          label: "Move Row Below",
          icon: <LowPriorityIcon />,
          action: () => props.handleContextMenuAction("Move Row Below"),
        },
        {
          label: "Move Column to the Left",
          icon: <LowPriorityIcon />,
          action: () => props.handleContextMenuAction("Move Column Left"),
        },
        {
          label: "Move Column to the Right",
          icon: <LowPriorityIcon />,
          action: () => props.handleContextMenuAction("Move Column Right"),
        },
      ],
    },

    {
      label: "Delete Cell(s)",
      icon: <DeleteIcon />,
      options: [
        {
          label: "Delete Entire Row",
          icon: <TableRowsIcon />,
          action: () => props.handleContextMenuAction("Delete Entire Row"),
        },
        {
          label: "Delete Entire Column",
          icon: <ViewColumnIcon />,
          action: () => props.handleContextMenuAction("Delete Entire Column"),
        },
      ],
    },

    {
      label: "Background Color",
      icon: <FormatColorFillIcon />,
      options: [
        {
          label: "Change Cell Color",
          icon: <FormatColorFillIcon />,
          action: () => props.handleContextMenuAction("Change Cell Color"),
        },
        {
          label: "Change Entire Row Color",
          icon: <FormatColorFillIcon />,
          action: () =>
            props.handleContextMenuAction("Change Entire Row Color"),
        },
        {
          label: "Change Entire Column Color",
          icon: <FormatColorFillIcon />,
          action: () =>
            props.handleContextMenuAction("Change Entire Column Color"),
        },
      ],
    },
    {
      label: "Content Color",
      icon: <FormatColorTextIcon />,
      options: [
        {
          label: "Cell Content Color",
          icon: <FormatColorTextIcon />,
          action: () => props.handleContextMenuAction("Change Cell Text Color"),
        },
        {
          label: "Entire Row Content Color",
          icon: <FormatColorTextIcon />,
          action: () =>
            props.handleContextMenuAction("Change Entire Row Text Color"),
        },
        {
          label: "Entire Column Content Color",
          icon: <FormatColorTextIcon />,
          action: () =>
            props.handleContextMenuAction("Change Entire Column Text Color"),
        },
      ],
    },
    {
      label: "Column Width",
      icon: <FormatSizeIcon />,

      options: [
        {
          label: "Custom Width",
          icon: <FormatColorTextIcon />,
          action: () => props.handleContextMenuAction("Custom Width"),
        },
        {
          label: "Reset To Default",
          icon: <FormatColorTextIcon />,
          action: () => props.handleContextMenuAction("Column Width", "none"),
        },
        {
          label: "5mm",
          icon: <FormatColorTextIcon />,
          action: () => props.handleContextMenuAction("Column Width", "5"),
        },
        {
          label: "10mm",
          icon: <FormatColorTextIcon />,
          action: () => props.handleContextMenuAction("Column Width", "10"),
        },
        {
          label: "15mm",
          icon: <FormatColorTextIcon />,
          action: () => props.handleContextMenuAction("Column Width", "15"),
        },
      ],
    },
    // {
    //   label: "Font Size",
    //   icon: <FormatSizeIcon />,
    //   // options: [
    //   //   {
    //   //     label: "Cell Font Size",
    //   //     icon: <FormatSizeIcon />,
    //   //     action: () => props.handleContextMenuAction("Change Cell Font Size"),
    //   //     options: [
    //   //       {
    //   //         label: "12pt",
    //   //         icon: <FormatSizeIcon />,
    //   //         action: () =>
    //   //           props.handleContextMenuAction("Change Cell Font Size"),
    //   //       },
    //   //       {
    //   //         label: "13pt",
    //   //         icon: <FormatSizeIcon />,
    //   //         action: () =>
    //   //           props.handleContextMenuAction("Change Entire Row Font Size"),
    //   //       },
    //   //       {
    //   //         label: "14pt",
    //   //         icon: <FormatSizeIcon />,
    //   //         action: () =>
    //   //           props.handleContextMenuAction("Change Entire Column Font Size"),
    //   //       },
    //   //     ],
    //   //   },
    //   //   {
    //   //     label: "Entire Row Font Size",
    //   //     icon: <FormatSizeIcon />,
    //   //     action: () =>
    //   //       props.handleContextMenuAction("Change Entire Row Font Size"),
    //   //     options: [
    //   //       {
    //   //         label: "Cell Font Size",
    //   //         icon: <FormatSizeIcon />,
    //   //         action: () =>
    //   //           props.handleContextMenuAction("Change Cell Font Size"),
    //   //       },
    //   //       {
    //   //         label: "Entire Row Font Size",
    //   //         icon: <FormatSizeIcon />,
    //   //         action: () =>
    //   //           props.handleContextMenuAction("Change Entire Row Font Size"),
    //   //       },
    //   //       {
    //   //         label: "Entire Column Font Size",
    //   //         icon: <FormatSizeIcon />,
    //   //         action: () =>
    //   //           props.handleContextMenuAction("Change Entire Column Font Size"),
    //   //       },
    //   //     ],
    //   //   },
    //   //   {
    //   //     label: "Entire Column Font Size",
    //   //     icon: <FormatSizeIcon />,
    //   //     action: () =>
    //   //       props.handleContextMenuAction("Change Entire Column Font Size"),
    //   //     options: [
    //   //       {
    //   //         label: "Cell Font Size",
    //   //         icon: <FormatSizeIcon />,
    //   //         action: () =>
    //   //           props.handleContextMenuAction("Change Cell Font Size"),
    //   //       },
    //   //       {
    //   //         label: "Entire Row Font Size",
    //   //         icon: <FormatSizeIcon />,
    //   //         action: () =>
    //   //           props.handleContextMenuAction("Change Entire Row Font Size"),
    //   //       },
    //   //       {
    //   //         label: "Entire Column Font Size",
    //   //         icon: <FormatSizeIcon />,
    //   //         action: () =>
    //   //           props.handleContextMenuAction("Change Entire Column Font Size"),
    //   //       },
    //   //     ],
    //   //   },
    //   // ],

    // },

    {
      label: "Row Alignment",
      icon: <TableRowsIcon />,
      options: [
        {
          label: "Align Left",
          icon: <FormatAlignLeftIcon />,
          action: () =>
            props.handleContextMenuAction("Row Align Left", "Alignmnet"),
        },
        {
          label: "Align Center",
          icon: <FormatAlignCenterIcon />,
          action: () =>
            props.handleContextMenuAction("Row Align Center", "Alignmnet"),
        },
        {
          label: "Align Right",
          icon: <FormatAlignRightIcon />,
          action: () =>
            props.handleContextMenuAction("Row Align Right", "Alignmnet"),
        },
      ],
    },
    {
      label: "Column Alignment",
      icon: <ViewColumnIcon />,
      options: [
        {
          label: "Align Left",
          icon: <FormatAlignLeftIcon />,
          action: () =>
            props.handleContextMenuAction("Column Align Left", "Alignmnet"),
        },
        {
          label: "Align Center",
          icon: <FormatAlignCenterIcon />,
          action: () =>
            props.handleContextMenuAction("Column Align Center", "Alignmnet"),
        },
        {
          label: "Align Right",
          icon: <FormatAlignRightIcon />,
          action: () =>
            props.handleContextMenuAction("Column Align Right", "Alignmnet"),
        },
      ],
    },
  ];

  const handleColorPicker = (
    color: any,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setSelectedColor(color.hex);
    event.stopPropagation();
  };

  const handleColorPickerClose = (event: React.MouseEvent) => {
    handleCloseContext()
    event.preventDefault();
    setColorPickerOpen(false);
    setColorPickerAction("");
    handleCloseContext()
    event.stopPropagation();
   
  };

  const handleColorPickerSave = (event: React.MouseEvent) => {
    event.preventDefault();
    props.handleContextMenuAction(
      colorPickerAction,
      selectedColor.substring(1)
    );
    setColorPickerOpen(false);
    setColorPickerAction("");
    event.stopPropagation();
    
  };

  const handleColumnWidthSave = (event: React.MouseEvent) => {
    event.preventDefault();
    props.handleContextMenuAction(
      colorPickerAction,
      selectedFontSize.toString()
    );
    setColorPickerOpen(false);
    setColorPickerAction("");
    setSelectedFontSize(0);
    event.stopPropagation();
  };

  const handleCloseContext = () => {
    props.handleContextMenuAction("Close");
  };

  const handleColorPickerOpen = (action: string) => {
    setColorPickerAction(action);
    setColorPickerOpen((prevState: any) => !prevState);
    setHoveredIndex(null);
  };

  const handleFontPickerOpen = (action: string) => {
    setColorPickerAction(action);
    setFontPickerOpen((prevState: any) => !prevState);
    setHoveredIndex(null);
  };

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleInputBlur = (event: any) => {
    // Prevent the onBlur event from propagating to the parent component
    event.preventDefault();
    event.stopPropagation();
  };

  const renderSubMenu = (
    subMenuOptions: any[],
    parentIndex: number,
    lastIndex: boolean
  ) => (
    <ul
      style={{
        color: "black !important",
        listStyle: "none",
        padding: 0,
        margin: 0,
        paddingLeft: 2,
        paddingTop: 15,
        position: "absolute",
        // position: "relative",
        top: parentIndex === 0 ? 0 : lastIndex || parentIndex == 6 ? -120 : -40,
        left: "100%",
        // display: "flex",
        alignItems: "center",
        zIndex: 1,
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      {subMenuOptions.map((option: any, index: number) => (
        <li
          key={index}
          onMouseDown={(e) => e.preventDefault()}
          onClick={(e) => {
            e.preventDefault();
            if (
              option.label === "Custom Width" ||
              option.label === "Entire Row Font Size" ||
              option.label === "Entire Column Font Size"
            ) {
              option.action(); // Call the action associated with the clicked option
              handleFontPickerOpen("Column Width");
              option.action(); // Call the action associated with the clicked option
            } else if (
              option.label === "Cell Content Color" ||
              option.label === "Entire Row Content Color" ||
              option.label === "Entire Column Content Color"
            ) {
              props.handleContextMenuAction("Custom Width")
              setSelectedColor(
                cellFormatting.textColor
                  ? "#" + cellFormatting.textColor
                  : "#000000"
              );
              handleColorPickerOpen(option.label);
            } else if (
              option.label === "Change Cell Color" ||
              option.label === "Change Entire Row Color" ||
              option.label === "Change Entire Column Color"
            ) {
              props.handleContextMenuAction("Custom Width")
              setSelectedColor(
                cellFormatting.backgroundColor
                  ? "#" + cellFormatting.backgroundColor
                  : "#000000"
              );
              handleColorPickerOpen(option.label);
            } else {
              // props.handleContextMenuAction(option.label)
              option.action(); // Call the action associated with the clicked option
            }

            e.stopPropagation();
          }}
          style={{
            cursor: "pointer",
            padding: "8px",
            color: "black !important",
            borderBottom:
              index !== subMenuOptions.length - 1 ? "1px solid #eee" : "none",
            transition: "background-color 0.3s",
            whiteSpace: "nowrap",
            backgroundColor: "white",
            boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
            position: "relative",
            display: "flex",
            // justifyContent: 'space-between',
            alignItems: "center",
          }}
          onMouseEnter={(e) => {
            setSubHoveredIndex(index);
            // e.preventDefault()
            (e.currentTarget as HTMLLIElement).style.backgroundColor =
              "#f4f4f4";
          }}
          onMouseLeave={(e) => {
            setSubHoveredIndex(null);
            e.preventDefault();
            (e.currentTarget as HTMLLIElement).style.backgroundColor = "white";
          }}
        >
          {option.icon && (
            <div style={{ marginRight: "8px" }}>{option.icon}</div>
          )}
          {option.label}
          {option.options && (
            <div style={{ marginLeft: "8px", color: "black !important" }}>
              {subHoveredIndex === index ? (
                <ArrowRightIcon />
              ) : (
                <ArrowDropDownIcon />
              )}
            </div>
          )}

          {/* {option.options &&
            subHoveredIndex === index &&
            renderSubMenu(option.options)} */}
          {/* {option.label} */}
        </li>
      ))}
    </ul>
  );

  return (
    <div style={{ color: "black !important" }}>
      {options.map((option: any, index: any) => (
        <div
          key={index}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseEnter={(e) => {
            e.preventDefault();
            handleMouseEnter(index);
            e.currentTarget.style.backgroundColor = "#f4f4f4";
            e.stopPropagation();
          }}
          onMouseLeave={(e) => {
            e.preventDefault();
            handleMouseLeave();
            e.currentTarget.style.backgroundColor = "white";
            e.stopPropagation();
          }}
          style={{
            position: "relative",
            cursor: "pointer",
            padding: "4px",

            borderBottom:
              index !== props.options.length - 1 ? "1px solid #eee" : "none",
            transition: "background-color 0.3s",
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
            color: "black !important",
          }}
          // onClick={(e)=>{
          //   e.preventDefault();
          //   e.stopPropagation()
          //   if(option.label==="Font Size"){

          //     setFontPickerOpen(true)
          //   }

          // }}
        >
          {option.icon && (
            <div style={{ marginRight: "8px", color: "black !important" }}>
              {option.icon}
            </div>
          )}
          <div style={{ flex: 1, fontSize: "16px", color: "black !important" }}>
            {option.label}
          </div>
          {option.options && (
            <div style={{ marginLeft: "8px", color: "black !important" }}>
              {hoveredIndex === index ? (
                <ArrowRightIcon />
              ) : (
                <ArrowDropDownIcon />
              )}
            </div>
          )}
          {hoveredIndex === index &&
            option.options &&
            !props.contextMenu.isOpen &&
            renderSubMenu(option.options, index, index === options.length - 1)}
        </div>
      ))}
      {fontPickerOpen && (
        <div
          style={{
            position: "absolute",
            top: props.contextMenu.position.y - 220,

            paddingLeft: "215px",
            paddingBottom: "150px",
          }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <Box
            style={{
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
              borderRadius: "1px",
              padding: "10px",
              background: "#fff",
              position: "absolute",
            }}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <Typography gutterBottom>Column Width</Typography>
            <TextField
              sx={{ zIndex: 10000 }}
              type="number"
              value={selectedFontSize}
              onMouseEnter={handleInputBlur}
              onFocus={handleInputBlur}
              onBlur={handleInputBlur}
              onChange={(event) => {
                event.preventDefault();
                const inputValue = parseFloat(event.target.value);
                if (!isNaN(inputValue) && inputValue >= 0) {
                setSelectedFontSize(Number(event.target.value)); // Convert the value to a number
                event.stopPropagation();
                }
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">mm</InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <Button
                fullWidth
                onClick={handleColumnWidthSave}
                onMouseDown={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
                style={{ paddingRight: "10px" }}
              >
                Save
              </Button>
              <Button
                fullWidth
                onClick={handleCloseContext}
                style={{ paddingLeft: "10px" }}
              >
                Close
              </Button>
            </div>
          </Box>
        </div>
      )}

      {colorPickerOpen && (
        <div
          style={{
            position: "absolute",
            top: props.contextMenu.position.y - 300,

            //    top:  "30vh" ,
            //   left: `${props.contextMenu.position.x}px`,
            zIndex: 10000,
            paddingLeft: "180px",
            paddingBottom: "200px",
          }}
        >
          <Box
            style={{
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
              borderRadius: "1px",
              padding: "10px",
              background: "#fff",
              position: "absolute",
              zIndex: 10000,
            }}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <ChromePicker color={selectedColor} onChange={handleColorPicker} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <Button
                fullWidth
                onClick={handleColorPickerSave}
                onMouseDown={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
                style={{ paddingRight: "10px" }}
              >
                Save
              </Button>
              <Button
                fullWidth
                onClick={handleColorPickerClose}
                style={{ paddingLeft: "10px" }}
              >
                Close
              </Button>
            </div>
          </Box>
        </div>
      )}
    </div>
  );
};

const ContextMenu = (props: {
  contextMenu: any;
  handleContextMenuAction: any;
  formatting?: any[][];
  handleClose?: any;
}) => {
  const options: any[] = [
    {
      label: "Insert",
      icon: <AddIcon />,
      options: [
        {
          label: "Insert Row Above",
          icon: <AddIcon />,
          action: () => props.handleContextMenuAction("Insert Row Above"),
        },
        {
          label: "Insert Row Below",
          icon: <AddIcon />,
          action: () => props.handleContextMenuAction("Insert Row Below"),
        },
        {
          label: "Insert Column to the Left",
          icon: <AddIcon />,
          action: () => props.handleContextMenuAction("Insert Column Left"),
        },
        {
          label: "Insert Column to the Right",
          icon: <AddIcon />,
          action: () => props.handleContextMenuAction("Insert Column Right"),
        },
      ],
    },

    {
      label: "Duplicate",
      icon: <ContentCopyIcon />,
      options: [
        {
          label: "Duplicate Row Above",
          icon: <ContentCopyIcon />,
          action: () => props.handleContextMenuAction("Duplicate Row Above"),
        },
        {
          label: "Duplicate Row Below",
          icon: <ContentCopyIcon />,
          action: () => props.handleContextMenuAction("Duplicate Row Below"),
        },
        {
          label: "Duplicate Column to the Left",
          icon: <ContentCopyIcon />,
          action: () => props.handleContextMenuAction("Duplicate Column Left"),
        },
        {
          label: "Duplicate Column to the Right",
          icon: <ContentCopyIcon />,
          action: () => props.handleContextMenuAction("Duplicate Column Right"),
        },
      ],
    },
    {
      label: "Move",
      icon: <LowPriorityIcon />,
      options: [
        {
          label: "Move Row Above",
          icon: <LowPriorityIcon />,
          action: () => props.handleContextMenuAction("Move Row Above"),
        },
        {
          label: "Move Row Below",
          icon: <LowPriorityIcon />,
          action: () => props.handleContextMenuAction("Move Row Below"),
        },
        {
          label: "Move Column to the Left",
          icon: <LowPriorityIcon />,
          action: () => props.handleContextMenuAction("Move Column Left"),
        },
        {
          label: "Move Column to the Right",
          icon: <LowPriorityIcon />,
          action: () => props.handleContextMenuAction("Move Column Right"),
        },
      ],
    },

    {
      label: "Delete Cell(s)",
      icon: <DeleteIcon />,
      options: [
        {
          label: "Delete Entire Row",
          icon: <TableRowsIcon />,
          action: () => props.handleContextMenuAction("Delete Entire Row"),
        },
        {
          label: "Delete Entire Column",
          icon: <ViewColumnIcon />,
          action: () => props.handleContextMenuAction("Delete Entire Column"),
        },
      ],
    },

    {
      label: "Background Color",
      icon: <FormatColorFillIcon />,
      options: [
        {
          label: "Change Cell Color",
          icon: <FormatColorFillIcon />,
          action: () => props.handleContextMenuAction("Change Cell Color"),
        },
        {
          label: "Change Entire Row Color",
          icon: <FormatColorFillIcon />,
          action: () =>
            props.handleContextMenuAction("Change Entire Row Color"),
        },
        {
          label: "Change Entire Column Color",
          icon: <FormatColorFillIcon />,
          action: () =>
            props.handleContextMenuAction("Change Entire Column Color"),
        },
      ],
    },
    {
      label: "Content Color",
      icon: <FormatColorTextIcon />,
      options: [
        {
          label: "Cell Content Color",
          icon: <FormatColorTextIcon />,
          action: () => props.handleContextMenuAction("Change Cell Text Color"),
        },
        {
          label: "Entire Row Content Color",
          icon: <FormatColorTextIcon />,
          action: () =>
            props.handleContextMenuAction("Change Entire Row Text Color"),
        },
        {
          label: "Entire Column Content Color",
          icon: <FormatColorTextIcon />,
          action: () =>
            props.handleContextMenuAction("Change Entire Column Text Color"),
        },
      ],
    },

    {
      label: "Column Width ",
      icon: <FormatSizeIcon />,

      options: [
        {
          label: "Cell Content Color",
          icon: <FormatColorTextIcon />,
          action: () => props.handleContextMenuAction("Change Cell Text Color"),
        },
        {
          label: "Entire Row Content Color",
          icon: <FormatColorTextIcon />,
          action: () =>
            props.handleContextMenuAction("Change Entire Row Text Color"),
        },
        {
          label: "Entire Column Content Color",
          icon: <FormatColorTextIcon />,
          action: () =>
            props.handleContextMenuAction("Change Entire Column Text Color"),
        },
      ],

      // options: [
      //   {
      //     label: "Cell Font Size",
      //     icon: <FormatSizeIcon />,
      //     action: () => props.handleContextMenuAction("Change Cell Font Size"),
      //     options: [
      //       {
      //         label: "12pt",
      //         icon: <FormatSizeIcon />,
      //         action: () =>
      //           props.handleContextMenuAction("Change Cell Font Size"),
      //       },
      //       {
      //         label: "13pt",
      //         icon: <FormatSizeIcon />,
      //         action: () =>
      //           props.handleContextMenuAction("Change Entire Row Font Size"),
      //       },
      //       {
      //         label: "14pt",
      //         icon: <FormatSizeIcon />,
      //         action: () =>
      //           props.handleContextMenuAction("Change Entire Column Font Size"),
      //       },
      //     ],
      //   },
      //   {
      //     label: "Entire Row Font Size",
      //     icon: <FormatSizeIcon />,
      //     action: () =>
      //       props.handleContextMenuAction("Change Entire Row Font Size"),
      //     options: [
      //       {
      //         label: "Cell Font Size",
      //         icon: <FormatSizeIcon />,
      //         action: () =>
      //           props.handleContextMenuAction("Change Cell Font Size"),
      //       },
      //       {
      //         label: "Entire Row Font Size",
      //         icon: <FormatSizeIcon />,
      //         action: () =>
      //           props.handleContextMenuAction("Change Entire Row Font Size"),
      //       },
      //       {
      //         label: "Entire Column Font Size",
      //         icon: <FormatSizeIcon />,
      //         action: () =>
      //           props.handleContextMenuAction("Change Entire Column Font Size"),
      //       },
      //     ],
      //   },
      //   {
      //     label: "Entire Column Font Size",
      //     icon: <FormatSizeIcon />,
      //     action: () =>
      //       props.handleContextMenuAction("Change Entire Column Font Size"),
      //     options: [
      //       {
      //         label: "Cell Font Size",
      //         icon: <FormatSizeIcon />,
      //         action: () =>
      //           props.handleContextMenuAction("Change Cell Font Size"),
      //       },
      //       {
      //         label: "Entire Row Font Size",
      //         icon: <FormatSizeIcon />,
      //         action: () =>
      //           props.handleContextMenuAction("Change Entire Row Font Size"),
      //       },
      //       {
      //         label: "Entire Column Font Size",
      //         icon: <FormatSizeIcon />,
      //         action: () =>
      //           props.handleContextMenuAction("Change Entire Column Font Size"),
      //       },
      //     ],
      //   },
      // ],
    },

    {
      label: "Row Alignment",
      icon: <TableRowsIcon />,
      options: [
        {
          label: "Align Left",
          icon: <FormatAlignLeftIcon />,
          action: () =>
            props.handleContextMenuAction("Row Align Left", "Alignmnet"),
        },
        {
          label: "Align Center",
          icon: <FormatAlignCenterIcon />,
          action: () =>
            props.handleContextMenuAction("Row Align Center", "Alignmnet"),
        },
        {
          label: "Align Right",
          icon: <FormatAlignRightIcon />,
          action: () =>
            props.handleContextMenuAction("Row Align Right", "Alignmnet"),
        },
      ],
    },
    {
      label: "Column Alignment",
      icon: <ViewColumnIcon />,
      options: [
        {
          label: "Align Left",
          icon: <FormatAlignLeftIcon />,
          action: () =>
            props.handleContextMenuAction("Column Align Left", "Alignmnet"),
        },
        {
          label: "Align Center",
          icon: <FormatAlignCenterIcon />,
          action: () =>
            props.handleContextMenuAction("Column Align Center", "Alignmnet"),
        },
        {
          label: "Align Right",
          icon: <FormatAlignRightIcon />,
          action: () =>
            props.handleContextMenuAction("Column Align Right", "Alignmnet"),
        },
      ],
    },
  ];

  return (
    <div
      style={{
        position: "absolute",
        top: props.contextMenu.position.y,
        left: props.contextMenu.position.x,
        border: "1px solid #ccc",
        borderRadius: "5px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
        zIndex: 999999,
        minWidth: "120px",
        color: "black !important",
      }}
      onBlur={() => props.handleClose()}
    >
      {props.contextMenu.visible && (
        <NestedContextMenu
          options={options}
          handleContextMenuAction={props.handleContextMenuAction}
          contextMenu={props.contextMenu}
          formatting={props.formatting}
        />
      )}
    </div>
  );
};

export default ContextMenu;

import * as React from "react";
import { BrowserRouter as Route, Router } from "react-router-dom";
import { CSSProperties } from "@material-ui/styles"; // Import CSSProperties
import { HeaderMenu } from "./features/headermenu/HeaderMenu";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import Library from "./features/library/Library";

import useActions, { useAppSelector } from "./app/hooks";
import { selectEditingDocumentTemplate } from "./features/editor/editorSlice";
import { selectAuth, selectUser, setUser } from "./features/auth/authSlice";

import EditorContainer from "./features/editor/EditorContainer";
import SignInModal from "./features/auth/SignInModal";

import ResetModal from "./features/auth/ResetModal";
import UpdateUserDetailsModal from "./features/auth/updateUserDetailsModal";
import ChangePasswordModal from "./features/auth/ChangePasswordModal";

import "./App.css";

import { ThemeProvider } from "@mui/material/styles";
import { nomiaTheme } from "./theme";
import { Hidden } from "@mui/material";
import { getLocalUserState, getRememberMeState } from "./services/userAPI";

function getParameterByName(name: string, url = window.location.href) {
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function App() {
  const authState = useAppSelector(selectAuth);
  const rememberMeState= getRememberMeState()
  const userData = useAppSelector(selectUser);
  const actions = useActions();
  //TODO: Implement a better state management
  const selectedDocumentTemplate = useAppSelector(
    selectEditingDocumentTemplate
  );

  // For password reset
  if (window.location.pathname == "/reset") {
    const token = getParameterByName("token");
    if (token !== null) {
      return <ResetModal authState={authState} token={token}></ResetModal>;
    } else {
      (window as any).location = "/";
    }
  }

  if(authState.status === "logged-out") {
    return (<SignInModal authState={authState} open={true}></SignInModal>)
  }
  
  // if (authState.status === "change-password") {
  //   if (userData !== null) {
  //     return (
  //       <ChangePasswordModal
  //         email={userData.email}
  //         authState={authState}
  //       ></ChangePasswordModal>
  //     );
  //   }
  // }


  if (authState.status === "change-password") {
    if (userData !== null) {
      return (
        <UpdateUserDetailsModal 
        email={userData.email}
        authState={authState}></UpdateUserDetailsModal>
      );
    }
  }

  


  // when ever user refresh/reload the page it check the local storage if user exist refresh the all the organizations.
  //todo? check if existing token expires?
  if (authState.status  === "logged-in" && rememberMeState &&  authState.organizations === null) {
    actions.getOrganizations();
  }

  //Home Screen
  const library =
    authState.status === "logged-in" ? (
      <Box>
        <Library></Library>
      </Box>
    ) : null;


  return (
    // <ThemeProvider theme={nomiaTheme} >
    <Box sx={{ bgColor: grey[200] }}>
      <CssBaseline />
      <HeaderMenu></HeaderMenu>
      {selectedDocumentTemplate !== null ? <EditorContainer /> : library}
    </Box>
    //  </ThemeProvider>

    //   <Box sx={{ bgColor: grey[200] }}>
    //   <CssBaseline />
    //   <HeaderMenu></HeaderMenu>
    //   <Router>
    //     <Route path="/">
    //       {selectedDocumentTemplate !== null ? (
    //         <EditorContainer />
    //       ) : authState.status === "logged-in" ? (
    //         <Box>
    //           <Library></Library>
    //         </Box>
    //       ) : (
    //         <SignInModal authState={authState} open={true}></SignInModal>
    //       )}
    //     </Route>
    //   </Router>
    // </Box>
  );
}

export default App;

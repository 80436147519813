import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useActions, { useAppSelector } from "../../app/hooks";
import { AuthState, logout } from "./authSlice";
import { Warning } from "@mui/icons-material";
import Alert from "@mui/material/Alert";

import logo from "../../nomia-logo2.png";

import { useSnackbar } from "notistack";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://nomia.co.za/">
        Nomia Pty. Ltd.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function ResetModal(props: {
  authState: AuthState;
  token: string;
}) {
  const actions = useActions();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [password, setPassword] = React.useState<string>("");

  const handleFinalizeReset = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const resetData = {
      password: password,
      token: props.token
    };
    actions.finalizeReset(resetData);
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog  style={{background: "#000000"}}
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
            <img src={logo} width="200px" alt="nomiaLogo"/>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
        
          <Box
            component="form"
            noValidate
            onSubmit={handleFinalizeReset}
            sx={{ mt: 1, width: 400 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              type="password"
              id="password"
              label="New Password"
              name="password"
              autoFocus
              onChange={ (event) => setPassword(event.target.value)}
            />
            {props.authState.error !== undefined ? (
              <Alert severity="error">
                {props.authState.error}.
              </Alert>
            ) : null}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </Button>
          </Box> 
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

import { client } from "./client";
import { User } from "../types/User";
import { Password } from "@mui/icons-material";

export function apiLogin(user: string | null, pass: string | null) {
  const headers = {
    "Content-Type": "application/json",
  };
  
  const body = {
    email: user,
    password: pass,   
  };
  return client.post("/login", body, { headers: headers } ,  true);
}
export function apiSignup( body:any ) {
  const headers = {
    "Content-Type": "application/json",
  };
  return client.post("/signup", body, { headers: headers } ,  true);
}



export function apiGenerateOTP( body:any ) {
  const headers = {
    "Content-Type": "application/json",
  };
  return client.post("/generate_otp", body, { headers: headers } ,  true);
}

export function apiVerifyOTP( body:any ) {
  const headers = {
    "Content-Type": "application/json",
  };
  return client.post("/verify_otp", body, { headers: headers } ,  true);
}


export function apiPasswordReset(user: string | null) {
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    email: user,
  };
  return client.post("/password_reset", body, { headers: headers });
}

export function apiPasswordResetFinalize(
  password: string | null,
  token: string | null
) {
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    password: password,
    token: token,
  };
  return client.post("/password_reset_finalize", body, { headers: headers });
}


export function apiPasswordResetFinalizeWithUserDetails(
userDetails:any
) {
  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    email: userDetails.userDetails.email,
    password: userDetails.userDetails.password,
    first_name:userDetails.userDetails.first_name,
    last_name:userDetails.userDetails.last_name,
    timezone:userDetails.userDetails.timezone
  };
  return client.post("/password_reset_finalize_with_bio", body, { headers: headers });
}

export function apiChangePassword(
  password: string | null,
  token: string | null
) {
  const body = {
    password: password,
    token: token,
  };

  return client.post("/change_password", body);
}

export async function apiGetUserRole(
  organizationId: number,
  workspaceId: number,
  userId: number
) {
  try {
    const response = await client.get(
      `/organizations/${organizationId}/users/${userId}/user_role`
    );
    return response.data;
// Result will be Only 
// {"user_role":"NORMAL"}
// {"user_role":"ADMIN"}

  } catch (error) {
    console.error("Error retrieving User Role:", error);
  }
}

export function getLocalUserState(): User | null {
  const userState = window.localStorage.getItem("authState");
  return userState != null ? JSON.parse(userState) : null;
}


export function setTokenExpiration() {
  const expirationTime = new Date().getTime() + 1 * 24 * 60 * 60 * 1000; // 1 days
  localStorage.setItem('tokenExpiration', expirationTime.toString());
}

export function getLocalUserToken(): string {
  const userState = window.localStorage.getItem("authState");
  return userState != null ? JSON.parse(userState).token : null;
  
}

export function setLocalUserState(userState: User) {
  window.localStorage.setItem("authState", JSON.stringify(userState));
  setTokenExpiration()
}

export function getSessionExpired() {
  const expirationTime = localStorage.getItem('tokenExpiration');
  if (!expirationTime) return true;
  
  return new Date().getTime() > parseInt(expirationTime);
}

export function clearLocalUserState() {
  clearExpireSession()
  window.localStorage.removeItem("authState");
}

export function clearExpireSession() {
  localStorage.removeItem('tokenExpiration');
}


export function setRememberMeState(check:boolean){
  window.localStorage.setItem("remberUserState", JSON.stringify({rememberUser:check}))
}
export function getRememberMeState(){
  const state = window.localStorage.getItem("remberUserState");
  return state != null ? JSON.parse(state).rememberUser : false;

}
export function clearRememberMeState(){
  window.localStorage.removeItem("remberUserState")
}
import React from 'react';
import {
  FormControl,
  TextField,
  InputAdornment,
  InputLabel,
} from '@mui/material';

interface FontSizeInputProps {
  value: any;
  label: string;
  onChange: (value: any) => void;
  min?: number;
  max?: number;
  step?: number;
}

const FontSizeInput: React.FC<FontSizeInputProps> = ({
  value,
  label,
  onChange,
  min = 8,
  max = 72,
  step = 1,
}) => {
//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     let newValue = parseInt(e.target.value, 10) 
//     // || min;
//     // newValue = Math.min(Math.max(newValue, min), max);
//     onChange(newValue);
//   };

const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const newValue = parseInt(e.target.value, 10)   ;
    // onChange(newValue);

    const newValue = e.target.value.replace(/\D/g, '');

    onChange(newValue)
  };

  return (
    // <FormControl fullWidth variant="outlined" sx={{ marginTop: 0 , padding:0 }}>
      <TextField
        fullWidth
        margin="normal"
    
        // type="number"
        value={value}
        label={label}
        error={ value===""||parseInt(value, 10)>32 }
        helperText={value===""?"value cannot be empty":parseInt(value, 10)>32 ?"Limit exceed" : "" }
        onChange={handleInputChange}
        inputProps={{
          style: {
            textAlign: "left",
            // padding: '16px 16px',
            fontSize: '16px',
          },
          'aria-label': 'Font Size',
          step: step.toString(),
          min: min.toString(),
          max: max.toString(),
        }}
        variant="outlined"
        InputProps={{
          endAdornment: <InputAdornment position="end">pt</InputAdornment>,
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    // </FormControl>
  );
};

export default FontSizeInput;

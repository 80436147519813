import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useSnackbar } from "notistack";
import {
  Organization,
  User,
  USER_ROLE,
  Workspace,
  WorkspaceUserRole,
} from "../../types/User";
import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { KeyboardReturnOutlined, Workspaces } from "@mui/icons-material";
import {
  selectAuth,
  selectOrganizationOwner,
  selectOrganizations,
  selectResponseError,
  selectUserRole,
  setErrorMsg,
} from "../auth/authSlice";

/**
 * Edit the workspace if a workspace is passed in, otherwise create a new one.
 *
 * @export
 * @return {*}
 */
export default function ShowAdminUsers(props: { closeDialogHandler: any }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [nameError, setNameError] = React.useState("");
  const [dialogError, setDialogError] = React.useState("");
  const authState = useAppSelector(selectAuth);
  const actions = useActions();
  const organizationOwner = useAppSelector(selectOrganizationOwner);
  const selectErrorMsgState = useAppSelector(selectResponseError);

  const selectOrganizationsList = useAppSelector(selectOrganizations)
  const userRole = useAppSelector(selectUserRole);

  const [selection, setSelection] = React.useState<string>(
    // "CreateAdminOrg"
    "CreateAdmin"
    );
  /*
  const [addUser, setAddUser] = React.useState<User | undefined>(
    props.organization.users && props.organization.users.length > 0
      ? props.organization.users[0]
      : undefined
  );
*/
  const [createUserMode, setCreateUserMode] = React.useState<boolean>(true);
  const [newUserEmail, setNewUserEmail] = React.useState<string>("");
  const [newOrganizationName, setNewOrganizationName] =
    React.useState<string>("");

  const handleEditUserEmail = (e: any) => {
    setNewUserEmail(e.target.value);
  };

  const handleEditOrganizationName = (e: any) => {
    setNewOrganizationName(e.target.value);
  };

  const handleDeleteUser = (user: User) => {
    //actions.detachUser({ workspace: props.workspace!, user: role.user });
  };

  const handleClose = () => {
    dispatch(setErrorMsg({
      status:"idle",
      errorMsg:undefined,
      errorType:undefined
    }))
    props.closeDialogHandler();
  };


  React.useEffect(()=>{
    if(selectErrorMsgState.status==="success"){
      if(selectErrorMsgState.errorType==="createSuperUserInOrg"){
        enqueueSnackbar("Email sent successfully!", { variant: "success" });
        dispatch(setErrorMsg({
          status:"idle",
          errorMsg:undefined,
          errorType:undefined
        }))
        handleClose()

      }
      if(selectErrorMsgState.errorType==="createAdminUserInOrg"){
        enqueueSnackbar("Email sent successfully!", { variant: "success" });
        dispatch(setErrorMsg({
          status:"idle",
          errorMsg:undefined,
          errorType:undefined
        }))
        handleClose()
      }

    }else if(selectErrorMsgState.status==="failed"){
      if(selectErrorMsgState.errorType==="createSuperUserInOrg"){
        enqueueSnackbar("User already exist!", { variant: "error" });
        dispatch(setErrorMsg({
          status:"idle",
          errorMsg:undefined,
          errorType:undefined
        }))
       
      }
      if(selectErrorMsgState.errorType==="createAdminUserInOrg"){
        enqueueSnackbar("User already exist!", { variant: "error" });
        dispatch(setErrorMsg({
          status:"idle",
          errorMsg:undefined,
          errorType:undefined
        }))
       
    }
  }


  },[selectErrorMsgState])

  

  const handleCreateSuperUserInOrg = (e: any) => {
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!newUserEmail.match(validRegex)) {
      enqueueSnackbar("Not a valid email address", { variant: "error" });
      return;
    }

    actions.createSuperUserInOrg({
      email: newUserEmail,
    });
    // enqueueSnackbar("Email sent successfully!", { variant: "success" });
    setCreateUserMode(false);
  
  };


  const handleCreateAdminUserInOrg = (e: any) => {
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!newUserEmail.match(validRegex)) {
      enqueueSnackbar("Not a valid email address", { variant: "error" });
      return;
    }
    actions.createAdminUserInOrg({
      email: newUserEmail,
    });
    // enqueueSnackbar("Email sent successfully!", { variant: "success" });
    setCreateUserMode(false);
    // props.closeDialogHandler();
  };

  const handleCreateUser = (e: any) => {
    //TODO: Check for duplicates.
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!newUserEmail.match(validRegex)) {
      enqueueSnackbar("Not a valid email address", { variant: "error" });
      return;
    }

    if(newOrganizationName==""){
      enqueueSnackbar("Organization name cannot be empty", { variant: "error" });
      return false;
    }

    actions.createUserWithOrg({
      email: newUserEmail,
      organization_name: newOrganizationName,
    });
    setCreateUserMode(false);
    enqueueSnackbar("Email sent successfully!", { variant: "success" });
    props.closeDialogHandler();
  };

  const handleChangeSelection = (
    event: React.MouseEvent<HTMLElement>,
    selection: string
  ) => {
if( selection!=null){
  setSelection(selection);
}
  };

  const userListCard = (
    <Card>
      <Box>
        <CardHeader
          title={
            // userRole === USER_ROLE.ADMIN
             selection==="CreateAdmin"
              ? 
                "Create a new admin user":
                selection==="CreateSuperUser"?
                "Create a new super user"
              : "Create a new admin user and organization"
          }
        ></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={8} sx={{ marginBottom: "20px" }}>
              <InputLabel>Email</InputLabel>
              <Input
                value={newUserEmail}
                onChange={handleEditUserEmail}
                fullWidth={true}
              />
            </Grid>
            {
              selection==="CreateAdminOrg"
            // userRole === USER_ROLE.SUPER_USER 
            && (
              //  selection==="CreateAdminOrg"
              <Grid item xs={8} sx={{ marginBottom: "20px" }}>
                <InputLabel>Organization Name</InputLabel>
                <Input
                  value={newOrganizationName}
                  onChange={handleEditOrganizationName}
                  fullWidth={true}
                />
              </Grid>
            )}
            {/*<Grid item xs={5}>
              <InputLabel>Password</InputLabel>
              <Input
                value={newUserPassword}
                onChange={handleEditUserPassword}
              />
          </Grid> */}
          </Grid>

          <Box>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={(event) => {
                if(selection==="CreateSuperUser"){
                  handleCreateSuperUserInOrg(event)
                }else 
                if (
                  selection==="CreateAdmin"
                  // userRole === USER_ROLE.ADMIN
                  ) {
                  handleCreateAdminUserInOrg(event);
                } else {
                  handleCreateUser(event);
                }
              }}
            >
             {selection==="CreateSuperUser"? "Create Super User":"Create Admin User"} 
            </Button>
          </Box>
        </CardContent>
      {/* {selectErrorMsgState.status==="loading" && ( <LinearProgress/>)} */}
      </Box>
    </Card>

  );

  return (
    <Dialog
      open={true}
      onClose={(e) => {
        props.closeDialogHandler();
      }}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        {userRole === USER_ROLE.ADMIN
          ? "Admin User"
          : "Admin Users (Super Admin Mode)"}
      </DialogTitle>
      <DialogContent>
{ userRole === USER_ROLE.SUPER_USER && (        <Box>

        <ToggleButtonGroup
      color="primary"
      fullWidth
      value={selection}
      exclusive
      onChange={handleChangeSelection}
      aria-label="Platform"
      style={{ whiteSpace: 'nowrap' }}
    >
            <ToggleButton sx={{fontWeight:"bold"}} value="CreateSuperUser">Super User </ToggleButton>
      <ToggleButton sx={{fontWeight:"bold"}} value="CreateAdmin">Admin </ToggleButton>
    
      <ToggleButton sx={{fontWeight:"bold"}} value="CreateAdminOrg">Admin And Organization</ToggleButton>
    </ToggleButtonGroup>

        </Box>
)}
        {dialogError !== "" ? (
          <Alert severity="error">{dialogError}</Alert>
        ) : null}
        {userListCard}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { TABLE_THEMES } from "../../../../../globals";

export const data = [
  { name: "Microsoft", q1: 20.3, q2: 30.5, q3: 23.5 },
  { name: "Google", q1: 50.2, q2: 40.63, q3: 45.23 },
  { name: "Apple", q1: 25.4, q2: 30.2, q3: 33.3 },
];




export const TABLE_ALIGNMENT={
  left:"raggedright" ,
  right:"raggedleft",
  center:"centering"
}

export interface themeData {
  name: string;
  type: "custom" | "default";
  data: any;
}


export const textAlignOptions = [
  { value: TABLE_ALIGNMENT.left, label: 'Left' },
  { value: TABLE_ALIGNMENT.center, label: 'Center' },
  { value: TABLE_ALIGNMENT.right, label: 'Right' },
];

export function convertMmToPx(mm: string): number {
  const mmAsNumber: number = parseFloat(mm);
  if (isNaN(mmAsNumber)) {
    return 1
    throw new Error("Invalid input: mm must be a valid number");
  }
  // Assuming 1mm is approximately 3.78 pixels (common in web development)
  return mmAsNumber * 3.78;
}

export const convertMmToPt = (mm: string | number): number => {
  if(mm===undefined){
    return 0
  }
  
  // If mm is a string, parse it as a number
  const mmAsNumber: number = typeof mm === 'string' ? parseFloat(mm) : mm;
  // Check if the parsing was unsuccessful
  if (isNaN(mmAsNumber)) {
    // If parsing failed, throw an error
    return 0 
    throw new Error("Invalid input: mm must be a valid number");
  }

  // Perform the conversion using the assumed conversion factor
  // 1mm is approximately 2.83 points
  return mmAsNumber * 2.83;
};


export const currentIndex = (
  action: string,
  rowIndex: number,
  columnIndex: number
) => {
  let updatedRowIndex = rowIndex;
  let updatedColumnIndex = columnIndex;

  if (action === "Insert Row Above") {
    updatedRowIndex += 1;
  } else if (action === "Insert Row Below") {
    // Implement logic for inserting row below
    // updatedRowIndex += 1;
  } else if (action === "Insert Column Left") {
    // Implement logic for inserting column to the left

    updatedColumnIndex += 1;
  } else if (action === "Insert Column Right") {
    // Implement logic for inserting column to the right
    // updatedColumnIndex += 1;
  } else if (action === "Delete Entire Column") {
    // Implement logic for deleting entire column

    // updatedColumnIndex = Math.max(0, columnIndex - 1);
    updatedColumnIndex = 100;
  } else if (action === "Delete Entire Row") {
    // Implement logic for deleting entire row

    // updatedRowIndex = Math.max(0, rowIndex - 1);
    updatedRowIndex = 100;
  } else if (action === "Duplicate Row Above") {
    // Implement logic for duplicating row above

    updatedRowIndex += 1;
  } else if (action === "Duplicate Row Below") {
    // Implement logic for duplicating row below
    // updatedRowIndex += 1;
  } else if (action === "Duplicate Column Left") {
    // Implement logic for duplicating column to the left
    updatedColumnIndex += 1;
  } else if (action === "Duplicate Column Right") {
    // Implement logic for duplicating column to the right
    // updatedColumnIndex += 1;
  } else if (action === "Move Row Above") {
    // Implement logic for moving row above
    updatedRowIndex = Math.max(0, rowIndex - 1);
  } else if (action === "Move Row Below") {
    // Implement logic for moving row below
    updatedRowIndex += 1;
  } else if (action === "Move Column Left") {
    // Implement logic for moving column to the left
    updatedColumnIndex = Math.max(0, columnIndex - 1);
  } else if (action === "Move Column Right") {
    // Implement logic for moving column to the right
    updatedColumnIndex += 1;
  }

  return { updatedRowIndex, updatedColumnIndex };
};

export function updateCellBackgroundFormatting(
  tableData: any[][],
  rowIndex: number,
  columnIndex: number,
  toUpdate:string,
  updatedValue: string
  // textColor
  // backgroundColor

): any[][] {
  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));

  // Check if the row and column indices are within bounds
  if (
    rowIndex >= 0 &&
    rowIndex < updatedTableData.length &&
    columnIndex >= 0 &&
    columnIndex < updatedTableData[0].length
  ) {
    // Update the textAlign property
    updatedTableData[rowIndex][columnIndex].style.backgroundColor = updatedValue;
  }

  return updatedTableData;
}



export function updateCellFormatting(
  tableData: any[][],
  rowIndex: number,
  columnIndex: number,
  toUpdate:string,
  updatedValue: string
  // textColor
  // backgroundColor

): any[][] {
  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));

  // Check if the row and column indices are within bounds
  if (
    rowIndex >= 0 &&
    rowIndex < updatedTableData.length &&
    columnIndex >= 0 &&
    columnIndex < updatedTableData[0].length
  ) {
    // Update the textAlign property
    updatedTableData[rowIndex][columnIndex].style.textColor = updatedValue;
  }

  return updatedTableData;
}


export function updateCellTextAlign(
  tableData: any[][],
  rowIndex: number,
  columnIndex: number,
  textAlign: string
): any[][] {


  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));

  // Check if the row and column indices are within bounds
  if (
    rowIndex >= 0 &&
    rowIndex < updatedTableData.length &&
    columnIndex >= 0 &&
    columnIndex < updatedTableData[0].length
  ) {
    // Update the textAlign property
    updatedTableData[rowIndex][columnIndex].style.textAlign = textAlign;
  }

  return updatedTableData;
}


export function formatTableWithTheme(
  rows: number,
  columns: number,
  theme: any
): any {
  const tableData: any[] = [];
  for (let i = 0; i < rows; i++) {
    const row: any[] = [];
    for (let j = 0; j < columns; j++) {
      const isHeader: boolean = i === 0; // Check if it's the first row (header)
 
      const cellData: any = {
        style: {
          // Background Color
          backgroundColor: isHeader
            ? theme.THBackgroundColor :
            theme.stripe==="row" && i % 2===0 ||  theme.stripe==="column" && j % 2===1  ?  theme.StripeBackgroundColor 
            : theme.CellBackgroundColor,

          // Text Color
          textColor: isHeader ? theme.THTextColor : theme.CellTextColor,

          // Text Alignment
          textAlign: isHeader ? theme.THTextAlign : theme.CellTextAlign,

          // Font Size
          fontSize: isHeader ? theme.THFontSize : theme.CellFontSize,

          // Font (you can adjust the property name based on your needs)
          font: isHeader ? theme.THFont : theme.CellFont,

          columnWidth:"none"
        },
      };
      row.push(cellData);
    }
    tableData.push(row);
  }

  return tableData;
}

/**
 * Get the style of a specific cell in a formatted table.
 * @param row - The row number of the cell.
 * @param col - The column number of the cell.
 * @param formattedTableData - The formatted table data containing styles.
 * @returns The style object of the specified cell. Returns an empty object if the cell is not found.
 */

export function getCellStyle(row: any, col: any, formattedTableData: any): any {
  // Check if the specified cell exists in the formatted table data
  if (formattedTableData[row] && formattedTableData[row][col]) {
    // Return the style object of the specified cell
    return formattedTableData[row][col].style;
  }
  // Return an empty object if the cell is not found
  return {};
}


export const tableThemes: themeData[] = [{
    name: "default",
    type: "default",
    data:TABLE_THEMES.default
  },
  {
    name: "theme1",
    type: "default",
   data:TABLE_THEMES.theme1
  },
  {
    name: "theme2",
    type: "default",
    data:TABLE_THEMES.theme2
  },

  {
    name: "theme3",
    type: "default",
    data:TABLE_THEMES.theme3
  },
  {
    name: "theme4",
    type: "default",
    data:TABLE_THEMES.theme4
  },
  {
    name: "theme5",
    type: "default",
    data:TABLE_THEMES.theme5
  },
  {
    name: "theme6",
    type: "default",
    data:TABLE_THEMES.theme6
  },
  {
    name: "theme7",
    type: "default",
    data:TABLE_THEMES.theme7
  },
  {
    name: "theme8",
    type: "default",
    data:TABLE_THEMES.theme8
  },
  {
    name: "theme9",
    type: "default",
    data:TABLE_THEMES.theme9
  },
  {
    name: "theme10",
    type: "default",
    data:TABLE_THEMES.theme10
  },
  {
    name: "theme11",
    type: "default",
    data:TABLE_THEMES.theme11
  },
  {
    name: "theme12",
    type: "default",
    data:TABLE_THEMES.theme12
  },
  {
    name: "theme13",
    type: "default",
    data:TABLE_THEMES.theme13
  },
  {
    name: "theme14",
    type: "default",
    data:TABLE_THEMES.theme14
  },

  // {
  //   name: "theme15",
  //   type: "default",
  //   data:TABLE_THEMES.theme15
  // },
  // {
  //   name: "theme16",
  //   type: "default",
  //   data:TABLE_THEMES.theme16
  // },
  // {
  //   name: "theme17",
  //   type: "default",
  //   data:TABLE_THEMES.theme17
  // },
  // {
  //   name: "theme18",
  //   type: "default",
  //   data:TABLE_THEMES.theme18
  // },  {
  //   name: "theme19",
  //   type: "default",
  //   data:TABLE_THEMES.theme19
  // },  {
  //   name: "theme20",
  //   type: "default",
  //   data:TABLE_THEMES.theme20
  // },


];

export const getAlignment=(align:string)=>{
  if(align===TABLE_ALIGNMENT.center){
    return "center"
  }else if(align===TABLE_ALIGNMENT.left)
  {
    return "left"
  }else  if(align===TABLE_ALIGNMENT.right){
    return "right"
  }

  return "left"
}

export const getDefaultThemeDetails = (themeName: any) => {
  switch (themeName) {
    case "default":
      return TABLE_THEMES.default;
    case "theme1":
      return TABLE_THEMES.theme1;
    case "theme2":
      return TABLE_THEMES.theme2;
    case "theme3":
      return TABLE_THEMES.theme3;
    case "theme4":
      return TABLE_THEMES.theme4;
    case "theme5":
      return TABLE_THEMES.theme5;
    case "theme6":
      return TABLE_THEMES.theme6;
    case "theme7":
      return TABLE_THEMES.theme7;
    case "theme8":
      return TABLE_THEMES.theme8;
      case "theme9":
        return TABLE_THEMES.theme9;
    case "theme10":
      return TABLE_THEMES.theme10;
    case "theme11": return TABLE_THEMES.theme11;
    case "theme12": return TABLE_THEMES.theme12;
    case "theme13": return TABLE_THEMES.theme13;
    case "theme14": return TABLE_THEMES.theme14;
    case "theme15": return TABLE_THEMES.theme15;
    case "theme16": return TABLE_THEMES.theme16;
    case "theme17": return TABLE_THEMES.theme17;
 
    case "theme18": return TABLE_THEMES.theme18;
    case "theme19": return TABLE_THEMES.theme19;
    case "theme20": return TABLE_THEMES.theme20;

    // Add more cases for other themes as needed
    default: TABLE_THEMES.default
  }
};

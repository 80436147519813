import React, { useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import useActions, { useAppSelector } from "../../app/hooks";
import { CloudUpload } from "@mui/icons-material";
import CreateDoc from "../../../src/Icons/MainHeader/Create.svg";
import ImportTemplateForm from "./importTemplate/ImportTemplateForm";
import CreateDocumentTemplateForm from "./CreateDocumentTemplateForm";
import { selectEditingDocumentTemplate } from "../editor/editorSlice";
import DocumentCreationForm from "../generation/DocumentCreationForm";
import { ReactComponent as CreateIcon } from "./../../Icons/HeaderMenu/Create.svg";
import ImportRecordForm from "./uploadDocumentRecord/ImportRecordForm";
import ImportTemplateFromDocForm from "./importTemplate/ImportTempleteFromDocForm";

/**
 * The create menu.
 *
 * @export
 * @param {{user: User | null, documentTemplates: DocumentTemplate[]}} props
 * @return {*}
 */
export function CreateMenu(props: {
  // user: User | null;
  // documentTemplates: DocumentTemplate[];
}) {
  const actions = useActions();
  const selectedDocumentTemplate = useAppSelector(
    selectEditingDocumentTemplate
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [createTemplateFormOpen, setCreateTemplateFormOpen] = React.useState<{
    open: boolean;
    once_off: boolean;
  }>({ open: false, once_off: false });

  const [uploadPDFFormOpen, setUploadPDFFormOpen] = React.useState(false);

  const [importTemplateFormOpen, setImportTemplateFormOpen] =
    React.useState(false);
  const [createDocumentFormOpen, setCreateDocumentFormOpen] =
    React.useState(false);

  const [importDocTemplateFormOpen, setImportDocTemplateFormOpen] =
    React.useState(false);

  const handleClickCreateDocument = () => {
    setAnchorEl(null);
    setCreateDocumentFormOpen(true);
  };

  const handleClickCreateAdhocDocument = () => {
    setAnchorEl(null);
    // if (props.user?.default_organization_id !== org.id)
    //   actions.setOrganization(org);
  };

  const handleClickImportTemplate = () => {
    setAnchorEl(null);
    setImportTemplateFormOpen(true);
    // if (props.user?.default_organization_id !== org.id)
    //   actions.setOrganization(org);
  };
  const handleClickImportDocTemplate = () => {
    setImportDocTemplateFormOpen(true);
    setAnchorEl(null)
    // if (props.user?.default_organization_id !== org.id)
    //   actions.setOrganization(org);
  };

  const handleClickImportAdhocDocument = () => {
    setAnchorEl(null);
    // if (props.user?.default_organization_id !== org.id)
    //   actions.setOrganization(org);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Create Templates and Documents">
        <Button
          // startIcon={<AddIcon/>}
          sx={{ color: "white" }}
          onClick={handleOpenMenu}
        >
          <img src={CreateDoc} alt="publishIcon" width="25px" height="20px" />
          Create
        </Button>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          key="createTemplate"
          onClick={() =>
            {setCreateTemplateFormOpen({ open: true, once_off: false })
            setAnchorEl(null)}
          }
        >
          <ListItemIcon>
            <AddIcon></AddIcon>
          </ListItemIcon>
          <ListItemText>Create template</ListItemText>
        </MenuItem>
        {/* <MenuItem key="createDocument" onClick={handleClickCreateDocument} disabled={selectedDocumentTemplate == null || selectedDocumentTemplate?.published_date == null || selectedDocumentTemplate?.deleted_at !== null}>
          <ListItemIcon>
            <AddIcon></AddIcon>
          </ListItemIcon>
          <ListItemText>Create new document from template</ListItemText>
      </MenuItem> */}
        <MenuItem
          key="createAdhocDocument"
          onClick={() =>
          {  setCreateTemplateFormOpen({ open: true, once_off: true })
            setAnchorEl(null)}
          }
        >
          <ListItemIcon>
            <AddIcon></AddIcon>
          </ListItemIcon>
          <ListItemText>Create once-off document</ListItemText>
        </MenuItem>

        <MenuItem
          key="importDocTemplate"
          onClick={handleClickImportDocTemplate}
        >
          <ListItemIcon>
            <CloudUpload></CloudUpload>
          </ListItemIcon>
          <ListItemText>Import text from existing document</ListItemText>
        </MenuItem>

        {/* <MenuItem key="Upload PDF" onClick={() => setUploadPDFFormOpen(true)}>
          <ListItemIcon>
            <CloudUpload></CloudUpload>
          </ListItemIcon>
          <ListItemText>Upload PDF</ListItemText>
        </MenuItem> */}

        {/* <MenuItem key="importTemplate" onClick={handleClickImportTemplate}>
          <ListItemIcon>
            <CloudUpload></CloudUpload>
          </ListItemIcon>
          <ListItemText>Import template</ListItemText>
        </MenuItem> */}

        {/* <MenuItem key="importAdhoc" onClick={handleClickImportAdhocDocument}>
          <ListItemIcon>
            <CloudUpload></CloudUpload>
          </ListItemIcon>
          <ListItemText>Import ad-hoc document</ListItemText>
    </MenuItem> */}
      </Menu>

      {importTemplateFormOpen ? (
        <ImportTemplateForm
          closeDialogHandler={() => setImportTemplateFormOpen(false)}
        ></ImportTemplateForm>
      ) : null}

      {importDocTemplateFormOpen ? (
        <ImportTemplateFromDocForm
          open={importDocTemplateFormOpen}
          closeDialogHandler={() => {
            setImportDocTemplateFormOpen(false);
            setAnchorEl(null);
          }}
        ></ImportTemplateFromDocForm>
      ) : null}

      <CreateDocumentTemplateForm
        open={createTemplateFormOpen.open}
        once_off={createTemplateFormOpen.once_off}
        parentCloseHandle={() => {
          setCreateTemplateFormOpen({ open: false, once_off: false });
          setAnchorEl(null);
        }}
      />
      {uploadPDFFormOpen && (
        <ImportRecordForm
          open={uploadPDFFormOpen}
          closeDialogHandler={() => {
            setUploadPDFFormOpen(false);
            setAnchorEl(null);
          }}
        />
      )}
      {createDocumentFormOpen ? (
        <DocumentCreationForm
          parentCloseHandle={() => {
            setCreateDocumentFormOpen(false);
            setAnchorEl(null);
          }}
          documentTemplate={selectedDocumentTemplate}
        />
      ) : null}
    </Box>
  );
}

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EditorState, RichUtils } from "draft-js";
import { Modifier, SelectionState } from "draft-js";
import { RootState } from "../../app/store";
import { Map } from "immutable";

import {
  changeDepth,
  getSelectedBlocksMetadata,
  setBlockData,
  // getSelectedBlock,
  // isListBlock,
} from "draftjs-utils";
import { MAX_LIST_DEPTH } from "../../globals";
import { DocumentSection } from "../../types/DocumentTemplate";
import { hexToRgb } from "./document/HelperEditorFunctions";
import { TypedUseSelectorHook, useSelector } from "react-redux";

/**
 * Set an inline style property. Returns the new EditorState.
 *
 * @param {EditorState} editorState
 * @param {string} property
 * @return {*}  {EditorState}
 */
function _toggleInlineProperty(
  editorState: EditorState,
  property: "BOLD" | "ITALIC" | "UNDERLINE"
): EditorState {
  return RichUtils.toggleInlineStyle(editorState, property);
}

// function _toggleInlineColorProperty(
//   editorState: EditorState,
//   color: string
// ): EditorState {
//   const newEditorObject = RichUtils.toggleInlineStyle(editorState, `COLOR_${color}`)
//   return newEditorObject

//  ;
// }

function updateSelectedTextMarkdown(
  editorState: EditorState,
  additionalMarkdown: string
): EditorState {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const startOffset = selection.getStartOffset();
  const endOffset = selection.getEndOffset();
  const selectedText = contentState
    .getBlockForKey(startKey)
    .getText()
    .slice(startOffset, endOffset);

  // Create a new ContentState with the updated text
  const updatedContentState = Modifier.replaceText(
    contentState,
    new SelectionState({
      anchorKey: startKey,
      anchorOffset: startOffset,
      focusKey: endKey,
      focusOffset: endOffset,
    }),
    // `<${additionalMarkdown}>${selectedText}<${additionalMarkdown}/>`,
    `<span style="color: ${additionalMarkdown}">${selectedText}</span>`
  );

  // Create a new EditorState with the updated ContentState
  const updatedEditorState = EditorState.push(
    editorState,
    updatedContentState,
    "insert-characters" // You can use a different change type based on your needs
  );

  return updatedEditorState;
}

const ChangeEditorColor = (
  editorState: EditorState,
  color: string
): EditorState => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const updatedColor = color.substring(1);
  const rbgColor = hexToRgb(color);
  // Create a new content state with the desired color entity
  const contentStateWithColor = contentState.createEntity("COLOR", "MUTABLE", {
    color: updatedColor,
    property: `color-rgb(${rbgColor.r},${rbgColor.g},${rbgColor.b})`,
  });
  const entityKey = contentStateWithColor.getLastCreatedEntityKey();
  const contentWithColor = Modifier.applyEntity(
    contentStateWithColor,
    selection,
    entityKey
  );
  const entityMap = contentWithColor.getEntityMap();
  // Update the editor state with the new content state
  const editorStateWithColor = EditorState.push(
    editorState,
    contentWithColor,
    "apply-entity"
  );

  // Set the selection to the entire styled text
  const newSelection = selection.merge({
    anchorOffset: 0,
    focusOffset: selection.getEndOffset(),
  });

  return EditorState.forceSelection(editorStateWithColor, newSelection);
};

function _toggleInlineColorProperty(
  editorState: EditorState,
  color: string
): EditorState {
  // Convert the hex color to RGB format
  const rbgColor = hexToRgb(color);

  // Remove existing color styles from the selection
  const withoutColorState = RichUtils.toggleInlineStyle(
    editorState,
    "color-rgb" // Remove any color styles
  );

  // Toggle the new inline style for the color
  const newEditorState = RichUtils.toggleInlineStyle(
    withoutColorState,
    `color-rgb(${rbgColor.r},${rbgColor.g},${rbgColor.b})`
  );

  // Return the updated editor state with the toggled color style
  return newEditorState;
}

// function _toggleInlineColorProperty(
//   editorState: EditorState,
//   color: string
// ): EditorState {
//   // Toggle the inline style as you did before
//   const rbgColor = hexToRgb(color);
//   const newEditorState = RichUtils.toggleInlineStyle(
//     editorState,
//     `color-rgb(${rbgColor.r},${rbgColor.g},${rbgColor.b})`
//   );

//   // const newEditorState = RichUtils.toggleInlineStyle(
//   //   editorState,
//   //   `FONTSIZE`
//   // );

//   return newEditorState;
//   // Update the selected text in Markdown
//   // const updatedEditorState = updateSelectedTextMarkdown(newEditorState,color );
//   // Adjust based on your markdown syntax

//   // Now you can use the updated editor state wherever needed
//   // return updatedEditorState;
// }

/**
 * Set an inline style property. Returns the new EditorState.
 *
 * @param {EditorState} editorState
 * @param {string} property
 * @return {*}  {EditorState}
 */
function _toggleBlockType(
  editorState: EditorState,
  property: "unordered-list-item" | "ordered-list-item"
): EditorState {
  return RichUtils.toggleBlockType(editorState, property);
}

export interface FormattingState {
  currentEditorState: EditorState | null;
  currentComponent?: DocumentSection | null;
}

const initialState: FormattingState = {
  currentEditorState: null,
  currentComponent: null,
};

export const editorSlice = createSlice({
  name: "markdownEditor",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setEditorState: (state, action: PayloadAction<EditorState | null>) => {
      // console.log("Setting Editor Slice" ,action.payload )
      state.currentEditorState = action.payload;
    },
    setCurrentElement: (state, action: PayloadAction<DocumentSection>) => {
      state.currentComponent = action.payload;
    },
    clearEditorState: (state) => {
      state.currentEditorState = null;
    },
    toggleBold: (state) => {
      if (state.currentEditorState) {
        state.currentEditorState = _toggleInlineProperty(
          state.currentEditorState,
          "BOLD"
        );
        // state.currentEditorState = _toggleInlineColorProperty(
        //   state.currentEditorState,
        //   "RED"
        // );
        // state.currentEditorState =  ChangeEditorColor(state.currentEditorState,
        //   "RED")
      }
    },

    toggleColor: (state, action: PayloadAction<string>) => {
      if (state.currentEditorState) {
        const inlineEditorState = _toggleInlineColorProperty(
          state.currentEditorState,
          action.payload
        );
        state.currentEditorState = ChangeEditorColor(
          inlineEditorState,
          action.payload
        );
      }
    },

    toggleItalic: (state) => {
      if (state.currentEditorState)
        state.currentEditorState = _toggleInlineProperty(
          state.currentEditorState,
          "ITALIC"
        );
    },

    toggleUnderline: (state) => {
      if (state.currentEditorState)
        state.currentEditorState = _toggleInlineProperty(
          state.currentEditorState,
          "UNDERLINE"
        );
    },
    toggleUnorderedList: (state) => {
      if (state.currentEditorState)
        state.currentEditorState = _toggleBlockType(
          state.currentEditorState,
          "unordered-list-item"
        );
    },
    toggleOrderedList: (state) => {
      if (state.currentEditorState)
        state.currentEditorState = _toggleBlockType(
          state.currentEditorState,
          "ordered-list-item"
        );
    },
    toggleListIncreaseIndent: (state) => {
      if (state.currentEditorState)
        state.currentEditorState = changeDepth(
          state.currentEditorState,
          1,
          MAX_LIST_DEPTH
        );
    },
    toggleListDecreaseIndent: (state) => {
      if (state.currentEditorState)
        state.currentEditorState = changeDepth(
          state.currentEditorState,
          -1,
          MAX_LIST_DEPTH
        );
    },

    setAlignment: (
      state,
      action: PayloadAction<"left" | "center" | "right">
    ) => {
      if (state.currentEditorState)
        state.currentEditorState = setBlockData(state.currentEditorState, {
          "text-align": action.payload,
        });
    },

    undoEditor: (state) => {
      if (state.currentEditorState == null) return;
      if (state.currentEditorState.getUndoStack().isEmpty()) return;
      state.currentEditorState = EditorState.undo(state.currentEditorState);
    },
    redoEditor: (state) => {
      if (state.currentEditorState == null) return;
      if (state.currentEditorState.getRedoStack().isEmpty()) return;
      state.currentEditorState = EditorState.redo(state.currentEditorState);
    },
    clearUndoRedoHistory: (state) => {
      if (state.currentEditorState == null) return;
      if (state.currentEditorState.getRedoStack().isEmpty()) return;
      state.currentEditorState = EditorState.set(state.currentEditorState, {
        allowUndo: false,
      });
      state.currentEditorState = EditorState.set(state.currentEditorState, {
        allowUndo: true,
      });
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {},
});

export const {
  setEditorState,
  clearEditorState,
  setCurrentElement,
  toggleBold,
  toggleItalic,
  toggleUnderline,
  toggleOrderedList,
  toggleUnorderedList,
  toggleListDecreaseIndent,
  toggleListIncreaseIndent,
  setAlignment,
  undoEditor,
  redoEditor,
  toggleColor,
} = editorSlice.actions;

//Selectors
export const selectCurrentEditorState = (state: RootState) =>
  state.markdownEditor!.currentEditorState;

export const selectCurrentEditorStateCustom = (
  state: RootState,
  subsectionId: number | null
) => {
  if (state.document.selectedSubsection?.id == subsectionId) {
    return state.markdownEditor!.currentEditorState;
  }

  return null;
};


export const makeSelectCurrentEditorStateCustom = (
  state: RootState,
  props: any
): EditorState | null => {
  if (
    props.isHeading &&
    state.document.selectedSection?.id === props.parentSection.id
  ) {
    return state.markdownEditor.currentEditorState;
  } else if (
    !props.isHeading &&
    props.parentSubsection?.id === state.document.selectedSubsection?.id 
  ) {
    return state.markdownEditor.currentEditorState;
  }
  return null;
};

export const selectCurrentElement = (state: RootState) =>
  state.markdownEditor!.currentComponent;

export const selectCurrentMarkdownEditorDetails = (state: RootState) => {
  if (!state.markdownEditor) return null;
  if (!state.markdownEditor.currentEditorState) return null;
  const currentEditorState = state.markdownEditor.currentEditorState;
  const inlineStyle = currentEditorState.getCurrentInlineStyle();
  const blockType = RichUtils.getCurrentBlockType(currentEditorState);
  const blockMetadata = getSelectedBlocksMetadata(currentEditorState);
  return {
    disabled: inlineStyle === null,
    bold: inlineStyle?.contains("BOLD") ?? false,
    italic: inlineStyle?.contains("ITALIC") ?? false,
    underlined: inlineStyle?.contains("UNDERLINE") ?? false,
    unordered_list: blockType === "unordered-list-item",
    ordered_list: blockType === "ordered-list-item",
    alignment: blockMetadata?.get("text-align") ?? "left",
    isUndoAvailable: !currentEditorState.getUndoStack().isEmpty(),
    isRedoAvailable: !currentEditorState.getRedoStack().isEmpty(),
  };
};
export default editorSlice.reducer;

// The time to wait until committing changes during editing.
export const COMMIT_CHANGES_TIME_SEC = 2;

// Formatting
export const MAX_LIST_DEPTH = 4;

// Editor
export const TABLE_OF_CONTENTS = "Table of Contents";
export const TABLE_OF_CONTENTS_SECTION = "Table of Contents Section";
export const TITLE_PAGE = "Title Page";
export const LIST_OF_FIGURES = "List of Figures";
export const LIST_OF_TABLES = "List of Tables";

// Max summary heading length
export const MAX_HEADING_SUMMARY_LENGTH = 32;
export const SUB_CATEGORY = "Sub-category";
export const secondarySilver = " #7284A3";

// Credit Count
export const MAX_CREDIT_LIMIT = 999999999;
export const WARNING_CREDIT_COUNT = 50;
export const ERROR_CREDIT_COUNT = 10;

// tables themes

export const DEFAULT_THEME_FORMATTING =
  '[[{"style":{"backgroundColor":"ffffff","textColor":"000000","textAlign":"raggedright","fontSize":"14","columnWidth":"none"}},{"style":{"backgroundColor":"ffffff","textColor":"000000","textAlign":"raggedright","fontSize":"14","columnWidth":"none"}},{"style":{"backgroundColor":"ffffff","textColor":"000000","textAlign":"raggedright","fontSize":"14","columnWidth":"none"}}],[{"style":{"backgroundColor":"ffffff","textColor":"000000","textAlign":"raggedright","fontSize":"12","columnWidth":"none"}},{"style":{"backgroundColor":"ffffff","textColor":"000000","textAlign":"raggedright","fontSize":"12","columnWidth":"none"}},{"style":{"backgroundColor":"ffffff","textColor":"000000","textAlign":"raggedright","fontSize":"12","columnWidth":"none"}}],[{"style":{"backgroundColor":"ffffff","textColor":"000000","textAlign":"raggedright","fontSize":"12","columnWidth":"none"}},{"style":{"backgroundColor":"ffffff","textColor":"000000","textAlign":"raggedright","fontSize":"12","columnWidth":"none"}},{"style":{"backgroundColor":"ffffff","textColor":"000000","textAlign":"raggedright","fontSize":"12","columnWidth":"none"}}],[{"style":{"backgroundColor":"ffffff","textColor":"000000","textAlign":"raggedright","fontSize":"12","columnWidth":"none"}},{"style":{"backgroundColor":"ffffff","textColor":"000000","textAlign":"raggedright","fontSize":"12","columnWidth":"none"}},{"style":{"backgroundColor":"ffffff","textColor":"000000","textAlign":"raggedright","fontSize":"12","columnWidth":"none"}}]]';


  export const DEFAULT_THEME_FORMATTING_DYNAMIC =
  '[[{"style":{"backgroundColor":"ffffff","textColor":"000000","textAlign":"raggedright","fontSize":"14","columnWidth":"none"}},{"style":{"backgroundColor":"ffffff","textColor":"000000","textAlign":"raggedright","fontSize":"14","columnWidth":"none"}},{"style":{"backgroundColor":"ffffff","textColor":"000000","textAlign":"raggedright","fontSize":"14","columnWidth":"none"}}]';

const defaultTableWidth = "70";
const defaultRowSize = "0";
const defaultColumnSize = "15";

export const TABLE_THEMES = {
  default: {
    name: "Default",
    rows: 4,
    columns: 3,
    leftMargin: 0,
    rightMargin: 0,
    THBackgroundColor: "ffffff",
    THTextColor: "000000",
    THTextAlign: "raggedright",
    CellBorderColor: "000000",
    CellBorderStyle: "solid",
    CellBorderThickness: "0.352",
    CellTextAlign: "raggedright",
    evenRowBackgroundColor: "f9f9f9",
    CellTextColor: "000000",
    CellFontSize: "12",
    THFontSize: "14",
    CellBackgroundColor: "ffffff",
    stripe: "none", /// row column none
    StripeBackgroundColor: "ffffff",
    //Table properties
    tableSize: "auto",
    columnSize: defaultColumnSize, // cm
    rowSize: defaultRowSize, // cm
    tableWidth: defaultTableWidth,
    tableFont: "arial",
    tableAlignmnet: "center",
    colType: "automatic",
    rowType: "automatic",
  },
  theme1: {
    name: "Theme 1",
    rows: 4,
    columns: 3,
    THBackgroundColor: "428bca",
    THTextColor: "ffffff",
    THTextAlign: "centering",
    CellBorderColor: "357ebd",
    CellBorderStyle: "solid",
    CellBorderThickness: "0.352",
    CellTextAlign: "centering",
    evenRowBackgroundColor: "d9edf7",
    CellTextColor: "333333",
    CellFontSize: "12",
    THFontSize: "14",
    CellBackgroundColor: "ffffff",
    stripe: "row", /// row column none
    StripeBackgroundColor: "bedfed",
    //Table properties
    tableSize: "auto",
    columnSize: defaultColumnSize, // cm
    rowSize: defaultRowSize, // cm
    tableWidth: defaultTableWidth,
    tableFont: "arial",
    tableAlignmnet: "center",
  },
  theme2: {
    name: "Theme 2",
    rows: 4,
    columns: 3,
    THBackgroundColor: "d9534f",
    THTextColor: "ffffff",
    THTextAlign: "centering",
    CellBorderColor: "c9302c",
    CellBorderStyle: "none",
    CellBorderThickness: "0.352",
    CellTextAlign: "centering",
    evenRowBackgroundColor: "f2dede",
    CellTextColor: "333333",
    CellFontSize: "12",
    THFontSize: "14",
    CellBackgroundColor: "ffffff",
    stripe: "column", /// row column none
    StripeBackgroundColor: "f2f2f2",
    //Table properties
    tableSize: "auto",
    columnSize: defaultColumnSize, // cm
    rowSize: defaultRowSize, // cm
    tableWidth: defaultTableWidth,
    tableFont: "arial",
    tableAlignmnet: "center",
  },
  theme3: {
    name: "Theme 3",
    rows: 4,
    columns: 3,
    THBackgroundColor: "5cb85c",
    THTextColor: "ffffff",
    THTextAlign: "centering",
    CellBorderColor: "4cae4c",
    CellBorderStyle: "none",
    CellBorderThickness: "0.352",
    CellTextAlign: "raggedright",
    evenRowBackgroundColor: "dff0d8",
    CellTextColor: "333333",
    CellFontSize: "12",
    THFontSize: "14",
    CellBackgroundColor: "ffffff",
    stripe: "column", /// row column none
    StripeBackgroundColor: "f2f2f2",
    //Table properties
    tableSize: "auto",
    columnSize: defaultColumnSize, // cm
    rowSize: defaultRowSize, // cm
    tableWidth: defaultTableWidth,
    tableFont: "arial",
    tableAlignmnet: "center",
  },
  theme4: {
    name: "Theme 4",
    rows: 4,
    columns: 3,
    THBackgroundColor: "f0ad4e",
    THTextColor: "ffffff",
    THTextAlign: "centering",
    CellBorderColor: "eea236",
    CellBorderStyle: "none",
    CellBorderThickness: "0.352",
    CellTextAlign: "centering",
    evenRowBackgroundColor: "fcf8e3",
    CellTextColor: "333333",
    CellFontSize: "12",
    THFontSize: "14",
    CellBackgroundColor: "ffffff",
    stripe: "row", /// row column none
    StripeBackgroundColor: "f2f2f2",
    //Table properties
    tableSize: "auto",
    columnSize: defaultColumnSize, // cm
    rowSize: defaultRowSize, // cm
    tableWidth: defaultTableWidth,
    tableFont: "arial",
    tableAlignmnet: "center",
  },
  theme5: {
    name: "Theme 5",
    rows: 4,
    columns: 3,
    THBackgroundColor: "9b59b6",
    THTextColor: "ffffff",
    THTextAlign: "centering",
    CellBorderColor: "8e44ad",
    CellBorderStyle: "solid",
    CellBorderThickness: "0.352",
    CellTextAlign: "centering",
    evenRowBackgroundColor: "e8daef",
    CellTextColor: "333333",
    CellFontSize: "12",
    THFontSize: "14",
    CellBackgroundColor: "ffffff",
    stripe: "column", /// row column none
    StripeBackgroundColor: "f2f2f2",
    //Table properties
    tableSize: "auto",
    columnSize: defaultColumnSize, // cm
    rowSize: defaultRowSize, // cm
    tableWidth: defaultTableWidth,
    tableFont: "arial",
    tableAlignmnet: "center",
  },
  theme6: {
    name: "Theme 6",
    rows: 4,
    columns: 3,
    THBackgroundColor: "4C33FF",
    THTextColor: "ffffff",
    THTextAlign: "raggedright",
    CellBorderColor: "4C33FF",
    CellBorderStyle: "solid",
    CellBorderThickness: "0.352",
    CellTextAlign: "raggedright",
    evenRowBackgroundColor: "ecf0f1",
    CellTextColor: "333333",
    CellFontSize: "12",
    THFontSize: "14",
    CellBackgroundColor: "ffffff",
    stripe: "row", /// row column none
    StripeBackgroundColor: "f2f2f2",
    //Table properties
    tableSize: "auto",
    columnSize: defaultColumnSize, // cm
    rowSize: defaultRowSize, // cm
    tableWidth: defaultTableWidth,
    tableFont: "arial",
    tableAlignmnet: "center",
    // ... other properties for theme6
  },
  theme7: {
    name: "Theme 7",
    rows: 4,
    columns: 3,
    THBackgroundColor: "000000",
    THTextColor: "ffffff",
    THTextAlign: "centering",
    CellBorderColor: "000000",
    CellBorderStyle: "solid",
    CellBorderThickness: "0.352",
    CellTextAlign: "centering",
    evenRowBackgroundColor: "ecf0f1",
    CellTextColor: "000000",
    CellFontSize: "12",
    THFontSize: "14",
    CellBackgroundColor: "ffffff",
    stripe: "column", /// row column none
    StripeBackgroundColor: "f2f2f2",
    //Table properties
    tableSize: "auto",
    columnSize: defaultColumnSize, // cm
    rowSize: defaultRowSize, // cm
    tableWidth: defaultTableWidth,
    tableFont: "arial",
    tableAlignmnet: "center",
    // ... other properties for theme7
  },
  theme8: {
    name: "Theme 8",
    rows: 4,
    columns: 3,
    THBackgroundColor: "27c471",
    THTextColor: "000000",
    THTextAlign: "centering",
    CellBorderColor: "27ae60",
    CellBorderStyle: "solid",
    CellBorderThickness: "0.352",
    CellTextAlign: "centering",
    evenRowBackgroundColor: "ecf0f1",
    CellTextColor: "000000",
    CellFontSize: "12",
    THFontSize: "14",
    CellBackgroundColor: "ecf0f1",
    stripe: "row", /// row column none
    StripeBackgroundColor: "f2f2f2",
    //Table properties
    tableSize: "auto",
    columnSize: defaultColumnSize, // cm
    rowSize: defaultRowSize, // cm
    tableWidth: defaultTableWidth,
    tableFont: "arial",
    tableAlignmnet: "center",
    // ... other properties for theme8
  },
  theme9: {
    name: "Theme 9",
    rows: 4,
    columns: 3,
    THBackgroundColor: "f39c12",
    THTextColor: "ffffff",
    THTextAlign: "centering",
    CellBorderColor: "d35400",
    CellBorderStyle: "solid",
    CellBorderThickness: "0.352",
    CellTextAlign: "centering",
    evenRowBackgroundColor: "ecf0f1",
    CellTextColor: "333333",
    CellFontSize: "12",
    THFontSize: "14",
    CellBackgroundColor: "ffffff",
    stripe: "column", /// row column none
    StripeBackgroundColor: "f2f2f2",
    //Table properties
    tableSize: "auto",
    columnSize: defaultColumnSize, // cm
    rowSize: defaultRowSize, // cm
    tableWidth: defaultTableWidth,
    tableFont: "arial",
    tableAlignmnet: "center",
    // ... other properties for theme9
  },
  theme10: {
    name: "Theme 10",
    rows: 4,
    columns: 3,
    THBackgroundColor: "95a5a6",
    THTextColor: "ffffff",
    THTextAlign: "centering",
    CellBorderColor: "7f8c8d",
    CellBorderStyle: "solid",
    CellBorderThickness: "0.352",
    CellTextAlign: "centering",
    evenRowBackgroundColor: "ecf0f1",
    CellTextColor: "333333",
    CellFontSize: "12",
    THFontSize: "14",
    CellBackgroundColor: "ffffff",
    stripe: "column", /// row column none
    StripeBackgroundColor: "f2f2f2",
    //Table properties
    tableSize: "auto",
    columnSize: defaultColumnSize, // cm
    rowSize: defaultRowSize, // cm
    tableWidth: defaultTableWidth,
    tableFont: "arial",
    tableAlignmnet: "center",
  },
  theme11: {
    name: "Theme 11",
    rows: 4,
    columns: 3,
    THBackgroundColor: "1abc9c",
    THTextColor: "ffffff",
    THTextAlign: "centering",
    CellBorderColor: "16a085",
    CellBorderStyle: "solid",
    CellBorderThickness: "0.352",
    CellTextAlign: "centering",
    evenRowBackgroundColor: "ecf0f1",
    CellTextColor: "333333",
    CellFontSize: "12",
    THFontSize: "14",
    CellBackgroundColor: "ffffff",
    stripe: "column", /// row column none
    StripeBackgroundColor: "f2f2f2",
    //Table properties
    tableSize: "auto",
    columnSize: defaultColumnSize, // cm
    rowSize: defaultRowSize, // cm
    tableWidth: defaultTableWidth,
    tableFont: "arial",
    tableAlignmnet: "center",
    // ... other properties for theme11
  },
  theme12: {
    name: "Theme 12",
    rows: 4,
    columns: 3,
    THBackgroundColor: "34495e",
    THTextColor: "ffffff",
    THTextAlign: "centering",
    CellBorderColor: "2c3e50",
    CellBorderStyle: "solid",
    CellBorderThickness: "0.352",
    CellTextAlign: "centering",
    evenRowBackgroundColor: "7f8c8d",
    CellTextColor: "333333",
    CellFontSize: "12",
    THFontSize: "14",
    CellBackgroundColor: "ffffff",
    stripe: "row", /// row column none
    StripeBackgroundColor: "95a5a6",
    //Table properties
    tableSize: "auto",
    columnSize: defaultColumnSize, // cm
    rowSize: defaultRowSize, // cm
    tableWidth: defaultTableWidth,
    tableFont: "arial",
    tableAlignmnet: "center",
  },
  theme13: {
    name: "Theme 13",
    rows: 4,
    columns: 3,
    THBackgroundColor: "d35400",
    THTextColor: "ffffff",
    THTextAlign: "centering",
    CellBorderColor: "e74c3c",
    CellBorderStyle: "solid",
    CellBorderThickness: "0.352",
    CellTextAlign: "centering",
    evenRowBackgroundColor: "f39c12",
    CellTextColor: "333333",
    CellFontSize: "12",
    THFontSize: "14",
    CellBackgroundColor: "ffffff",
    stripe: "none", /// row column none
    StripeBackgroundColor: "e67e22",
    //Table properties
    tableSize: "auto",
    columnSize: defaultColumnSize, // cm
    rowSize: defaultRowSize, // cm
    tableWidth: defaultTableWidth,
    tableFont: "arial",
    tableAlignmnet: "center",
  },
  theme14: {
    name: "Theme 14",
    rows: 4,
    columns: 3,
    THBackgroundColor: "c0392b",
    THTextColor: "ffffff",
    THTextAlign: "centering",
    CellBorderColor: "e74c3c",
    CellBorderStyle: "solid",
    CellBorderThickness: "0.352",
    CellTextAlign: "centering",
    evenRowBackgroundColor: "f2f2f2",
    CellTextColor: "333333",
    CellFontSize: "12",
    THFontSize: "14",
    CellBackgroundColor: "ffffff",
    stripe: "column", /// row column none
    StripeBackgroundColor: "ecf0f1",
    //Table properties
    tableSize: "auto",
    columnSize: defaultColumnSize, // cm
    rowSize: defaultRowSize, // cm
    tableWidth: defaultTableWidth,
    tableFont: "arial",
    tableAlignmnet: "center",
    // ... other properties for theme14
  },

  theme15: {
    name: "Theme 15",
    rows: 4,
    columns: 3,
    THBackgroundColor: "d4ac0d", // Sand yellow
    THTextColor: "ffffff", // White
    THTextAlign: "center", // Center
    CellBorderColor: "8e44ad", // Deep purple
    CellBorderStyle: "none", // Solid border
    CellBorderThickness: "0.5", // Medium thickness
    CellTextAlign: "center", // Center
    evenRowBackgroundColor: "d98880", // Coral pink
    CellTextColor: "333333", // Dark gray
    CellFontSize: "12", // Medium font size
    THFontSize: "12", // Larger font size for headers
    CellBackgroundColor: "ffffff", // White background
    stripe: "column", // Stripe columns
    StripeBackgroundColor: "d4ac01", // Light purple stripe
    // Table properties
    tableSize: "auto", // Auto table size
    columnSize: defaultColumnSize, // Default column size
    rowSize: defaultRowSize, // Default row size
    tableWidth: defaultTableWidth, // Default table width
    tableFont: "Arial", // Font family
    tableAlignment: "center" // Center align the table
    // ... other properties for theme14
  },

  theme16: {
    name: "Theme 16",
    rows: 4,
    columns: 3,
    THBackgroundColor: "007fff", // Azure
    THTextColor: "#ffffff", // White
    THTextAlign: "center", // Center
    CellBorderColor: "4682b4", // Steel blue
    CellBorderStyle: "solid", // Solid border
    CellBorderThickness: "0.5px", // Thin border
    CellTextAlign: "center", // Center
    evenRowBackgroundColor: "b0e0e6", // Powder blue
    CellTextColor: "#000000", // Black
    CellFontSize: "12", // Medium font size
    THFontSize: "12", // Larger font size for headers
    CellBackgroundColor: "ffffff", // Azure
    stripe: "row", // No stripes
    StripeBackgroundColor: "b0e0e6", // No stripes
    // Table properties
    tableSize: "auto", // Auto table size
    columnSize: "110px", // Default column size
    rowSize: "50px", // Default row size
    tableWidth: "100%", // Full width
    tableFont: "Tahoma, sans-serif", // Font family
    tableAlignment: "center", // Center align the table
  },
  theme17: {
    name: "Theme 17",
    rows: 4,
    columns: 3,
    THBackgroundColor: "ecf0f1", // Frost white
    THTextColor: "2c3e50", // Dark blue
    THTextAlign: "center", // Center
    CellBorderColor: "3498db", // Sky blue
    CellBorderStyle: "solid", // Solid border
    CellBorderThickness: "0.5", // Medium thickness
    CellTextAlign: "center", // Center
    evenRowBackgroundColor: "bdc3c7", // Silver
    CellTextColor: "333333", // Dark gray
    CellFontSize: "12", // Medium font size
    THFontSize: "12", // Larger font size for headers
    CellBackgroundColor: "ffffff", // White background
    stripe: "none", // No stripes
    StripeBackgroundColor: "", // No stripes
    // Table properties
    tableSize: "auto", // Auto table size
    columnSize: defaultColumnSize, // Default column size
    rowSize: defaultRowSize, // Default row size
    tableWidth: defaultTableWidth, // Default table width
    tableFont: "Arial", // Font family
    tableAlignment: "center" // Center align the table
  },
  theme18: {
    name: "Theme 18",
    rows: 4,
    columns: 3,
    THBackgroundColor: "dc143c", // Crimson
    THTextColor: "ffffff", // White
    THTextAlign: "center", // Center
    CellBorderColor: "800000", // Maroon
    CellBorderStyle: "solid", // Solid border
    CellBorderThickness: "0.5px", // Thin border
    CellTextAlign: "center", // Center
    evenRowBackgroundColor: "8b0000", // Dark red
    CellTextColor: "dc143c", // White
    CellFontSize: "12", // Medium font size
    THFontSize: "12", // Larger font size for headers
    CellBackgroundColor: "fffff", // Crimson
    stripe: "none", // No stripes
    StripeBackgroundColor: "8b0000", // No stripes
    // Table properties
    tableSize: "auto", // Auto table size
    columnSize: "120px", // Default column size
    rowSize: "60px", // Default row size
    tableWidth: "100%", // Full width
    tableFont: "Georgia, serif", // Font family
    tableAlignment: "center", // Center align the table
  },
  theme19: {
    name: "Theme 19",
    rows: 4,
    columns: 3,
    THBackgroundColor: "34495e", // Midnight blue
    THTextColor: "ffffff", // White
    THTextAlign: "center", // Center
    CellBorderColor: "ffffff", // White
    CellBorderStyle: "solid", // Solid border
    CellBorderThickness: "0.5px", // Thin border
    CellTextAlign: "center", // Center
    evenRowBackgroundColor: "95a5a6", // Light slate gray
    CellTextColor: "34495e", // White
    CellFontSize: "12", // Medium font size
    THFontSize: "12", // Larger font size for headers
    CellBackgroundColor: "#2c3e50", // Dark slate blue
    stripe: "row", // Stripe rows
    StripeBackgroundColor: "95a5a6", // Gray stripe
    // Table properties
    tableSize: "auto", // Auto table size
    columnSize: "110px", // Default column size
    rowSize: "50px", // Default row size
    tableWidth: "100%", // Full width
    tableFont: "Courier New, monospace", // Font family
    tableAlignment: "center", // Center align the table
  },
  theme20: {
    name: "Theme 20",
    rows: 4,
    columns: 3,
    THBackgroundColor: "ffd700", // Gold
    THTextColor: "ffffff", // White
    THTextAlign: "center", // Center
    CellBorderColor: "ffd700", // Dark green
    CellBorderStyle: "solid", // Solid border
    CellBorderThickness: "0.5px", // Thin border
    CellTextAlign: "center", // Center
    evenRowBackgroundColor: "8b4513", // Saddle brown
    CellTextColor: "000000", // White
    CellFontSize: "12", // Medium font size
    THFontSize: "12", // Larger font size for headers
    CellBackgroundColor: "fffff", // Gold
    stripe: "none", // No stripes
    StripeBackgroundColor: "8b4513", // No stripes
    // Table properties
    tableSize: "auto", // Auto table size
    columnSize: "130px", // Default column size
    rowSize: "70px", // Default row size
    tableWidth: "100%", // Full width
    tableFont: "Arial, sans-serif", // Font family
    tableAlignment: "center", // Center align the table
}
}


// avaiable font List
export const font_Families = [
  { label: "American Typewriter", value: "americantypewriter" },
  { label: "Arial", value: "arial" },
  { label: "Avenir", value: "avenir" },
  { label: "Baskerville", value: "baskerville" },
  { label: "Bodoni", value: "bodoni" },
  { label: "Bodoni MT", value: "bodonimt" },
  { label: "Bookman", value: "bookman" },
  { label: "Bradley Hand", value: "bradleyhand" },
  { label: "Cambria", value: "cambria" },
  { label: "Candara", value: "candara" },
  { label: "Century Gothic", value: "centurygothic" },
  { label: "Charlemagne", value: "charlemagne" },
  { label: "Cochin", value: "cochin" },
  { label: "Courier New", value: "couriernew" },
  { label: "Didot", value: "didot" },
  { label: "Edwardian Script", value: "edwardianscript" },
  { label: "Eurostile", value: "eurostile" },
  { label: "Futura", value: "futura" },
  { label: "Garamond Premier Pro", value: "garamondpremierpro" },
  { label: "Georgia", value: "georgia" },
  { label: "Gill Sans", value: "gillsans" },
  { label: "Helvetica", value: "helvetica" },
  { label: "Imprint", value: "imprint" },
  { label: "Joanna", value: "joanna" },
  { label: "Kozuka Gothic", value: "kozukagothic" },
  { label: "Minion", value: "courier" },
  { label: "Perpetua", value: "perpetua" },
  { label: "Tahoma", value: "tahoma" },
  { label: "Twentieth Century", value: "twentiethcentury" },
  { label: "Verdana", value: "verdana" },
];

export const dummyString = JSON.parse(
  '{"id":1,"title":"Heading 1","topMargin":"0","bottomMargin":"12","showColorPicker":false,"fontColor":"df0b0b","font":"arial","fontSize":"12","alignment":"raggedright","fontStyle":[""],"indent":0,"format":"arabic","separator":".","prefix":"(","suffix":")","compound":true}'
);

//   export const font_Families =
// [
//     { label: "Arial", value: "arial" },
//     // { label: "AvantGarde", value: "avantgarde" },
//     { label: "Baskerville", value: "baskerville" },
//     { label: "Bodoni", value: "bodoni" },
//     { label: "Bookman", value: "bookman" },
//     { label: "Century Gothic", value: "centurygothic" },
//     // { label: "Chancery", value: "chancery" },
//     // { label: "Computer Modern", value: "computerm" },
//     // { label: "Courier", value: "courier" },
//     { label: "Eurostile", value: "eurostile" },

//     // { label: "Garamond", value: "garamond" },
//     { label: "Georgia", value: "georgia" },
//     { label: "Gill Sans", value: "gillsans" },
//     { label: "Helvetica", value: "helvetica" },
//     // { label: "Hoefler Text", value: "hoefler" },
//     // { label: "Impact", value: "impact" },
//     // { label: "Latin Modern", value: "impact" },
//     // { label: "Lucida", value: "lucida" },
//     { label: "Minion", value: "courier" },
//     // { label: "Optima", value: "optima" },
//     // { label: "Palatino", value: "palatino" },
//     // { label: "Rockwell", value: "rockwell" },
//     // { label: "Brush Script", value: "brushscript" },
//     // { label: "Souvenir", value: "souvenir" },
//     // { label: "Times", value: "timesfont" },
//     // { label: "Univers", value: "univers" },
//     { label: "Verdana", value: "verdana" },
//     // { label: "Zapf Chancery", value: "zapfchancery" },
//     { label: "Futura", value: "futura" },
//     // { label: "Adobe Caslon", value: "adobecaslon" },
//     // { label: "Albertus", value: "albertus" },
//     { label: "American Typewriter", value: "americantypewriter" },
//     // { label: "Andale Mono", value: "andalemono" },
//     { label: "Avenir", value: "avenir" },
//     // { label: "Bembo", value: "bembo" },
//     // { label: "Berling", value: "berling" },
//     // { label: "Berlin Sans", value: "berlinsans" },
//     // { label: "Blackadder", value: "blackadder" },
//     { label: "Bodoni MT", value: "bodonimt" },
//     { label: "Bradley Hand", value: "bradleyhand" },

//     // { label: "Calibri", value: "calibri" },
//     { label: "Cambria", value: "cambria" },
//     { label: "Candara", value: "candara" },
//     // { label: "Castellar", value: "castellar" },
//     { label: "Charlemagne", value: "charlemagne" },
//     { label: "Cochin", value: "cochin" },
//     // { label: "Colonna", value: "colonna" },
//     // { label: "Comic Sans", value: "comicsans" },
//     // { label: "Cooper", value: "cooper" },
//     // { label: "Copperplate", value: "copperplate" },
//     { label: "Courier New", value: "couriernew" },
//     { label: "Didot", value: "didot" },
//     { label: "Edwardian Script", value: "edwardianscript" },
//     // { label: "Elephant", value: "elephant" },
//     // { label: "Engravers", value: "engravers" },
//     // { label: "Euphemia", value: "euphemia" },
//     // { label: "Florence", value: "florence" },
//     // { label: "Folio", value: "folio" },
//     // { label: "Franklin Gothic", value: "franklingothic" },
//     // { label: "Frutiger", value: "frutiger" },
//     // { label: "Futura PT", value: "futurapt" },
//     // { label: "Gabriola", value: "gabriola" },
//     { label: "Garamond Premier Pro", value: "garamondpremierpro" },
//     // { label: "Geneva", value: "geneva" },
//     // { label: "Gentium", value: "gentium" },
//     // { label: "Gill Sans MT", value: "gillsansmt" },
//     // { label: "Gloucester", value: "gloucester" },
//     // { label: "Harrington", value: "harrington" },
//     // { label: "Hausa", value: "hausa" },
//     // { label: "Humanist", value: "humanist" },
//     { label: "Imprint", value: "imprint" },
//     // { label: "ITC Benguiat", value: "itcbenguiat" },
//     { label: "Joanna", value: "joanna" },
//     // { label: "Kaufmann", value: "kaufmann" },
//     { label: "Kozuka Gothic", value: "kozukagothic" },
//     // { label: "Lato", value: "lato" },
//     // { label: "Lavanderia", value: "lavanderia" },
//     // { label: "Leelawadee", value: "leelawadee" },
//     // { label: "Lobster", value: "lobster" },
//     // { label: "Matisse", value: "matisse" },
//     // { label: "Mistral", value: "mistral" },
//     // { label: "Monotype Corsiva", value: "monotypecorsiva" },
//     // { label: "Myriad", value: "myriad" },
//     // { label: "Neutraface", value: "neutraface" },
//     // { label: "OCR A", value: "ocra" },
//     // { label: "Old English", value: "oldenglish" },
//     // { label: "Onyx", value: "onyx" },
//     // { label: "Optima Nova", value: "optimanova" },
//     // { label: "Orator", value: "orator" },
//     // { label: "Papyrus", value: "papyrus" },
//     { label: "Perpetua", value: "perpetua" },
//     // { label: "Ravie", value: "ravie" },
//     // { label: "Segoe UI", value: "segoeui" },
//     // { label: "Showcard Gothic", value: "showcardgothic" },
//     // { label: "Snell Roundhand", value: "snellroundhand" },
//     // { label: "Stempel Garamond", value: "stempelgaramond" },
//     { label: "Tahoma", value: "tahoma" },
//     // { label: "Trajan", value: "trajan" },
//     { label: "Twentieth Century", value: "twentiethcentury" },
//     // { label: "Zurich", value: "zurich" }
//   ];

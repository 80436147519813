import { client } from './client'; 
import { User, Workspace, WorkspaceCreateRequest } from '../types/User';

export function apiGetWorkspaces(organizationId: number) {
  return client.get(`/organizations/${organizationId}/workspaces`)
}

export function apiGetWorkspace(organizationId: number, workspaceId: number) {
  return client.get(`/organizations/${organizationId}/workspaces/${workspaceId}`)
}

export function apiGetWorkspaceSummary(organizationId: number, workspaceId: number) {
  return client.get(`/organizations/${organizationId}/workspaces/${workspaceId}/tsummary`)
}
// export function apiGetWorkspaceSummarySearch(organizationId: number, workspaceId: number, search:string | undefined, IsOnceoffDocument?:boolean | undefined , IsOtherWorkSpace?:boolean | undefined,  Purpose?:string | undefined,  Category?:string | undefined , StartDate?:string | undefined ,  EndDate?:string | undefined  ) {
//   return client.post(`/organizations/${organizationId}/workspaces/${workspaceId}/summary_search`,${workspaceId} {
//    // search
//     search,
//     IsOnceoffDocument,
//     IsOtherWorkSpace,
//     Purpose,
//     Category,
//     StartDate,
//     EndDate
//   })
// }

export function apiGetWorkspaceSummarySearch(organizationId: number, workspaceId: number, search:any | undefined) {
  return client.post(`/organizations/${organizationId}/workspaces/${workspaceId}/t_summary_search`,search ,{
  })
}

export function apiGetWorkspaceSummaryDocument(organizationId: number, workspaceId: number, documentID:number) {
  return client.post(`/organizations/${organizationId}/workspaces/${workspaceId}/document_template/${documentID}/get_template_summary`,
  {})
}

export function apiGetWorkspaceSearch(organizationId: number, workspaceId: number , search:any) {
  return client.post(`/organizations/${organizationId}/workspaces/${workspaceId}/summary_search2`,search,{})
}

export function apiCreateWorkspace(organizationId: number, workspace: WorkspaceCreateRequest) {
  return client.post(`/organizations/${organizationId}/workspaces`, workspace)
}

export function apiDeleteWorkspace(organizationId: number, workspace: Workspace) {
  return client.delete(`/organizations/${organizationId}/workspaces/${workspace.id}`)
}

export function apiAttachUser(organizationId: number, workspace: Workspace, user: User, role: string) {
  return client.post(`/organizations/${organizationId}/workspaces/${workspace.id}/attach/${user.id}`, {
    role_type: role
  })
}

export function apiCreateUser(organizationId: number, workspace: Workspace, email: string, password: string) {
  return client.post(`/organizations/${organizationId}/workspaces/${workspace.id}/users`, {
    email, password
  })
}

export function apiDetachUser(organizationId: number, workspace: Workspace, user: User) {
  return client.post(`/organizations/${organizationId}/workspaces/${workspace.id}/detach/${user.id}`, {})
}

export function apiDetachOrganizationUser(organizationId: number, workspace: Workspace, user: User) {
  return client.post(`/organizations/${organizationId}/detach/${user.id}`, {})
}

export function apiUpdateOrganizationUserRole(organizationId: number, user: number, updatedRole:string) {
  return client.put(`/organizations/${organizationId}/users/${user}/role_type/${updatedRole}`, {})
}

export function apiUpdateOrganization(organizationId: number, workspace: Workspace) {
  return client.put(`/organizations/${organizationId}/workspaces/${workspace.id}`, workspace)
}

export function apiChangeUserRole(organizationId: number, workspace: Workspace, user_id: number, role:string) {
  return client.post(`/organizations/${organizationId}/workspaces/${workspace.id}/change_role/${user_id}`, { role })
}

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { DocumentSection } from "../../../../../types/DocumentTemplate";
import useActions, {
  useAppDispatch,
  useAppSelector,
} from "../../../../../app/hooks";
import { selectSections } from "../../../editorSlice";
import TableOfContentsItem from "./TableOfContentsSection";
import ListSubheader from "@mui/material/ListSubheader";
import {
  selectApprovalMode,
  selectEditingDocumentTemplate,
} from "../../../editorSlice";
import { ListAltRounded } from "@mui/icons-material";

export function TableOfContents(props: any) {
  const actions = useActions();
  const sections = useAppSelector(selectSections);

  const approvalsEnabled = useAppSelector(selectApprovalMode);

  const editingDocumentTemplate = useAppSelector(selectEditingDocumentTemplate);

  /**
   * Sort sections when they are dragged around.
   *
   * @param {*} result
   * @return {*}
   */
  function onDragEnd(result: any) {
    const updatedSectionsList = sections.filter(
      (section) => section.pending_deleted_at === null
    );

    if (!result.destination) {
      return;
    }

    actions.switchSections({
      sourceSection: updatedSectionsList[result.source.index],
      destinationSection: updatedSectionsList[result.destination.index],
    });
  }

  // const handleApprovalPublishClicked = () => {
  //   actions.approvalPublishDocumentTemplate(editingDocumentTemplate);
  // };
  const handleApprovalPublishClicked = () => {
    actions.approvalImpactChanges(editingDocumentTemplate);
  };

  return (
    <Box>
      {/* Add code here for show Document Name*/}

      <Typography
        variant="h4"
        color="Black"
        align="center"
        sx={{
          border: "1px solid gray",
          borderRadius: "2px",
          padding: "5px",
          maxWidth: "100%", // Limit the width to 90% of the container
          fontSize: 22, // Adjust the font size as needed
          whiteSpace: "nowrap", // Prevent text from wrapping
          overflow: "hidden", // Hide overflow
          textOverflow: "ellipsis", // Show ellipsis for truncated text
        }}
      >
        {editingDocumentTemplate.name}
      </Typography>

      {!editingDocumentTemplate.import_mode && (
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              <span
                style={{ fontWeight: "bold", color: "black", fontSize: "16px" }}
              >
                Navigation
              </span>
            </ListSubheader>
          }
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="section_droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                >
                  {!approvalsEnabled &&
                    sections
                      .filter((section) => section.pending_deleted_at === null)
                      .map((section: DocumentSection, index: number) => (
                        <Draggable
                          key={section.id}
                          draggableId={String(section.id)}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              // style={getItemStyle(
                              //   snapshot.isDragging,
                              //   provided.draggableProps.style
                              // )}
                            >
                              <TableOfContentsItem
                                id={index}
                                key={index}
                                section={section}
                                icon={<LibraryBooksIcon color="primary" />}
                                isDragging={snapshot.isDragging}
                                draggableProps={provided.dragHandleProps}
                              />
                            </Box>
                          )}
                        </Draggable>
                      ))}

                  {approvalsEnabled &&
                    sections.map((section: DocumentSection, index: number) => (
                      <Draggable
                        key={section.id}
                        draggableId={String(section.id)}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            // style={getItemStyle(
                            //   snapshot.isDragging,
                            //   provided.draggableProps.style
                            // )}
                          >
                            <TableOfContentsItem
                              id={index}
                              key={index}
                              section={section}
                              icon={
                                section.pending_addition ? (
                                  <CreateIcon style={{ color: "green" }} />
                                ) : section.pending_deleted_at ? (
                                  <DeleteIcon style={{ color: "red" }} />
                                ) : section.pending_heading ? (
                                  <ChangeCircleIcon
                                    style={{ color: "orange" }}
                                  />
                                ) : null
                              }
                              isDragging={snapshot.isDragging}
                              draggableProps={provided.dragHandleProps}
                            />
                          </Box>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </List>
      )}
    </Box>
  );
}

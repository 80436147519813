/* WYSIWYG Editing cell, which produces and parses markdown.
Draft.js react editor.
REF: https://jpuri.github.io/react-draft-wysiwyg/#/docs.
REF: https://draftjs.org/docs/api-reference-data-conversion
Exporting and importing reference: https://www.npmjs.com/package/markdown-draft-js
*/
import React, {
  Component,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TableChartSharpIcon from "@mui/icons-material/TableChartSharp";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import GridOnIcon from "@mui/icons-material/GridOn";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";

import {
  countTableElementInDocumentTemplate,
  findMatchingFont,
  getFontSize,
  highlightVariables,
  removeWhitespace,
  handleDetachSelection,
  applyMarkdownSyntax,
} from "../../HelperEditorFunctions";
import { Editor } from "react-draft-wysiwyg";
import ".././tableStyle.css";
import "../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  RichUtils,
  convertFromRaw,
  convertToRaw,
  ContentBlock,
  SelectionState,
} from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
// Snackbar notification integration. REF: https://www.npmjs.com/package/material-ui-snackbar-provider
import { useSnackbar } from "notistack";
import { selectDocumentMasterState } from "../../../header/documentMasterSlice";
import DynamicTableElementForm from "./DynamicTableElementDialog";
import ReactMarkdown from "react-markdown";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import {
  ContentType,
  DocumentElement,
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateSummary,
} from "../../../../../types/DocumentTemplate";

import { useTimer } from "react-timer-hook";
import { useDrop } from "react-dnd";

import useActions, {
  useAppDispatch,
  useAppSelector,
} from "../../../../../app/hooks";
import {
  approveChangedSection,
  approveDeletedSection,
  clearSelectedComponent,
  declineChangedSection,
  declineDeletedSection,
  selectApprovalState,
  selectApprovedChangedSections,
  selectApprovedDeletedSections,
  selectCurrentTemplateState,
  selectDeclinedChangedSections,
  selectDeclinedDeletedSections,
  selectSelected,
  selectSelectedTable,
  selectSkippedChangedSections,
  selectSkippedDeletedSections,
  setSelectedComponent,
  setSelectedTableComponent,
  skipChangedSection,
  skipDeletedSection,
} from "../../../editorSlice";
import GridOffIcon from "@mui/icons-material/GridOff";
import {
  selectCurrentEditorState,
  setEditorState,
} from "../../../markdownTextEditorSlice";
import validateContent from "../../validation";
import {
  COMMIT_CHANGES_TIME_SEC,
  DEFAULT_THEME_FORMATTING,
  secondarySilver,
  TABLE_THEMES,
} from "../../../../../globals";
import { ViewPreferences } from "../../../header/preferencesSlice";
import Skeleton from "@mui/material/Skeleton";
import Grow from "@mui/material/Grow";
import {
  editorStateToMarkdown,
  isDeletedSubsection,
  markdowntoEditorState,
  normalizeText,
} from "../../utils";
import { DropSkeleton } from "../../DropSkeleton";
import {
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { LeftDrawer } from "../../../drawers/left/LeftDrawer";
import LibraryContentConfigFormSubSection from "../../libraryContentSubSection/LibraryContentFormSubSection";
import TableElementTheme from ".././ThemeTemplate/TableElementTheme";
import {
  convertMmToPt,
  convertMmToPx,
  currentIndex,
  formatTableWithTheme,
  getAlignment,
  getCellStyle,
  getDefaultThemeDetails,
  TABLE_ALIGNMENT,
  updateCellBackgroundFormatting,
  updateCellFormatting,
} from ".././HelperFuctions/TableUtils";
import {
  deleteColumn,
  deleteRow,
  deleteRowOrColumn,
  duplicateColumnFormatting,
  duplicateColumnLeft,
  duplicateColumnRight,
  duplicateRowAbove,
  duplicateRowBelow,
  DuplicateRowFormatting,
  insertColumnLeft,
  insertColumnRight,
  insertRowAbove,
  insertRowBelow,
  moveColumn,
  moveColumnLeft,
  moveColumnRight,
  moveRow,
  moveRowAbove,
  moveRowBelow,
  updateColumnAlignment,
  updateColumnBackground,
  updateColumnTextColor,
  updateColumnWidth,
  updateRowAlignmnet,
  updateRowBackground,
  updateRowTextColor,
} from ".././HelperFuctions/TableElementHelperFunctions";
import ContextMenu from ".././ContextMenu/ContextMenu";
import { isIncludeImpact } from "../../../drawers/right/Approval/ApprovalHelperFunctions/approvalHelper";
import DeleteConfirmForm from "../../CustomEditorDecorators/DeleteConfirmForm";
import SwitchSubsection from "../../CustomEditorDecorators/SwitchSubSection";
import ImportContentFromExcel from ".././UploadExcelFile/ImportContentFromExcel";
import LibraryContentIcon from "../../CustomEditorDecorators/LibraryContentIcon";
import EditorUndoForm from "../../CustomEditorDecorators/EditorUndoForm";
import { COLORS } from "../../../../shared/Constants";
import { shallowEqual } from "react-redux";
import ApprovalIcon from "../../CustomEditorDecorators/ApporvalIcon";
import DeleteUnsharedConfirm from "../../CustomEditorDecorators/DeleteUnsharedConfirm";
// import { setTimeout } from "timers";

export function getTableArray(
  input: string,
  rows: number,
  columns: number
): Array<Array<string>> {
  let originalTable = input.split("!TR").map((_) => _.split("!TC"));

  if (originalTable.length < rows) {
    while (originalTable.length < rows) {
      originalTable.push([]);
    }
  }

  for (let i = 0; i < originalTable.length; i++) {
    if (originalTable[i].length < columns) {
      while (originalTable[i].length < columns) {
        originalTable[i].push("");
      }
    }
  }

  if (originalTable.length > rows) {
    originalTable = originalTable.slice(0, rows);
    for (let i = 0; i < originalTable.length; i++) {
      if (originalTable[i].length > columns) {
        originalTable[i] = originalTable[i].slice(0, columns);
      }
    }
  }

  // console.log("the input of table" , input ,"originalTable" , originalTable )

  return originalTable;
}

/**
 * Table editor for a document element.
 * Responsible for allowing editing and persisting the changes asynchronously.
 *
 * @export
 * @param {({
 *   element: DocumentElement | null;
 *   parentSubsection: DocumentSubsection | null;
 *   parentSection: DocumentSection;
 *   documentTemplate: DocumentTemplate;
 *   organizationId: number;
 *   workspaceId: number;
 *   viewPreferences: ViewPreferences;
 * })} props
 * @return {*}
 */
export function DynamicTableElementEditor(props: {
  element: DocumentElement | null;
  parentSubsection: DocumentSubsection | null;
  parentSection: DocumentSection | DocumentSectionSummary;
  parentSection__: DocumentSection | null;
  documentTemplate: DocumentTemplate | DocumentTemplateSummary;
  organizationId: number;
  workspaceId: number;
  viewPreferences: ViewPreferences;
  readOnly?: boolean;
  forApproval: boolean;
  approvalsEnabled: boolean;
}) {
  const dispatch = useAppDispatch();
  const actions = useActions();
  const approvalState = useAppSelector(selectApprovalState);
  const selectedCurrentTemplateState = useAppSelector(
    selectCurrentTemplateState,
    shallowEqual
  );
  const [isDeleteConfigOpen, setIsDeleteConfigOpen] =
    React.useState<boolean>(false);
    const [isDeleteUnsharedOpen, setIsDeleteUnsharedOpen] =
    useState<boolean>(false);  
  const [isImportConfigOpen, setIsImportConfigOpen] =
    React.useState<boolean>(false);
  const [isFocued, setIsisFocued] = React.useState<boolean>(false);
  const editorRef = useRef<Editor>(null);
  const useRefBox = useRef(null);
  const [IsLibraryOpen, SetIsLibarayOpen] = useState<boolean>(false);
  const selected = useAppSelector(selectSelected);
  const selectedTableState = useAppSelector(selectSelectedTable);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

  const startElement = React.useMemo(() => {
    return JSON.parse(props.element!.content_format);
  }, [props.element]);

  const [isNumberFormat, setIsNumberFormat] = React.useState<boolean>(
    startElement.isNumberFormat ? startElement.isNumberFormat : false
  );
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] =
    useState<boolean>(false);

  const [columns, setColumns] = React.useState<number>(startElement.columns);
  const [rows, setRows] = React.useState<number>(startElement.rows);
  const [caption, setCaption] = React.useState<number>(startElement.caption);
  const [column, setColumn] = React.useState<number>(999);
  const [row, setRow] = React.useState<number>(999);

  const [captionAlignment, setCaptionAlignment] = React.useState<string>(
    startElement.captionAlignment ? startElement.captionAlignment : "Center"
  );

  const [hideTable, setHideTable] = React.useState<boolean>(false);

  const [isDisplayCaption, setIsDisplayCaption] = React.useState<boolean>(
    startElement.displayCaption ? startElement.displayCaption : false
  );
  const [captionOrder, setCaptionOrder] = React.useState<string>(
    startElement.captionOrder ? startElement.captionOrder : "Below"
  );
  const [fontSize, setFontsize] = React.useState<string>(
    startElement.fontSize ? startElement.fontSize : "16"
  );
  const [color, setColor] = React.useState<string>(
    startElement.color ? startElement.color : "black"
  );
  const isDeleted = props.parentSubsection?.pending_deleted_at != null;
  const isSelected =
    props.element === null || selected.documentElement === null
      ? false
      : selected.documentElement!.id == props.element!.id;
  const [font, setFont] = React.useState<string>(
    startElement.font ? startElement.font : "rmfamily"
  );
  const [originalTable, setOriginalTable] = React.useState<
    Array<Array<string>>
  >(getTableArray(props.element!.content, rows, columns));

  useEffect(() => {
    const updated = JSON.parse(props.element!.content_format);
    setColumns(updated.columns);
    setRows(updated.Rows);
    setOriginalTable(
      getTableArray(props.element!.content, updated.rows, updated.columns)
    );
  }, [props.element?.content_format]);

  //  const originalMarkdown = normalizeText(originalTable[row][column] );
  const originalMarkdown =
    column != 999 ? normalizeText(originalTable[row]?.[column]) : "";
  const selectedCurrentEditorState = useAppSelector(selectCurrentEditorState);

  // const isPublished = (props.parentSection as DocumentSection)?.edit ?? false;
  const isPublished = false;

  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

  const [isBold, setIsBold] = React.useState<boolean>(
    startElement.isBold ? startElement.isBold : false
  );
  const [isItalic, setIsItalic] = React.useState<boolean>(
    startElement.isItalic ? startElement.isItalic : false
  );
  const [isUnderline, setIsUnderline] = React.useState<boolean>(
    startElement.isUnderline ? startElement.isUnderline : false
  );
  const [isStandard, setIsStandard] = React.useState<boolean>(
    startElement.isStandard ? startElement.isStandard : false
  );

  const handleData = (data: any) => {
    setColumns(data.columns);
    setRows(data.rows);
    setCaption(data.caption);
    setIsNumberFormat(data.isNumberFormat);
    setCaptionAlignment(data.captionAlignment);
    setCaptionOrder(data.captionOrder);
    setColor(data.color);
    setFontsize(data.fontSize);
    setFont(data.font);
    setIsDisplayCaption(data.isDisplayCaption);
    setIsBold(data.isBold);
    setIsItalic(data.isItalic);
    setIsUnderline(data.isUnderline);
    setIsStandard(data.isStandard);
    // handleSave();
  };

  /**
   * Returns true if the passed in (or if passed in is null, the current state)
   * content is not the same as the committed content.
   * @return {*}  {boolean}
   */
  function isDirty(newEditorState: EditorState | null = null): boolean {
    // Shortcut, if readonly it can't be dirty.
    if (props.readOnly) return false;

    const state = newEditorState
      ? newEditorState
      : isFocused
      ? selectedCurrentEditorState
      : editorState;
    if (!state) return false;
    const newMarkdown = editorStateToMarkdown(state);

    if (
      (props.viewPreferences.logDebug && originalMarkdown !== newMarkdown) ||
      props.viewPreferences.logAllTextChecking
    ) {
      console.log(
        `Dirty content check for section=${props.parentSection.id}, subsection=${props.parentSubsection?.id}\r\n
          Old = ${originalMarkdown}\r\n
          New = ${newMarkdown}
        `
      );
    }
    return removeWhitespace(originalMarkdown) !== removeWhitespace(newMarkdown);
    // return normalizeText(originalMarkdown) !== normalizeText(newMarkdown);
  }

  // const variableNames =
  //   (props.documentTemplate as DocumentTemplate).variables?.map((variable) => {
  //     return "@" + variable.variable_name; // Assuming 'name' is the correct property name
  //   }) || [];

  // // This is the static, default editor state until we edit this.
  // const editorState =highlightVariables( EditorState.createWithContent(
  //   markdowntoEditorState(originalMarkdown)) ,variableNames  )

  // This is the static, default editor state until we edit this.
  let editorState =applyMarkdownSyntax( EditorState.createWithContent(
    markdowntoEditorState(originalMarkdown)
  ));

  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [isHovered, setIsHovered] = React.useState<boolean>(false);

  // Only allow drag+drop if this is not read only.
  const [{ isOver }, drop] = props.readOnly
    ? [{ isOver: null }, null]
    : useDrop(() => ({
        accept: "right-drawer-item",
        drop: (dropItem: any) => addElementToSection({ ...dropItem.element }),
        collect: (monitor) => ({
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop(),
        }),
      }));

  function addElementToSection(element: DocumentElement) {
    if (element.content_type === "LIBRARYCONTENTSUBSECTION") {
      SetIsLibarayOpen(true);
    } else {
      actions.createSubsectionWithElement({
        section: props.parentSection as DocumentSection,
        documentElement: element,
        subsectionAbove: props.parentSubsection!,
      });
    }
  }

  // --- Timer code --- ///

  /**
   * When the idle timer expires in create mode, save the changes
   * to the database.
   */
  const handleCommitChanges = () => {
    if (isDirty()) {
      console.log("Dirty");
      //Save the changes
      // handleSave();
    } else {
      console.log("Not dirty");
    }
  };
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp: new Date(Date.now() + COMMIT_CHANGES_TIME_SEC * 1000),
    onExpire: handleCommitChanges,
  });
  // --- Timer code --- ///

  /**
   * Handle onChange edits in the markdown editor and update the DocumentSlice store with this state so that we can
   * change the style in EditorHeaderMenu.
   * Validates the content before allowing the change.
   * @param {EditorState} editorState
   */

  function handleEditorStateChange(newEditorState: EditorState) {
 
    setIsisFocued(true);
    if (
      (isEditing || !isPublished) &&
      !props.readOnly &&
      !props.approvalsEnabled
    ) {
      //Validate and update the editor state, set the content to dirty
      const newContent = editorStateToMarkdown(newEditorState);
      if (validateContent(newContent, props.element!.content_type).valid) {
        dispatch(setEditorState(newEditorState));
        // If in creation mode, use the timer to commit changes asynchronously.
        // if (!isPublished) {
        //   if (isDirty(newEditorState)) {
        //     //(Re)Start the commit time
        //     //REF: https://github.com/amrlabib/react-timer-hook/issues/70
        //     restart(new Date(Date.now() + COMMIT_CHANGES_TIME_SEC * 1000));
        //   }
        // }
      }
    }
  }
  const handleSetEditing = () => {
    setIsEditing(true);
    //Focus on the editor
    if (editorRef && editorRef.current) editorRef.current.focusEditor();
  };

  const handleCopyContent = () => {
    enqueueSnackbar("Duplication not supported quite just yet.");
    // if (props.isHeading) {
    //   enqueueSnackbar("Section duplication not supported quite just yet.");
    //   return;
    // }
    // actions.createSubsectionWithElement({
    //   section: props.parentSection as DocumentSection,
    //   documentElement: {...props.element as DocumentElement},
    //   subsectionAbove: props.parentSubsection!,
    // });
    // enqueueSnackbar("Duplicated!");
  };

  const handleDelete = () => {
    if (
      actions.deleteSubsection({
        section: props.parentSection as DocumentSection,
        subSection: props.parentSubsection as DocumentSubsection,
      })
    )
      enqueueSnackbar("Deleted!");
  };

  const editActions = [
    { icon: <ContentCopyIcon />, name: "Copy", action: handleCopyContent },
    { icon: <DeleteIcon />, name: "Delete", action: handleDelete },
  ];

  //
  /**
   * Commit the changes to the database and in the case of
   * edit mode, set back to read-only.
   */

  const handleUpdateFormatting = (action: string, value: string) => {
    const updatedDocumentElement = { ...props.element };
    const Formatting = JSON.parse(props.element!.formatting!);
    let updatedFormatting;

    if (action == "Change Cell Color") {
      updatedFormatting = updateCellBackgroundFormatting(
        Formatting,
        row,
        column,
        action,
        value
      );
    } else if (action === "Change Entire Row Color") {
      updatedFormatting = updateRowBackground(Formatting, row, value);
    } else if (action == "Change Entire Column Color") {
      updatedFormatting = updateColumnBackground(Formatting, column, value);
    } else if (action === "Row Align Left") {
      updatedFormatting = updateRowAlignmnet(
        Formatting,
        row,
        TABLE_ALIGNMENT.left
      );
    } else if (action === "Row Align Center") {
      updatedFormatting = updateRowAlignmnet(
        Formatting,
        row,
        TABLE_ALIGNMENT.center
      );
    } else if (action === "Row Align Right") {
      updatedFormatting = updateRowAlignmnet(
        Formatting,
        row,
        TABLE_ALIGNMENT.right
      );
    } else if (action === "Column Align Left") {
      updatedFormatting = updateColumnAlignment(
        Formatting,
        column,
        TABLE_ALIGNMENT.left
      );
    } else if (action === "Column Align Center") {
      updatedFormatting = updateColumnAlignment(
        Formatting,
        column,
        TABLE_ALIGNMENT.center
      );
    } else if (action === "Column Align Right") {
      updatedFormatting = updateColumnAlignment(
        Formatting,
        column,
        TABLE_ALIGNMENT.right
      );
    } else if (action === "Cell Content Color") {
      updatedFormatting = updateCellFormatting(
        Formatting,
        row,
        column,
        "",
        value
      );
    } else if (action === "Entire Row Content Color") {
      updatedFormatting = updateRowTextColor(Formatting, row, value);
    } else if (action === "Entire Column Content Color") {
      updatedFormatting = updateColumnTextColor(Formatting, column, value);
    } else if (action === "Column Width") {
      updatedFormatting = updateColumnWidth(Formatting, column, value);
    } else {
      console.error(`Unhandled action: ${action}`);
      setContextMenu({
        visible: false,
        options: [],
        position: { x: 0, y: 0 },
        cell: { row: 0, column: 0 },
        isOpen: false,
      });
      return;
    }

    updatedDocumentElement.formatting = JSON.stringify(updatedFormatting);

    actions.updateDocumentElement({
      section: props.parentSection as DocumentSection,
      subSection: props.parentSubsection!,
      documentElement: updatedDocumentElement as DocumentElement,
    });

    dispatch(
      setSelectedComponent({
        selectedSection: props.parentSection as DocumentSection,
        selectedSubsection: props.parentSubsection!,
        selectedComponent: updatedDocumentElement as DocumentElement,
      })
    );
    if (
      action === "Column Width" ||
      action === "Entire Column Content Color" ||
      action === "Entire Row Content Color" ||
      action === "Change Entire Column Color" ||
      action === "Change Entire Row Color" ||
      action === "Change Cell Color" ||
      action === "Cell Content Color"
    ) {
      dispatch(clearSelectedComponent());
      dispatch(setEditorState(null));
    }
    setContextMenu({
      visible: false,
      options: [],
      position: { x: 0, y: 0 },
      cell: { row: 0, column: 0 },
      isOpen: false,
    });
  };

  const handleUpdateCell = (action: string) => {
    const rowIndex = row;
    const columnIndex = column;
    const updatedCurrentIndexs = currentIndex(action, rowIndex, columnIndex);
    setRow(updatedCurrentIndexs.updatedRowIndex);
    setColumn(updatedCurrentIndexs.updatedColumnIndex);

    const updatedDocumentElement = { ...props.element };
    //  const updatedTable = insertColumnLeft(originalTable, column)
    let updatedTable;
    let formattedTableData = formatting;
    if (action === "Insert Row Above") {
      updatedTable = insertRowAbove(originalTable, rowIndex);
      formattedTableData = DuplicateRowFormatting(
        formatting,
        rowIndex,
        "above"
      );
    } else if (action === "Insert Row Below") {
      formattedTableData = DuplicateRowFormatting(
        formatting,
        rowIndex,
        "below"
      );
      updatedTable = insertRowBelow(originalTable, rowIndex);
    } else if (action === "Insert Column Left") {
      formattedTableData = duplicateColumnFormatting(
        formatting,
        columnIndex,
        "left"
      );
      updatedTable = insertColumnLeft(originalTable, columnIndex);
    } else if (action === "Insert Column Right") {
      formattedTableData = duplicateColumnFormatting(
        formatting,
        columnIndex,
        "right"
      );
      updatedTable = insertColumnRight(originalTable, columnIndex);
    } else if (action === "Delete Entire Column") {
      if (originalTable[0].length == 1) {
        enqueueSnackbar(
          "Unable to delete column: There is only one column in the table.",
          {
            variant: "info",
          }
        );
        setContextMenu({
          visible: false,
          options: [],
          position: { x: 0, y: 0 },
          cell: { row: 0, column: 0 },
          isOpen: false,
        });
        return false;
      }
      updatedTable = deleteColumn(originalTable, columnIndex);
      formattedTableData = deleteRowOrColumn(formatting, columnIndex, "column");
      enqueueSnackbar("Column successfully deleted.", {
        variant: "success",
      });
    } else if (action === "Delete Entire Row") {
      if (originalTable.length == 1) {
        enqueueSnackbar(
          "Unable to delete row: There is only one row in the table.",
          {
            variant: "info",
          }
        );
        setContextMenu({
          visible: false,
          options: [],
          position: { x: 0, y: 0 },
          cell: { row: 0, column: 0 },
          isOpen: false,
        });
        return false;
      }
      updatedTable = deleteRow(originalTable, rowIndex);
      formattedTableData = deleteRowOrColumn(formatting, rowIndex, "row");
      enqueueSnackbar("Row successfully deleted.", {
        variant: "success",
      });
    } else if (action === "Duplicate Row Above") {
      updatedTable = duplicateRowAbove(originalTable, rowIndex);
      formattedTableData = DuplicateRowFormatting(
        formatting,
        rowIndex,
        "above"
      );
    } else if (action === "Duplicate Row Below") {
      updatedTable = duplicateRowBelow(originalTable, rowIndex);
      formattedTableData = DuplicateRowFormatting(
        formatting,
        rowIndex,
        "below"
      );
    } else if (action === "Duplicate Column Left") {
      updatedTable = duplicateColumnLeft(originalTable, columnIndex);
      formattedTableData = duplicateColumnFormatting(
        formatting,
        columnIndex,
        "left"
      );
    } else if (action === "Duplicate Column Right") {
      updatedTable = duplicateColumnRight(originalTable, columnIndex);
      formattedTableData = duplicateColumnFormatting(
        formatting,
        columnIndex,
        "right"
      );
    } else if (action === "Move Row Above") {
      updatedTable = moveRowAbove(originalTable, rowIndex);
      formattedTableData = moveRow(formatting, rowIndex, "above");
    } else if (action === "Move Row Below") {
      updatedTable = moveRowBelow(originalTable, rowIndex);
      formattedTableData = moveRow(formatting, rowIndex, "below");
    } else if (action === "Move Column Left") {
      updatedTable = moveColumnLeft(originalTable, columnIndex);
      formattedTableData = moveColumn(formatting, columnIndex, "left");
    } else if (action === "Move Column Right") {
      formattedTableData = moveColumn(formatting, columnIndex, "right");
      updatedTable = moveColumnRight(originalTable, columnIndex);
    } else {
      console.error(`Unhandled action: ${action}`);
      setContextMenu({
        visible: false,
        options: [],
        position: { x: 0, y: 0 },
        cell: { row: 0, column: 0 },
        isOpen: false,
      });
      return;
    }

    const newTableArray: Array<string> = [];
    updatedTable.newData.forEach((_: any) => {
      newTableArray.push(_.join("!TC"));
    });

    // const formattedTableData = formatTableWithTheme(
    //   updatedTable.rowCount,
    //   updatedTable.columnCount,
    //   customTableDetails
    // );
    const stringFormat = JSON.stringify(formattedTableData);
    const contentFormatObject = JSON.parse(props.element!.content_format);
    contentFormatObject.rows = updatedTable.rowCount;
    contentFormatObject.columns = updatedTable.columnCount;
    const newTableString = newTableArray.join("!TR");
    updatedDocumentElement.content_format = JSON.stringify(contentFormatObject);
    updatedDocumentElement.content = newTableString;
    updatedDocumentElement.formatting = stringFormat;
    actions.updateDocumentElement({
      section: props.parentSection as DocumentSection,
      subSection: props.parentSubsection!,
      documentElement: updatedDocumentElement as DocumentElement,
    });

    setOriginalTable(updatedTable.newData);
    setColumns(updatedTable.columnCount);
    setRows(updatedTable.rowCount);
    dispatch(
      setSelectedComponent({
        selectedSection: props.parentSection as DocumentSection,
        selectedSubsection: props.parentSubsection!,
        selectedComponent: updatedDocumentElement as DocumentElement,
      })
    );

    setContextMenu({
      visible: false,
      options: [],
      position: { x: 0, y: 0 },
      cell: { row: 0, column: 0 },
      isOpen: false,
    });

    if (action === "Delete Entire Row" || action === "Delete Entire Column") {
      dispatch(clearSelectedComponent());
      dispatch(setEditorState(null));
    }
  };

  const handleSave = () => {
    if (isPublished) setIsEditing(false);
    const state = isFocused ? selectedCurrentEditorState : editorState;
    if (!state) return false;
    //enqueueSnackbar("Saving...");

    const newContent = editorStateToMarkdown(state);

    originalTable[row][column] = newContent;

    const newTableArray: Array<string> = [];

    originalTable.forEach((_) => {
      newTableArray.push(_.join("!TC"));
    });
    const newTableString = newTableArray.join("!TR");
    // console.log(`Saving! New content = ${newContent}`);
    // Update the content
    const updatedDocumentElement = { ...props.element };
    updatedDocumentElement.content = newTableString;
    actions.updateDocumentElement({
      section: props.parentSection as DocumentSection,
      subSection: props.parentSubsection!,
      documentElement: updatedDocumentElement as DocumentElement,
      isTextEmpty: true,
    });
  };

  const handleRevert = () => {
    enqueueSnackbar("Reverted!");
    setIsEditing(false);
    dispatch(
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(markdownToDraft(originalMarkdown))
        )
      )
    );
  };

  const editingActions = [
    // { icon: <SaveIcon />, name: "Save", action: handleSave },
    { icon: <UndoIcon />, name: "Undo", action: handleRevert },
  ];

  /**
   * When the component is focused, update the state.
   * @param {*} e
   * @return {*}  {*}
   */

  // Old Implementation of HandleOnfocus by previous Developer.

  const handleOnFocus = useCallback(
    (row: number, column: number) => {
      dispatch(
        setSelectedComponent({
          selectedSection: props.parentSection as DocumentSection,
          selectedSubsection: props.parentSubsection,
          selectedComponent: props.element,
        })
      );
      dispatch(
        setSelectedTableComponent({
          selectedSection: props.parentSection as DocumentSection,
          selectedSubsection: props.parentSubsection,
          selectedComponent: props.element,
          row: row,
          column: column,
        })
      );
      const originalMarkdowns = normalizeText(originalTable[row]?.[column]);
      const newEditorState = applyMarkdownSyntax(EditorState.createWithContent(
        markdowntoEditorState(originalMarkdowns))
      );
      dispatch(setEditorState(newEditorState));
      editorState = newEditorState;
      setIsFocused(true);
    },
    [
      dispatch,
      props.parentSection,
      props.parentSubsection,
      props.element,
      editorState,
    ]
  );

  const handleFocusCellEditor = (
    element: DocumentElement,
    column: number,
    row: number
  ) => {
    // const id = `table-${props.element?.id}-${row}-${column}`;
    const id = `tableElement-${props.element?.id}-${row}-${column}`;
    const boxElement = document.getElementById(
      `subsection-${props.element?.id}`
    );
    if (boxElement) {
      const cellElement = boxElement.querySelectorAll(`#${id}`);

      if (cellElement[0]) {
        if (cellElement[0]) {
          const nextEditor = cellElement[0].querySelector(".rdw-editor-main");
          if (
            nextEditor instanceof HTMLDivElement ||
            nextEditor instanceof HTMLTextAreaElement
          ) {
            nextEditor.click();
            const currentEditorState = editorState; // Replace with your function to get the current state
            // Get the content block containing the text
            if (currentEditorState) {
              const contentBlock: ContentBlock | null = currentEditorState
                .getCurrentContent()
                .getBlockMap()
                .find(
                  (block: any) => block.getText() === nextEditor.textContent
                );

              if (contentBlock) {
                // Calculate the selection position at the end of the content
                const selectionPosition = contentBlock.getText().length;
                // Create a new selection state at the end of the content
                const selectionState = SelectionState.createEmpty(
                  contentBlock.getKey()
                ).merge({
                  anchorOffset: selectionPosition,
                  focusOffset: selectionPosition,
                });
                // Update the editor state with the new selection
                const newEditorState = EditorState.forceSelection(
                  currentEditorState,
                  selectionState
                );
                dispatch(setEditorState(newEditorState));
                editorState = newEditorState;
              }
            }
          } else {
            console.log("not focusing next editor");
          }

          // console.log(" Cell element handle click call" ,cellElement );
        } else {
          console.log("Cell element not found.");
        }
      } else {
        console.log("Cell element is not an instance of HTMLTableCellElement");
      }
    }
  };

  // function handleOnFocus() {
  //   dispatch(
  //     setSelectedComponent({
  //       selectedSection: props.parentSection as DocumentSection,
  //       selectedSubsection: props.parentSubsection,
  //       selectedComponent: props.element,
  //     })
  //   );
  //   dispatch(
  //     setSelectedTableComponent({
  //       selectedSection: props.parentSection as DocumentSection,
  //       selectedSubsection: props.parentSubsection,
  //       selectedComponent: props.element,
  //       row: row,
  //       column: column,
  //     })
  //   );
  //   dispatch(setEditorState(editorState));
  //   setIsFocused(true);
  //   }

  //New Implementation to avoid the couser Move left to the typed Text

  // const handleOnFocus = useCallback(() => {
  //   dispatch(
  //     setSelectedComponent({
  //       selectedSection: props.parentSection as DocumentSection,
  //       selectedSubsection: props.parentSubsection,
  //       selectedComponent: props.element,
  //     })
  //   );
  //   dispatch(
  //     setSelectedTableComponent({
  //       selectedSection: props.parentSection as DocumentSection,
  //       selectedSubsection: props.parentSubsection,
  //       selectedComponent: props.element,
  //       row: row,
  //       column: column,
  //     })
  //   );

  //   const originalMarkdowns = normalizeText(originalTable[row]?.[column]);
  //   const newEditorState = EditorState.createWithContent(
  //     markdowntoEditorState(originalMarkdowns)
  //   );

  //   console.log("editorstate", editorState , "editorStatecontent" , editorState.getCurrentContent() , row , column )

  //   // Only set the editor state if it's not already set
  //   // if (!editorState) {
  //   dispatch(setEditorState(editorState));

  //   // handleFocusCellEditor(props.element!, column, row);
  //   // }

  //   // handleFocusCellEditor(props.element! , column , row)
  //   setIsFocused(true);
  // }, [
  //   dispatch,
  //   props.parentSection,
  //   props.parentSubsection,
  //   props.element,
  //   editorState,
  // ]);

  /**
   * Similarly track when focus is lost.
   * NOTE: We can't do this because the blur is lost before the
   * delete button handle is triggered.
   * @param {*} e
   */

  function handleOnBlur(e: any) {
    if (contextMenu.isOpen) {
      //Do nothing
      return false;
    }
    setIsisFocued(false);
    if (props.readOnly || props.approvalsEnabled) return;
    setIsFocused(false);
    // handleSave();
    //Save before we move away.
    if (isDirty()) handleSave();
    // if (isDirty() && isPublished) handleRevert();
    dispatch(clearSelectedComponent());
    dispatch(setEditorState(null));
    setContextMenu({
      visible: false,
      options: [],
      position: { x: 0, y: 0 },
      cell: { row: 0, column: 0 },
      isOpen: false,
    });
    setColumn(1000);
    setRow(1000);
  }

  function getBorder(): any {
    if (
      (props.parentSection.pending_deleted_at != null || isDeleted) &&
      props.approvalsEnabled
    ) {
      return {
        border: COLORS.BORDER_SIZE,
        borderRadius: 0,
        borderStyle: isSelected ? "solid" : "dashed",
        borderColor: COLORS.NOMIA_RED,
      };
    }

    if (
      props.parentSubsection &&
      props.approvalsEnabled &&
      isIncludeImpact(approvalState, props.parentSubsection.id)
    ) {
      return {
        border: COLORS.BORDER_SIZE,
        borderColor: COLORS.NOMIA_ORANGE,
        borderStyle: isSelected ? "solid" : "dashed",
      };
    }

    if (
      props.approvalsEnabled &&
      props.element !== null &&
      props.element.should_show
    ) {
      return {
        border: COLORS.BORDER_SIZE,
        borderColor: COLORS.NOMIA_GREEN,
        borderStyle: isSelected ? "solid" : "dashed",
      };
    }

    if (props.element !== null && props.element.element_approvals) {
      return {
        border: props.approvalsEnabled ? COLORS.BORDER_SIZE : 1,
        borderColor: props.approvalsEnabled
          ? "orange !important"
          : secondarySilver,
        borderStyle: isSelected ? "solid" : "dashed",
      };
    }

    if (
      props.parentSection.pending_deleted_at !== null &&
      props.approvalsEnabled
    ) {
      return { border: 1, borderColor: "error.main", borderStyle: "dashed" };
    }

    if (props.readOnly) return {};
    if (props.viewPreferences.showSections)
      return {
        border: 1,
        borderColor: "silver.main",
        borderStyle: isSelected ? "solid" : "dashed",
      };
    //Otherwise nothing
    return {};
  }
  function getSuggestions() {
    if ((props.documentTemplate as DocumentTemplate).variables == undefined)
      return [];
    return (props.documentTemplate as DocumentTemplate).variables?.map(
      (variable) => {
        return {
          text: variable.variable_name,
          value: variable.variable_name,
          url: "Not used",
        };
      }
    );
  }

  function handleDeleteClicked(e: any) {
    // actions.deleteSubsection({
    //   section: props.parentSection as DocumentSection,
    //   subSection: props.parentSubsection as DocumentSubsection,
    // });
    setIsDeleteConfigOpen(true);
    dispatch(clearSelectedComponent());
  }

  const [tableFormatting, setTableFormatting] = React.useState(
    props.element?.formatting != null
      ? JSON.parse(props.element.formatting)
      : JSON.parse(DEFAULT_THEME_FORMATTING)
  );
  React.useEffect(() => {
    if (props.element?.formatting != null) {
      setTableFormatting(JSON.parse(props.element.formatting));
    }
  }, [props.element?.formatting]);

  const formatting = tableFormatting;
  const [customTableDetails, setCustomTableDetails] = React.useState(
    startElement.themeDetails ? startElement.themeDetails : TABLE_THEMES.default
  );

  const handleUpdateProperties = (updatedProperties: any) => {
    setCustomTableDetails(updatedProperties);
  };

  const desiredFont = React.useMemo(() => {
    return findMatchingFont(font);
  }, [font]);

  const tableFont = React.useMemo(() => {
    return findMatchingFont(startElement.tableFont);
  }, [startElement.tableFont]);

  const TableCaption = (choice: "above" | "below") => {
    return (
      <Box
        sx={{
          // textAlign: captionAlignment,
          textAlign: captionAlignment === "custom" ? "left" : captionAlignment,

          padding: "5px",
          paddingTop: choice === "above" ? "0px" : "5px",
          paddingLeft:
            captionAlignment === "custom"
              ? convertMmToPt(
                  startElement.captionIndent ? startElement.captionIndent : 0
                ) + "pt"
              : "0pt",
        }}
        onClick={() => {
          if (!props.readOnly && !props.approvalsEnabled) {
            setIsDialogOpen(true);
          }
        }}
      >
        <Typography
          sx={{
            fontFamily: desiredFont,
            fontSize: getFontSize(fontSize),
            fontStyle: isItalic ? "italic" : "normal",
            fontWeight: isBold ? "bold" : "normal",
            textDecoration: isUnderline ? "underline" : "none",
            color: "#" + color,
          }}
        >
          {isNumberFormat && isDisplayCaption && !props.readOnly
            ? "Table " +
              countTableElementInDocumentTemplate(
                {
                  documentTemplate: props.documentTemplate,
                  element: props.element,
                },
                "TABLE"
              ) +
              ": " +
              caption
            : caption}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    if (contextMenu.visible && isSelected === false) {
      setIsFocused(false);
      setContextMenu({
        visible: false,
        options: [],
        position: { x: 0, y: 0 },
        cell: { row: 0, column: 0 },
        isOpen: false,
      });
    }
  }, [isSelected]);

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    options: [
      { label: "Option 1", action: () => handleContextMenuAction("Option 1") },
    ],
    position: { x: 0, y: 0 },
    cell: { row: 0, column: 0 },
    isOpen: false,
  });

  const handleCellContextMenu = (event: any, row: any, column: any) => {
    event.preventDefault();
    const options: any[] = [
      {
        label: "Insert Row Above",
        action: () => handleContextMenuAction("Insert Row Above"),
      },
    ];
    setContextMenu({
      visible: true,
      options,
      position: { x: event.clientX, y: event.clientY },
      cell: { row, column },
      isOpen: false,
    });
  };

  const handleContextMenuAction = (option: any, value?: string) => {
    // Implement actions based on the selected option
    try {
      if (option === "Close") {
        setContextMenu({ ...contextMenu, isOpen: false, visible: false });
        dispatch(clearSelectedComponent());
        dispatch(setEditorState(null));
        return false;
      }
      if (option === "Custom Width") {
        setContextMenu({ ...contextMenu, isOpen: true });
        return false;
      }
      if (value) {
        handleUpdateFormatting(option, value);
      } else {
        handleUpdateCell(option);
      }
    } catch (e) {
      console.log("Error While Updating Table Formatting", e);
    }

    // Close the context menu
  };

  return (
    <>
      <Box
        id={`subsection-${props.parentSubsection!.id}`}
        ref={drop}
        // onFocus={handleOnFocus}
        onClick={() => {
          if (props.approvalsEnabled) {
            dispatch(
              setSelectedComponent({
                selectedSection: props.parentSection as DocumentSection,
                selectedSubsection: props.parentSubsection,
                selectedComponent: props.element,
              })
            );
          }
        }}
        // onBlur={handleOnBlur}
        sx={Object.assign(
          {
            padding: 1,
            margin: 1,
            // REF: https://mui.com/system/borders/
            borderRadius: 0,
            transform: "translateZ(0px)",
            flexGrow: 1,
            overflow: "visible",
            lineHeight: 1,
            backgroundColor:
              isSelected && props.approvalsEnabled
                ? "secondarySilver"
                : "white",
          },
          getBorder()
        )}
        // onMouseOver={() => setShowEditButton(true)}
        // onMouseOut={() => setShowEditButton(false)}
      >
        {!props.readOnly && props.approvalsEnabled && (
          <Box
            sx={{
              position: "relative",
              my: 2,
              zIndex: 99,
            }}
          >
            <LibraryContentIcon
              isHeading={false}
              parentSection={props.parentSection as DocumentSection}
              parentSubsection={props.parentSubsection as DocumentSubsection}
              documentTemplate={props.documentTemplate as DocumentTemplate}
              style={{
                outline: "none",
                color: "#7284A3",
                position: "absolute",
                top: -24,
                right: 98,
                transition: "none", // Remove transition on button press
                margin: 0,
                padding: 0,
                width: "fit-content",
                zIndex: 999,
              }}
            />
            <ApprovalIcon
              isHeading={false}
              parentSection={props.parentSection as DocumentSection}
              parentSubsection={props.parentSubsection as DocumentSubsection}
              approvalState={approvalState}
              style={{
                outline: "none",
                color: "#7284A3",
                position: "absolute",
                top: -24,
                left: -46,
                transition: "none", // Remove transition on button press
                margin: 0,
                padding: 0,
                width: "fit-content",
              }}
            />
            {(props.approvalsEnabled &&
              !props.readOnly &&
              isDeletedSubsection(props.parentSubsection!) &&
              !(
                props.documentTemplate as DocumentTemplate
              ).deleted_sub_sections.includes(props.parentSubsection!.id)) ||
              (props.approvalsEnabled &&
                !props.readOnly &&
                (
                  props.documentTemplate as DocumentTemplate
                ).deleted_sub_sections.includes(props.parentSubsection!.id) &&
                !props.readOnly &&
                (
                  props.documentTemplate as DocumentTemplate
                ).is_undo_show!.includes(props.parentSubsection!.id) && (
                  // props.parentSubsection?.pending_deleted_at !== null
                  <IconButton
                    style={{
                      position: "absolute",
                      top: -24,
                      right: 5,
                      transition: "none", // Remove transition on button press
                      margin: 0,
                      padding: 0,
                      paddingBottom: 2,
                      width: "fit-content",
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsDeleteOpen(true);
                      e.stopPropagation(); // Add this line to stop event propagation
                    }}
                    title="Undo Deletion"
                  >
                    <UndoIcon fontSize="small" />
                  </IconButton>
                ))}
          </Box>
        )}

        {!props.readOnly && !props.approvalsEnabled && (
          <Box
            sx={{
              position: "relative",
              my: 2,
              zIndex: 99,
            }}
          >
            <SwitchSubsection
              parentSection={props.parentSection as DocumentSection}
              parentSubsection={props.parentSubsection as DocumentSubsection}
              direction={"up"}
              isDisabled={false}
              element={props.element as DocumentElement}
              style={{
                paddingBottom: 2,
              }}
              readonly={props.readOnly}
            />

            <LibraryContentIcon
              isHeading={false}
              parentSection={props.parentSection as DocumentSection}
              parentSubsection={props.parentSubsection as DocumentSubsection}
              documentTemplate={props.documentTemplate as DocumentTemplate}
              style={{
                outline: "none",
                color: "#7284A3",
                position: "absolute",
                top: -7,
                right: 98,
                transition: "none", // Remove transition on button press
                margin: 0,
                padding: 0,
                width: "fit-content",
                zIndex: 999,
              }}
            />

            <Tooltip title={"Import"} placement="top">
              <IconButton
                style={{
                  position: "absolute",
                  top: -7,
                  right: 47,
                  transition: "none", // Remove transition on button press
                  margin: 0,
                  padding: 0,
                  width: "fit-content",
                  zIndex: 999,
                }}
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => setIsImportConfigOpen(true)}
              >
                <UploadFileIcon
                  style={{ fontSize: 22, outline: "none", color: "#7284A3" }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={!hideTable ? "Hide Table" : "View Table"}
              placement="top"
            >
              <IconButton
                // style={{
                //   position: "absolute",
                //   top: -7,
                //   right: 47,
                //   transition: "none", // Remove transition on button press
                //   margin: 0,
                //   padding: 0,
                //   width: "fit-content",
                //   zIndex: 999,
                // }}

                style={{
                  position: "absolute",
                  top: -7,
                  right: 71,
                  // right: 47,
                  transition: "none", // Remove transition on button press
                  margin: 0,
                  padding: 0,
                  width: "fit-content",
                  zIndex: 999,
                }}
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => setHideTable(!hideTable)}
              >
                {hideTable ? (
                  <VisibilityOffIcon
                    style={{ fontSize: 22, outline: "none", color: "#7284A3" }}
                  />
                ) : (
                  <VisibilityIcon
                    style={{ fontSize: 22, outline: "none", color: "#7284A3" }}
                  />
                )}
              </IconButton>
            </Tooltip>

            <Tooltip title="" placement="top">
              <IconButton
                style={{
                  position: "absolute",
                  top: -7,
                  right: 25,
                  transition: "none", // Remove transition on button press
                  margin: 0,
                  padding: 0,
                  width: "fit-content",
                  zIndex: 999,
                }}
                onMouseDown={(e) => e.preventDefault()}
                // onClick={handleDeleteClicked}
                onClick={(e) => {
                  if (
                    !!(props.documentTemplate as DocumentTemplate)
                      .included_subSections?.[props.parentSubsection!.id] ||
                    !!(props.documentTemplate as DocumentTemplate)
                      .imported_sub_sections?.[props.parentSubsection!.id]
                  ) {
                    if (
                      selectedCurrentTemplateState.approval ||
                      selectedCurrentTemplateState.editing ||
                      !selectedCurrentTemplateState.draft
                    ) {
                      setIsDeleteConfirmOpen(true);
                    } else {
                      handleDetachSelection(props, actions, dispatch);
                    }
                  } else {
                    // actions.deleteSubsection({
                    //   section: props.parentSection as DocumentSection,
                    //   subSection: props.parentSubsection as DocumentSubsection,
                    // });
                    setIsDeleteUnsharedOpen(true)
                    // handleDeleteClicked(e)
                  }
                }}
              >
                <DeleteIcon
                  style={{ fontSize: 20, outline: "none", color: "#7284A3" }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Table Settings" placement="top">
              <IconButton
                onMouseDown={(e) => e.preventDefault()}
                onClick={(e) => {
                  e.preventDefault();
                  setIsDialogOpen(true);
                  e.stopPropagation(); // Add this line to stop event propagation
                }}
                style={{
                  position: "absolute",
                  top: -7,
                  right: 3,
                  transition: "none", // Remove transition on button press
                  margin: 0,
                  padding: 0,
                  width: "fit-content",
                  zIndex: 999,
                }}
              >
                <SettingsIcon
                  style={{ fontSize: 20, outline: "none", color: "#7284A3" }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}

        {isDisplayCaption && captionOrder === "above" && TableCaption("above")}
        {/* correct */}

        {hideTable && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "48px", // Adjust the size as needed
              cursor: "pointer",
              padding: 1,
            }}
            onClick={() => setHideTable(!hideTable)}
          >
            <GridOffIcon sx={{ fontSize: "1em", color: "#4C33FF" }} />
          </Box>
        )}

        {/* updated */}
        {!props.forApproval && !hideTable && (
          <Box
            sx={{
              display: "flex",
              justifyContent: startElement.justify,
            }}
          >
            <table
              id={`subsection-${props.element?.id}`}
              style={{
                fontFamily: tableFont,
                width: "100%",
                // startElement.tableSize === "auto"
                //   ? "100%"
                //   : convertMmToPx(startElement.tableWidth),
                borderCollapse: "collapse",
                borderRadius: "5px 5px 0 0",
                // Apply border Around the Table
                border: `${
                  startElement.tableBorderThickeness
                    ? convertMmToPx(startElement.tableBorderThickness) + "px"
                    : "1px"
                } ${
                  startElement.tableBorderStyle
                    ? startElement.tableBorderStyle
                    : "dotted"
                } ${
                  startElement.tableBorderColor
                    ? startElement.tableBorderColor
                    : "white"
                }`,
              }}
            >
              {originalTable.map((_, r) => (
                <tr
                  style={{
                    // Border color
                    border: `${
                      startElement.tableBorderThickness
                        ? convertMmToPx(startElement.tableBorderThickness) +
                          "px"
                        : "1px"
                    } ${
                      startElement.tableBorderStyle
                        ? startElement.tableBorderStyle
                        : "none"
                    } ${
                      startElement.tableBorderColor
                        ? startElement.tableBorderColor
                        : "white"
                    }`,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {_.map((__, c) => (
                    <td
                      id={`table-${props.element?.id}-${r}-${c}`}
                      className="table-cell"
                      style={{
                        backgroundColor:
                          "#" + getCellStyle(r, c, formatting).backgroundColor,
                        color: "#" + getCellStyle(r, c, formatting).textColor,
                        border: `${
                          startElement.tableBorderThickness
                            ? convertMmToPx(startElement.tableBorderThickness) +
                              "px"
                            : "1px"
                        } ${
                          startElement.tableBorderStyle
                            ? startElement.tableBorderStyle
                            : "none"
                        } ${
                          startElement.tableBorderColor
                            ? "#" + startElement.tableBorderColor
                            : "black"
                        }`,
                        padding: 0,
                        margin: 0,
                        // width:
                        //   startElement.tableSize === "auto"
                        //     ? 100 / columns + "%"
                        //     : convertMmToPx(startElement.rowWidth) + "px",
                        width: 100 / columns + "%",
                        height: "auto",
                        // height:
                        //   startElement.tableSize === "auto"
                        //     ? "auto"
                        //     : convertMmToPx(startElement.columnSize) + "px",
                        wordWrap: "break-word", // Add this line for text to move to the next line
                        whiteSpace: "normal", // Adjust whiteSpace property
                        overflowWrap: "anywhere", // Allow breaking within words
                        verticalAlign: "middle", // Align text to the top
                      }}
                      onContextMenu={(event) => {
                        event.preventDefault();
                        if (
                          !props.readOnly &&
                          !props.approvalsEnabled &&
                          isSelected
                        ) {
                          handleCellContextMenu(event, row, column);
                        }

                        event.stopPropagation();
                      }}
                      onBlur={handleOnBlur}
                      onClick={(event) => {
                        if (r != row || c != column) {
                          setColumn(c);
                          setRow(r);
                          dispatch(
                            setSelectedTableComponent({
                              selectedSection:
                                props.parentSection as DocumentSection,
                              selectedSubsection: props.parentSubsection,
                              selectedComponent: props.element,
                              row: r,
                              column: c,
                            })
                          );
                          handleOnFocus(r, c);
                          setTimeout(() => {
                            handleFocusCellEditor(props.element!, c, r);
                          }, 800);
                        }
                      }}
                    >
                      {r == row && c == column ? (
                        <div id={`tableElement-${props.element?.id}-${r}-${c}`}>
                          <Editor
                            editorStyle={{
                              width: 100 / columns + "% !important",

                              overflow: "hidden",
                              wordWrap: "break-word", // Add this line for text to move to the next line
                              whiteSpace: "normal", // Adjust whiteSpace property
                              wordBreak: "break-word",
                              verticalAlign: "top !important", // Align text to the top
                              fontSize:
                                getCellStyle(r, c, formatting).fontSize + "pt",
                              height: "min-content",
                            }}
                            editorClassName={`EditorPosition${getAlignment(
                              getCellStyle(r, c, formatting).textAlign
                            )}`}
                            toolbarHidden={true}
                            editorState={
                              isFocused && selectedCurrentEditorState
                                ? selectedCurrentEditorState
                                : editorState
                            }
                            onEditorStateChange={handleEditorStateChange}
                            mention={{
                              separator: " ",
                              trigger: "@",
                              suggestions: getSuggestions(),
                            }}
                            spellCheck
                            ref={editorRef}
                            placeholder="Add Variable"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            textAlign: getAlignment(
                              getCellStyle(r, c, formatting).textAlign
                            ),
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          {__ === "" ? (
                            <span
                              style={{
                                color: "#CCCCCC",
                                fontSize: "10pt",
                                  // getCellStyle(r, c, formatting).fontSize +
                                  // "pt",
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              Variable...
                            </span>
                          ) : (
                            // <ReactMarkdown style={{ fontSize: "22pt" }}>{__}</ReactMarkdown>
                            <ReactMarkdown
                              components={{
                                p: ({ node, ...props }) => (
                                  <p
                                    style={{
                                      fontSize:
                                        getCellStyle(r, c, formatting)
                                          .fontSize + "pt",
                                      margin: 0,
                                      padding: 0,
                                    }}
                                    {...props}
                                  />
                                ),
                              }}
                            >
                              {__}
                            </ReactMarkdown>
                          )}
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </table>
          </Box>
        )}

        {/* REF: https://mui.com/material-ui/react-speed-dial/ */}
        {!props.approvalsEnabled &&
        !props.forApproval &&
        !props.parentSection.pending_deleted_at &&
        isPublished &&
        !props.readOnly &&
        isHovered ? (
          <SpeedDial
            ariaLabel="Edit"
            sx={{
              position: "absolute",
              bottom: 16,
              right: 16,
              overflow: "visible",
              zIndex: 5,
            }}
            icon={isEditing ? <SaveIcon /> : <EditIcon />}
            // Default click to the nominal action
            onClick={(e) => {
              isEditing ? handleSave() : handleSetEditing();
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            id="speeddial-primary-button"
          >
            {(isEditing ? editingActions : editActions).map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  action.action();
                }}
                sx={{
                  zIndex: 5,
                }}
              />
            ))}
          </SpeedDial>
        ) : null}

        {isDisplayCaption && captionOrder === "below" && TableCaption("below")}

        <SwitchSubsection
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          direction={"down"}
          isDisabled={false}
          element={props.element as DocumentElement}
          style={{
            paddingTop: 4,
          }}
          readonly={props.readOnly}
        />

        {isDialogOpen && !props.readOnly ? (
          <DynamicTableElementForm
            closeDialogHandler={() => {
              setIsDialogOpen(false);
              dispatch(clearSelectedComponent());
              dispatch(setEditorState(null));
            }}
            setColumns={(c: number) => setColumns(c)}
            setRows={(r: number) => setRows(r)}
            section={props.parentSection as DocumentSection}
            subSection={props.parentSubsection!}
            element={props.element!}
            originalTable={originalTable}
            setOriginalTable={setOriginalTable}
            documentTemplate={props.documentTemplate as DocumentTemplate}
            sendData={handleData}
            currentThemeDetails={customTableDetails}
            updateCurrentThemeDetails={handleUpdateProperties}
          />
        ) : null}
      </Box>
      {IsLibraryOpen && (
        <LibraryContentConfigFormSubSection
          closeDialogHandler={() => SetIsLibarayOpen(false)}
          organizationId={props.organizationId}
          workspaceId={props.workspaceId}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          viewPreferences={props.viewPreferences}
        />
      )}
      {/* The drag+drop element showing that you are going to drop something here. */}
      {isOver ? <DropSkeleton /> : null}
      {isDeleteConfigOpen === true ? (
        <DeleteConfirmForm
          isHeading={false}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          customMsg="Do you really want to delete this table? It may impact other documents."
          closeDialogHandler={() => setIsDeleteConfigOpen(false)}
        />
      ) : null}

{isDeleteUnsharedOpen === true ? (
        <DeleteUnsharedConfirm
          isHeading={false}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteUnsharedOpen(false)}
        />
      ) : null} 

      {isImportConfigOpen && (
        <ImportContentFromExcel
          element={props.element as DocumentElement}
          parentSubsection={props.parentSubsection as DocumentSection}
          parentSection={props.parentSection as DocumentSection}
          parentCloseHandle={() => {
            dispatch(clearSelectedComponent());
            dispatch(setEditorState(null));
            setIsImportConfigOpen(false);
          }}
        />
      )}

      {isDeleteConfirmOpen === true ? (
        <DeleteConfirmForm
          isHeading={false}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteConfirmOpen(false)}
        />
      ) : null}

      {isDeleteOpen === true ? (
        <EditorUndoForm
          isHeading={false}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteOpen(false)}
        />
      ) : null}

      {contextMenu.visible && (
        <ContextMenu
          contextMenu={contextMenu}
          formatting={formatting}
          handleContextMenuAction={handleContextMenuAction}
          handleClose={() => {
            setContextMenu({
              visible: false,
              options: [],
              position: { x: 0, y: 0 },
              cell: { row: 0, column: 0 },
              isOpen: false,
            });
          }}
        />
      )}
    </>
  );
}

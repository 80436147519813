import React from "react";
import clsx from "clsx";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Card } from "@mui/material";

export type FileUploadProps = {
  imageButton?: boolean;
  accept: string;
  hoverLabel?: string;
  dropLabel?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  image?: {
    url: string;
    imageStyle?: {
      width?: string;
      height?: string;
    };
  };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDrop: (event: React.DragEvent<HTMLElement>) => void;
};

export const ExcelFileUpload: React.FC<FileUploadProps> = ({
  accept,
  imageButton = false,
  hoverLabel = "Click or drag to upload file",
  dropLabel = "Drop file here",
  width = "600px",
  height = "100px",
  backgroundColor = "#fff",
  image: {
    url = "",
    imageStyle = {
      height: "inherit",
    },
  } = {},
  onChange,
  onDrop,
}) => {
  const [fileUrl, setFileUrl] = React.useState(url);
  const [labelText, setLabelText] = React.useState<string>(hoverLabel);
  const [isDragOver, setIsDragOver] = React.useState<boolean>(false);
  const [isMouseOver, setIsMouseOver] = React.useState<boolean>(false);
  const [fileKey, setFileKey] = React.useState(0);
  const stopDefaults = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true);
    },
    onMouseLeave: () => {
      setIsMouseOver(false);
    },
    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(dropLabel);
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(hoverLabel);
    },
    onDragOver: stopDefaults,
    onDrop: (e: React.DragEvent<HTMLElement>) => {
      stopDefaults(e);
      setLabelText(hoverLabel);
      setIsDragOver(false);
      if (imageButton && e.dataTransfer.files[0]) {
        setFileUrl(URL.createObjectURL(e.dataTransfer.files[0]));
      }
      onDrop(e);
    },
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   
    if (event.target?.files![0]) {
      if (event.target.files.length === 1) {
        setFileKey(fileKey + 1); // Reset the input element
      }

      setFileUrl(URL.createObjectURL(event.target.files[0]));
    }
    onChange(event);
  };

  return (
  <Card sx={{ border: '2px dotted silver', borderRadius: 1 }} >
      <input
        onChange={handleChange}
        accept={accept}
        style={{display: 'None'}}
        id="file-upload"
        type="file"
        key={fileKey}
      />

      <label
        htmlFor="file-upload"
        {...dragEvents}
      >
        <Box
          bgcolor={ backgroundColor}
          sx={{
            textAlign: 'center',
            cursor: 'pointer',
            backgroundColor: backgroundColor,
            transition: 'background-color 0.3s ease',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        
        >

          {(!imageButton || isDragOver || isMouseOver) && (
            <>
              <Box sx={{textAlign:"center" , cursor: "pointer"}} 
              >
                <CloudUploadIcon sx={{color:"grey", fontSize: 48 }}  />
                <Typography variant="h6" sx={{ fontSize:"22px"}} color={isDragOver ? 'textSecondary' : 'textSecondary'} >{labelText}</Typography>
              </Box>
            </>
          )}
        </Box>
      </label>
      </Card>

  );
};

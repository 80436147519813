import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  approvalPublishDocumentTemplate,
  approveDocumentSection,
  attachSection,
  attachUser,
  attachVersionDocumentElement,
  againPublishDocumentTemplate ,
  changePassword,
  changeUserRole,
  cloneDocumentTemplate,
  createDocumentRecord,
  createBulkDocumentRecord ,
  createDocumentTemplate,
  createReference,
  createSection,
  createSectionAboveSection,
  createSectionWithElement,
  createSubsectionWithElement,
  createUser,
  createUserInOrg,
  createUserWithOrg,
  updateOrganizationCredits,
  deleteOrganization,
  deleteSuperUser ,
  createAdminUserInOrg ,
  createSuperUserInOrg,
  createVariable,
  createWorkspace,
  deleteDocumentRecord,
  deleteDocumentTemplate,
 
  deleteReference,
  deleteSection,
  deleteSectionAllDocuments,
  deleteSubsection,
  deleteVariable,
  deleteWorkspace,
  detachUser,
  enableApprovalMode,
  executeDocumentTemplateImport,
  favouriteDocumentTemplate,
  finalizeReset,
  finalizeResetWithUserDetails,
  generateDocumentTemplate,
  getDocumentImagePreview,
  getDocumentMaster,
  getSectionDocumentMaster ,
  getDocumentRecords,
  getDocumentTemplateVariables,
  getDocumentTemplates,
  getOrganizationUsers,
  getOrganizations,
  getRecordImagePreview,
  getReferences,
  getVariables,
  getWorkspace,
  getWorkspaceSummary,
  getWorkspaceSummarySearch,
  getWorkspaceSummaryDocument,
  getWorkspaces,
  importDocumentTemplate,
  importDocumentTemplateFromDoc,
  login,  
  signup,
  makeNormalDocumentTemplate,
  draftCollaborationDocumentTemplate,
  moveDocumentTemplate,
  previewDocumentRecord,
  previewDocumentTemplate,
  publishDocumentTemplate,
  reset,
  resetUserPassword,
  updateDocumentElementContent,
  refreshDocumentTemplate,
  approvalImpactChanges,
  setEditingDocumentTemplate,
  fetchDocumentTemplate,
  setOpenApprovalDocumentTemplate,
  setHeadingLevel,
  setOrganization,
  setWorkspace,
  switchSections,
  switchSubsections,
  switchSubsectionBlock,
  undoDeletedSection,
  undoDeletedSubsection,
  undeleteDocumentRecord,
  undeleteDocumentTemplate,
  unfavouriteDocumentTemplate,
  updateDocumentElement,
  updateDocumentMaster,
  updateSectionDocumentMaster,
  createDocumentMaster,
  detachOrganizationUser,
  updateOrganizationUserRole,
  updateDocumentTemplate,
  updateDocumentTemplateImportText,
  updateReference,
  updateSection,
  updateSectionHeadingLevels,
  updateSectionContent,
  updateVariable,
  updateWorkspaceDocumentMaster ,
  uploadDocumentElementImage,
} from "./actions";
import type { AppDispatch, RootState } from "./store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      approvalPublishDocumentTemplate,
      approvalImpactChanges,
      approveDocumentSection,
      attachVersionDocumentElement,
      againPublishDocumentTemplate,
      createBulkDocumentRecord ,
      login,
      signup,
      reset,
      resetUserPassword,
      undoDeletedSection,
      undoDeletedSubsection,
      finalizeReset,
      finalizeResetWithUserDetails,
      changePassword,
      getOrganizations,
      setOrganization,
      getOrganizationUsers,
      getWorkspace,
      getWorkspaces,
      getWorkspaceSummary,
      updateOrganizationCredits,
      deleteOrganization,
      deleteSuperUser,
      updateDocumentElementContent,
      getWorkspaceSummarySearch,
      getWorkspaceSummaryDocument,
      setWorkspace,
      createWorkspace,
      deleteWorkspace,
      attachUser,
      createUser,
      createUserInOrg,
      createUserWithOrg,
      createAdminUserInOrg,
      createSuperUserInOrg,
      updateSectionContent,
      detachUser,
      detachOrganizationUser,
      updateOrganizationUserRole,
      changeUserRole,
      enableApprovalMode,
      getDocumentTemplates,
      createDocumentTemplate,
      updateDocumentTemplate,
      getDocumentTemplateVariables,
      setEditingDocumentTemplate,
      fetchDocumentTemplate,
      setOpenApprovalDocumentTemplate,
      previewDocumentTemplate,
      generateDocumentTemplate,
      getDocumentImagePreview,
      getRecordImagePreview,
      deleteDocumentRecord,
      deleteDocumentTemplate,
      makeNormalDocumentTemplate,
      draftCollaborationDocumentTemplate,
      publishDocumentTemplate,
      importDocumentTemplate,
      importDocumentTemplateFromDoc,
      favouriteDocumentTemplate,
      unfavouriteDocumentTemplate,
      getDocumentRecords,
      createDocumentRecord,
      previewDocumentRecord,
      createSection,
      attachSection,
      deleteSection,
      deleteSectionAllDocuments,
      updateSection,
      updateSectionHeadingLevels,
      createSectionAboveSection,
      createSectionWithElement,
      switchSections,
      switchSubsections,
      switchSubsectionBlock,
      refreshDocumentTemplate,
      createSubsectionWithElement,
      deleteSubsection,
      updateDocumentElement,
      getDocumentMaster,
      getSectionDocumentMaster ,
      updateDocumentMaster,
      updateSectionDocumentMaster,
      createDocumentMaster,
      updateWorkspaceDocumentMaster ,
      getVariables,
      createVariable,
      updateVariable,
      deleteVariable,
      getReferences,
      createReference,
      updateReference,
      deleteReference,
      undeleteDocumentRecord,
      undeleteDocumentTemplate,
      updateDocumentTemplateImportText,
      executeDocumentTemplateImport,
      uploadDocumentElementImage,
      setHeadingLevel,
      cloneDocumentTemplate,
      moveDocumentTemplate,
    },
    dispatch
  );
};

export default useActions;

import React, { useRef, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DocumentTemplate } from "../../types/DocumentTemplate";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import useActions from "../../app/hooks";
import WorkspaceConfigForm from "./WorkspaceConfigForm";
import OrganizationConfigForm from "./OrganizationConfigForm";
import Divider from "@mui/material/Divider";

/**
 * The organization and workspace menu.
 *
 * @export
 * @param {{user: User | null, organizations: Organization[] | null, workspaces: Workspace[] | null}} props
 * @return {*}
 */
export function ApprovalMenu(props: {
  documentTemplates: DocumentTemplate[];
}) {
  const actions = useActions();

  const [anchorElOrg, setAnchorElOrg] = React.useState<null | HTMLElement>(
    null
  );

  const handleClickDocumentTemplate = (dt: DocumentTemplate) => {
    actions.setOpenApprovalDocumentTemplate(dt)
  };

  const handleOpenApprovalMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOrg(event.currentTarget);
  };

  const handleCloseApprovalMenu = () => {
    setAnchorElOrg(null);
  };

  
  const organizationMenuItems = props.documentTemplates?.filter(_ => _.needs_approval && !_.is_editing).map((dt_) => (
    <MenuItem
      key={'menu_' + dt_.id}
      onClick={(e) => handleClickDocumentTemplate(dt_)}
    >
      <ListItemText>{dt_.name}</ListItemText>
    </MenuItem>
  ));

  return (
  
   <Box sx={{ flexGrow: 0, marginLeft: "10px" }}>
     {/* <Tooltip title="Document Templates To Be Approved"> */}
        <Button
          color="info"
          variant="contained"
          onClick={handleOpenApprovalMenu}
          disabled={ props.documentTemplates?.filter(_ => _.needs_approval && !_.is_editing).length == 0}
          
          endIcon={
            anchorElOrg == null ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )
          }
        >
          Approvals #{ props.documentTemplates?.filter(_ => _.needs_approval && !_.is_editing).length }
        </Button>
          {/* </Tooltip> */}
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElOrg}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElOrg)}
        onClose={handleCloseApprovalMenu}
      >
        {organizationMenuItems}
      </Menu>
    </Box>
    
  );
}

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { DocumentMaster } from "../../../types/DocumentMaster";
export interface DocumentMasterState {
  documentMaster: any | undefined;
  sectionsDocumentMaster: any;
  sortedSectionsDocumentMaster: any;
  tabledocumentMaster: any | undefined;
  imagedocumentMaster: any | undefined;
  status: "idle" | "loading" | "failed";
  error: string | undefined;
}

const initialState: DocumentMasterState = {
  documentMaster: undefined,
  sectionsDocumentMaster: {},
  sortedSectionsDocumentMaster:{},

  tabledocumentMaster:
    '{"format":"MARKDOWN","columns":2,"rows":2,"caption":"Table","justify":"center","justifyContent":"left","displayCaption":true,"captionAlignment":"center","captionOrder":"below","font":"arial","fontSize":"normalsize","color":"black","isNumberFormat":false,"isBold":false,"isItalic":false,"isUnderline":false,"isStandard":true}',
  imagedocumentMaster:
    '{"size":"Original","custom":100,"justify":"center","caption":"Image","DisplyCaption":true,"captionOrder":"below","captionAlignment":"center","font":"arial","fontSize":"normalsize","color":"black","style":"bold","isBold":false,"isItalic":false,"isUnderline":false,"isStandard":true}',
  status: "idle",
  error: undefined,
};

export const documentMasterSlice = createSlice({
  name: "documentMaster",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setDocumentMasterLocal: (
      state,
      action: PayloadAction<DocumentMaster | undefined>
    ) => {
      state.documentMaster = action.payload;
    },

    setSectionsDocumentMasterLocal: (
      state,
      action: PayloadAction<DocumentMaster[]>
    ) => {
      state.sectionsDocumentMaster = action.payload;
    },
    setSortedSectionsDocumentMasterLocal: (
      state,
      action: PayloadAction<any>
    ) => {

      state.sortedSectionsDocumentMaster = action.payload;

    },

    updateSectionsDocumentMasterLocal:(
      state,
      action: PayloadAction<{
        sectionID: number;
        DocumentMaster: DocumentMaster;
      }>
    ) => {
      state.sectionsDocumentMaster = {
        ...state.sectionsDocumentMaster,
        [action.payload.sectionID]: action.payload.DocumentMaster,
      };
    },

    setStatus: (
      state,
      action: PayloadAction<{
        status: "idle" | "loading" | "failed";
        error: string | undefined;
      }>
    ) => {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setImageDocumentMaster: (state, action: PayloadAction<any | undefined>) => {
      state.imagedocumentMaster = action.payload;
    },
    setTableDocumentMaster: (state, action: PayloadAction<any | undefined>) => {
      state.tabledocumentMaster = action.payload;
    },
    clearMasterElementSettings: (state) => {
      state.tabledocumentMaster =
        '{"format":"MARKDOWN","columns":2,"rows":2,"caption":"Table","justify":"center","justifyContent":"left","displayCaption":true,"captionAlignment":"center","captionOrder":"below","font":"arial","fontSize":"normalsize","color":"black","isNumberFormat":false,"isBold":false,"isItalic":false,"isUnderline":false,"isStandard":true}';

      state.imagedocumentMaster =
        '{"size":"Original","custom":100,"justify":"center","caption":"Image","DisplyCaption":true,"captionOrder":"below","captionAlignment":"center","font":"arial","fontSize":"normalsize","color":"black","style":"bold","isBold":false,"isItalic":false,"isUnderline":false,"isStandard":true}';
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {},
});

export const {
  clearMasterElementSettings,
  setDocumentMasterLocal,
  setStatus,
  setError,
  setTableDocumentMaster,
  setImageDocumentMaster,
  setSortedSectionsDocumentMasterLocal,
  setSectionsDocumentMasterLocal,
  updateSectionsDocumentMasterLocal,
} = documentMasterSlice.actions;

//Selectors
export const selectDocumentMasterState = (state: RootState) =>
  state.documentMaster;
export const selectSectionsDocumentMasterState = (state: RootState) =>
  state.documentMaster.sectionsDocumentMaster;
export const selectDocumentTemplateMaster = (state: RootState) =>
  state.documentMaster.documentMaster;


  export const selectFormattingSection = (
    state: RootState,
    sectionID: number | undefined
  ) => {
    // Check if sectionID is undefined or if the section does not exist in the state
    if (sectionID === undefined || Object.keys(state.documentMaster.sectionsDocumentMaster).length == 0  || state.documentMaster.sectionsDocumentMaster[sectionID] == null) {
      return {
        ...state.documentMaster,
        documentMaster: state.documentMaster.documentMaster,
        isFollowSecSettings: false,
        numberingSetting:"CN",
        isdefault:true
      };
    }
    const currentSectionFormatting = JSON.parse(state.documentMaster.sectionsDocumentMaster[sectionID]);
  
    // Determine if the section is following section settings
    const isFollowSecSettings =
      Object.keys(state.documentMaster.sectionsDocumentMaster).length !== 0 &&
      state.documentMaster.sectionsDocumentMaster[sectionID] &&
      !currentSectionFormatting.followDTM;

  
    // Select the appropriate section formatting
    const sectionFormatting = currentSectionFormatting.followDTM
      ? state.documentMaster.documentMaster
      : currentSectionFormatting.previousSettings
        ? state.documentMaster.sortedSectionsDocumentMaster[sectionID]
        : currentSectionFormatting;
  
    return {
      ...state.documentMaster,
      documentMaster: sectionFormatting ?? state.documentMaster.documentMaster,
      isFollowSecSettings: isFollowSecSettings,
      numberingSetting:
      //  isFollowSecSettings && sectionFormatting  ?
      JSON.parse(state.documentMaster.sectionsDocumentMaster[sectionID]).numberingSetting
        ??"CN",
        isdefault: currentSectionFormatting.previousSettings || currentSectionFormatting.followDTM

    };
  };

export default documentMasterSlice.reducer;

import React from "react";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  DocumentElement,
  DocumentSection,
  DocumentSubsection,
} from "../../../../types/DocumentTemplate";
import {
  moveSelectedSubsectionDownward,
  moveSelectedSubsectionUpward,
} from "../HelperEditorFunctions";
import useActions, {
  useAppDispatch,
  useAppSelector,
} from "../../../../app/hooks";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { selectApprovalMode } from "../../editorSlice";

export default function SwitchSubsection(props: {
  parentSection: DocumentSection;
  parentSubsection: DocumentSubsection;
  element: DocumentElement;
  direction: "up" | "down";
  isDisabled: boolean;
  style? :any ;
readonly?:boolean
}) {
  const dispatch = useAppDispatch();
  const actions = useActions();
  const approvalsEnabled = useAppSelector(selectApprovalMode);
  // const approvalsEnabled = true;

  return (
    <Box
      sx={ 
        // !approvalsEnabled && !props.readonly ?
        {
        ...props.style,
        position: "relative",
        zIndex:99
     }
     //:{}
    
    }
    >
      {props.direction == "up" && !approvalsEnabled && !props.readonly && (
        <IconButton
          disableRipple
          style={{
            position: "absolute",
            // top: 8,
            top: -8,
            // right: "50%",
            // transform: "translate(50%, -50%)",
            left:-13,
            zIndex: "99",
            color: "silver.main !important",
            backgroundColor: "transparent", // Remove the grey color effect on hover
          }}
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => {
            moveSelectedSubsectionUpward(
              props.parentSection as DocumentSection,
              props.parentSubsection!,
              actions.switchSubsections
            );
          }}
        >
          {" "}
          <ExpandLessIcon
            fontSize="small"
            style={{
              fontSize: 22,
              outline: "none",
              color: "#7284A3",
            }}
          />
        </IconButton>
      )}
      {props.direction == "down" && !approvalsEnabled  && !props.readonly && (
        <IconButton
          disableRipple
          style={{
            position: "absolute",
            bottom: -10,
            left:-13,
            // right: "50%",
            // transform: "translate(50%, -50%)",
            zIndex: "99",
            color: "silver.main !important",
            backgroundColor: "transparent", // Remove the grey color effect on hover
          }}
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => {
            moveSelectedSubsectionDownward(
              props.parentSection as DocumentSection,
              props.parentSubsection!,
              actions.switchSubsections
            );
          }}
        >
          {" "}
          <ExpandMoreIcon
            fontSize="small"
            style={{
              fontSize: 22,
              outline: "none",
              color: "#7284A3",
            }}
          />
        </IconButton>
      )}
    </Box>
  );
}

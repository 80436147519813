import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { TableOfContents } from './TableOfContent/TableOfContents';

// const drawerWidth = 260;

// REF: https://mui.com/components/drawers/#clipped-under-the-app-bar
export function LeftDrawer(props: any) {
  const drawer = (
    <Box sx={{ overflow: 'auto' }}>
        <TableOfContents></TableOfContents>
    </Box>
  );

  return (
    <>
    <Drawer
      variant="permanent"
      sx={{
        width: '18vw',
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { mx: 0, width: '18vw', boxSizing: 'border-box' },
      }}
      anchor="left"
    >
      <Toolbar />
        {drawer}
    </Drawer>
    </>
  )
}

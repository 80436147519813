import { createAction } from "@reduxjs/toolkit";
import { DocumentMaster } from "../types/DocumentMaster";
import {
  DocumentElement,
  DocumentRecord,
  DocumentSection,
  DocumentSectionCreateRequest,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateCreateRequest,
  Reference,
  ReferenceCreateRequest,
  Variable,
  VariableCreateRequest,
} from "../types/DocumentTemplate";
import {
  Organization,
  Search,
  User,
  Workspace,
  WorkspaceCreateRequest,
} from "../types/User";

export const login = createAction<{
  email: string;
  pass: string;
  rememberMe?: boolean;
}>("login");
export const signup = createAction<any>("signup");
export const reset = createAction<{ email: string }>("reset");
export const resetUserPassword = createAction<{ email: string }>(
  "resetUserPassword"
);
export const finalizeReset = createAction<{ password: string; token: string }>(
  "finalizeReset"
);
export const finalizeResetWithUserDetails = createAction<{ userDetails:any }>(
  "finalizeResetWithUserDetails"
);

export const changePassword = createAction<{ email: string; password: string }>(
  "changePassword"
);

// Organizations
export const getOrganizations = createAction("getOrganizations");
export const setOrganization = createAction<Organization>("setOrganization");
export const getOrganizationUsers = createAction("getOrganizationUsers");

// Workspaces
export const getWorkspace = createAction<number>("getWorkspace");
export const getWorkspaces = createAction("getWorkspaces");
export const getWorkspaceSummary = createAction("getWorkspaceSummary");
export const getWorkspaceSummarySearch = createAction<any>(
  "getWorkspaceSummarySearch"
);
export const getWorkspaceSummaryDocument = createAction<{
  documentID:number,
  workspaceID:number
}>(
  "getWorkspaceSummaryDocument"
);
// export const getWorkspaceSummarySearch = createAction<string>("getWorkspaceSummarySearch");
export const setWorkspace = createAction<Workspace>("setWorkspace");
export const deleteWorkspace = createAction<Workspace>("deleteWorkspace");
export const createWorkspace =
  createAction<WorkspaceCreateRequest>("createWorkspace");
export const attachUser = createAction<{
  workspace: Workspace;
  user: User;
  role: string;
}>("attachUser");
export const createUser = createAction<{
  workspace: Workspace;
  email: string;
  password: string;
}>("createUser");

export const createUserInOrg = createAction<{
  email: string;
  password: string;
  workspaces: Array<{ id: number; role: string }>;
}>("createUserInOrg");

export const createUserWithOrg = createAction<{
  email: string;
  organization_name: string;
}>("createUserWithOrg");
export const updateOrganizationCredits = createAction<{
  organizationID: number;
  updatedCredit: number;
  organizationName: string;
  allowNegitiveCredits:boolean;
}>("updateOrganizationCredits");

export const deleteOrganization = createAction<{
  organizationID: number;
}>("deleteOrganization");

export const deleteSuperUser = createAction<{
  userID: number;
}>("deleteSuperUser");

export const createAdminUserInOrg = createAction<{
  email: string;
}>("createAdminUserInOrg");

export const createSuperUserInOrg = createAction<{
  email: string;
}>("createSuperUserInOrg");

export const detachUser = createAction<{ workspace: Workspace; user: User }>(
  "detachUser"
);
export const detachOrganizationUser = createAction<{
  workspace: Workspace;
  user: User;
}>("detachOrganizationUser");

export const updateOrganizationUserRole = createAction<{
  user: number;
  newRole: string;
}>("updateOrganizationUserRole");

export const changeUserRole = createAction<{
  role: string;
  user_id: number;
  workspace: Workspace | undefined;
}>("changeUserRole");

// Document Templates
export const getDocumentTemplates = createAction("getDocumentTemplates");
export const createDocumentTemplate =
  createAction<DocumentTemplateCreateRequest>("createDocumentTemplate");
export const updateDocumentTemplate = createAction<DocumentTemplate>(
  "updateDocumentTemplate"
);
export const updateDocumentTemplateImportText = createAction<DocumentTemplate>(
  "updateDocumentTemplateImportText"
);
export const executeDocumentTemplateImport = createAction<DocumentTemplate>(
  "executeDocumentTemplateImport"
);
export const getDocumentTemplateVariables = createAction<DocumentTemplate>(
  "getDocumentTemplateVariables"
);
export const previewDocumentTemplate = createAction<DocumentTemplate>(
  "previewDocumentTemplate"
);
export const generateDocumentTemplate = createAction<DocumentTemplate>(
  "generateDocumentTemplate"
);
export const getDocumentImagePreview = createAction<DocumentTemplate>(
  "getDocumentImagePreview"
);

export const getRecordImagePreview = createAction<DocumentRecord>(
  "getRecordImagePreview"
);

export const approvalImpactChanges = createAction<DocumentTemplate>(
  "approvalImpactChanges"
);

export const approvalPublishDocumentTemplate = createAction<DocumentTemplate>(
  "approvalPublishDocumentTemplate"
);
export const publishDocumentTemplate = createAction<DocumentTemplate>(
  "publishDocumentTemplate"
);
export const againPublishDocumentTemplate = createAction<DocumentTemplate>(
  "againPublishDocumentTemplate"
);
export const deleteDocumentTemplate = createAction<DocumentTemplate>(
  "deleteDocumentTemplate"
);

export const deleteDocumentRecord = createAction<DocumentRecord>(
  "deleteDocumentRecord"
);

export const cloneDocumentTemplate = createAction<{
  document_template: DocumentTemplate;
  target_workspace_id: number;
}>("cloneDocumentTemplate");
export const moveDocumentTemplate = createAction<{
  document_template: DocumentTemplate;
  target_workspace_id: number;
}>("moveDocumentTemplate");

export const draftCollaborationDocumentTemplate = createAction<{
  document_template: DocumentTemplate;
  updatedDocumentStatus: "locked" | "unlocked";
}>("draftCollaborationDocumentTemplate");

export const makeNormalDocumentTemplate = createAction<DocumentTemplate>(
  "makeNormalDocumentTemplate"
);
export const undeleteDocumentTemplate = createAction<DocumentTemplate>(
  "undeleteDocumentTemplate"
);

export const undeleteDocumentRecord = createAction<DocumentRecord>(
  "undeleteDocumentRecord "
);
export const importDocumentTemplate = createAction<File>(
  "importDocumentTemplate"
);
export const importDocumentTemplateFromDoc = createAction<{
  name: string;
  isOnceOff: boolean;
  category: string;
  subCategory: string;
  file: File;
}>("importDocumentTemplateFromDoc");
export const favouriteDocumentTemplate = createAction<DocumentTemplate>(
  "favouriteDocumentTemplate"
);
export const unfavouriteDocumentTemplate = createAction<DocumentTemplate>(
  "unfavouriteDocumentTemplate"
);

// Document Records
export const getDocumentRecords = createAction("getDocumentRecords");
export const createDocumentRecord = createAction<{
  documentTemplate: DocumentTemplate;
  name: string;
  variables: Variable[];
}>("createDocumentRecord");

export const createBulkDocumentRecord = createAction<{
  documentTemplate: DocumentTemplate;
  bulkDocuments: any;
}>("createBulkDocumentRecord");

export const previewDocumentRecord = createAction<DocumentRecord>(
  "previewDocumentRecord"
);

// Editor
export const enableApprovalMode = createAction("enableApprovalMode");

export const setEditingDocumentTemplate = createAction<DocumentTemplate>(
  "setEditingDocumentTemplate"
);

export const refreshDocumentTemplate = createAction<DocumentTemplate>(
  "refreshDocumentTemplate"
);

export const fetchDocumentTemplate = createAction<{
  documentTemplate:DocumentTemplate, 
  fetchTemplate:boolean,
  fetchMaster:boolean,
  fetchSectionFormatting:boolean

}>(
  "fetchDocumentTemplate"
);

export const setOpenApprovalDocumentTemplate = createAction<DocumentTemplate>(
  "setOpenApprovalDocumentTemplate"
);
export const createSection =
  createAction<DocumentSectionCreateRequest>("createSection");

//Writing Action for CreateSectionWithElement
export const createSectionWithElement = createAction<{
  heading: string;
  component_order: number;
  belowSectionID?: number;
  documentElement: DocumentElement;
}>("createSectionWithElement");

export const attachSection = createAction<{
  sourceDocumentSectionId: number;
  componentOrder: number;
  sourceDocumentId?: number;
  subSectionsId?: any;
  parentSection?: any;
  destinationSectionId?: any;
  SectionswithSubSections?: any;
  belowSectionID?: number;
  sectionSequence?: any;
  createCopy?: boolean;
}>("attachSection");
export const updateSection = createAction<DocumentSection>("updateSection");
export const updateSectionContent = createAction<DocumentSection>(
  "updateSectionContent"
);

export const createSectionAboveSection = createAction<{
  belowSection: DocumentSection;
  insertedSection: DocumentSectionCreateRequest;
}>("createSectionAboveSection");
export const switchSections = createAction<{
  sourceSection: DocumentSection;
  destinationSection: DocumentSection;
}>("switchSections");
export const deleteSection = createAction<DocumentSection>(
  "deleteSectionSection"
);

export const deleteSectionAllDocuments = createAction<DocumentSection>(
  "deleteSectionAllDocuments"
);

export const undoDeletedSection =
  createAction<DocumentSection>("undoDeletedSection");

export const createSubsectionWithElement = createAction<{
  section: DocumentSection;
  documentElement: DocumentElement;
  subsectionAbove?: DocumentSubsection;
  isFocusNext?: boolean;
}>("createSubsectionWithElement");

export const deleteSubsection = createAction<{
  section: DocumentSection;
  subSection: DocumentSubsection;
  currentDocOnly?: boolean;
}>("deleteSubsection");

export const undoDeletedSubsection = createAction<{
  section: DocumentSection;
  subSection: DocumentSubsection;
}>("undoDeletedSubsection");
export const switchSubsections = createAction<{
  section: DocumentSection;
  sourceSubsection: DocumentSubsection;
  destinationSubsection: DocumentSubsection;
  onlyHeading?: boolean;
}>("switchSubsections");

export const switchSubsectionBlock = createAction<{
  section: DocumentSection;
  sourceSubsection: DocumentSubsection;
  destinationSubsection: DocumentSubsection;
  onlyHeading?: boolean;
}>("switchSubsectionBlock");

export const updateDocumentElement = createAction<{
  section: DocumentSection;
  subSection: DocumentSubsection;
  documentElement: DocumentElement;
  isTextEmpty?: boolean;
}>("updateDocumentElement");
export const updateDocumentElementContent = createAction<{
  section: DocumentSection;
  subSection: DocumentSubsection;
  documentElement: DocumentElement;
  updateLevels?: boolean;
  // isTextEmpty?:boolean
}>("updateDocumentElementContent");

export const attachVersionDocumentElement = createAction<{
  sectionID: number;
  subSectionID: number;
  documentElementID: number;
}>("attachVersionDocumentElement");

export const approveDocumentSection = createAction<{
  approveSectionID: number | null;
  declinedSectionID: number | null;
}>("approveDocumentSection");

export const getVariables = createAction("getVariables");
export const createVariable =
  createAction<VariableCreateRequest>("createVariable");
export const updateVariable = createAction<Variable>("updateVariable");
export const deleteVariable = createAction<Variable>("deleteVariable");

export const getReferences = createAction("getReferences");
export const createReference =
  createAction<ReferenceCreateRequest>("createReference");
export const updateReference = createAction<Reference>("updateReference");
export const deleteReference = createAction<Reference>("deleteReference");

export const getDocumentMaster = createAction("getDocumentMaster");

export const getSectionDocumentMaster = createAction(
  "getSectionDocumentMaster"
);
export const updateDocumentMaster = createAction<DocumentMaster>(
  "updateDocumentMaster"
);
export const updateSectionDocumentMaster = createAction<{
  DocumentMaster: DocumentMaster;
  documentSectionId: number;
}>("updateSectionDocumentMaster");

export const createDocumentMaster = createAction<DocumentMaster>(
  "createDocumentMaster"
);

export const updateWorkspaceDocumentMaster = createAction<DocumentMaster>(
  "updateWorkspaceDocumentMaster"
);

export const uploadDocumentElementImage = createAction<{
  section: DocumentSection;
  subSection: DocumentSubsection;
  documentElement: DocumentElement;
  file: File;
}>("uploadDocumentElementImage");

export const setHeadingLevel = createAction<{
  selected: {
    section: DocumentSection | null;
    subSection: DocumentSubsection | null;
    documentElement: DocumentElement | null;
  };
  heading_level: number;
}>("setHeadingLevel");

export const updateSectionHeadingLevels = createAction<{
  documentTemplate: DocumentTemplate;
  section: DocumentSection;
  refreshDocument?: boolean;
  sectionFromTemplate?: boolean;
}>("updateSectionHeadingLevels");

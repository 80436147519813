import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import tempImg from "./images/backgroundImageSignin.jpg";

import useActions, { useAppSelector } from "../../app/hooks";
import { AuthState, logout } from "./authSlice";
import { Email, Warning } from "@mui/icons-material";
import Alert from "@mui/material/Alert";

import logo from "../../nomia-logo2.png";

import { useSnackbar } from "notistack";
import TimeZoneSelector from "../shared/UtilitesComponents/TimeZoneSelector";
import TermsAndConditionsDialog from "./TermsAndCondition";
import EmailInput from "../shared/UtilitesComponents/EmailInput";
import OTPScreen from "../shared/UtilitesComponents/OTPScreen";
import { host } from "src/services/client";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://nomia.co.za/">
        Nomia Pty. Ltd.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignupModal(props: {
  authState: AuthState;
  handleClose: any;
}) {
  const actions = useActions();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showTermsDialog, setShowTermsDialog] = React.useState<boolean>(false);
  const [isOtpScreen, setIsOtpScreen] = React.useState<boolean>(false);
  const [userDetails, setUserDetails] = React.useState({
    email: "",
    password: "",
    confirmPassword:"",
    firstName: "",
    lastName: "",
    timeZone: "",
    organizationName: "",
  });
  const passwordValid = (password:string) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };
  const handleFinalizeSignUp = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleResendOtp()
    setIsOtpScreen(true);
//  setShowTermsDialog(true);
  };

  const handleTermsClose = (accepted: boolean) => {
    setShowTermsDialog(false);
    if (accepted) {
      const signUpData = {
        email: userDetails.email,
        organization_name: userDetails.organizationName,
        firstname: userDetails.firstName,
        lastname: userDetails.lastName,
        timezone: userDetails.timeZone,
        password:userDetails.password
      };
      // Perform the sign-up action here
      actions.signup(signUpData);
      enqueueSnackbar("Sign up successful!", { variant: "success" });
      props.handleClose();
    } else {
      enqueueSnackbar("You must accept the Terms and Conditions to sign up.", {
        variant: "error",
      });
    }
  };

  const handleVerifyOtp =async (otp: string) => {  

    try {
      const response = await fetch(`${host}/verify_otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email:userDetails.email ,otp:parseInt(otp, 10) }),
      });
      if (response.ok) {
        setShowTermsDialog(true);
      } else {
        const errorData = await response.json();
        enqueueSnackbar(`${errorData.message}`, { variant: "error" });
      }
    } catch (error:any) {
      enqueueSnackbar(`Error sending OTP: ${error.message}`, { variant: "error" });
    }
  };
  const handleResendOtp = async () => {
    try {
      const response = await fetch(`${host}/generate_otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email:userDetails.email }),
      });

      if (response.ok) {
        // enqueueSnackbar("OTP sent successfully!", { variant: "success" });
      } else {
        const errorData = await response.json();
        enqueueSnackbar(`Error sending OTP: ${errorData.message}`, { variant: "error" });
      }
    } catch (error:any) {
      enqueueSnackbar(`Error sending OTP: ${error.message}`, { variant: "error" });
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        style={{ backgroundImage: `url(${tempImg})`, backgroundSize: "cover" }}
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} width="200px" alt="nomiaLogo" />
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!isOtpScreen && (
            <Box sx={{ mt: 1, width: 400 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                type="Text"
                id="firstName"
                label="First Name"
                value={userDetails.firstName}
                name="firstName"
                autoComplete = "off"
                autoFocus
                onChange={(event) =>
                  setUserDetails({
                    ...userDetails,
                    firstName: event.target.value,
                  })
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                type="Text"
                id="_LastName"
                label="Last Name"
                name="LastName"
                autoComplete = "off"
                value={userDetails.lastName}
                onChange={(event) =>
                  setUserDetails({
                    ...userDetails,
                    lastName: event.target.value,
                  })
                }
              />

              <EmailInput
                value={userDetails.email}
                onChange={(newEmail: string) =>
                  setUserDetails({ ...userDetails, email: newEmail })
                }
                required
              />
              <TextField
                margin="normal"
                required
                fullWidth
                type="password"
                autoComplete = "off"
                id="first_password"
                label="Password"
                // name="Password"
                value={userDetails.password}
                onChange={(event) =>
                  setUserDetails({
                    ...userDetails,
                    password: event.target.value,
                  })
                }
                error={
                  userDetails.password !== "" && !passwordValid(userDetails.password)
                }
                helperText={
                  userDetails.password !== "" && !passwordValid(userDetails.password)
                    ? "Password must be at least 8 characters long, contain at least one uppercase letter, one number, and one special character."
                    : ""
                }
              />
                    <TextField
                margin="normal"
                required
                fullWidth
                autoComplete = "off"
                type="password"
                id="Confirm_password"
                label="Confirm Password"
                name="Confirm Password"
                value={userDetails.confirmPassword}
                onChange={(event) =>
                  setUserDetails({
                    ...userDetails,
                    confirmPassword: event.target.value,
                  })
                }
                error={userDetails.confirmPassword!="" && userDetails.password !=userDetails.confirmPassword}
                helperText={
                  userDetails.confirmPassword !== "" &&
                  userDetails.password !== userDetails.confirmPassword
                    ? "Passwords do not match"
                    : ""
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                autoComplete = "off"
                type="Text"
                id="_organizationName"
                label="Organization Name (Organization name can't be changed)"
                name="_organizationName"
                value={userDetails.organizationName}
                onChange={(event) =>
                  setUserDetails({
                    ...userDetails,
                    organizationName: event.target.value,
                  })
                }
              />
              <TimeZoneSelector
                value={userDetails.timeZone}
                onChange={(newTimeZone: any) =>
                  setUserDetails({ ...userDetails, timeZone: newTimeZone })
                }
                label="Time Zone"
                required
              />
              {/* {props.authState.error !== undefined ? (
                <Alert severity="error">{props.authState.error}.</Alert>
              ) : null} */}
              <Button
                onClick={handleFinalizeSignUp}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={
                  userDetails.email === "" ||
                  userDetails.firstName == "" ||
                  userDetails.lastName == "" ||
                  userDetails.organizationName == "" ||
                  userDetails.password !=userDetails.confirmPassword ||
                  userDetails.timeZone == "" ||
                  !userDetails.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
                }
              >
                continue
              </Button>
            </Box>
          )}

          {isOtpScreen && (
            <OTPScreen
              email={userDetails.email}
              onVerifyOTP={handleVerifyOtp}
              onResendOTP={handleResendOtp}
              error={props.authState.error}
            />
          )}

          <Grid container>
            <Grid item xs>
              <Button
                onClick={() => {
                  if (isOtpScreen) {
                    setIsOtpScreen(false);
                  } else {
                    props.handleClose();
                  }
                }}
              >
                {isOtpScreen ? "Return to Sign Up" : "Return to Sign In"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <TermsAndConditionsDialog
          open={showTermsDialog}
          onClose={handleTermsClose}
        />
      </Dialog>
    </ThemeProvider>
  );
}

import { select } from "redux-saga/effects";
import { call, put } from "redux-saga/effects";
import * as actions from "../actions";
import {
  selectDocumentTemplateMaster,
  selectSectionsDocumentMasterState,
  setDocumentMasterLocal,
  setError,
  setSectionsDocumentMasterLocal,
  setSortedSectionsDocumentMasterLocal,
  setStatus,
} from "../../features/editor/header/documentMasterSlice";
import { DocumentMaster } from "../../types/DocumentMaster";
import {
  apiCreateDocumentMaster,
  apiGetDocumentMaster,
  apiGetSectionsDocumentMaster,
  apiUpdateDocumentMaster,
  apiUpdateSectionsDocumentMaster,
  apiUpdateWorkspaceDocumentMaster,
} from "../../services/documentMasterAPI";
import { DocumentTemplate } from "../../types/DocumentTemplate";
import { selectEditingDocumentTemplate } from "../../features/editor/editorSlice";
import { User } from "../../types/User";
import { selectUser } from "../../features/auth/authSlice";
import { fixSectionFormatMaster } from "./utils";

/**
 * Get the document master for a given DocumentTemplate.
 *
 * @param {ReturnType<typeof actions.getDocumentMaster>} action
 * @return {*}
 */


export function* getDocumentMaster(
  action: ReturnType<typeof actions.getDocumentMaster>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    const response = yield call(
      apiGetDocumentMaster,
      userState.default_organization_id,
      documentTemplate
    );
    
    // Update the local instance
    yield put(setDocumentMasterLocal(response.data.document_template_master));
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "idle", error: String(e) }));
    return;
  }
}
export function* getSectionDocumentMaster(
  action: ReturnType<typeof actions.getSectionDocumentMaster>
) {
  yield put(setStatus({ status: "loading", error: undefined }));
  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;

    if (documentTemplate == null) throw "No document is currently being edited";
    const response = yield call(
      apiGetSectionsDocumentMaster,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate
    );
    yield put(setSectionsDocumentMasterLocal(response.data));

    const documentTemplateMaster = yield select(
      selectDocumentTemplateMaster
    ) 

    // Update the local instance
  const fixedSectionFormating=  fixSectionFormatMaster( documentTemplate ,response.data , documentTemplateMaster )
     yield put(setSortedSectionsDocumentMasterLocal(fixedSectionFormating));
     
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
    return;
  }
}
/**
 * Update the document master for a given DocumentTemplate.
 *
 * @param {ReturnType<typeof actions.updateDocumentMaster>} action
 * @return {*}
 */

export function* updateWorkspaceDocumentMaster(
  action: ReturnType<typeof actions.updateWorkspaceDocumentMaster>
) {
  yield put(setStatus({ status: "loading", error: undefined }));
  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";

    const response = yield call(
      apiUpdateWorkspaceDocumentMaster,
      userState.default_organization_id,
      documentTemplate,
      action.payload
    );
    
    // Update the local instance
    yield put(setDocumentMasterLocal(response.data.document_template_master));
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "idle", error: String(e) }));
    return;
  }
}

export function* createDocumentMaster(
  action: ReturnType<typeof actions.createDocumentMaster>
) {
  yield put(setStatus({ status: "loading", error: undefined }));
  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    const response = yield call(
      apiCreateDocumentMaster,
      userState.default_organization_id,
      documentTemplate,
      action.payload
    );
    // Update the local instance
    yield put(setDocumentMasterLocal(response.data.document_template_master));
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "idle", error: String(e) }));
    return;
  }
}

export function* updateSectionDocumentMaster(
  action: ReturnType<typeof actions.updateSectionDocumentMaster>
) {
  yield put(setStatus({ status: "loading", error: undefined }));
  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    const response = yield call(
      apiUpdateSectionsDocumentMaster,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload.documentSectionId,
      action.payload.DocumentMaster
    );
    // Update the local instance


    yield put(actions.getSectionDocumentMaster())
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "idle", error: String(e) }));
    return;
  }
}

export function* updateDocumentMaster(
  action: ReturnType<typeof actions.updateDocumentMaster>
) {
  yield put(setStatus({ status: "loading", error: undefined }));
  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    const response = yield call(
      apiUpdateDocumentMaster,
      userState.default_organization_id,
      documentTemplate,
      action.payload
    );

    // Update the local instance
    yield put(setDocumentMasterLocal(response.data.document_template_master));
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "idle", error: String(e) }));
    return;
  }
}



export const isIncludeImpact = (approvalState:any, subsectionID:any) => {
    return Object.prototype.hasOwnProperty.call(approvalState, subsectionID);
  };

 export const returnImpactSubsection = (approvalState:any,subsectionID: any) => {
    if (
      subsectionID &&
      Object.prototype.hasOwnProperty.call(approvalState, subsectionID)
    ) {
      const retrievedData = approvalState[subsectionID];
      return retrievedData;
    }
    return null;
  };

export  const  isImpactPending = (approvalState:any, subsectionID: any): boolean => {
    if (
      subsectionID &&
      Object.prototype.hasOwnProperty.call(approvalState, subsectionID)
    ) {
      const retrievedData = approvalState[subsectionID];
       return  isPendingAvailable(retrievedData)
    }
   return false
  };

  export  const  isPendingAvailable = (approvalObject: any): boolean => {
    return approvalObject.some((item: any) => item.status === "pending");
  };


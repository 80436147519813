import { ContentType } from "../../../types/DocumentTemplate";

class ValidationResult {
    valid: boolean;
    message: string;

    constructor(valid: boolean, message = "") {
        this.valid = valid;
        this.message = message;
    }
}


/**
 * Validate the content and return status and a message if there
 * is an error.
 * @export
 * @param {string} text
 * @param {ContentType} type
 * @return {*}  {ValidationResult}
 */
export default function validateContent(text: string, type: ContentType):ValidationResult {
    switch (type) {
        case ContentType.LibraryContent: return validateLibraryContent(text);
        case ContentType.Heading: return validateHeading(text);
        // did this to disallow the add new line to the text box
        // case ContentType.Text: return validateHeading(text);
        case ContentType.Text: return validateText(text);
        case ContentType.Table: return validateTable(text);
        case ContentType.dynamicTable: return validateTable(text);
        case ContentType.Image: return validateImage(text);
        default:
            console.error(`Document content type ${type} does not have a validation handler.`)
    }
    return new ValidationResult(false);
}

function validateLibraryContent(text:string):ValidationResult {
    return new ValidationResult(true);
}

function validateHeading(text:string):ValidationResult {
    if(text.includes("\n")) return new ValidationResult(false, "Documents should only contain single heading lines")
    return new ValidationResult(true);
}


function validateText(text:string):ValidationResult {
    return new ValidationResult(true);
}

function validateTable(text:string):ValidationResult {
    return new ValidationResult(true);
}

function validateImage(text:string):ValidationResult {
    return new ValidationResult(true);
}
import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Folder } from "./Folder";
import Grid from "@mui/material/Grid";
import useActions, {
  useAppDispatch,
  useAppSelector,
} from "../../../../app/hooks";
import {
  selectLibrary,
  setGroupBy,
  setViewAuthoredByMe,
  setViewAwaitingApproval,
  setViewDeleted,
  setViewDocumentRecords,
  setViewDraft,
  setViewFavourites,
  setViewOnceOffDocuments,
  setViewPublished,
  setViewRecents,
  setViewTemplates,
} from "../../librarySlice";

import RecentBlueIcon from "../../../../Icons/Library Left Drawer/Blue Icons/Recent.svg";
import RecentBlackIcon from "../../../../Icons/Library Left Drawer/Black Icons/recent Folder.svg";
import FavoriteBlack from "../../../../Icons/Library Left Drawer/Black Icons/(Favorites Folder).svg";
import FavoriteBlue from "../../../../Icons/Library Left Drawer/Blue Icons/Favorites Folder.svg";
import DraftBlack from "../../../../Icons/Library Left Drawer/Black Icons/Draft Folder.svg";
import DraftBlue from "../../../../Icons/Library Left Drawer/Blue Icons/Drafts Folder.svg";
import TemplatesBlack from "../../../../Icons/Library Left Drawer/Black Icons/Templates Folder.svg";
import TemplatesBlue from "../../../../Icons/Library Left Drawer/Blue Icons/Templates Folder.svg";
import PublishedBlack from "../../../../Icons/Library Left Drawer/Black Icons/published.svg";
import PublishedBlue from "../../../../Icons/Library Left Drawer/Blue Icons/Published Folder.svg";
import OnceOffDocumentBlack from "../../../../Icons/Library Left Drawer/Black Icons/once of documents.svg";
import OnceOffDocumentBlue from "../../../../Icons/Library Left Drawer/Blue Icons/once of documents.svg";
import PDFBlack from "../../../../Icons/Library Left Drawer/Black Icons/PDF.svg";
import PDFBlue from "../../../../Icons/Library Left Drawer/Blue Icons/PDF.svg";
import ArchivedBlack from "../../../../Icons/Library Left Drawer/Black Icons/Archive.svg";
import ArchivedBlue from "../../../../Icons/Library Left Drawer/Blue Icons/Archive.svg";
import AuthoredBymeBlack from "../../../../Icons/Library Left Drawer/Black Icons/Authered by me.svg";
import AuthoredBymeBlue from "../../../../Icons/Library Left Drawer/Blue Icons/Authered by me.svg";
import AwaitingApprovalBlack from "../../../../Icons/Library Left Drawer/Black Icons/Folders Awaiting.svg";
import AwaitingApprovalBlue from "../../../../Icons/Library Left Drawer/Blue Icons/Approval Waiting.svg";
import AuthoredBymeBlack_ from "../../../../Icons/Library Left Drawer/Black Icons/Edit Paragraph.svg";
import AuthoredBymeBlue_ from "../../../../Icons/Library Left Drawer/Blue Icons/EditParagraph.svg";

import GroupByBlue from "../../../../Icons/Library Left Drawer/Blue Icons/GroupBy.svg";
import GroupByBlack from "../../../../Icons/Library Left Drawer/Black Icons/GroupBy.svg";

import { FilteredIcons } from "./FilterIcons";
import { Divider, Tooltip, Typography } from "@mui/material";
import { FilterStackTile } from "./FiltersStackTile";
// REF: https://mui.com/components/drawers/#clipped-under-the-app-bar
export function LeftDrawerLibrary() {
  const gridHeight = "13.5vh";
  const librarySlice = useAppSelector(selectLibrary);
  const dispatch = useAppDispatch();
  const actions = useActions();

  const isFilterApplied =
    librarySlice.viewRecents ||
    librarySlice.viewFavourites ||
    librarySlice.viewDraft ||
    librarySlice.viewTemplates ||
    librarySlice.viewPublished ||
    librarySlice.viewOnceOffDocumnet ||
    librarySlice.viewDeleted ||
    librarySlice.viewDocumentRecords ||
    librarySlice.viewAuthoredByMe ||
    librarySlice.viewAwaitingApprovals;

  // React.useEffect(()=>{
  //   noFilterSelected()
  // }, [librarySlice])

  // const noFilterSelected=()=>{
  //   // Check if any Filter Applied ? if not than set groupBy ("none")
  //   if(isFilterApplied==false){
  //     handleGrougBy("none")
  //   }
  // }

  const handleViewDrafts = () => {
    dispatch(setViewDraft(!librarySlice.viewDraft));
    actions.getDocumentTemplates();
  };

  const handleViewRecents = () => {
    dispatch(setViewRecents(!librarySlice.viewRecents));
    actions.getDocumentTemplates();
  };

  const handleViewFavourites = () => {
    dispatch(setViewFavourites(!librarySlice.viewFavourites));
    actions.getDocumentTemplates();
  };

  const handleViewTempalates = () => {
    dispatch(setViewTemplates(!librarySlice.viewTemplates));
    actions.getDocumentTemplates();
  };

  const handleViewPublished = () => {
    dispatch(setViewPublished(!librarySlice.viewPublished));
    actions.getDocumentTemplates();
  };

  const handleViewOnceOffDocuments = () => {
    dispatch(setViewOnceOffDocuments(!librarySlice.viewOnceOffDocumnet));
    actions.getDocumentTemplates();
  };

  const handleViewRecords = () => {
    if (librarySlice.viewDocumentRecords && librarySlice.groupBy === "PDF") {
      dispatch(setGroupBy("none"));
    }
    dispatch(setViewDocumentRecords(!librarySlice.viewDocumentRecords));

    actions.getDocumentTemplates();
  };

  const handleViewArchived = () => {
    dispatch(setViewDeleted(!librarySlice.viewDeleted));
    actions.getDocumentTemplates();
  };

  const handleViewAuthoredByMe = () => {
    dispatch(setViewAuthoredByMe(!librarySlice.viewAuthoredByMe));
    actions.getDocumentTemplates();
  };

  const handleViewAwaitingApproval = () => {
    dispatch(setViewAwaitingApproval(!librarySlice.viewAwaitingApprovals));
    actions.getDocumentTemplates();
  };

  const handleGrougBy = (selection: string) => {
    if (librarySlice.groupBy === selection) {
      dispatch(setGroupBy("none"));
      actions.getDocumentTemplates();
    } else {
      dispatch(setGroupBy(selection));
      actions.getDocumentTemplates();
    }
  };

  const iconButtonStyle = {
    width: "100%",
    // padding:'0px',
    // Add more inline styles if needed
    backgroundColor: "transparent", // Remove the grey color effect on hover
  };

  const filtersData = [
    {
      text: "Recents",
      isSelected: librarySlice.viewRecents,
      unSelectedIcon: RecentBlackIcon,
      selectedIcon: RecentBlueIcon,
      action: handleViewRecents,
    },
    {
      text: "Favourites",
      isSelected: librarySlice.viewFavourites,
      unSelectedIcon: FavoriteBlack,
      selectedIcon: FavoriteBlue,
      action: handleViewFavourites,
    },
    {
      text: "Authored by me",
      isSelected: librarySlice.viewAuthoredByMe,
      unSelectedIcon: AuthoredBymeBlack_,
      selectedIcon: AuthoredBymeBlue_,
      action: handleViewAuthoredByMe,
    },
    {
      text: "Drafts",
      isSelected: librarySlice.viewDraft,
      unSelectedIcon: DraftBlack,
      selectedIcon: DraftBlue,
      action: handleViewDrafts,
    },
    {
      text: "Templates",
      isSelected: librarySlice.viewTemplates,
      unSelectedIcon: TemplatesBlack,
      selectedIcon: TemplatesBlue,
      action: handleViewTempalates,
    },
    {
      text: "Once-off documents",
      isSelected: librarySlice.viewOnceOffDocumnet,
      unSelectedIcon: OnceOffDocumentBlack,
      selectedIcon: OnceOffDocumentBlue,
      action: handleViewOnceOffDocuments,
    },
    {
      text: "Published",
      isSelected: librarySlice.viewPublished,
      unSelectedIcon: PublishedBlack,
      selectedIcon: PublishedBlue,
      action: handleViewPublished,
    },
    {
      text: "Awaiting approval",
      isSelected: librarySlice.viewAwaitingApprovals,
      unSelectedIcon: AwaitingApprovalBlack,
      selectedIcon: AwaitingApprovalBlue,
      action: handleViewAwaitingApproval,
    },
    {
      text: "PDF's",
      isSelected: librarySlice.viewDocumentRecords,
      unSelectedIcon: PDFBlack,
      selectedIcon: PDFBlue,
      action: handleViewRecords,
    },

    {
      text: "Archived",
      isSelected: librarySlice.viewDeleted,
      unSelectedIcon: ArchivedBlack,
      selectedIcon: ArchivedBlue,
      action: handleViewArchived,
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        marginTop: "15px",
      
        // borderRight: "2px solid #7284A3",
        paddingRight: "10px",
      
      }}
    >
      <Typography
        className="Filter-typography"
        variant="h3"
        color="Black"
        align="left"
        sx={{
          maxWidth: "80%", // Limit the width to 90% of the container
          fontSize: 17, // Adjust the font size as needed
          whiteSpace: "nowrap", // Prevent text from wrapping
          overflow: "hidden", // Hide overflow
          textOverflow: "ellipsis", // Show ellipsis for truncated text
          paddingLeft: 2,
          paddingBottom: 1,
        }}
      >
        Filters:
      </Typography>
      <Box sx={{width: "14vw"}}>
        <Grid container spacing={0}>
          {filtersData.map((filter: any, index: number) => (
            <Box
              sx={{
                zindex: 9999999999999,
                cursor: "pointer",
                 marginLeft: 2,
                // minWidth: "100%",
             
              }}
              onClick={filter.action}
              key={index}
            >
              <Grid sx={{ py: 0 }} item xs={10}>
                <FilterStackTile
                  key={index} // Use a unique key for each filter tile
                  text={filter.text}
                  isSelected={filter.isSelected}
                  unselectedIcon={filter.unSelectedIcon}
                  selectedIcon={filter.selectedIcon}
                />
              </Grid>
            </Box>
          ))}
        </Grid>
      </Box>

      <Divider
        flexItem
        style={{
          margin: "0 5px",
          marginTop: "7px",
          border: "1.5px solid #7284A3",
          backgroundColor: "#7284A3",
        }}
      />
      <Typography
        className="GroupBy-typography"
        variant="h3"
        color="black"
        align="left"
        sx={{
          width: "25vw",
          fontSize: 17,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          paddingLeft: 2,
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        Group by:
      </Typography>

      <Grid container spacing={0} sx={{width: "25vw",}}>
        <Grid sx={{ py: 0 }} item xs={11}>
          <Box
        sx={{ marginLeft: 2, marginRight: 1 , cursor:"pointer" }}
            onClick={() => handleGrougBy("Category")}
          >
            <FilterStackTile
              key={13} // Use a unique key for each filter tile
              text={"Category"}
              isSelected={librarySlice.groupBy === "Category"}
              unselectedIcon={AuthoredBymeBlack}
              selectedIcon={AuthoredBymeBlue}
            />
          </Box>
        </Grid>
        <Grid sx={{ py: 0 }} item xs={11}>
          <Box
           sx={{ marginLeft: 2, marginRight: 1 , cursor:"pointer" }}
            onClick={() => handleGrougBy("Sub-Category")}
          >
            <FilterStackTile
              key={14} // Use a unique key for each filter tile
              text={"Sub-category"}
              isSelected={librarySlice.groupBy === "Sub-Category"}
              unselectedIcon={AuthoredBymeBlack}
              selectedIcon={AuthoredBymeBlue}
            />
          </Box>
        </Grid>
        {librarySlice.viewDocumentRecords && (
          <Grid sx={{ py: 0 }} item xs={11}>
            <Box
              sx={{ marginLeft: 2, marginRight: 1 , cursor:"pointer" }}
              onClick={() => handleGrougBy("PDF")}
              
            >
              <FilterStackTile
                key={16} // Use a unique key for each filter tile
                text={"Template/Once-off"}
                isSelected={librarySlice.groupBy === "PDF"}
                unselectedIcon={AuthoredBymeBlack}
                selectedIcon={AuthoredBymeBlue}
              />
            </Box>
          </Grid>
        )}
      </Grid>

      {/* <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <IconButton
              disableRipple
              // disabled={!isFilterApplied}
              onClick={() => handleGrougBy("Category")}
            >
              <FilteredIcons
                text={"Category"}
                isSelected={librarySlice.groupBy === "Category"}
                unselectedIcon={GroupByBlack}
                selectedIcon={GroupByBlue}
              />
            </IconButton>
          </Grid>
          <Grid item xs={4}>
            <IconButton
              disableRipple
              // disabled={!isFilterApplied}
              onClick={() => handleGrougBy("Sub-Category")}
            >
              <FilteredIcons
                text={"Sub-category"}
                isSelected={librarySlice.groupBy === "Sub-Category"}
                unselectedIcon={GroupByBlack}
                selectedIcon={GroupByBlue}
              />
            </IconButton>
          </Grid>
          {librarySlice.viewDocumentRecords && (
            <Grid item xs={3.5}>
              <IconButton
                disableRipple
                onClick={() => handleGrougBy("PDF")}
                // disabled={!isFilterApplied}
              >
                <FilteredIcons
                  text={"Template/once-off document"}
                  isSelected={librarySlice.groupBy === "PDF"}
                  unselectedIcon={GroupByBlack}
                  selectedIcon={GroupByBlue}
                />
                
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Box> */}
    </Box>
  );
}

/* WYSIWYG Editing cell, which produces and parses markdown.
Draft.js react editor.
REF: https://jpuri.github.io/react-draft-wysiwyg/#/docs.
REF: https://draftjs.org/docs/api-reference-data-conversion
Exporting and importing reference: https://www.npmjs.com/package/markdown-draft-js
*/
import React, { useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// Snackbar notification integration. REF: https://www.npmjs.com/package/material-ui-snackbar-provider
import { useDrop } from "react-dnd";
import { memo } from "react";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import {
  ContentType,
  DocumentElement,
  DocumentSection,
  DocumentSubsection,
  DocumentTemplate,
} from "../../../types/DocumentTemplate";

import useActions, { useAppDispatch, useAppSelector } from "../../../app/hooks";
// import { DocumentElementEditor } from "./DocumentElementEditor";
import DocumentElementEditor from "./DocumentElementEditor";
import SettingsIcon from "@mui/icons-material/Settings";
import Typography from "@mui/material/Typography";
import { ImageElementEditor } from "./images/ImageElementEditor";
// import { LibraryContentEditor } from "./libraryContent/LibraryContentEditor";
import { SectionAdder } from "./SectionAdder";
import { ViewPreferences } from "../header/preferencesSlice";
import { selectApprovalMode } from "../editorSlice";
import { selectCurrentWorkspaceRole } from "../../auth/authSlice";
import { DebugID } from "./DebugID";
import { DropSkeleton } from "./DropSkeleton";
import { getEditorForDocumentElement } from "./utils";

import {
  approveChangedSection,
  declineChangedSection,
  selectApprovedChangedSections,
  selectDeclinedChangedSections,
  selectSkippedChangedSections,
  skipChangedSection,
} from "../editorSlice";
import { secondarySilver } from "../../../globals";
import {
  selectFormattingSection,
  selectSectionsDocumentMasterState,
} from "../header/documentMasterSlice";

import { useTheme } from "@mui/material/styles";
import SectionVersionControl from "./versionControl/SectionVersionControl";
import { isEditorDisabled } from "./HelperEditorFunctions";
import { IconButton, Tooltip } from "@mui/material";
import SectionSettingConfigForm from "./sectionSettings/SettingConfig";
import { shallowEqual } from "react-redux";
import { COLORS } from "../../shared/Constants";

const SectionEditor = memo(function SectionEditor(props: {
  organizationId: number;
  workspaceId: number;
  documentTemplate: DocumentTemplate;
  section: DocumentSection;
  viewPreferences: ViewPreferences;
  isSelected: boolean;
}) {
  const actions = useActions();
  const approvalsEnabled = useAppSelector(selectApprovalMode);
  const [openSettingModal, setOpenSettingModal] =
    React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const section = props.section;
  const _documentMasterState = useAppSelector(
    (state) => selectFormattingSection(state, props.section.id),
    shallowEqual
  );
  return (
    <Box
      sx={{
        border: props.viewPreferences.showSections ? 2 : 0,
        borderColor: "silver.main",
        borderRadius: 0,
        boxShadow: props.viewPreferences.showSections ? 3 : 0,
        background: props.isSelected ? "#DFDFFF" : "#FFFFFF",
        marginLeft: 1,
        marginRight: 1,
        marginTop: 0.5,
        marginBottom: 0.5,
        overflow: "visible",
      }}
    >
      {props.viewPreferences.showIDs ? (
        <DebugID
          name="Section"
          id={section.id}
          componentOrder={section.component_order}
        />
      ) : null}

      {!(isEditorDisabled(section.heading))  && !approvalsEnabled  && (
          <Box
            sx={{
              position: "relative",
              zIndex: 99,
            }}
          >
            <Tooltip title="Section Formatting" placement="top">
              <IconButton
                onClick={(e: any) => setOpenSettingModal(true)}
                disabled={
                  _documentMasterState.sectionsDocumentMaster[props.section.id]
                    ? false
                    : true
                }
                style={{
                  position: "absolute",
                  top: 0,
                  right: -30,
                  transition: "none", // Remove transition on button press
                  margin: 0,
                  padding: 0,
                  width: "fit-content",
                }}
              >
                <FormatPaintIcon
                  style={{
                    fontSize: 22,
                    outline: "none",
                    color: !_documentMasterState.isdefault
                      ? COLORS.NOMIA_BLUE
                      : "#7284A3",
                    //  boxShadow: _documentMasterState.isFollowSecSettings ? "0 0 10px rgba(0, 0, 255, 0.5)" : "none",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}

      {!(
        isEditorDisabled(section.heading) &&
        props.section.heading !== "Title Page"
      ) && (
        <DocumentElementEditor
          key={section.id}
          element={null}
          parentSubsection={null}
          parentSection={section}
          documentTemplate={props.documentTemplate}
          organizationId={props.organizationId}
          workspaceId={props.workspaceId}
          isHeading={true}
          viewPreferences={props.viewPreferences}
          forApproval={false}
          approvalsEnabled={approvalsEnabled}
          approvalOf={-1}
          readOnly={false}
        />
      )}
      {section
        .subsections!.filter(
          (__) => __.pending_deleted_at === null || approvalsEnabled
        )

        .map((subsection, index) => {
          if (subsection.elements!.length > 0) {
            let hasPub = null;
            let hasNew = null;
            subsection.elements!.forEach((_) => {
              // if ((_ as any).publication_no === null) {
              if ((_ as any).valid_until === null) {
                hasNew = _;
              } else {
                hasPub = _;
              }
            });
            return (
              <Box 
              sx={{overflow:"visble"}}
              key={subsection.id + "_ss"}>
                {props.viewPreferences.showIDs ? (
                  <DebugID
                    name="Subsection"
                    id={subsection.id}
                    componentOrder={subsection.component_order}
                  />
                ) : null}

                {hasNew &&
                  getEditorForDocumentElement(
                    hasNew,
                    subsection,
                    section,
                    section,
                    props.documentTemplate,
                    props.workspaceId,
                    props.organizationId,
                    props.viewPreferences,
                    false,
                    subsection.id + "_ss_e1",
                    approvalsEnabled,
                    -1
                  )}
                {hasPub &&
                  !hasNew &&
                  getEditorForDocumentElement(
                    hasPub,
                    subsection,
                    section,
                    section,
                    props.documentTemplate,
                    props.workspaceId,
                    props.organizationId,
                    props.viewPreferences,
                    false,
                    subsection.id + "_ss_e3",
                    approvalsEnabled,
                    -1
                  )}
              </Box>
            );
          } else
            return (
              <div key={subsection.id}>
                <Typography align="center">
                  {/* Subsection does not contain a document element. */}
                </Typography>
                {/* Is this possible? Do we want to allow users to delete this in this scenario? */}
              </div>
            );
        })}
      {openSettingModal && (
        <SectionSettingConfigForm
          documentTemplate={props.documentTemplate}
          section={props.section}
          closeDialogHandler={() => {
            setOpenSettingModal(false);
          }}
        />
      )}
    </Box>
  );
});

export default SectionEditor;

import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import DialogTitle from "@mui/material/DialogTitle";
import useActions, { useAppSelector } from "../../../../app/hooks";
import {
  Alert,
  Autocomplete,
  DialogContentText,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { selectDocumentMasterState } from "../documentMasterSlice";
import SettingTemplateDocumentMaster from "./ContentFormattingMaster";
import HeaderFooterConfigForm from "../HeaderFooterConfigForm";
import DocumentMasterPageSetting from "./DocumentPageSetting";
import { useAppDispatch } from "../../../../app/hooks";
import { setDocumentMasterLocal } from "../documentMasterSlice";
import { json } from "react-router-dom";
import { useSnackbar } from "notistack";
import WarningIcon from "@mui/icons-material/Warning";
import { selectAuth } from "../../../auth/authSlice";
import { apiGetWorkspaceDocumentMasters } from "../../../../services/documentMasterAPI";
import { padding } from "@mui/system";
import { Title } from "@mui/icons-material";
import { compareDeepObjects } from "../FormattingHelperFunctions/MenuHelper";
import { selectEditingDocumentTemplate } from "../../editorSlice";

const _ignoreMasterFields: string[] = [
  "created_at",
  "deleted_at",
  "document_template_id",
  "id",
  "updated_at",
];

/**
 * Document master configuration form.
 *
 * @export
 * @return {*}
 */
export default function DocumentMasterConfigForm(props: {
  closeDialogHandler: any;
}) {
  // const documentTemplate = useAppSelector(selectEditingDocumentTemplate);
  const documentMasterState = useAppSelector(selectDocumentMasterState);
  const [documentMaster, setDocumentMaster] = React.useState<any | undefined>(
    undefined
  );
  const [DocumentMasterState, setDocumentMasterState] = React.useState<
    any | undefined
  >(documentMasterState.documentMaster);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const actions = useActions();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const [isStateChange, setIsStateChange] = React.useState(false);
  const [workspaceDoumentTemplateMaster, setWorkspaceDoumentTemplateMaster] =
    React.useState<any | undefined>([]);
  const handleCancel = () => {
    actions.getDocumentMaster();
    props.closeDialogHandler();
  };
  const documentTemplate = useAppSelector(selectEditingDocumentTemplate);

  // If the document master is loaded, update the state with a copy.

  React.useEffect(() => {
    if (
      documentMaster !== undefined &&
      documentMasterState.documentMaster !== null
    )
      setDocumentMasterState(documentMasterState.documentMaster);
  }, [documentMasterState]);

  if (
    documentMaster === undefined &&
    documentMasterState.documentMaster !== null
  ) {
    setDocumentMaster(Object.assign({}, documentMasterState.documentMaster));
  }
  function updateMasterProperty(prop: string, value: string) {
    if (value.trim() === "") return;
    if (typeof documentMaster[prop] === "number") {
      const val = Number(value);
      if (!isNaN(val)) documentMaster[prop] = val;
    } else documentMaster[prop] = value;
    setDocumentMaster({ ...documentMaster });
  }

  // console.log(" DocumentMasterState.inherited_master_id", DocumentMasterState.inherited_master_id , "jh",  DocumentMasterState)

  React.useEffect(() => {
    async function awaitapiGetWorkspaceDocumentMasters() {
      const response = await apiGetWorkspaceDocumentMasters(
        auth.user!.default_workspace_id!,
        auth.user!.default_organization_id!
      );

      setWorkspaceDoumentTemplateMaster(response.document_template_masters);
      if (DocumentMasterState.inherited_master_id) {
        const matchingMaster = response.document_template_masters.find(
          (master: any) => master.id === DocumentMasterState.inherited_master_id
        );

        if (matchingMaster) {
          setMasterTitle(matchingMaster.name);
        }
      }
    }

    try {
      awaitapiGetWorkspaceDocumentMasters();
    } catch (error) {
      console.error(
        "Error While calling get Organization, Workspace,  awaitapiGetWorkspaceDocumentMasters",
        error
      );
    }
  }, [props.closeDialogHandler]);

  // const [masterTitle, setMasterTitle] = React.useState<string>(
  //   documentMasterState.documentMaster.name
  // );
  const [masterTitle, setMasterTitle] = React.useState<string>(
    DocumentMasterState.name
  );

  const [pageStyleText, setPageStyleText] = React.useState<string>(() => {
    const {
      header_pageno_l,
      header_pageno_r,
      header_pageno_c,
      footer_pageno_l,
      footer_pageno_c,
      footer_pageno_r,
    } = documentMasterState.documentMaster;

    const values = [
      header_pageno_l,
      header_pageno_r,
      header_pageno_c,
      footer_pageno_l,
      footer_pageno_c,
      footer_pageno_r,
    ];

    return values.find((value) => value !== null) || "";
  });

  const [headerChecked, setHeaderChecked] = React.useState(
    documentMasterState.documentMaster.is_header
  );
  const handleHeaderChange = (event: any) => {
    setHeaderChecked(event.target.checked);
    setHeaderUnderline(event.target.checked);
  };

  const [footerChecked, setFooterChecked] = React.useState(
    documentMasterState.documentMaster.is_footer
  );
  const handleFooterChange = (event: any) => {
    setFooterChecked(event.target.checked);
    setFooterUnderline(event.target.checked);
  };

  const [headerSettings, setHeaderSettings] = React.useState([
    {
      id: 1,
      title: "Header Left",
      font: documentMasterState.documentMaster.header_font_style_l,
      fontSize:
        documentMasterState.documentMaster.header_font_size_l.toString(),
      fontStyle: JSON.parse(documentMasterState.documentMaster.header_styles_l),
      fontColor: documentMasterState.documentMaster.header_styles_l_color,
      selection: documentMasterState.documentMaster.header_selection_l,
      freeText: documentMasterState.documentMaster.header_free_text_l,
      image_Id: documentMasterState.documentMaster.header_image_l,
      pageNumberStyle: documentMasterState.documentMaster.page_number_style,
      isPageNumberOnFirst: false,
      pageStyleText:
        documentMasterState.documentMaster.header_pageno_l === null
          ? " "
          : documentMasterState.documentMaster.header_pageno_l,
    },
    {
      id: 2,
      title: "Header Center",
      font: documentMasterState.documentMaster.header_font_style_c,
      fontSize:
        documentMasterState.documentMaster.header_font_size_c.toString(),
      fontStyle: JSON.parse(documentMasterState.documentMaster.header_styles_c),
      fontColor: documentMasterState.documentMaster.header_styles_c_color,
      selection: documentMasterState.documentMaster.header_selection_c,
      freeText: documentMasterState.documentMaster.header_free_text_c,
      image_Id: documentMasterState.documentMaster.header_image_c,
      pageNumberStyle: documentMasterState.documentMaster.page_number_style,
      isPageNumberOnFirst: false,
      pageStyleText:
        documentMasterState.documentMaster.header_pageno_c === null
          ? " "
          : documentMasterState.documentMaster.header_pageno_c,
    },
    {
      id: 3,
      title: "Header Right",
      font: documentMasterState.documentMaster.header_font_style_r,
      fontSize:
        documentMasterState.documentMaster.header_font_size_r.toString(),
      fontStyle: JSON.parse(documentMasterState.documentMaster.header_styles_r),
      fontColor: documentMasterState.documentMaster.header_styles_r_color,
      selection: documentMasterState.documentMaster.header_selection_r,
      freeText: documentMasterState.documentMaster.header_free_text_r,
      image_Id: documentMasterState.documentMaster.header_image_r,
      pageNumberStyle: documentMasterState.documentMaster.page_number_style,
      isPageNumberOnFirst: false,
      pageStyleText:
        documentMasterState.documentMaster.header_pageno_r === null
          ? " "
          : documentMasterState.documentMaster.header_pageno_r,
    },
  ]);

  const [footerSettings, setFooterSettings] = React.useState([
    {
      id: 4,
      title: "Footer Left",
      font: documentMasterState.documentMaster.footer_font_style_l,
      fontSize:
        documentMasterState.documentMaster.footer_font_size_l.toString(),
      fontStyle: JSON.parse(documentMasterState.documentMaster.footer_styles_l),
      fontColor: documentMasterState.documentMaster.footer_styles_l_color,
      selection: documentMasterState.documentMaster.footer_selection_l,
      freeText: documentMasterState.documentMaster.footer_free_text_l,
      image_Id: documentMasterState.documentMaster.footer_image_l,
      pageNumberStyle: documentMasterState.documentMaster.page_number_style,
      isPageNumberOnFirst: false,
      pageStyleText:
        documentMasterState.documentMaster.footer_pageno_l === null
          ? " "
          : documentMasterState.documentMaster.footer_pageno_l,
    },
    {
      id: 5,
      title: "Footer Center",
      font: documentMasterState.documentMaster.footer_font_style_c,
      fontSize:
        documentMasterState.documentMaster.footer_font_size_c.toString(),
      fontStyle: JSON.parse(documentMasterState.documentMaster.footer_styles_c),
      fontColor: documentMasterState.documentMaster.footer_styles_c_color,
      selection: documentMasterState.documentMaster.footer_selection_c,
      freeText: documentMasterState.documentMaster.footer_free_text_c,
      image_Id: documentMasterState.documentMaster.footer_image_c,
      pageNumberStyle: documentMasterState.documentMaster.page_number_style,
      isPageNumberOnFirst: false,
      pageStyleText:
        documentMasterState.documentMaster.footer_pageno_c === null
          ? " "
          : documentMasterState.documentMaster.footer_pageno_c,
    },
    {
      id: 6,
      title: "Footer Right",
      font: documentMasterState.documentMaster.footer_font_style_r,
      fontSize:
        documentMasterState.documentMaster.footer_font_size_r.toString(),
      fontStyle: JSON.parse(documentMasterState.documentMaster.footer_styles_r),
      fontColor: documentMasterState.documentMaster.footer_styles_r_color,
      selection: documentMasterState.documentMaster.footer_selection_r,
      freeText: documentMasterState.documentMaster.footer_free_text_r,
      image_Id: documentMasterState.documentMaster.footer_image_r,
      pageNumberStyle: documentMasterState.documentMaster.page_number_style,
      isPageNumberOnFirst: false,
      pageStyleText:
        documentMasterState.documentMaster.footer_pageno_r === null
          ? " "
          : documentMasterState.documentMaster.footer_pageno_r,
    },
  ]);

  const [pageSettings, setPageSettings] = React.useState([
    {
      id: 1,
      title: "Page Settings",
      pageTopMargin: documentMasterState.documentMaster.page_margin_top,
      pageBottomMargin: documentMasterState.documentMaster.page_margin_bottom,
      pageLeftMargin: documentMasterState.documentMaster.page_margin_left,
      pageRightMargin: documentMasterState.documentMaster.page_margin_right,
      pageWidth: documentMasterState.documentMaster.page_width,
      pageHeight: documentMasterState.documentMaster.page_height,
      pageColumn: documentMasterState.documentMaster.page_columns,
      pageSize:
        documentMasterState.documentMaster.custom === "true"
          ? "custom"
          : documentMasterState.documentMaster.page_type,
      pageColor: documentMasterState.documentMaster.page_background_color,
      pageOrientation: documentMasterState.documentMaster.orientation,
      headerUnderline: documentMasterState.documentMaster.header_line,
      footerUnderline: documentMasterState.documentMaster.footer_line,
      raggedColumn: documentMasterState.documentMaster.ragged_column,
      defaultIndent: documentMasterState.documentMaster.default_indent,
    },
  ]);

  const generalHeadingFormatting = JSON.parse(
    documentMasterState.documentMaster.general_heading_settings
  );
  const [generalHeadingSettings, setGeneralHeadingSettings] = React.useState([
    {
      id: 1,
      title: "General Heading Settings",
      topMargin: generalHeadingFormatting.topMargin,
      bottomMargin: generalHeadingFormatting.bottomMargin,
      showColorPicker: false,
      fontColor: generalHeadingFormatting.fontColor,
      font: generalHeadingFormatting.font,
      fontSize: generalHeadingFormatting.fontSize,
      alignment: generalHeadingFormatting.alignment,
      fontStyle: generalHeadingFormatting.fontStyle,
      indent: generalHeadingFormatting.indent,
      format: generalHeadingFormatting.format,
      separator: generalHeadingFormatting.separator,
      prefix: generalHeadingFormatting.prefix,
      suffix: generalHeadingFormatting.suffix,
      compound: generalHeadingFormatting.compound,
    },
  ]);

  const paragraph1Formatting = JSON.parse(
    documentMasterState.documentMaster.paragraph1_formatting
  );
  const paragraph2Formatting = JSON.parse(
    documentMasterState.documentMaster.paragraph2_formatting
  );
  const paragraph3Formatting = JSON.parse(
    documentMasterState.documentMaster.paragraph3_formatting
  );
  const paragraph4Formatting = JSON.parse(
    documentMasterState.documentMaster.paragraph4_formatting
  );
  const paragraph5Formatting = JSON.parse(
    documentMasterState.documentMaster.paragraph5_formatting
  );
  const paragraph6Formatting = JSON.parse(
    documentMasterState.documentMaster.paragraph6_formatting
  );

  const [paragraphSettings, setParagraphSettings] = React.useState([
    {
      id: 1,
      title: "Title Page Paragraph",
      topMargin: paragraph1Formatting.topMargin,
      bottomMargin: paragraph1Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: paragraph1Formatting.fontColor,
      font: paragraph1Formatting.font,
      fontSize: paragraph1Formatting.fontSize,
      alignment: paragraph1Formatting.alignment,
      fontStyle: paragraph1Formatting.fontStyle,
      indent: paragraph1Formatting.indent,
      format: paragraph1Formatting.format,
      separator: paragraph1Formatting.separator,
      prefix: paragraph1Formatting.prefix,
      suffix: paragraph1Formatting.suffix,
      compound: paragraph1Formatting.compound,
      linespacing: paragraph1Formatting.linespacing,
    },
    {
      id: 2,
      title: "Paragraph 2",
      topMargin: paragraph2Formatting.topMargin,
      bottomMargin: paragraph2Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: paragraph2Formatting.fontColor,
      font: paragraph2Formatting.font,
      fontSize: paragraph2Formatting.fontSize,
      alignment: paragraph2Formatting.alignment,
      fontStyle: paragraph2Formatting.fontStyle,
      indent: paragraph2Formatting.indent,
      format: paragraph2Formatting.format,
      separator: paragraph2Formatting.separator,
      prefix: paragraph2Formatting.prefix,
      suffix: paragraph2Formatting.suffix,
      compound: paragraph2Formatting.compound,
      linespacing: paragraph2Formatting.linespacing,
    },
    {
      id: 3,
      title: "Paragraph 3",
      topMargin: paragraph3Formatting.topMargin,
      bottomMargin: paragraph3Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: paragraph3Formatting.fontColor,
      font: paragraph3Formatting.font,
      fontSize: paragraph3Formatting.fontSize,
      alignment: paragraph3Formatting.alignment,
      fontStyle: paragraph3Formatting.fontStyle,
      indent: paragraph3Formatting.indent,
      format: paragraph3Formatting.format,
      separator: paragraph3Formatting.separator,
      prefix: paragraph3Formatting.prefix,
      suffix: paragraph3Formatting.suffix,
      compound: paragraph3Formatting.compound,
      linespacing: paragraph3Formatting.linespacing,
    },
    {
      id: 4,
      title: "Paragraph 4",
      topMargin: paragraph4Formatting.topMargin,
      bottomMargin: paragraph4Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: paragraph4Formatting.fontColor,
      font: paragraph4Formatting.font,
      fontSize: paragraph4Formatting.fontSize,
      alignment: paragraph4Formatting.alignment,
      fontStyle: paragraph4Formatting.fontStyle,
      indent: paragraph4Formatting.indent,
      format: paragraph4Formatting.format,
      separator: paragraph4Formatting.separator,
      prefix: paragraph4Formatting.prefix,
      suffix: paragraph4Formatting.suffix,
      compound: paragraph4Formatting.compound,
      linespacing: paragraph4Formatting.linespacing,
    },
    {
      id: 5,
      title: "Paragraph 5",
      topMargin: paragraph5Formatting.topMargin,
      bottomMargin: paragraph5Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: paragraph5Formatting.fontColor,
      font: paragraph5Formatting.font,
      fontSize: paragraph5Formatting.fontSize,
      alignment: paragraph5Formatting.alignment,
      fontStyle: paragraph5Formatting.fontStyle,
      indent: paragraph5Formatting.indent,
      format: paragraph5Formatting.format,
      separator: paragraph5Formatting.separator,
      prefix: paragraph5Formatting.prefix,
      suffix: paragraph5Formatting.suffix,
      compound: paragraph5Formatting.compound,
      linespacing: paragraph5Formatting.linespacing,
    },
    {
      id: 6,
      title: "Paragraph 6",
      topMargin: paragraph6Formatting.topMargin,
      bottomMargin: paragraph6Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: paragraph6Formatting.fontColor,
      font: paragraph6Formatting.font,
      fontSize: paragraph6Formatting.fontSize,
      alignment: paragraph6Formatting.alignment,
      fontStyle: paragraph6Formatting.fontStyle,
      indent: paragraph6Formatting.indent,
      format: paragraph6Formatting.format,
      separator: paragraph6Formatting.separator,
      prefix: paragraph6Formatting.prefix,
      suffix: paragraph6Formatting.suffix,
      compound: paragraph6Formatting.compound,
      linespacing: paragraph6Formatting.linespacing,
    },
  ]);

  const generalParagraphFormatting = JSON.parse(
    documentMasterState.documentMaster.general_paragraph_settings
  );

  const [generalParagraphSettings, setParagraphHeadingSettings] =
    React.useState([
      {
        id: 1,
        title: "General Paragraph Settings",
        topMargin: generalParagraphFormatting.topMargin,
        bottomMargin: generalParagraphFormatting.bottomMargin,
        showColorPicker: false,
        fontColor: generalParagraphFormatting.fontColor,
        font: generalParagraphFormatting.font,
        fontSize: generalParagraphFormatting.fontSize,
        alignment: generalParagraphFormatting.alignment,
        fontStyle: generalParagraphFormatting.fontStyle,
        indent: generalParagraphFormatting.indent,
        format: generalParagraphFormatting.format,
        separator: generalParagraphFormatting.separator,
        prefix: generalParagraphFormatting.prefix,
        suffix: generalParagraphFormatting.suffix,
        compound: generalParagraphFormatting.compound,
        continueHeadingSettings: true,
        linespacing: generalParagraphFormatting.linespacing,
      },
    ]);

  // ! Header Setting

  const setHeaderFont = (index: number, newValue: string) => {
    setHeaderSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].font = newValue;
      return updatedSettings;
    });
  };

  const setPageNumberStyle = (index: number, newValue: string) => {
    // setHeaderSettings((prevSettings) => {
    //   const updatedSettings = [...prevSettings];
    //   updatedSettings[index].pageNumberStyle = newValue;
    //   return updatedSettings;
    // });

    setHeaderSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => ({
        ...setting,
        pageNumberStyle: newValue,
      }));
      return updatedSettings;
    });

    setFooterSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => ({
        ...setting,
        pageNumberStyle: newValue,
      }));
      return updatedSettings;
    });
  };

  const setFooterPageTextStyle = (index: number, newValue: string) => {
    setFooterSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].pageStyleText = newValue;
      return updatedSettings;
    });
  };
  const setHeaderPageTextStyle = (index: number, newValue: string) => {
    setHeaderSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].pageStyleText = newValue;
      return updatedSettings;
    });
  };
  const setHeaderFontSize = (index: number, newValue: string) => {
    setHeaderSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].fontSize = newValue;
      return updatedSettings;
    });
  };
  const setHeaderFontStyle = (index: number, newValue: any) => {
    setHeaderSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].fontStyle = newValue;
      return updatedSettings;
    });
  };
  const setHeaderFontColor = (index: number, newValue: string) => {
    setHeaderSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].fontColor = newValue;
      return updatedSettings;
    });
  };
  const setHeaderSelection = (index: number, newValue: string) => {
    setHeaderSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].selection = newValue;
      return updatedSettings;
    });
  };
  const setHeaderFreeText = (index: number, newValue: string) => {
    setHeaderSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].freeText = newValue;
      return updatedSettings;
    });
  };
  const setHeaderisPageNumberOnFirst = (index: number, newValue: any) => {
    setHeaderSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].isPageNumberOnFirst = newValue;
      return updatedSettings;
    });
  };
  const setHeaderimageID = (index: number, newValue: any) => {
    setHeaderSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].image_Id = newValue;
      return updatedSettings;
    });
  };

  const setFooterimageID = (index: number, newValue: string) => {
    setFooterSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].image_Id = newValue;
      return updatedSettings;
    });
  };

  // ! Footer Setting

  const setFooterPageNumberStyle = (index: number, newValue: string) => {
    setFooterSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].pageNumberStyle = newValue;
      return updatedSettings;
    });
  };

  const setFooterFont = (index: any, newValue: any) => {
    setFooterSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index] = { ...updatedSettings[index], font: newValue };
      return updatedSettings;
    });
  };
  const setFooterFontSize = (index: any, newValue: any) => {
    setFooterSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index] = {
        ...updatedSettings[index],
        fontSize: newValue,
      };
      return updatedSettings;
    });
  };
  const setFooterFontStyle = (index: any, newValue: any) => {
    setFooterSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index] = {
        ...updatedSettings[index],
        fontStyle: newValue,
      };
      return updatedSettings;
    });
  };
  const setFooterFontColor = (index: any, newValue: any) => {
    setFooterSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index] = {
        ...updatedSettings[index],
        fontColor: newValue,
      };
      return updatedSettings;
    });
  };
  const setFooterFreeText = (index: any, newValue: any) => {
    setFooterSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index] = {
        ...updatedSettings[index],
        freeText: newValue,
      };
      return updatedSettings;
    });
  };
  const setFooterSelection = (index: any, newValue: any) => {
    setFooterSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index] = {
        ...updatedSettings[index],
        selection: newValue,
      };
      return updatedSettings;
    });
  };
  const setFooterisPageNumberOnFirst = (index: number, newValue: any) => {
    setFooterSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].isPageNumberOnFirst = newValue;
      return updatedSettings;
    });
  };

  // ! Paragrapg General Settings

  const setContinueHeadingSetting = (newValue: any) => {
    // setParagraphHeadingSettings((prevSettings) => {
    //   const updatedSettings = prevSettings.map((setting) => {
    //     if (setting.id === 1) {
    //       return {
    //         ...setting,
    //         continueHeadingSettings: newValue,
    //       };
    //     }
    //     return setting;
    //   });
    //   return updatedSettings;
    // });
    const updatedParagraphGeneralSettings = generalHeadingSettings.map(
      (headingSetting) => {
        return {
          ...headingSetting,
          title: headingSetting.title.replace("Heading", "Paragraph"),
          linespacing: 1,
          continueHeadingSettings: true,
        };
      }
    );
    setParagraphHeadingSettings(updatedParagraphGeneralSettings);
    if (newValue === true) {
      const updatedParagraphSettings = headingSettings.map((headingSetting) => {
        return {
          ...headingSetting,
          title: headingSetting.title.replace("Heading", "Paragraph"),
          linespacing: 1,
        };
      });
      setParagraphSettings(updatedParagraphSettings);
    }

    enqueueSnackbar("Heading Settings Successfully applied", {
      variant: "success",
    });
  };

  const setTopMargin = (newValue: any) => {
    setParagraphHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            topMargin: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });

    for (let i = 0; i < 6; i++) {
      updateTopMargin(i, newValue);
    }
  };

  const setLineSpacing = (newValue: any) => {
    setParagraphHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            linespacing: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });

    for (let i = 0; i < 6; i++) {
      updateLineSpacing(i, newValue);
    }
  };

  const setBottomMargin = (newValue: any) => {
    setParagraphHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            bottomMargin: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      updateBottomMargin(i, newValue);
    }
  };
  const setFontColor = (newValue: any) => {
    setParagraphHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            fontColor: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      updateFontColor(i, newValue);
    }
  };
  const setFont = (newValue: any) => {
    setParagraphHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            font: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      updateFont(i, newValue);
    }
  };
  const setFontSize = (newValue: any) => {
    setParagraphHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            fontSize: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      updateFontSize(i, newValue);
    }
  };
  const setAlignment = (newValue: any) => {
    setParagraphHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            alignment: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      updateAlignment(i, newValue);
    }
  };
  const setFontStyle = (newValue: any) => {
    setParagraphHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            fontStyle: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      updateFontStyle(i, newValue);
    }
  };
  const setIndent = (newValue: any) => {
    setParagraphHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            indent: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      updateIndent(i, newValue);
    }
  };
  const setFormat = (newValue: any) => {
    setParagraphHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            format: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      updateFormat(i, newValue);
    }
  };

  const setSeparator = (newValue: any) => {
    setParagraphHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            separator: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      updateSeparator(i, newValue);
    }
  };
  const setPrefix = (newValue: any) => {
    setParagraphHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            prefix: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      updatePrefix(i, newValue);
    }
  };

  const setSuffix = (newValue: any) => {
    setParagraphHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            suffix: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      updateSuffix(i, newValue);
    }
  };

  const setCompound = (newValue: any) => {
    setParagraphHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            compound: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      updateCompound(i, newValue);
    }
  };

  //! General Heading Setting object

  const handleUpdateTopMargin = (newValue: any) => {
    setGeneralHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => ({
        ...setting,
        topMargin: newValue,
      }));
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      setHeadingTopMargin(i, newValue);
    }
  };

  const handleUpdateBottomMargin = (newValue: any) => {
    setGeneralHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => ({
        ...setting,
        bottomMargin: newValue,
      }));
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      setHeadingBottomMargin(i, newValue);
    }
  };

  const handleUpdateFontColor = (newValue: any) => {
    setGeneralHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => ({
        ...setting,
        fontColor: newValue,
      }));
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      setHeadingFontColor(i, newValue);
    }
  };

  const handleUpdateIndent = (newValue: any) => {
    setGeneralHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => ({
        ...setting,
        indent: newValue,
      }));
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      setHeadingIndent(i, newValue);
    }
  };

  const handleUpdateFont = (newValue: any) => {
    setGeneralHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => ({
        ...setting,
        font: newValue,
      }));
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      setHeadingFont(i, newValue);
    }
  };

  const handleUpdateFontSize = (newValue: any) => {
    setGeneralHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => ({
        ...setting,
        fontSize: newValue,
      }));
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      setHeadingFontSize(i, newValue);
    }
  };

  const handleUpdateFontStyle = (newValue: any) => {
    setGeneralHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => ({
        ...setting,
        fontStyle: newValue,
      }));
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      setHeadingFontStyle(i, newValue);
    }
  };

  const handleUpdateAlignment = (newValue: any) => {
    setGeneralHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => ({
        ...setting,
        alignment: newValue,
      }));
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      setHeadingAlignment(i, newValue);
    }
  };

  const handleUpdateFormat = (newValue: any) => {
    setGeneralHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => ({
        ...setting,
        format: newValue,
      }));
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      setHeadingFormat(i, newValue);
    }
  };

  const handleUpdateSeparator = (newValue: any) => {
    setGeneralHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => ({
        ...setting,
        separator: newValue,
      }));
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      setHeadingSeparator(i, newValue);
    }
  };

  const handleUpdatePrefix = (newValue: any) => {
    setGeneralHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => ({
        ...setting,
        prefix: newValue,
      }));
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      setHeadingPrefix(i, newValue);
    }
  };

  const handleUpdateSuffix = (newValue: any) => {
    setGeneralHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => ({
        ...setting,
        suffix: newValue,
      }));
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      setHeadingSuffix(i, newValue);
    }
  };

  const handleUpdateCompound = (newValue: any) => {
    setGeneralHeadingSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => ({
        ...setting,
        compound: newValue,
      }));
      return updatedSettings;
    });
    for (let i = 0; i < 6; i++) {
      setHeadingCompound(i, newValue);
    }
  };

  // ! Paragraph Setting Object

  const updateLineSpacing = (index: any, newValue: any) => {
    setParagraphSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].linespacing = newValue;
      return updatedSettings;
    });
  };

  const updateTopMargin = (index: any, newValue: any) => {
    setParagraphSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].topMargin = newValue;
      return updatedSettings;
    });
  };

  const updateBottomMargin = (index: any, newValue: any) => {
    setParagraphSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].bottomMargin = newValue;
      return updatedSettings;
    });
  };

  const updateShowColorPicker = (index: any, newValue: any) => {
    setParagraphSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].showColorPicker = newValue;
      return updatedSettings;
    });
  };

  const updateFontColor = (index: any, newValue: any) => {
    setParagraphSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].fontColor = newValue;
      return updatedSettings;
    });
  };

  const updateFont = (index: any, newValue: any) => {
    setParagraphSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].font = newValue;
      return updatedSettings;
    });
  };

  const updateFontSize = (index: any, newValue: any) => {
    setParagraphSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].fontSize = newValue;
      return updatedSettings;
    });
  };

  const updateAlignment = (index: any, newValue: any) => {
    setParagraphSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].alignment = newValue;
      return updatedSettings;
    });
  };

  const updateFontStyle = (index: any, newValue: any) => {
    setParagraphSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].fontStyle = newValue;
      return updatedSettings;
    });
  };

  const updateIndent = (index: any, newValue: any) => {
    setParagraphSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].indent = newValue;
      return updatedSettings;
    });
  };

  const updateFormat = (index: any, newValue: any) => {
    setParagraphSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].format = newValue;
      return updatedSettings;
    });
  };

  const updateSeparator = (index: any, newValue: any) => {
    setParagraphSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].separator = newValue;
      return updatedSettings;
    });
  };

  const updatePrefix = (index: any, newValue: any) => {
    setParagraphSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].prefix = newValue;
      return updatedSettings;
    });
  };

  const updateSuffix = (index: any, newValue: any) => {
    setParagraphSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].suffix = newValue;
      return updatedSettings;
    });
  };

  const updateCompound = (index: any, newValue: any) => {
    setParagraphSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].compound = newValue;
      return updatedSettings;
    });
  };

  const [headerImage, setHeaderImage] = React.useState([
    {
      id: 1,
      image: null,
    },
    {
      id: 2,
      image: null,
    },
    {
      id: 3,
      image: null,
    },
  ]);
  const [footerImage, setFooterImage] = React.useState([
    {
      id: 1,
      image: null,
    },
    {
      id: 2,
      image: null,
    },
    {
      id: 3,
      image: null,
    },
  ]);

  // console.log("headerr Image" , headerImage);

  const handleHeaderImageBind = (index: any) => (file: any) => {
    // console.log("header Image index", index);
    setHeaderImage((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].image = file;
      return updatedSettings;
    });
  };

  const handleHeaderImage = (index: number, id: any, file: any) => {
    console.log("the header : ", "index", index, "ID", id, file);
    setHeaderImage((prevSettings) => {
      const updatedSettings = prevSettings.map((setting, i) => {
        if (setting.id === id) {
          return {
            ...setting,
            image: file,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
  };

  const handleFooterImage = (index: any, file: any) => {
    setHeaderImage((prevSettings) => {
      const updatedSettings = [...prevSettings];
      footerImage[index].image = file;
      return updatedSettings;
    });
  };

  const heading1Formatting = JSON.parse(
    documentMasterState.documentMaster.heading1_formatting
  );
  const heading2Formatting = JSON.parse(
    documentMasterState.documentMaster.heading2_formatting
  );
  const heading3Formatting = JSON.parse(
    documentMasterState.documentMaster.heading3_formatting
  );
  const heading4Formatting = JSON.parse(
    documentMasterState.documentMaster.heading4_formatting
  );
  const heading5Formatting = JSON.parse(
    documentMasterState.documentMaster.heading5_formatting
  );
  const heading6Formatting = JSON.parse(
    documentMasterState.documentMaster.heading6_formatting
  );
  // ! Heading Setting Object
  const [headingSettings, setHeadingSettings] = React.useState([
    {
      id: 1,
      title: "Heading 1",
      topMargin: heading1Formatting.topMargin,
      bottomMargin: heading1Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: heading1Formatting.fontColor,
      font: heading1Formatting.font,
      fontSize: heading1Formatting.fontSize,
      alignment: heading1Formatting.alignment,
      fontStyle: heading1Formatting.fontStyle,
      indent: heading1Formatting.indent,
      format: heading1Formatting.format,
      prefix: heading1Formatting.prefix,
      suffix: heading1Formatting.suffix,
      compound: heading1Formatting.compound,
      separator: heading1Formatting.separator,
    },
    {
      id: 2,
      title: "Heading 2",
      topMargin: heading2Formatting.topMargin,
      bottomMargin: heading2Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: heading2Formatting.fontColor,
      font: heading2Formatting.font,
      fontSize: heading2Formatting.fontSize,
      alignment: heading2Formatting.alignment,
      fontStyle: heading2Formatting.fontStyle,
      indent: heading2Formatting.indent,
      format: heading2Formatting.format,
      separator: heading2Formatting.separator,
      prefix: heading2Formatting.prefix,
      suffix: heading2Formatting.suffix,
      compound: heading2Formatting.compound,
    },
    {
      id: 3,
      title: "Heading 3",
      topMargin: heading3Formatting.topMargin,
      bottomMargin: heading3Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: heading3Formatting.fontColor,
      font: heading3Formatting.font,
      fontSize: heading3Formatting.fontSize,
      alignment: heading3Formatting.alignment,
      fontStyle: heading3Formatting.fontStyle,
      indent: heading3Formatting.indent,
      format: heading3Formatting.format,
      separator: heading3Formatting.separator,
      prefix: heading3Formatting.prefix,
      suffix: heading3Formatting.suffix,
      compound: heading3Formatting.compound,
    },
    {
      id: 4,
      title: "Heading 4",
      topMargin: heading4Formatting.topMargin,
      bottomMargin: heading4Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: heading4Formatting.fontColor,
      font: heading4Formatting.font,
      fontSize: heading4Formatting.fontSize,
      alignment: heading4Formatting.alignment,
      fontStyle: heading4Formatting.fontStyle,
      indent: heading4Formatting.indent,
      format: heading4Formatting.format,
      separator: heading4Formatting.separator,
      prefix: heading4Formatting.prefix,
      suffix: heading4Formatting.suffix,
      compound: heading4Formatting.compound,
    },
    {
      id: 5,
      title: "Heading 5",
      topMargin: heading5Formatting.topMargin,
      bottomMargin: heading5Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: heading5Formatting.fontColor,
      font: heading5Formatting.font,
      fontSize: heading5Formatting.fontSize,
      alignment: heading5Formatting.alignment,
      fontStyle: heading5Formatting.fontStyle,
      indent: heading5Formatting.indent,
      format: heading5Formatting.format,
      separator: heading5Formatting.separator,
      prefix: heading5Formatting.prefix,
      suffix: heading5Formatting.suffix,
      compound: heading5Formatting.compound,
    },
    {
      id: 6,
      title: "Heading 6",
      topMargin: heading6Formatting.topMargin,
      bottomMargin: heading6Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: heading6Formatting.fontColor,
      font: heading6Formatting.font,
      fontSize: heading6Formatting.fontSize,
      alignment: heading6Formatting.alignment,
      fontStyle: heading6Formatting.fontStyle,
      indent: heading6Formatting.indent,
      format: heading6Formatting.format,
      separator: heading6Formatting.separator,
      prefix: heading6Formatting.prefix,
      suffix: heading6Formatting.suffix,
      compound: heading6Formatting.compound,
    },
    // Add more heading settings as needed
  ]);

  const setHeadingTopMargin = (index: any, newValue: any) => {
    setHeadingSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].topMargin = newValue;
      return updatedSettings;
    });
  };

  const setHeadingBottomMargin = (index: any, newValue: any) => {
    setHeadingSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].bottomMargin = newValue;
      return updatedSettings;
    });
  };

  const setHeadingShowColorPicker = (index: any, newValue: any) => {
    setHeadingSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].showColorPicker = newValue;
      return updatedSettings;
    });
  };

  const setHeadingFontColor = (index: any, newValue: any) => {
    setHeadingSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].fontColor = newValue;
      return updatedSettings;
    });
  };

  const setHeadingFont = (index: any, newValue: any) => {
    setHeadingSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].font = newValue;
      return updatedSettings;
    });
  };

  const setHeadingFontSize = (index: any, newValue: any) => {
    setHeadingSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].fontSize = newValue;
      return updatedSettings;
    });
  };

  const setHeadingAlignment = (index: any, newValue: any) => {
    setHeadingSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].alignment = newValue;
      return updatedSettings;
    });
  };

  const setHeadingFontStyle = (index: any, newValue: any) => {
    setHeadingSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].fontStyle = newValue;
      return updatedSettings;
    });
  };

  const setHeadingIndent = (index: any, newValue: any) => {
    setHeadingSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].indent = newValue;
      return updatedSettings;
    });
  };

  const setHeadingFormat = (index: any, newValue: any) => {
    setHeadingSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].format = newValue;
      return updatedSettings;
    });
  };

  const setHeadingSeparator = (index: any, newValue: any) => {
    setHeadingSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].separator = newValue;
      return updatedSettings;
    });
  };

  const setHeadingPrefix = (index: any, newValue: any) => {
    setHeadingSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].prefix = newValue;
      return updatedSettings;
    });
  };

  const setHeadingSuffix = (index: any, newValue: any) => {
    setHeadingSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].suffix = newValue;
      return updatedSettings;
    });
  };

  const setHeadingCompound = (index: any, newValue: any) => {
    setHeadingSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].compound = newValue;
      return updatedSettings;
    });
  };

  // ! Page Setting

  const setPageTopMargin = (newValue: any) => {
    setPageSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            pageTopMargin: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
  };

  const setPageOrientation = (newValue: any) => {
    setPageSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            pageOrientation: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
  };

  const setHeaderUnderline = (newValue: any) => {
    setPageSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            headerUnderline: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
  };

  const setFooterUnderline = (newValue: any) => {
    setPageSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            footerUnderline: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
  };
  const setRaggedColumn = (newValue: any) => {
    setPageSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            raggedColumn: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
  };
  const setDefaultIndent = (newValue: any) => {
    setPageSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            defaultIndent: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });

    if (newValue) {
      // For Paragraphs
      setCompound(true);
      setSeparator(".");
      setPrefix("");
      setSuffix("");
      setAlignment("raggedright");
      setIndent(0);
      // For Headings
      handleUpdateCompound(true);
      handleUpdateSuffix("");
      handleUpdatePrefix("");
      handleUpdateSeparator(".");
      handleUpdateAlignment("raggedright");
      handleUpdateIndent(0);
    } else {
      setPrefix("(");
      setSuffix(")");
      handleUpdateSuffix(")");
      handleUpdatePrefix("(");
    }
  };
  const setPageBottomMargin = (newValue: any) => {
    setPageSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            pageBottomMargin: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
  };

  const setPageLeftMargin = (newValue: any) => {
    setPageSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            pageLeftMargin: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
  };

  const setPageRightMargin = (newValue: any) => {
    setPageSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            pageRightMargin: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
  };

  const setPageWidth = (newValue: any) => {
    setPageSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            pageWidth: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
  };

  const setPageHeight = (newValue: any) => {
    setPageSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            pageHeight: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
  };

  const setPageColumn = (newValue: any) => {
    setPageSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            pageColumn: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
  };

  const setPageSize = (newValue: any) => {
    setPageSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            pageSize: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
  };

  const setPageColor = (newValue: any) => {
    setPageSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
        if (setting.id === 1) {
          return {
            ...setting,
            pageColor: newValue,
          };
        }
        return setting;
      });
      return updatedSettings;
    });
  };
  const [isSaveConfirmationVisible, setSaveConfirmationVisible] =
    React.useState(false);
  const [isSaveAsConfirmationVisible, setSaveAsConfirmationVisible] =
    React.useState(false);
  const handleSavePopUP = () => {
    setSaveConfirmationVisible(true);
  };
  const handleSavePopUPClose = () => {
    setSaveConfirmationVisible(false);
  };

  const handleSaveAsPopUP = () => {
    setSaveAsConfirmationVisible(true);
    setSaveConfirmationVisible(false);
    setMasterTitle("");
  };

  const handleSaveAsPopUPClose = () => {
    setSaveAsConfirmationVisible(false);
  };

  const isNameExist = workspaceDoumentTemplateMaster.find(
    (item: any) => item.name === masterTitle
  );
  const [selectedDocumentMaster, setSelectedDocumentMaster] = React.useState<
    any | undefined
  >();
  const handleSelectDocumentTemplate = (value: any) => {
    // Find the corresponding object in workspaceDoumentTemplateMaster based on the name
    const selectedDocument = workspaceDoumentTemplateMaster.find(
      (item: any) => item.name === value
    );
    setSelectedDocumentMaster(selectedDocument);
  };

  const [isDocumentMasterApplied, setisDocumentMasterApplied] =
    React.useState(false);
  const applySelectedDocument = () => {
    if (selectedDocumentMaster !== undefined || null) {
      dispatch(setDocumentMasterLocal(selectedDocumentMaster));
      setisDocumentMasterApplied(true);
      enqueueSnackbar("Formatting successfully applied.", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Unable to apply formatting", {
        variant: "error",
      });
    }
  };

  const updateGeneralHeadingSettings = () => {
    const generalHeadingFormatting = JSON.parse(
      documentMasterState.documentMaster.general_heading_settings
    );
    setGeneralHeadingSettings((prevSettings) =>
      prevSettings.map((setting) => ({
        ...setting,
        topMargin: generalHeadingFormatting.topMargin,
        bottomMargin: generalHeadingFormatting.bottomMargin,
        showColorPicker: false,
        fontColor: generalHeadingFormatting.fontColor,
        font: generalHeadingFormatting.font,
        fontSize: generalHeadingFormatting.fontSize,
        alignment: generalHeadingFormatting.alignment,
        fontStyle: generalHeadingFormatting.fontStyle,
        indent: generalHeadingFormatting.indent,
        format: generalHeadingFormatting.format,
        separator: generalHeadingFormatting.separator,
        prefix: generalHeadingFormatting.prefix,
        suffix: generalHeadingFormatting.suffix,
        compound: generalHeadingFormatting.compound,
      }))
    );
  };

  const updateGeneralParagraphSettings = () => {
    const generalParagraphFormatting = JSON.parse(
      documentMasterState.documentMaster.general_paragraph_settings
    );
    setParagraphHeadingSettings((prevSettings) =>
      prevSettings.map((setting) => ({
        ...setting,
        topMargin: generalParagraphFormatting.topMargin,
        bottomMargin: generalParagraphFormatting.bottomMargin,
        showColorPicker: false,
        fontColor: generalParagraphFormatting.fontColor,
        font: generalParagraphFormatting.font,
        fontSize: generalParagraphFormatting.fontSize,
        alignment: generalParagraphFormatting.alignment,
        fontStyle: generalParagraphFormatting.fontStyle,
        indent: generalParagraphFormatting.indent,
        format: generalParagraphFormatting.format,
        separator: generalParagraphFormatting.separator,
        prefix: generalParagraphFormatting.prefix,
        suffix: generalParagraphFormatting.suffix,
        compound: generalParagraphFormatting.compound,
        continueHeadingSettings: true,
        linespacing: generalParagraphFormatting.linespacing,
      }))
    );
  };

  const updateParagraphSettings = () => {
    const paragraphFormatting = [
      JSON.parse(documentMasterState.documentMaster.paragraph1_formatting),
      JSON.parse(documentMasterState.documentMaster.paragraph2_formatting),
      JSON.parse(documentMasterState.documentMaster.paragraph3_formatting),
      JSON.parse(documentMasterState.documentMaster.paragraph4_formatting),
      JSON.parse(documentMasterState.documentMaster.paragraph5_formatting),
      JSON.parse(documentMasterState.documentMaster.paragraph6_formatting),
    ];
    setParagraphSettings((prevSettings) =>
      prevSettings.map((setting, index) => ({
        ...setting,
        topMargin: paragraphFormatting[index].topMargin,
        bottomMargin: paragraphFormatting[index].bottomMargin,
        showColorPicker: false,
        fontColor: paragraphFormatting[index].fontColor,
        font: paragraphFormatting[index].font,
        fontSize: paragraphFormatting[index].fontSize,
        alignment: paragraphFormatting[index].alignment,
        fontStyle: paragraphFormatting[index].fontStyle,
        indent: paragraphFormatting[index].indent,
        format: paragraphFormatting[index].format,
        separator: paragraphFormatting[index].separator,
        prefix: paragraphFormatting[index].prefix,
        suffix: paragraphFormatting[index].suffix,
        compound: paragraphFormatting[index].compound,
        linespacing: paragraphFormatting[index].linespacing,
      }))
    );
  };

  // Function to update the headingSettings based on the new values from documentMasterState
  const updateHeadingSettings = () => {
    const headingFormatting = [
      JSON.parse(documentMasterState.documentMaster.heading1_formatting),
      JSON.parse(documentMasterState.documentMaster.heading2_formatting),
      JSON.parse(documentMasterState.documentMaster.heading3_formatting),
      JSON.parse(documentMasterState.documentMaster.heading4_formatting),
      JSON.parse(documentMasterState.documentMaster.heading5_formatting),
      JSON.parse(documentMasterState.documentMaster.heading6_formatting),
    ];
    setHeadingSettings((prevSettings) =>
      prevSettings.map((setting, index) => ({
        ...setting,
        topMargin: headingFormatting[index].topMargin,
        bottomMargin: headingFormatting[index].bottomMargin,
        showColorPicker: false,
        fontColor: headingFormatting[index].fontColor,
        font: headingFormatting[index].font,
        fontSize: headingFormatting[index].fontSize,
        alignment: headingFormatting[index].alignment,
        fontStyle: headingFormatting[index].fontStyle,
        indent: headingFormatting[index].indent,
        format: headingFormatting[index].format,
        separator: headingFormatting[index].separator,
        prefix: headingFormatting[index].prefix,
        suffix: headingFormatting[index].suffix,
        compound: headingFormatting[index].compound,
      }))
    );
  };

  const updatePageSettings = () => {
    setPageSettings((prevSettings) =>
      prevSettings.map((setting) => ({
        ...setting,
        pageTopMargin: documentMasterState.documentMaster.page_margin_top,
        pageBottomMargin: documentMasterState.documentMaster.page_margin_bottom,
        pageLeftMargin: documentMasterState.documentMaster.page_margin_left,
        pageRightMargin: documentMasterState.documentMaster.page_margin_right,
        pageWidth: documentMasterState.documentMaster.page_width,
        pageHeight: documentMasterState.documentMaster.page_height,
        pageColumn: documentMasterState.documentMaster.page_columns,
        pageSize:
          documentMasterState.documentMaster.custom === "true"
            ? "custom"
            : documentMasterState.documentMaster.page_type,
        pageColor: documentMasterState.documentMaster.page_background_color,
        pageOrientation: documentMasterState.documentMaster.orientation,
        headerUnderline: documentMasterState.documentMaster.header_line,
        footerUnderline: documentMasterState.documentMaster.footer_line,
      }))
    );
  };

  const updateFooterSettings = () => {
    setFooterSettings((prevSettings) =>
      prevSettings.map((setting) => ({
        ...setting,
        font: documentMasterState.documentMaster[
          `footer_font_style_${setting.title.charAt(7).toLowerCase()}`
        ],
        fontSize:
          documentMasterState.documentMaster[
            `footer_font_size_${setting.title.charAt(7).toLowerCase()}`
          ].toString(),
        fontStyle: JSON.parse(
          documentMasterState.documentMaster[
            `footer_styles_${setting.title.charAt(7).toLowerCase()}`
          ]
        ),
        fontColor:
          documentMasterState.documentMaster[
            `footer_styles_${setting.title.charAt(7).toLowerCase()}_color`
          ],
        selection:
          documentMasterState.documentMaster[
            `footer_selection_${setting.title.charAt(7).toLowerCase()}`
          ],
        freeText:
          documentMasterState.documentMaster[
            `footer_free_text_${setting.title.charAt(7).toLowerCase()}`
          ],
        image_Id:
          documentMasterState.documentMaster[
            `footer_image_${setting.title.charAt(7).toLowerCase()}`
          ],
        pageNumberStyle: documentMasterState.documentMaster.page_number_style,
        isPageNumberOnFirst: false,
        pageStyleText:
          documentMasterState.documentMaster[
            `footer_pageno_${setting.title.charAt(7).toLowerCase()}`
          ] === null
            ? " "
            : documentMasterState.documentMaster[
                `footer_pageno_${setting.title.charAt(7).toLowerCase()}`
              ],
      }))
    );
  };

  const updateHeaderSettings = () => {
    setHeaderSettings((prevSettings) =>
      prevSettings.map((setting) => ({
        ...setting,
        font: documentMasterState.documentMaster[
          `header_font_style_${setting.title.charAt(7).toLowerCase()}`
        ],
        fontSize:
          documentMasterState.documentMaster[
            `header_font_size_${setting.title.charAt(7).toLowerCase()}`
          ].toString(),
        fontStyle: JSON.parse(
          documentMasterState.documentMaster[
            `header_styles_${setting.title.charAt(7).toLowerCase()}`
          ]
        ),
        fontColor:
          documentMasterState.documentMaster[
            `header_styles_${setting.title.charAt(7).toLowerCase()}_color`
          ],
        selection:
          documentMasterState.documentMaster[
            `header_selection_${setting.title.charAt(7).toLowerCase()}`
          ],
        freeText:
          documentMasterState.documentMaster[
            `header_free_text_${setting.title.charAt(7).toLowerCase()}`
          ],
        image_Id:
          documentMasterState.documentMaster[
            `header_image_${setting.title.charAt(7).toLowerCase()}`
          ],
        pageNumberStyle: documentMasterState.documentMaster.page_number_style,
        isPageNumberOnFirst: false,
        pageStyleText:
          documentMasterState.documentMaster[
            `header_pageno_${setting.title.charAt(7).toLowerCase()}`
          ] === null
            ? " "
            : documentMasterState.documentMaster[
                `header_pageno_${setting.title.charAt(7).toLowerCase()}`
              ],
      }))
    );
  };

  React.useEffect(() => {
    updateHeaderSettings();
    updateFooterSettings();
    updatePageSettings();
    updateHeadingSettings();
    updateParagraphSettings();
    updateGeneralParagraphSettings();
    updateGeneralHeadingSettings();
    setMasterTitle(documentMasterState.documentMaster.name);
    setHeaderChecked(documentMasterState.documentMaster.is_header);
    setFooterChecked(documentMasterState.documentMaster.is_footer);
  }, [documentMasterState.documentMaster]);

  const handleSaveAs = () => {
    setSaveConfirmationVisible(false);
    setSaveAsConfirmationVisible(false);
    const object = Object.assign({
      // Template Master Name
      name: masterTitle,
      //Header
      is_header: headerChecked,
      header_selection_l: headerSettings[0].selection,
      header_selection_r: headerSettings[2].selection,
      header_selection_c: headerSettings[1].selection,
      header_free_text_l:
        headerSettings[0].selection === "freeText"
          ? headerSettings[0].freeText
          : null,
      header_free_text_r:
        headerSettings[2].selection === "freeText"
          ? headerSettings[2].freeText
          : null,
      header_free_text_c:
        headerSettings[1].selection === "freeText"
          ? headerSettings[1].freeText
          : null,
      // send Page
      header_pageno_l:
        headerSettings[0].selection === "Page Number"
          ? headerSettings[0].pageStyleText
          : null,
      header_pageno_r:
        headerSettings[2].selection === "Page Number"
          ? headerSettings[2].pageStyleText
          : null,
      header_pageno_c:
        headerSettings[1].selection === "Page Number"
          ? headerSettings[1].pageStyleText
          : null,
      // Page Number Style:
      page_number_style: footerSettings[1].pageNumberStyle,

      // as a string
      header_styles_l: JSON.stringify(headerSettings[0].fontStyle),
      header_styles_r: JSON.stringify(headerSettings[2].fontStyle),
      header_styles_c: JSON.stringify(headerSettings[1].fontStyle),
      header_styles_l_color: headerSettings[0].fontColor,
      header_styles_r_color: headerSettings[2].fontColor,
      header_styles_c_color: headerSettings[1].fontColor,
      header_image_l:
        headerSettings[0].selection === "image"
          ? headerSettings[0].image_Id
          : null,
      header_image_r:
        headerSettings[2].selection === "image"
          ? headerSettings[2].image_Id
          : null,
      header_image_c:
        headerSettings[1].selection === "image"
          ? headerSettings[1].image_Id
          : null,

      header_font_size_l: parseInt(headerSettings[0].fontSize),
      header_font_size_r: parseInt(headerSettings[2].fontSize),
      header_font_size_c: parseInt(headerSettings[1].fontSize),
      header_font_style_l: headerSettings[0].font,
      header_font_style_r: headerSettings[2].font,
      header_font_style_c: headerSettings[1].font,

      //Footer Properties
      is_footer: footerChecked,

      footer_selection_l: footerSettings[0].selection,
      footer_selection_r: footerSettings[2].selection,
      footer_selection_c: footerSettings[1].selection,

      footer_free_text_l:
        footerSettings[0].selection === "freeText"
          ? footerSettings[0].freeText
          : null,
      footer_free_text_r:
        footerSettings[2].selection === "freeText"
          ? footerSettings[2].freeText
          : null,
      footer_free_text_c:
        footerSettings[1].selection === "freeText"
          ? footerSettings[1].freeText
          : null,

      footer_pageno_l:
        footerSettings[0].selection === "Page Number"
          ? footerSettings[0].pageStyleText
          : null,
      footer_pageno_r:
        footerSettings[2].selection === "Page Number"
          ? footerSettings[2].pageStyleText
          : null,
      footer_pageno_c:
        footerSettings[1].selection === "Page Number"
          ? footerSettings[1].pageStyleText
          : null,

      footer_styles_l: JSON.stringify(footerSettings[0].fontStyle),
      footer_styles_r: JSON.stringify(footerSettings[2].fontStyle),
      footer_styles_c: JSON.stringify(footerSettings[1].fontStyle),

      footer_styles_l_color: footerSettings[0].fontColor,
      footer_styles_r_color: footerSettings[2].fontColor,
      footer_styles_c_color: footerSettings[1].fontColor,

      footer_font_style_l: footerSettings[0].font,
      footer_font_style_r: footerSettings[2].font,
      footer_font_style_c: footerSettings[1].font,

      footer_image_l:
        footerSettings[0].selection === "image"
          ? footerSettings[0].image_Id
          : null,
      footer_image_r:
        footerSettings[2].selection === "image"
          ? footerSettings[2].image_Id
          : null,
      footer_image_c:
        footerSettings[1].selection === "image"
          ? footerSettings[1].image_Id
          : null,

      footer_font_size_l: parseInt(footerSettings[0].fontSize),
      footer_font_size_r: parseInt(footerSettings[2].fontSize),
      footer_font_size_c: parseInt(footerSettings[1].fontSize),

      // General Settings
      general_heading_settings: JSON.stringify(generalHeadingSettings[0]),
      general_paragraph_settings: JSON.stringify(generalParagraphSettings[0]),

      //Headings
      heading1_formatting: JSON.stringify(headingSettings[0]),
      heading2_formatting: JSON.stringify(headingSettings[1]),
      heading3_formatting: JSON.stringify(headingSettings[2]),
      heading4_formatting: JSON.stringify(headingSettings[3]),
      heading5_formatting: JSON.stringify(headingSettings[4]),
      heading6_formatting: JSON.stringify(headingSettings[5]),

      //Paragraphs
      paragraph1_formatting: JSON.stringify(paragraphSettings[0]),
      paragraph2_formatting: JSON.stringify(paragraphSettings[1]),
      paragraph3_formatting: JSON.stringify(paragraphSettings[2]),
      paragraph4_formatting: JSON.stringify(paragraphSettings[3]),
      paragraph5_formatting: JSON.stringify(paragraphSettings[4]),
      paragraph6_formatting: JSON.stringify(paragraphSettings[5]),

      //page Setting
      page_type:
        pageSettings[0].pageSize === "custom" ? "" : pageSettings[0].pageSize,

      page_height: pageSettings[0].pageHeight,
      page_width: pageSettings[0].pageWidth,
      page_margin_top: pageSettings[0].pageTopMargin,
      page_margin_bottom: pageSettings[0].pageBottomMargin,
      orientation: pageSettings[0].pageOrientation,
      custom: pageSettings[0].pageSize === "custom" ? "true" : "false",
      page_background_color: pageSettings[0].pageColor,
      page_columns: parseInt(pageSettings[0].pageColumn),
      page_margin_left: parseFloat(pageSettings[0].pageLeftMargin),
      page_margin_right: parseFloat(pageSettings[0].pageRightMargin),
      header_line: pageSettings[0].headerUnderline,
      footer_line: pageSettings[0].footerUnderline,
      ragged_column: pageSettings[0].raggedColumn,
      default_indent: pageSettings[0].defaultIndent,

      // extra
      justification: documentMasterState.documentMaster.justification,
      // paragraph_font_type:
      //   documentMasterState.documentMaster.paragraph_font_type,
      paragraph_font_size_pt:
        documentMasterState.documentMaster.paragraph_font_size_pt,
      paragraph_spacing_pt:
        documentMasterState.documentMaster.paragraph_spacing_pt,
      paragraph_color: documentMasterState.documentMaster.paragraph_color,
    });

    if (documentMaster !== undefined) {
      if (pageSettings[0].defaultIndent) {
        actions.refreshDocumentTemplate(documentTemplate);
      }
      actions.createDocumentMaster(object);

      props.closeDialogHandler();
    }
  };

  const isAnyChangeMade = () => {
    const object = Object.assign({
      //  the imported document Master

      template_master_id:
        documentMasterState.documentMaster.inherited_master_id != null
          ? documentMasterState.documentMaster.inherited_master_id
          : documentMasterState.documentMaster.id,
      // Template Master Name
      name: masterTitle,
      //Header
      is_header: headerChecked,
      header_selection_l: headerSettings[0].selection,
      header_selection_r: headerSettings[2].selection,
      header_selection_c: headerSettings[1].selection,
      header_free_text_l:
        headerSettings[0].selection === "freeText"
          ? headerSettings[0].freeText
          : null,
      header_free_text_r:
        headerSettings[2].selection === "freeText"
          ? headerSettings[2].freeText
          : null,
      header_free_text_c:
        headerSettings[1].selection === "freeText"
          ? headerSettings[1].freeText
          : null,
      // send Page
      header_pageno_l:
        headerSettings[0].selection === "Page Number"
          ? headerSettings[0].pageStyleText
          : null,
      header_pageno_r:
        headerSettings[2].selection === "Page Number"
          ? headerSettings[2].pageStyleText
          : null,
      header_pageno_c:
        headerSettings[1].selection === "Page Number"
          ? headerSettings[1].pageStyleText
          : null,
      // Page Number Style:
      page_number_style: footerSettings[1].pageNumberStyle,

      // as a string
      header_styles_l: JSON.stringify(headerSettings[0].fontStyle),
      header_styles_r: JSON.stringify(headerSettings[2].fontStyle),
      header_styles_c: JSON.stringify(headerSettings[1].fontStyle),
      header_styles_l_color: headerSettings[0].fontColor,
      header_styles_r_color: headerSettings[2].fontColor,
      header_styles_c_color: headerSettings[1].fontColor,

      header_image_l:
        headerSettings[0].selection === "image"
          ? headerSettings[0].image_Id
          : null,
      header_image_r:
        headerSettings[2].selection === "image"
          ? headerSettings[2].image_Id
          : null,
      header_image_c:
        headerSettings[1].selection === "image"
          ? headerSettings[1].image_Id
          : null,

      header_font_size_l: parseInt(headerSettings[0].fontSize),
      header_font_size_r: parseInt(headerSettings[2].fontSize),
      header_font_size_c: parseInt(headerSettings[1].fontSize),

      header_font_style_l: headerSettings[0].font,
      header_font_style_r: headerSettings[2].font,
      header_font_style_c: headerSettings[1].font,

      //Footer Properties
      is_footer: footerChecked,

      footer_selection_l: footerSettings[0].selection,
      footer_selection_r: footerSettings[2].selection,
      footer_selection_c: footerSettings[1].selection,

      footer_free_text_l:
        footerSettings[0].selection === "freeText"
          ? footerSettings[0].freeText
          : null,
      footer_free_text_r:
        footerSettings[2].selection === "freeText"
          ? footerSettings[2].freeText
          : null,
      footer_free_text_c:
        footerSettings[1].selection === "freeText"
          ? footerSettings[1].freeText
          : null,

      footer_pageno_l:
        footerSettings[0].selection === "Page Number"
          ? footerSettings[0].pageStyleText
          : null,
      footer_pageno_r:
        footerSettings[2].selection === "Page Number"
          ? footerSettings[2].pageStyleText
          : null,
      footer_pageno_c:
        footerSettings[1].selection === "Page Number"
          ? footerSettings[1].pageStyleText
          : null,

      footer_styles_l: JSON.stringify(footerSettings[0].fontStyle),
      footer_styles_r: JSON.stringify(footerSettings[2].fontStyle),
      footer_styles_c: JSON.stringify(footerSettings[1].fontStyle),

      footer_styles_l_color: footerSettings[0].fontColor,
      footer_styles_r_color: footerSettings[2].fontColor,
      footer_styles_c_color: footerSettings[1].fontColor,

      footer_font_style_l: footerSettings[0].font,
      footer_font_style_r: footerSettings[2].font,
      footer_font_style_c: footerSettings[1].font,

      footer_image_l:
        footerSettings[0].selection === "image"
          ? footerSettings[0].image_Id
          : null,
      footer_image_r:
        footerSettings[2].selection === "image"
          ? footerSettings[2].image_Id
          : null,
      footer_image_c:
        footerSettings[1].selection === "image"
          ? footerSettings[1].image_Id
          : null,

      footer_font_size_l: parseInt(footerSettings[0].fontSize),
      footer_font_size_r: parseInt(footerSettings[2].fontSize),
      footer_font_size_c: parseInt(footerSettings[1].fontSize),

      // General Settings
      general_heading_settings: JSON.stringify(generalHeadingSettings[0]),
      general_paragraph_settings: JSON.stringify(generalParagraphSettings[0]),

      //Headings
      heading1_formatting: JSON.stringify(headingSettings[0]),
      heading2_formatting: JSON.stringify(headingSettings[1]),
      heading3_formatting: JSON.stringify(headingSettings[2]),
      heading4_formatting: JSON.stringify(headingSettings[3]),
      heading5_formatting: JSON.stringify(headingSettings[4]),
      heading6_formatting: JSON.stringify(headingSettings[5]),
      //Paragraphs
      paragraph1_formatting: JSON.stringify(paragraphSettings[0]),
      paragraph2_formatting: JSON.stringify(paragraphSettings[1]),
      paragraph3_formatting: JSON.stringify(paragraphSettings[2]),
      paragraph4_formatting: JSON.stringify(paragraphSettings[3]),
      paragraph5_formatting: JSON.stringify(paragraphSettings[4]),
      paragraph6_formatting: JSON.stringify(paragraphSettings[5]),
      //page Setting
      page_type:
        pageSettings[0].pageSize === "custom" ? "" : pageSettings[0].pageSize,
      page_height: pageSettings[0].pageHeight,
      page_width: pageSettings[0].pageWidth,
      page_margin_top: pageSettings[0].pageTopMargin,
      page_margin_bottom: pageSettings[0].pageBottomMargin,
      orientation: pageSettings[0].pageOrientation,
      custom: pageSettings[0].pageSize === "custom" ? "true" : "false",
      page_background_color: pageSettings[0].pageColor,
      page_columns: parseInt(pageSettings[0].pageColumn),
      page_margin_left: parseFloat(pageSettings[0].pageLeftMargin),
      page_margin_right: parseFloat(pageSettings[0].pageRightMargin),
      header_line: pageSettings[0].headerUnderline,
      footer_line: pageSettings[0].footerUnderline,
      ragged_column: pageSettings[0].raggedColumn,
      default_indent: pageSettings[0].defaultIndent,

      // extra
      justification: documentMasterState.documentMaster.justification,
      // paragraph_font_type:
      //   documentMasterState.documentMaster.paragraph_font_type,
      paragraph_font_size_pt:
        documentMasterState.documentMaster.paragraph_font_size_pt,
      paragraph_spacing_pt:
        documentMasterState.documentMaster.paragraph_spacing_pt,
      paragraph_color: documentMasterState.documentMaster.paragraph_color,
    });
    return compareDeepObjects(object, documentMasterState.documentMaster);
  };
  const handleSave = () => {
    setSaveConfirmationVisible(false);

    const object = Object.assign({
      //  the imported document Master
      inherited_master_id: isDocumentMasterApplied
        ? selectedDocumentMaster.id
        : null,
      template_master_id:
        documentMasterState.documentMaster.inherited_master_id != null
          ? documentMasterState.documentMaster.inherited_master_id
          : documentMasterState.documentMaster.id,
      // Template Master Name
      name: masterTitle,
      //Header
      is_header: headerChecked,
      header_selection_l: headerSettings[0].selection,
      header_selection_r: headerSettings[2].selection,
      header_selection_c: headerSettings[1].selection,
      header_free_text_l:
        headerSettings[0].selection === "freeText"
          ? headerSettings[0].freeText
          : null,
      header_free_text_r:
        headerSettings[2].selection === "freeText"
          ? headerSettings[2].freeText
          : null,
      header_free_text_c:
        headerSettings[1].selection === "freeText"
          ? headerSettings[1].freeText
          : null,
      // send Page
      header_pageno_l:
        headerSettings[0].selection === "Page Number"
          ? headerSettings[0].pageStyleText
          : null,
      header_pageno_r:
        headerSettings[2].selection === "Page Number"
          ? headerSettings[2].pageStyleText
          : null,
      header_pageno_c:
        headerSettings[1].selection === "Page Number"
          ? headerSettings[1].pageStyleText
          : null,
      // Page Number Style:
      page_number_style: footerSettings[1].pageNumberStyle,

      // as a string
      header_styles_l: JSON.stringify(headerSettings[0].fontStyle),
      header_styles_r: JSON.stringify(headerSettings[2].fontStyle),
      header_styles_c: JSON.stringify(headerSettings[1].fontStyle),
      header_styles_l_color: headerSettings[0].fontColor,
      header_styles_r_color: headerSettings[2].fontColor,
      header_styles_c_color: headerSettings[1].fontColor,

      header_image_l:
        headerSettings[0].selection === "image"
          ? headerSettings[0].image_Id
          : null,
      header_image_r:
        headerSettings[2].selection === "image"
          ? headerSettings[2].image_Id
          : null,
      header_image_c:
        headerSettings[1].selection === "image"
          ? headerSettings[1].image_Id
          : null,

      header_font_size_l: parseInt(headerSettings[0].fontSize),
      header_font_size_r: parseInt(headerSettings[2].fontSize),
      header_font_size_c: parseInt(headerSettings[1].fontSize),

      header_font_style_l: headerSettings[0].font,
      header_font_style_r: headerSettings[2].font,
      header_font_style_c: headerSettings[1].font,

      //Footer Properties
      is_footer: footerChecked,

      footer_selection_l: footerSettings[0].selection,
      footer_selection_r: footerSettings[2].selection,
      footer_selection_c: footerSettings[1].selection,

      footer_free_text_l:
        footerSettings[0].selection === "freeText"
          ? footerSettings[0].freeText
          : null,
      footer_free_text_r:
        footerSettings[2].selection === "freeText"
          ? footerSettings[2].freeText
          : null,
      footer_free_text_c:
        footerSettings[1].selection === "freeText"
          ? footerSettings[1].freeText
          : null,

      footer_pageno_l:
        footerSettings[0].selection === "Page Number"
          ? footerSettings[0].pageStyleText
          : null,
      footer_pageno_r:
        footerSettings[2].selection === "Page Number"
          ? footerSettings[2].pageStyleText
          : null,
      footer_pageno_c:
        footerSettings[1].selection === "Page Number"
          ? footerSettings[1].pageStyleText
          : null,

      footer_styles_l: JSON.stringify(footerSettings[0].fontStyle),
      footer_styles_r: JSON.stringify(footerSettings[2].fontStyle),
      footer_styles_c: JSON.stringify(footerSettings[1].fontStyle),

      footer_styles_l_color: footerSettings[0].fontColor,
      footer_styles_r_color: footerSettings[2].fontColor,
      footer_styles_c_color: footerSettings[1].fontColor,

      footer_font_style_l: footerSettings[0].font,
      footer_font_style_r: footerSettings[2].font,
      footer_font_style_c: footerSettings[1].font,

      footer_image_l:
        footerSettings[0].selection === "image"
          ? footerSettings[0].image_Id
          : null,
      footer_image_r:
        footerSettings[2].selection === "image"
          ? footerSettings[2].image_Id
          : null,
      footer_image_c:
        footerSettings[1].selection === "image"
          ? footerSettings[1].image_Id
          : null,

      footer_font_size_l: parseInt(footerSettings[0].fontSize),
      footer_font_size_r: parseInt(footerSettings[2].fontSize),
      footer_font_size_c: parseInt(footerSettings[1].fontSize),

      // General Settings
      general_heading_settings: JSON.stringify(generalHeadingSettings[0]),
      general_paragraph_settings: JSON.stringify(generalParagraphSettings[0]),

      //Headings
      heading1_formatting: JSON.stringify(headingSettings[0]),
      heading2_formatting: JSON.stringify(headingSettings[1]),
      heading3_formatting: JSON.stringify(headingSettings[2]),
      heading4_formatting: JSON.stringify(headingSettings[3]),
      heading5_formatting: JSON.stringify(headingSettings[4]),
      heading6_formatting: JSON.stringify(headingSettings[5]),

      //Paragraphs
      paragraph1_formatting: JSON.stringify(paragraphSettings[0]),
      paragraph2_formatting: JSON.stringify(paragraphSettings[1]),
      paragraph3_formatting: JSON.stringify(paragraphSettings[2]),
      paragraph4_formatting: JSON.stringify(paragraphSettings[3]),
      paragraph5_formatting: JSON.stringify(paragraphSettings[4]),
      paragraph6_formatting: JSON.stringify(paragraphSettings[5]),

      //page Setting
      page_type:
        pageSettings[0].pageSize === "custom" ? "" : pageSettings[0].pageSize,

      page_height: pageSettings[0].pageHeight,
      page_width: pageSettings[0].pageWidth,
      page_margin_top: pageSettings[0].pageTopMargin,
      page_margin_bottom: pageSettings[0].pageBottomMargin,
      orientation: pageSettings[0].pageOrientation,
      custom: pageSettings[0].pageSize === "custom" ? "true" : "false",
      page_background_color: pageSettings[0].pageColor,
      page_columns: parseInt(pageSettings[0].pageColumn),
      page_margin_left: parseFloat(pageSettings[0].pageLeftMargin),
      page_margin_right: parseFloat(pageSettings[0].pageRightMargin),
      header_line: pageSettings[0].headerUnderline,
      footer_line: pageSettings[0].footerUnderline,
      ragged_column: pageSettings[0].raggedColumn,
      default_indent: pageSettings[0].defaultIndent,

      // extra
      justification: documentMasterState.documentMaster.justification,
      // paragraph_font_type:
      //   documentMasterState.documentMaster.paragraph_font_type,
      paragraph_font_size_pt:
        documentMasterState.documentMaster.paragraph_font_size_pt,
      paragraph_spacing_pt:
        documentMasterState.documentMaster.paragraph_spacing_pt,
      paragraph_color: documentMasterState.documentMaster.paragraph_color,
    });

    if (documentMaster !== undefined) {
      actions.updateWorkspaceDocumentMaster(object);
      if (pageSettings[0].defaultIndent) {
        actions.refreshDocumentTemplate(documentTemplate);
      }

      // actions.updateDocumentMaster(object);
      props.closeDialogHandler();
    }
  };

  // <Divider textAlign="left" sx={{ borderWidth: '2px', borderColor: 'black' }} />
  return (
    <>
      <Dialog
        open={true}
        onClose={(e) => {
          props.closeDialogHandler();
        }}
        id="dialogRef" // Add an ID to the Dialog component for reference
        fullWidth={true}
        maxWidth="xl"
        scroll="paper"
      >
        <DialogTitle>
          <FormatPaintIcon />
          &nbsp; &nbsp; Document Master
        </DialogTitle>
        <DialogContent>
          {/* {documentMasterState.error !== undefined ? (
          <Alert severity="error">{documentMasterState.error}</Alert>
        ) : null} */}
          {/* {documentMaster == null ? <CircularProgress /> : editorControls} */}

          <Box alignItems="center" paddingBottom={2}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                {" "}
                {/* Adjust the xs value according to your layout */}
                <Box alignItems="center">
                  {/* <Typography
                variant="h5"
                fontWeight="bold"
                // color="#261A80"
              > Existing Formatting Templates  </Typography> */}

                  <Autocomplete
                    id="auto"
                    freeSolo
                    // autoSelect
                    options={workspaceDoumentTemplateMaster.map(
                      (item: any) => item.name
                    )}
                    onChange={(event, newValue) => {
                      handleSelectDocumentTemplate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        id="workspace-formatting-templates"
                        label="Existing Formatting Templates"
                        fullWidth
                        required
                        variant="outlined"
                        placeholder={masterTitle}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={1}>
                {" "}
                {/* Adjust the xs value according to your layout */}
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <Button
                    onClick={applySelectedDocument}
                    variant="contained"
                    color="primary"
                    disabled={!selectedDocumentMaster}
                  >
                    Apply
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* 
          <Box display="flex" alignItems="center" sx={{width:"33%"}}>
            <TextField
              margin="dense"
              id="Title"
              label="Document Master Title"
              fullWidth
              variant="outlined"
              value={masterTitle}
              placeholder="Provide Title"
              onChange={(e) => {
                setMasterTitle(e.target.value);
              }}
            />
          </Box> */}
          {/* Header */}

          <Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                backgroundColor: "#ebeaea",
                borderRadius: "3px",
                padding: "8px",
                marginBottom: "8px",
                marginTop: "8px",
                width: "33%",
              }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                // color="#261A80"
                sx={{ marginRight: "2.5vh" }}
              >
                Header
              </Typography>
              <Switch
                checked={headerChecked}
                onChange={handleHeaderChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Box>
            {headerChecked ? (
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "#ebeaea",
                  borderRadius: "3px",
                  padding: "5px",
                  marginBottom: "5px",
                  marginTop: "5px",
                  //  marginRight:"400px"
                  width: "33%",
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  // color="#261A80"
                  sx={{ marginRight: "16px" }}
                >
                  Display Header Line
                </Typography>
                <Switch
                  checked={pageSettings[0].headerUnderline}
                  onChange={(e) => setHeaderUnderline(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Box>
            ) : null}

            {/* All header  */}
            {headerChecked ? (
              <Grid container spacing={2}>
                {headerSettings.map((header, index) => (
                  <Grid item xs={4} key={header.id}>
                    <HeaderFooterConfigForm
                      id={header.id}
                      title={header.title}
                      type="header"
                      font={header.font}
                      setFont={(newValue: any) =>
                        setHeaderFont(index, newValue)
                      }
                      fontSize={header.fontSize}
                      setFontSize={(newValue: any) =>
                        setHeaderFontSize(index, newValue)
                      }
                      fontStyle={header.fontStyle}
                      setFontStyle={(newValue: any) =>
                        setHeaderFontStyle(index, newValue)
                      }
                      fontColor={header.fontColor}
                      setFontColor={(newValue: any) =>
                        setHeaderFontColor(index, newValue)
                      }
                      freeText={header.freeText}
                      setFreeText={(newValue: any) =>
                        setHeaderFreeText(index, newValue)
                      }
                      selectionChoice={header.selection}
                      setSelectionChoice={(newValue: any) =>
                        setHeaderSelection(index, newValue)
                      }
                      pageNumberStyle={header.pageNumberStyle}
                      setPageNumberStyle={(newValue: any) =>
                        setPageNumberStyle(index, newValue)
                      }
                      isPageNumberOnFirst={header.isPageNumberOnFirst}
                      setIsPageNumberOnFirst={(newValue: any) =>
                        setHeaderisPageNumberOnFirst(index, newValue)
                      }
                      imageID={header.image_Id}
                      setImageID={(newValue: any) =>
                        setHeaderimageID(index, newValue)
                      }
                      imageFile={headerImage[index].image}
                      setImageFile={(id: number, newValue: any) =>
                        handleHeaderImage(index, id, newValue)
                      }
                      pageStyle={header.pageStyleText}
                      setPageStyle={(newValue: any) =>
                        setHeaderPageTextStyle(index, newValue)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            ) : null}
          </Box>
          {/* Footer */}
          <Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                backgroundColor: "#ebeaea",
                borderRadius: "3px",
                padding: "8px",
                marginBottom: "8px",
                marginTop: "8px",
                width: "33%",
              }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                // color="#261A80"
                sx={{ marginRight: "3.5vh" }}
              >
                Footer
              </Typography>
              <Box>
                <Switch
                  checked={footerChecked}
                  onChange={handleFooterChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Box>
            </Box>

            {footerChecked ? (
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "#ebeaea",
                  borderRadius: "3px",
                  padding: "5px",
                  marginBottom: "5px",
                  marginTop: "5px",
                  //  marginRight:"400px"
                  width: "33%",
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  // color="#261A80"
                  sx={{ marginRight: "16px" }}
                >
                  Display Footer Line
                </Typography>
                <Switch
                  checked={pageSettings[0].footerUnderline}
                  onChange={(e) => setFooterUnderline(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Box>
            ) : null}
            {/* All Footer  */}
            {footerChecked ? (
              <Grid container spacing={2}>
                {footerSettings.map((footer, index) => (
                  <Grid item xs={4} key={footer.id}>
                    <HeaderFooterConfigForm
                      key={footer.id} // Add the key prop here
                      id={footer.id}
                      title={footer.title}
                      type="footer"
                      selectionChoice={footer.selection}
                      setSelectionChoice={(newValue: any) =>
                        setFooterSelection(index, newValue)
                      }
                      font={footer.font}
                      setFont={(newValue: any) =>
                        setFooterFont(index, newValue)
                      }
                      fontSize={footer.fontSize}
                      setFontSize={(newValue: any) =>
                        setFooterFontSize(index, newValue)
                      }
                      fontStyle={footer.fontStyle}
                      setFontStyle={(newValue: any) =>
                        setFooterFontStyle(index, newValue)
                      }
                      fontColor={footer.fontColor}
                      setFontColor={(newValue: any) =>
                        setFooterFontColor(index, newValue)
                      }
                      freeText={footer.freeText}
                      setFreeText={(newValue: any) =>
                        setFooterFreeText(index, newValue)
                      }
                      pageNumberStyle={footer.pageNumberStyle}
                      setPageNumberStyle={(newValue: any) =>
                        setPageNumberStyle(index, newValue)
                      }
                      isPageNumberOnFirst={footer.isPageNumberOnFirst}
                      setIsPageNumberOnFirst={(newValue: any) =>
                        setFooterisPageNumberOnFirst(index, newValue)
                      }
                      imageID={footer.image_Id}
                      setImageID={(newValue: any) =>
                        setFooterimageID(index, newValue)
                      }
                      imageFile={footerImage[index].image}
                      setImageFile={(newValue: any) =>
                        handleFooterImage(index, newValue)
                      }
                      pageStyle={footer.pageStyleText}
                      setPageStyle={(newValue: any) =>
                        setFooterPageTextStyle(index, newValue)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            ) : null}
          </Box>

          {/* Page Setting */}
          <Box paddingTop={2}>
            {/* Page Setting Title */}
            <Box paddingBottom={2}>
              <Typography
                variant="h5"
                fontWeight="bold"
                // color="#261A80"
                sx={{ marginRight: "16px" }}
              >
                Page Settings
              </Typography>
            </Box>
            {/* page Settings */}
            <Box paddingTop={0}>
              <DocumentMasterPageSetting
                key={pageSettings[0].id}
                title={pageSettings[0].title}
                pageTopMargin={pageSettings[0].pageTopMargin}
                setPageTopMargin={setPageTopMargin}
                pageBottomMargin={pageSettings[0].pageBottomMargin}
                setPageBottomMargin={setPageBottomMargin}
                pageLeftMargin={pageSettings[0].pageLeftMargin}
                setPageLeftMargin={setPageLeftMargin}
                pageRightMargin={pageSettings[0].pageRightMargin}
                setPageRightMargin={setPageRightMargin}
                pageOrientation={pageSettings[0].pageOrientation}
                setPageOrientation={setPageOrientation}
                pageWidth={pageSettings[0].pageWidth}
                setPageWidth={setPageWidth}
                pageHeight={pageSettings[0].pageHeight}
                setPageHeight={setPageHeight}
                pageSize={pageSettings[0].pageSize}
                setPageSize={setPageSize}
                pageColumn={pageSettings[0].pageColumn}
                setPageColumn={setPageColumn}
                pageColor={pageSettings[0].pageColor}
                setPageColor={setPageColor}
                headerUnderline={pageSettings[0].headerUnderline}
                setHeaderUnderline={setHeaderUnderline}
                footerUnderline={pageSettings[0].footerUnderline}
                setFooterUnderLine={setFooterUnderline}
                raggedColumn={pageSettings[0].raggedColumn}
                setRaggedColumn={setRaggedColumn}
              />
            </Box>
          </Box>

          {/* Heading Settings */}
          {/* heading Setting Title */}
          <Box paddingTop={2} paddingBottom={2}>
            <Typography
              variant="h5"
              fontWeight="bold"
              // color="#261A80"
              sx={{ marginRight: "16px" }}
            >
              {" "}
              Heading Settings{" "}
            </Typography>
          </Box>
          {/* heading general */}
          <Box sx={{ width: "66.5%" }}>
            <SettingTemplateDocumentMaster
              key={generalHeadingSettings[0].id}
              title={generalHeadingSettings[0].title}
              topMargin={generalHeadingSettings[0].topMargin}
              setTopMargin={(newValue: any) => handleUpdateTopMargin(newValue)}
              bottomMargin={generalHeadingSettings[0].bottomMargin}
              setBottomMargin={(newValue: any) =>
                handleUpdateBottomMargin(newValue)
              }
              fontColor={generalHeadingSettings[0].fontColor}
              indent={generalHeadingSettings[0].indent}
              setIndent={(newValue: any) => handleUpdateIndent(newValue)}
              setFontColor={(newValue: any) => handleUpdateFontColor(newValue)}
              font={generalHeadingSettings[0].font}
              setFont={(newValue: any) => handleUpdateFont(newValue)}
              fontSize={generalHeadingSettings[0].fontSize}
              setFontSize={(newValue: any) => handleUpdateFontSize(newValue)}
              fontStyle={generalHeadingSettings[0].fontStyle}
              setFontStyle={(newValue: any) => handleUpdateFontStyle(newValue)}
              alignment={generalHeadingSettings[0].alignment}
              SetAlignment={(newValue: any) => handleUpdateAlignment(newValue)}
              format={generalHeadingSettings[0].format}
              setFormat={(newValue: any) => handleUpdateFormat(newValue)}
              separator={generalHeadingSettings[0].separator}
              setSeparator={(newValue: any) => handleUpdateSeparator(newValue)}
              compound={generalHeadingSettings[0].compound}
              setCompound={(newValue: any) => handleUpdateCompound(newValue)}
              prefix={generalHeadingSettings[0].prefix}
              setPrefix={(newValue: any) => handleUpdatePrefix(newValue)}
              suffix={generalHeadingSettings[0].suffix}
              setSuffix={(newValue: any) => handleUpdateSuffix(newValue)}
              object={generalHeadingSettings}
              defaultIndent={pageSettings[0].defaultIndent}
              setDefaultIndent={(newValue: any) => setDefaultIndent(newValue)}
            />
          </Box>

          {/* Heading component 1- 3 */}
          <Box paddingTop={3}>
            {/* Heading Component 1-3 */}
            <Grid container spacing={0.5}>
              {headingSettings.slice(0, 3).map((heading, index) => (
                <Grid item xs={4}>
                  <SettingTemplateDocumentMaster
                    key={heading.id}
                    title={heading.title}
                    topMargin={heading.topMargin}
                    setTopMargin={(newValue: any) =>
                      setHeadingTopMargin(index, newValue)
                    }
                    bottomMargin={heading.bottomMargin}
                    setBottomMargin={(newValue: any) =>
                      setHeadingBottomMargin(index, newValue)
                    }
                    fontColor={heading.fontColor}
                    indent={heading.indent}
                    setIndent={(newValue: any) =>
                      setHeadingIndent(index, newValue)
                    }
                    setFontColor={(newValue: any) =>
                      setHeadingFontColor(index, newValue)
                    }
                    font={heading.font}
                    setFont={(newValue: any) => setHeadingFont(index, newValue)}
                    fontSize={heading.fontSize}
                    setFontSize={(newValue: any) =>
                      setHeadingFontSize(index, newValue)
                    }
                    fontStyle={heading.fontStyle}
                    setFontStyle={(newValue: any) =>
                      setHeadingFontStyle(index, newValue)
                    }
                    alignment={heading.alignment}
                    SetAlignment={(newValue: any) =>
                      setHeadingAlignment(index, newValue)
                    }
                    format={heading.format}
                    setFormat={(newValue: any) =>
                      setHeadingFormat(index, newValue)
                    }
                    separator={heading.separator}
                    setSeparator={(newValue: any) =>
                      setHeadingSeparator(index, newValue)
                    }
                    compound={heading.compound}
                    setCompound={(newValue: any) =>
                      setHeadingCompound(index, newValue)
                    }
                    prefix={heading.prefix}
                    setPrefix={(newValue: any) =>
                      setHeadingPrefix(index, newValue)
                    }
                    suffix={heading.suffix}
                    setSuffix={(newValue: any) =>
                      setHeadingSuffix(index, newValue)
                    }
                    object={headingSettings}
                    defaultIndent={pageSettings[0].defaultIndent}
                    setDefaultIndent={(newValue: any) =>
                      setDefaultIndent(newValue)
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          {/* heading component 4-6 */}

          <Box paddingTop={3}>
            {/* heading 4-6 */}
            <Grid container spacing={0.5} style={{ marginTop: "10px" }}>
              {headingSettings.slice(3, 6).map((heading, index) => (
                <Grid item xs={4} key={heading.id}>
                  <SettingTemplateDocumentMaster
                    title={heading.title}
                    topMargin={heading.topMargin}
                    setTopMargin={(newValue: any) =>
                      setHeadingTopMargin(index + 3, newValue)
                    }
                    bottomMargin={heading.bottomMargin}
                    setBottomMargin={(newValue: any) =>
                      setHeadingBottomMargin(index + 3, newValue)
                    }
                    fontColor={heading.fontColor}
                    indent={heading.indent}
                    setIndent={(newValue: any) =>
                      setHeadingIndent(index + 3, newValue)
                    }
                    setFontColor={(newValue: any) =>
                      setHeadingFontColor(index + 3, newValue)
                    }
                    font={heading.font}
                    setFont={(newValue: any) =>
                      setHeadingFont(index + 3, newValue)
                    }
                    fontSize={heading.fontSize}
                    setFontSize={(newValue: any) =>
                      setHeadingFontSize(index + 3, newValue)
                    }
                    fontStyle={heading.fontStyle}
                    setFontStyle={(newValue: any) =>
                      setHeadingFontStyle(index + 3, newValue)
                    }
                    alignment={heading.alignment}
                    SetAlignment={(newValue: any) =>
                      setHeadingAlignment(index + 3, newValue)
                    }
                    format={heading.format}
                    setFormat={(newValue: any) =>
                      setHeadingFormat(index + 3, newValue)
                    }
                    separator={heading.separator}
                    setSeparator={(newValue: any) =>
                      setHeadingSeparator(index + 3, newValue)
                    }
                    compound={heading.compound}
                    setCompound={(newValue: any) =>
                      setHeadingCompound(index + 3, newValue)
                    }
                    prefix={heading.prefix}
                    setPrefix={(newValue: any) =>
                      setHeadingPrefix(index + 3, newValue)
                    }
                    suffix={heading.suffix}
                    setSuffix={(newValue: any) =>
                      setHeadingSuffix(index + 3, newValue)
                    }
                    object={headingSettings}
                    defaultIndent={pageSettings[0].defaultIndent}
                    setDefaultIndent={(newValue: any) =>
                      setDefaultIndent(newValue)
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          {/* paragraph Settings */}
          <Box paddingTop={2} paddingBottom={2}>
            <Typography
              variant="h5"
              fontWeight="bold"
              // color="#261A80"
              sx={{ marginRight: "16px" }}
            >
              {" "}
              Paragraph Settings{" "}
            </Typography>
          </Box>
          {/* paragraph general Settings */}
          <Box sx={{ width: "66.5%" }}>
            <SettingTemplateDocumentMaster
              key={generalParagraphSettings[0].id}
              title={generalParagraphSettings[0].title}
              topMargin={generalParagraphSettings[0].topMargin}
              setTopMargin={(newValue: any) => setTopMargin(newValue)}
              bottomMargin={generalParagraphSettings[0].bottomMargin}
              setBottomMargin={(newValue: any) => setBottomMargin(newValue)}
              fontColor={generalParagraphSettings[0].fontColor}
              indent={generalParagraphSettings[0].indent}
              setIndent={(newValue: any) => setIndent(newValue)}
              setFontColor={(newValue: any) => setFontColor(newValue)}
              font={generalParagraphSettings[0].font}
              setFont={(newValue: any) => setFont(newValue)}
              fontSize={generalParagraphSettings[0].fontSize}
              setFontSize={(newValue: any) => setFontSize(newValue)}
              fontStyle={generalParagraphSettings[0].fontStyle}
              setFontStyle={(newValue: any) => setFontStyle(newValue)}
              alignment={generalParagraphSettings[0].alignment}
              SetAlignment={(newValue: any) => setAlignment(newValue)}
              format={generalParagraphSettings[0].format}
              setFormat={(newValue: any) => setFormat(newValue)}
              separator={generalParagraphSettings[0].separator}
              setSeparator={(newValue: any) => setSeparator(newValue)}
              compound={generalParagraphSettings[0].compound}
              setCompound={(newValue: any) => setCompound(newValue)}
              prefix={generalParagraphSettings[0].prefix}
              setPrefix={(newValue: any) => setPrefix(newValue)}
              suffix={generalParagraphSettings[0].suffix}
              setSuffix={(newValue: any) => setSuffix(newValue)}
              continueHeadingSetting={
                generalParagraphSettings[0].continueHeadingSettings
              }
              setContinueHeadingSetting={(newValue: any) =>
                setContinueHeadingSetting(newValue)
              }
              lineSpacing={generalParagraphSettings[0].linespacing}
              setLineSpacing={(newValue: any) => setLineSpacing(newValue)}
              defaultIndent={pageSettings[0].defaultIndent}
              setDefaultIndent={(newValue: any) => setDefaultIndent(newValue)}
            />
          </Box>
          {/* Paragraph 1-6  */}

          <Box>
            {/* Paragraph 1-3  */}
            <Box paddingTop={3}>
              {/* Paragrapg 1-3 */}
              <Grid container spacing={0.5}>
                {/* Top Row */}
                {paragraphSettings.slice(0, 3).map((setting, index) => (
                  <Grid item xs={4} key={setting.id}>
                    <SettingTemplateDocumentMaster
                      key={setting.id}
                      title={setting.title}
                      topMargin={setting.topMargin}
                      setTopMargin={(newValue: any) =>
                        updateTopMargin(index, newValue)
                      }
                      bottomMargin={setting.bottomMargin}
                      setBottomMargin={(newValue: any) =>
                        updateBottomMargin(index, newValue)
                      }
                      fontColor={setting.fontColor}
                      indent={setting.indent}
                      setIndent={(newValue: any) =>
                        updateIndent(index, newValue)
                      }
                      setFontColor={(newValue: any) =>
                        updateFontColor(index, newValue)
                      }
                      font={setting.font}
                      setFont={(newValue: any) => updateFont(index, newValue)}
                      fontSize={setting.fontSize}
                      setFontSize={(newValue: any) =>
                        updateFontSize(index, newValue)
                      }
                      fontStyle={setting.fontStyle}
                      setFontStyle={(newValue: any) =>
                        updateFontStyle(index, newValue)
                      }
                      alignment={setting.alignment}
                      SetAlignment={(newValue: any) =>
                        updateAlignment(index, newValue)
                      }
                      format={setting.format}
                      setFormat={(newValue: any) =>
                        updateFormat(index, newValue)
                      }
                      separator={setting.separator}
                      setSeparator={(newValue: any) =>
                        updateSeparator(index, newValue)
                      }
                      compound={setting.compound}
                      setCompound={(newValue: any) =>
                        updateCompound(index, newValue)
                      }
                      prefix={setting.prefix}
                      setPrefix={(newValue: any) =>
                        updatePrefix(index, newValue)
                      }
                      suffix={setting.suffix}
                      setSuffix={(newValue: any) =>
                        updateSuffix(index, newValue)
                      }
                      object={paragraphSettings}
                      lineSpacing={setting.linespacing}
                      setLineSpacing={(newValue: any) =>
                        updateLineSpacing(index, newValue)
                      }
                      defaultIndent={pageSettings[0].defaultIndent}
                      setDefaultIndent={(newValue: any) =>
                        setDefaultIndent(newValue)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            {/* Paragraph 4-6  */}
            <Box paddingTop={2}>
              <Grid container spacing={0.5}>
                {paragraphSettings.slice(3, 6).map((setting, index) => (
                  <Grid item xs={4} key={setting.id}>
                    <SettingTemplateDocumentMaster
                      key={setting.id}
                      title={setting.title}
                      topMargin={setting.topMargin}
                      setTopMargin={(newValue: any) =>
                        updateTopMargin(index + 3, newValue)
                      }
                      bottomMargin={setting.bottomMargin}
                      setBottomMargin={(newValue: any) =>
                        updateBottomMargin(index + 3, newValue)
                      }
                      fontColor={setting.fontColor}
                      indent={setting.indent}
                      setIndent={(newValue: any) =>
                        updateIndent(index + 3, newValue)
                      }
                      setFontColor={(newValue: any) =>
                        updateFontColor(index + 3, newValue)
                      }
                      font={setting.font}
                      setFont={(newValue: any) =>
                        updateFont(index + 3, newValue)
                      }
                      fontSize={setting.fontSize}
                      setFontSize={(newValue: any) =>
                        updateFontSize(index + 3, newValue)
                      }
                      fontStyle={setting.fontStyle}
                      setFontStyle={(newValue: any) =>
                        updateFontStyle(index + 3, newValue)
                      }
                      alignment={setting.alignment}
                      SetAlignment={(newValue: any) =>
                        updateAlignment(index + 3, newValue)
                      }
                      format={setting.format}
                      setFormat={(newValue: any) =>
                        updateFormat(index + 3, newValue)
                      }
                      separator={setting.separator}
                      setSeparator={(newValue: any) =>
                        updateSeparator(index + 3, newValue)
                      }
                      compound={setting.compound}
                      setCompound={(newValue: any) =>
                        updateCompound(index + 3, newValue)
                      }
                      prefix={setting.prefix}
                      setPrefix={(newValue: any) =>
                        updatePrefix(index + 3, newValue)
                      }
                      suffix={setting.suffix}
                      setSuffix={(newValue: any) =>
                        updateSuffix(index + 3, newValue)
                      }
                      object={paragraphSettings}
                      lineSpacing={setting.linespacing}
                      setLineSpacing={(newValue: any) =>
                        updateLineSpacing(index + 3, newValue)
                      }
                      defaultIndent={pageSettings[0].defaultIndent}
                      setDefaultIndent={(newValue: any) =>
                        setDefaultIndent(newValue)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            onClick={() => {
              if (isDocumentMasterApplied || isAnyChangeMade()) {
                handleSavePopUP();
              } else {
                handleSave();
              }

              //   if(isAnyChangeMade()||isDocumentMasterApplied){
              //   handleSavePopUP()

              // }else {
              //   handleCancel()
              // }
            }}
            disabled={documentMaster === null}
          >
            Save
          </Button>
          <Button
            onClick={handleSaveAsPopUP}
            disabled={documentMaster === null}
          >
            Save as
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isSaveConfirmationVisible}
        onClose={handleSavePopUPClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{ textAlign: "center", fontWeight: "bold", color: "#f44336" }}
        >
          <WarningIcon
            style={{
              marginRight: "8px",
              fontSize: "24px",
              verticalAlign: "middle",
            }}
          />
          Warning: Unsaved Changes
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              marginBottom: "24px",
            }}
          >
            Do you want to save these settings throughout the workspace?
          </p>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", paddingBottom: "16px" }}
        >
          <Button
            onClick={handleSavePopUPClose}
            variant="outlined"
            style={{
              marginRight: "10px",
              color: "#f44336",
              borderColor: "#f44336",
              width: "80px",
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={handleSaveAsPopUP}
            style={{
              marginRight: "10px",
              color: "primary",
              borderColor: "primary",
              width: "80px",
            }}
            variant="outlined"
          >
            No
          </Button>

          <Button
            onClick={handleSave}
            style={{
              marginRight: "10px",
              color: "green",
              borderColor: "green",
              width: "80px",
            }}
            variant="outlined"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isSaveAsConfirmationVisible}
        onClose={handleSaveAsPopUPClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{ textAlign: "center", fontWeight: "bold", color: "#f44336" }}
        >
          <WarningIcon
            style={{
              marginRight: "8px",
              fontSize: "20px",
              verticalAlign: "left",
            }}
          />
          Warning:Do you want to save formatting for this Document?
        </DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" paddingBottom={0.2}>
            <TextField
              margin="dense"
              id="Title"
              label="Provide Master Title"
              fullWidth
              variant="outlined"
              value={masterTitle}
              placeholder="Provide Title"
              onChange={(e) => {
                setMasterTitle(e.target.value);
              }}
            />
          </Box>
          {workspaceDoumentTemplateMaster.some(
            (item: any) => item.name === masterTitle
          ) ? (
            <Alert severity="error">
              Document Master already exists (Error: Same name).{" "}
            </Alert>
          ) : null}

          {/* <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              marginBottom: "24px",
            }}
          >
            Do you want to save formatting for this Document?
          </p> */}
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", paddingBottom: "16px" }}
        >
          <Button
            onClick={handleSaveAsPopUPClose}
            variant="outlined"
            style={{
              marginRight: "16px",
              color: "#f44336",
              borderColor: "#f44336",
              width: "120px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveAs}
            style={{
              marginRight: "16px",
              color: "primary",
              borderColor: "primary",
              width: "120px",
            }}
            variant="outlined"
            disabled={workspaceDoumentTemplateMaster.some(
              (item: any) => item.name === masterTitle
            )}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

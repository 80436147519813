export interface User {
  token: string,
  id: string,
  email: string,
  default_organization_id: number,
  default_workspace_id: number
  user_id: string | undefined, // Not sure why this exists in some cases.
  first_login: boolean,
  super_user: boolean,
  admin?:boolean
}

export interface Organization {
  created_at: string,
  deleted_at: string|null,
  id: number,
  name: string,
  updated_at: string|null,
  users: User[]|null,
  adminUsers?:User[]|null
  allow_negative_credits?:boolean,
  amnesty_mode : string,
 
expiry_date:string|null

  
}

export interface WorkspaceCreateRequest {
  name: string,
  role_type: "READER" | "CREATOR" | "PUBLISHER" | "PUBLISHER_CREATOR"
}

export interface AttachWorkspaceRequest {
  role_type: "READER" | "CREATOR" | "PUBLISHER" | "PUBLISHER_CREATOR",
}

export interface WorkspaceUserRole {
  user: User,
  role_type: "READER" | "CREATOR" | "PUBLISHER" | "PUBLISHER_CREATOR"
}




export interface Search {
  Search:string,
  IsOnceoffDocument?:boolean,
  IsOtherWorkSpace?:boolean,
  Purpose?:string,
  Category?:string,
  StartDate?:any,
  EndDate?: any
}
export interface Workspace {
  created_at: string,
  deleted_at: string|null,
  id: number,
  name: string,
  updated_at: string|null
  my_role: "READER" | "CREATOR" | "PUBLISHER" | "PUBLISHER_CREATOR" | "FREE" | null,
  user_roles: WorkspaceUserRole[]|null
}


export const USER_ROLE = {
  SUPER_USER: "SUPER_USER",
  ADMIN: "ADMIN",
  USER: "USER",
};
import React, { Component, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CheckBox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {
  DocumentElement,
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateSummary,
} from "../../../types/DocumentTemplate";

import useActions, { useAppDispatch } from "../../../app/hooks";
import { clearSelectedComponent, setSelectedComponent } from "../editorSlice";
import Divider from "@mui/material/Divider";
import { ViewPreferences } from "../header/preferencesSlice";
import Typography from "@mui/material/Typography";
import { useDrop } from "react-dnd";
import { DebugID } from "./DebugID";
import { DropSkeleton } from "./DropSkeleton";

import { Approvals } from "./Approvals";
import { title } from "process";

/**
 * A cover page.
 *
 * @export
 * @param {({
 *   element: DocumentElement | null;
 *   parentSubsection: DocumentSubsection | null;
 *   parentSection: DocumentSection;
 *   documentTemplate: DocumentTemplate;
 *   organizationId: number;
 *   workspaceId: number;
 *   viewPreferences: ViewPreferences;
 * })} props
 * @return {*}
 */
export function TitlePage(props: {
  organizationId: number;
  workspaceId: number;
  documentTemplate: DocumentTemplate;
  closeDialogHandler: any;
  droppedOnBoxId?: number;
}) {
  const dispatch = useAppDispatch();
  const actions = useActions();
  // const startElement = React.useMemo(() => {
  //   return JSON.parse(props.documentTemplate!.content_format);
  // }, []);
  const [Title, setTitle] = React.useState<string>("Title Page");
  // const [text, setText] = React.useState<string>("");
  const [font, setFont] = React.useState<string>("rmfamily");
  const [fontSize, setFontsize] = React.useState<string>("12mm");
  const [color, setColor] = React.useState<string>("black");
  const [lineSpacing, setLineSpacing] = React.useState<string>("3mm");
  const [border, setBorder] = React.useState<string>("F");
  const [selectedDate, setSelectedDate] = React.useState<boolean>(false);
  const [isIndented, setIsIndented] = React.useState<boolean>(false);

  const [dialogOpen, setDialogOpen] = useState(true);
  function handleDialogOpen() {
    setDialogOpen(true);
  }

  const handleCancel = () => {
    props.closeDialogHandler();
    setDialogOpen(false);
  };

  function handleDialogClose() {
    props.closeDialogHandler();
    setDialogOpen(false);
  }
  const fontFamilies = [
    "rmfamily",
    "ttfamily",
    "sffamily",
  ];
  
  const colorOptions = [
    "black",
    "white",
    "red",
    "blue",
    "green",
    "yellow",
    "purple",
    "orange",
  ];

  useEffect(()=>{
    handlerTiitlePage();
  },[])

  function handlerTiitlePage(){
    if(props.droppedOnBoxId){
    insertTitleSectionAbove()
  }
  else {
    insertTitleSection()
  }
}
  function insertTitleSectionAbove() {
    //  Find the maximum component order

    const targetSection = props.documentTemplate.sections?.find(
      (section) => Number(section.id) === props.droppedOnBoxId
    ) as DocumentSection;

    const newParms = JSON.stringify({
       title: Title,
      font: font,
      fontSize: fontSize,
      color: color,
      lineSpacing: lineSpacing,
      date: isIndented,
      border: border,
      isBold:false,
        isItalic:false,
        isUnderline:false,
        isStandard:true,
        isCustomSettings:false
    });

    const newSection = {
      // heading: Title,
      heading: "Title Page",
      component_order: targetSection!.component_order,
      heading_level: 1,
      content_format: newParms,
    };
  
    actions.createSectionAboveSection({
      belowSection: targetSection!,
      insertedSection: newSection,
    });
    //  setDialogOpen(false);
    props.closeDialogHandler();
  }


  function insertTitleSection() {
    //  Find the maximum component order
    const component_order =
      props.documentTemplate.sections!.length > 0
        ? Math.max(
            ...props.documentTemplate.sections!.map(function (o) {
              return o.component_order;
            })
          ) + 10
        : 0;

    const newParms = JSON.stringify({
      // title: Title,
      title: Title,
      font: font,
      fontSize: fontSize,
      color: color,
      lineSpacing: lineSpacing,
      date: isIndented,
      border: border,
      isBold:false,
        isItalic:false,
        isUnderline:false,
        isStandard:true,
        isCustomSettings:false
    });
    actions.createSection({
      // heading: Title,
      heading: "Title Page",
      component_order: component_order,
      heading_level: 1,
      content_format: newParms,
    });
    //  setDialogOpen(false);
    props.closeDialogHandler();
  }
  return (
    <>
      {/* <Box sx={{ mx: 14 }}>
        <Divider>
          <DocumentScannerIcon
            fontSize="large"
            color="primary"
          ></DocumentScannerIcon>
          <Box>
            <Button variant="outlined" size="small" onClick={handleDialogOpen}>
              Adjust Title Page
            </Button>
            <Typography>Title Page</Typography>
          </Box>
        </Divider>
      </Box> */}
      <Dialog
        open={false}
        onClose={(e) => {
          props.closeDialogHandler();
        }}
      >
        <DialogTitle> Title Page</DialogTitle>
        <Box sx={{ p: 3 }}>
          <TextField
            autoFocus
            margin="dense"
            id="Heading"
            label="Heading"
            fullWidth
            variant="filled"
            value={Title}
            placeholder="Provide Heading"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          {/* <TextField
            autoFocus
            margin="dense"
            id="Title"
            label="Text"
            fullWidth
            variant="filled"
            value={text}
            placeholder="Provide Text"
            onChange={(e) => {
              setText(e.target.value);
            }}
          /> */}
          <Box sx={{ gap: 2 }}>
            <Select
              label="Font"
              defaultValue="rmfamily"
              onChange={(e: any) => {
                setFont(e.target.value);
              }}
              fullWidth={true}
            >
              {fontFamilies.map((font) => (
                <MenuItem key={font} value={font}>
                  {font}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={{ marginTop: 1 }}>
            <Select
              label="Color"
              defaultValue="black"
              onChange={(e: any) => {
                setColor(e.target.value);
              }}
              fullWidth={true}
            >
              {colorOptions.map((color) => (
                <MenuItem key={color} value={color}>
                  {color}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <TextField
            autoFocus
            margin="dense"
            id="FontSixe"
            label="Font Size *(mm)"
            fullWidth
            variant="filled"
            value={fontSize}
            placeholder="Provide Font Size"
            onChange={(e) => {
              setFontsize(e.target.value);
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="Line Spacing"
            label="Line Spacing *(mm)"
            fullWidth
            variant="filled"
            value={lineSpacing}
            placeholder="Provide Line Spacing in mm"
            onChange={(e) => {
              setLineSpacing(e.target.value);
            }}
          />
          {/* <Box>
            <Typography
              sx={{
                display: "inline",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              Do you want to add date?
            </Typography>
            <CheckBox
              checked={isIndented}
              onChange={(event) => setIsIndented(event.target.checked)}
            />
          </Box> */}
          <DialogActions>
            <Button
              onClick={(e) => {
                props.closeDialogHandler();
              }}
            >
              Cancel
            </Button>
            <Button onClick={handlerTiitlePage}>Save</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

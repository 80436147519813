import { combineReducers } from 'redux';
import documentReducer from '../features/editor/editorSlice';
import libraryReducer from '../features/library/librarySlice';
import documentRecordReducer from '../features/library/documentRecordsSlice';
import markdownEditorReducer from '../features/editor/markdownTextEditorSlice';
import authReducer from '../features/auth/authSlice';
import documentMasterReducer from '../features/editor/header/documentMasterSlice';
import preferencesReducer from '../features/editor/header/preferencesSlice';
import workspaceSummaryReducer from '../features/editor/document/libraryContent/workspaceSummarySlice';

const rootReducer = combineReducers({
    auth: authReducer,
    library: libraryReducer,
    documentRecords: documentRecordReducer,
    document: documentReducer,
    documentMaster: documentMasterReducer,
    markdownEditor: markdownEditorReducer,
    preferences: preferencesReducer,
    workspaceSummary: workspaceSummaryReducer
});
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
import { delay, select } from "redux-saga/effects";
import { call, put } from "redux-saga/effects";
import * as actions from "../actions";
import { selectUser, setErrorMsg } from "../../features/auth/authSlice";
// import { findParentElementLevel } from "../../services/utils";
import {
  addSectionLocal,
  addSectionTopLocal,
  addSubSectionWithElementLocal,
  clearDocumentTemplate,
  clearSelectedComponent,
  deleteSectionLocal,
  deleteSubsectionLocal,
  enableApprovalMode,
  markDeleteSectionLocal,
  markDeleteSubSectionLocal,
  selectEditingDocumentTemplate,
  selectStatusState,
  setCreateElementBelow,
  setDocumentTemplate,
  setError,
  setImpact,
  setLocalHeadingLevel,
  setReferences,
  setStatus,
  setVariables,
  updateDocumentElementLocal,
  updateSectionLocal,
  updateSectionLocalID,
  updateSubsectionComponentOrderLocal,
  updateSubsectionComponentOrder,
  updateSubsectionElementID,
  updateSubsectionsLocal,
  updateDocumentElementHeadingLocal,
  setEnableApprovalMode,
  disableApprovalMode,
  setNeedsApproval,
  setNewContentAdded,
  setImpactState,
  updateDocumentImangeContent,
  updateSectionComponenetOrder,
  updateAllSections,
  updateDocumentsubSectionHeadingLocal,
} from "../../features/editor/editorSlice";
import { User } from "../../types/User";
import {
  apiApproveDocumentSection,
  apiAttachSection,
  apiAttachSubSection,
  apiAttachVersionDocumentElement,
  apiCreateDocumentElement,
  apiCreateReference,
  apiCreateSection,
  apiCreateSectionWithElement,
  apiCreateSubsection,
  apiCreateSubsectionWithElement,
  apiCreateVariable,
  apiDeleteReference,
  apiDeleteSection,
  apiDetachSectionFromCurrentDoc,
  apiDeleteSubsection,
  apiDeleteVariable,
  apiDetachSubsection,
  apiGetDocumentNameSuggestion,
  apiGetDocumentTemplate,
  apiGetImpact,
  apiGetReferences,
  apiGetVariables,
  apiUndoDeletedSection,
  apiUndoDeletedSubsection,
  apiUpdateDocumentElement,
  apiUpdateDocumentElementContent,
  apiUpdateDocumentElementHeadingLevel,
  apiUpdateReference,
  apiUpdateSection,
  apiUpdateSectionContent,
  apiUpdateSectionsComponentOrders,
  apiUpdateSubsection,
  apiUpdateSubsectionsComponentOrder,
  apiUpdateVariable,
  apiUploadDocumentElementImage,
  apiAttachCopySubSection,
  apiAttachCopySection,
} from "../../services/documentTemplatesAPI";
import {
  ContentType,
  DocumentElement,
  DocumentSection,
  DocumentSubsection,
  DocumentTemplate,
} from "../../types/DocumentTemplate";
import {
  findAboveImageElement,
  findChildSubSections,
  findParentSubHeadingID,
  fixComponentOrderFromUpdatedElement,
  fixSectionFormatMaster,
  getSubheadingBlock,
  getSubheadingBlockSubSection,
} from "./utils";
import { idText, isConstructorDeclaration } from "typescript";
import { setEditorState } from "../../features/editor/markdownTextEditorSlice";
import { useAppDispatch } from "../hooks";
import React from "react";
import {
  DocumentMasterState,
  selectDocumentMasterState,
  setDocumentMasterLocal,
  setImageDocumentMaster,
  setSortedSectionsDocumentMasterLocal,
  setTableDocumentMaster,
  updateSectionsDocumentMasterLocal,
} from "../../features/editor/header/documentMasterSlice";
import { findParentSubHeadingLevel } from "../../services/utils";
import {
  findChildSubSectionsToUpdateHeadingLevels,
  findChildSubSectionsToUpdateTextLevels,
  findTextElementBelowSubHeading,
  fixHeadingLevelsOfCompleteSection,
} from "../../features/editor/helperFunctions/LevelUpdateElementFunction";
import {
  documentMasterFormat,
  getUpdatedSectionbyId,
} from "../../features/editor/document/HelperEditorFunctions";
import { attachedSubsectionElement } from "../../features/editor/document/utils";
import { DEFAULT_THEME_FORMATTING } from "../../globals";
import { PropaneSharp } from "@mui/icons-material";
import { act } from "react-dom/test-utils";

export function* fetchDocumentTemplate(
  action: ReturnType<typeof actions.fetchDocumentTemplate>
) {
  yield put(setStatus({ status: "loading", error: undefined }));
  try {
    const userState = (yield select(selectUser)) as User;

    if (action.payload.fetchTemplate) {
      const response = yield call(
        apiGetDocumentTemplate,
        userState.default_organization_id,
        userState.default_workspace_id,
        action.payload.documentTemplate.id
      );
      yield put(setDocumentMasterLocal(response.document_template_master));
      yield put(setDocumentTemplate(response));
    }
    if (action.payload.fetchMaster) {
      yield put(actions.getDocumentMaster());
    }
    // Get the document master
    if (action.payload.fetchSectionFormatting) {
      yield put(actions.getSectionDocumentMaster());
    }

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: "failed" }));
    yield put(setError(String(e)));
    return;
  }
}
export function* setEditingDocumentTemplate(
  action: ReturnType<typeof actions.setEditingDocumentTemplate>
) {
  yield put(setStatus({ status: "loading", error: undefined }));
  try {
    const userState = (yield select(selectUser)) as User;

    const response = yield call(
      apiGetDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.id
    );

    yield put(setDocumentMasterLocal(response.document_template_master));
    yield put(setDocumentTemplate(response));
    yield put(actions.getDocumentMaster());
    // Get the document master
    yield put(actions.getSectionDocumentMaster());

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: "failed" }));
    yield put(setError(String(e)));
    return;
  }
}

export function* refreshDocumentTemplate(
  action: ReturnType<typeof actions.refreshDocumentTemplate>
) {
  yield put(setStatus({ status: "loading", error: undefined }));
  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiGetDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.id
    );
    yield put(setDocumentTemplate(response));
    // Get the document master
    yield put(setDocumentMasterLocal(response.document_template_master));
    yield put(actions.getSectionDocumentMaster());
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "idle", error: "failed" }));
    yield put(setError(String(e)));
    return;
  }
}

export function* setOpenApprovalDocumentTemplate(
  action: ReturnType<typeof actions.setOpenApprovalDocumentTemplate>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiGetDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.id
    );

    yield put(setDocumentTemplate(response));
    yield put(setDocumentMasterLocal(response.document_template_master));
    yield put(actions.getSectionDocumentMaster());
    if (response.needs_approval) {
      yield put(setEnableApprovalMode());
      yield put(actions.enableApprovalMode());
    } else {
      yield put(disableApprovalMode());
    }

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "idle", error: "failed" }));
    yield put(setError(String(e)));
    return;
  }
}

// export function* attachSectionNew(
//   action: ReturnType<typeof actions.attachSection>
// ) {
//   yield put(setStatus({ status: "loading", error: undefined }));

//   try {
//     const userState = (yield select(selectUser)) as User;
//     const documentTemplate = (yield select(
//       selectEditingDocumentTemplate
//     )) as DocumentTemplate | null;
//     if (documentTemplate == null) throw "No document is currently being edited";
//     yield call(
//       apiAttachSection,
//       userState.default_organization_id,
//       userState.default_workspace_id,
//       documentTemplate,
//       action.payload.sourceDocumentSectionId,
//       action.payload.componentOrder,
//       action.payload?.sourceDocumentId,
//       action.payload?.subSectionsId
//     );

//     yield put(actions.setEditingDocumentTemplate(documentTemplate));
//     yield put(setStatus({ status: "idle", error: undefined }));
//   } catch (e) {
//     yield put(setStatus({ status: "failed", error: String(e) }));
//   }
// }

//Action to Create Secto
export function* createSectionWithElement(
  action: ReturnType<typeof actions.createSectionWithElement>
) {
  yield put(setStatus({ status: "loading", error: undefined }));
  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    const belowIndex = documentTemplate.sections!.findIndex(
      (s) => s.id === action.payload.belowSectionID
    );
    if (action.payload.belowSectionID === 0) {
      const response = yield call(
        apiCreateSectionWithElement,
        userState.default_organization_id,
        userState.default_workspace_id,
        documentTemplate,
        action.payload.heading,
        action.payload.component_order,
        action.payload.documentElement,
        null
      );
    } else if (documentTemplate.sections?.length === 0) {
      const response = yield call(
        apiCreateSectionWithElement,
        userState.default_organization_id,
        userState.default_workspace_id,
        documentTemplate,
        action.payload.heading,
        action.payload.component_order,
        action.payload.documentElement,
        null
      );
    } else {
      const newSubsection: DocumentSubsection = {
        id: -1,
        heading: "notused",
        component_order: 0,

        elements: [{ ...action.payload.documentElement }],
        pending_deleted_at: null,
        pending_heading: null,
        pending_addition: false,
      };

      const dummySection: DocumentSection = {
        component_order: action.payload.component_order,
        content_format: "notused",
        edit: false,
        heading: action.payload.heading,
        heading_level: 2,
        id: -101,
        pending_addition: false,
        pending_deleted_at: null,
        pending_heading: null,
        pending_heading_level: null,
        subsections: [newSubsection],
        impact_section_include: true,
      };

      const newSections = [...documentTemplate.sections!];
      newSections.splice(belowIndex, 0, dummySection);
      const sectionsToUpdate = fixComponentOrderFromUpdatedElement(
        newSections,
        belowIndex,
        action.payload.component_order
      );

      const sectionSComponentTobeUpdated: { [id: number]: number } = {};
      for (const updatedSection of sectionsToUpdate) {
        const SectionIndex = newSections.findIndex(
          (s) => s.id == (updatedSection as DocumentSection).id
        );
        if (SectionIndex >= 0) {
          newSections[SectionIndex] = updatedSection as DocumentSection;
        }

        sectionSComponentTobeUpdated[(updatedSection as DocumentSection).id] = (
          updatedSection as DocumentSubsection
        ).component_order;
      }

      const response = yield call(
        apiCreateSectionWithElement,
        userState.default_organization_id,
        userState.default_workspace_id,
        documentTemplate,
        action.payload.heading,
        action.payload.component_order,
        action.payload.documentElement,
        sectionSComponentTobeUpdated
      );
    }
    yield put(actions.refreshDocumentTemplate(documentTemplate));
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* createSection(
  action: ReturnType<typeof actions.createSection>
) {
  yield put(setStatus({ status: "loading", error: undefined }));
  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    const uniqueID = Math.floor(Math.random() * 999);
    const documentMaster = (yield select(
      selectDocumentMasterState
    )) as DocumentMasterState;

    const documentMasterFormatting = JSON.stringify(
      Object.assign({
        followDTM: false,
        headerLine: false,
        footerLine: false,
        numberingSetting: "CN",
        previousSettings: true,
        is_header: false,
        is_footer: false,
        default_indent: false,
        headerLeft:
          '{"id":1,"title":"Header Left","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
        headerCenter:
          '{"id":2,"title":"Header Center","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
        headerRight:
          '{"id":3,"title":"Header Right","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
        footerLeft:
          '{"id":1,"title":"Footer Left","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
        footerCenter:
          '{"id":2,"title":"Footer Center","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
        footerRight:
          '{"id":3,"title":"Footer Right","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
        pageSettings:
          '{"id":1,"title":"Page Settings","pageTopMargin":"25.54","pageBottomMargin":"25.54","pageLeftMargin":25.54,"pageRightMargin":25.54,"pageWidth":"210","pageHeight":"297","pageColumn":1,"pageSize":"A4","pageColor":"FFFFFF","pageOrientation":"portrait","headerUnderline":false,"footerUnderline":false,"raggedColumn":true,"defaultIndent":false}',
        general_heading_settings:
          '{"id":1,"title":"General Heading Settings","topMargin":"0","bottomMargin":"12","showColorPicker":false,"fontColor":"020202","font":"arial","fontSize":"12","alignment":"raggedright","fontStyle":[],"indent":0,"format":"arabic","separator":".","prefix":"(","suffix":")","compound":true}',
        general_paragraph_settings:
          '{"id":1,"title":"General Paragraph Settings","topMargin":"0","bottomMargin":"4","showColorPicker":false,"fontColor":"070707","font":"arial","fontSize":"12","alignment":"raggedright","fontStyle":["bold",""],"indent":0,"format":"arabic","separator":".","prefix":"(","suffix":")","compound":true,"continueHeadingSettings":true,"linespacing":"1"}',
        heading1_formatting: documentMaster.documentMaster.heading1_formatting,
        heading2_formatting: documentMaster.documentMaster.heading2_formatting,
        heading3_formatting: documentMaster.documentMaster.heading3_formatting,
        heading4_formatting: documentMaster.documentMaster.heading4_formatting,
        heading5_formatting: documentMaster.documentMaster.heading5_formatting,
        heading6_formatting: documentMaster.documentMaster.heading6_formatting,

        paragraph1_formatting:
          documentMaster.documentMaster.paragraph1_formatting,
        paragraph2_formatting:
          documentMaster.documentMaster.paragraph2_formatting,
        paragraph3_formatting:
          documentMaster.documentMaster.paragraph3_formatting,
        paragraph4_formatting:
          documentMaster.documentMaster.paragraph4_formatting,
        paragraph5_formatting:
          documentMaster.documentMaster.paragraph5_formatting,
        paragraph6_formatting:
          documentMaster.documentMaster.paragraph6_formatting,
      })
    );

    //     const documentMasterFormatting = {
    //       heading1_formatting:   documentMaster.documentMaster.heading1_formatting,
    //       heading2_formatting:   documentMaster.documentMaster.heading2_formatting,
    //       heading3_formatting:   documentMaster.documentMaster.heading3_formatting,
    //       heading4_formatting:   documentMaster.documentMaster.heading4_formatting,
    //       heading5_formatting:   documentMaster.documentMaster.heading5_formatting,
    //       heading6_formatting:   documentMaster.documentMaster.heading6_formatting,

    // paragraph1_formatting:   documentMaster.documentMaster.paragraph1_formatting,
    // paragraph2_formatting:   documentMaster.documentMaster.paragraph2_formatting,
    // paragraph3_formatting:   documentMaster.documentMaster.paragraph3_formatting,
    // paragraph4_formatting:   documentMaster.documentMaster.paragraph4_formatting,
    // paragraph5_formatting:   documentMaster.documentMaster.paragraph5_formatting,
    // paragraph6_formatting:   documentMaster.documentMaster.paragraph6_formatting,
    //     }
    // if (
    //   action.payload.heading === "Section Heading" &&
    //   documentMaster.documentMaster
    // ) {
    //   if (action.payload.heading_level === 1) {
    //     const markDownStyle = JSON.parse(
    //       documentMaster.documentMaster.heading1_formatting
    //     ).fontStyle;

    //     if (markDownStyle.includes("italic")) {
    //       action.payload.heading = `_${action.payload.heading}_`;
    //     }
    //     if (markDownStyle.includes("bold")) {
    //       action.payload.heading = `**${action.payload.heading}**`;
    //     }
    //     if (markDownStyle.includes("underline")) {
    //       action.payload.heading = `++${action.payload.heading}++`;
    //     }
    //   }
    // }

    const dummySection: DocumentSection = {
      component_order: action.payload.component_order,
      content_format: action.payload.content_format,
      edit: false,
      formatting: action.payload.formatting,
      heading: action.payload.heading,
      heading_level: action.payload.heading_level,
      id: uniqueID,
      pending_addition: false,
      pending_deleted_at: null,
      pending_heading: null,
      pending_heading_level: null,
      subsections: [],
      impact_section_include: true,
      // formating:'{"justify":"center","color":"#f0c30e","isBold":false,"isItalic":false,"isUnderline":false,"isStandard":true , indent:"1"}'
    };
    yield put(addSectionLocal(dummySection));

    const response = yield call(
      apiCreateSection,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload,
      uniqueID,
      documentMasterFormatting
    );
    yield put(
      updateSectionsDocumentMasterLocal({
        sectionID: response.data.document_section_id,
        DocumentMaster: response.data.section_formatting,
      })
    );

    // Pull the component order out the base response, and copy everything else.
    const newSection = Object.assign(
      {
        subsections: [],
        component_order: response.data.component_order,
      },
      response.data.document_section
    );
    yield put(
      updateSectionLocalID({ DocumentSection: newSection, uniqueID: uniqueID })
    );

    //  yield put(actions.setEditingDocumentTemplate(documentTemplate));
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* attachSection(
  action: ReturnType<typeof actions.attachSection>
) {
  yield put(setStatus({ status: "loading", error: undefined }));
  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    yield put(
      setErrorMsg({
        status: "loading",
        errorMsg: undefined,
        errorType: "attachSection",
      })
    );

    if (action.payload.destinationSectionId) {
      if (action.payload.createCopy) {
        const attchedSectionComponentOrder: { [id: string]: number } = {};
        const attchedSubsections: { [id: string]: number } = {};

        for (let i = 0; i < action.payload?.subSectionsId.length; i++) {
          // attchedSectionComponentOrder[action.payload?.subSectionsId[i]]
          attchedSectionComponentOrder[i] =
        
            action.payload.componentOrder + 10 * i;
          // You can perform your desired actions here with object[i]
          attchedSubsections[action.payload?.subSectionsId[i]] = i;
        }
        console.log("🚀 ~ attchedSectionComponentOrder: copy", attchedSectionComponentOrder , "action" , action.payload.subSectionsId)
        const section = documentTemplate.sections?.find(
          (section) => section.id === action.payload.destinationSectionId
        ) as DocumentSection;
        const splicedIndex = action.payload.componentOrder
          ? section.subsections?.findIndex(
              (subSection) =>
                subSection.component_order === action.payload.componentOrder
            )
          : null;

        if (
          splicedIndex != undefined &&
          splicedIndex != -1 &&
          splicedIndex != null
        ) {
          const newSubsections = [...section!.subsections!];
          const newComponentOrder =
            action.payload.componentOrder +
            action.payload?.subSectionsId.length * 10;
          const subSectionsToUpdate = fixComponentOrderFromUpdatedElement(
            newSubsections,
            splicedIndex,
            newComponentOrder
          );


          for (const updatedSubsection of subSectionsToUpdate) {
           
            attchedSectionComponentOrder[
              (updatedSubsection as DocumentSubsection).id
            ] = (updatedSubsection as DocumentSubsection).component_order;
          }
        }
        
        Object.keys(attchedSubsections).forEach((id, key) => {
          const value=   attchedSubsections[id]
          attchedSubsections[id] = attchedSectionComponentOrder[value];
          delete attchedSectionComponentOrder[value];
        });

        const response = yield call(
          apiAttachCopySubSection,
          userState.default_organization_id,
          userState.default_workspace_id,
          documentTemplate,
          action.payload.sourceDocumentSectionId,
          action.payload.componentOrder,
          action.payload?.sourceDocumentId,
          // action.payload?.subSectionsId,
          attchedSubsections,
          action.payload?.destinationSectionId,
          attchedSectionComponentOrder
        );
      } else {
        const attchedSectionComponentOrder: { [id: string]: number } = {};
        for (let i = 0; i < action.payload?.subSectionsId.length; i++) {
          attchedSectionComponentOrder[action.payload?.subSectionsId[i]] =
            action.payload.componentOrder + 10 * i;
          // You can perform your desired actions here with object[i]
        }
        const section = documentTemplate.sections?.find(
          (section) => section.id === action.payload.destinationSectionId
        ) as DocumentSection;
        const splicedIndex = action.payload.componentOrder
          ? section.subsections?.findIndex(
              (subSection) =>
                subSection.component_order === action.payload.componentOrder
            )
          : null;

        if (
          splicedIndex != undefined &&
          splicedIndex != -1 &&
          splicedIndex != null
        ) {
          const newSubsections = [...section!.subsections!];
          const newComponentOrder =
            action.payload.componentOrder +
            action.payload?.subSectionsId.length * 10;
          const subSectionsToUpdate = fixComponentOrderFromUpdatedElement(
            newSubsections,
            splicedIndex,
            newComponentOrder
          );
          for (const updatedSubsection of subSectionsToUpdate) {
            attchedSectionComponentOrder[
              (updatedSubsection as DocumentSubsection).id
            ] = (updatedSubsection as DocumentSubsection).component_order;
          }
        }

     

        const response = yield call(
        
          apiAttachSubSection,
          userState.default_organization_id,
          userState.default_workspace_id,
          documentTemplate,
          action.payload.sourceDocumentSectionId,
          action.payload.componentOrder,
          action.payload?.sourceDocumentId,
          action.payload?.subSectionsId,
          action.payload?.destinationSectionId,
          attchedSectionComponentOrder
        );
      }
    } else {
      const attchedSectionComponentOrder: { [id: string]: number } = {};

      let count = 0;
      // Get an array of sorted keys
      const sortedKeys = action.payload.sectionSequence
        ? action.payload.sectionSequence
        : Object.keys(action.payload.SectionswithSubSections);
      for (const key of sortedKeys) {
        attchedSectionComponentOrder[key] =
          action.payload.componentOrder + count * 10;
        count++;
      }
      const newSections = [...documentTemplate.sections!];

      const belowIndex = documentTemplate.sections!.findIndex(
        (s) => s.component_order === action.payload.componentOrder
      );

      if (belowIndex != null && belowIndex != -1) {
        const lenght = Object.keys(
          action.payload.SectionswithSubSections
        ).length;
        const order = action.payload.componentOrder + (lenght + 1) * 10;

        const sectionsToUpdate = fixComponentOrderFromUpdatedElement(
          newSections,
          belowIndex,
          order
        );
        const sectionSComponentTobeUpdated: { [id: number]: number } = {};

        for (const updatedSection of sectionsToUpdate) {
          const SectionIndex = newSections.findIndex(
            (s) => s.id == (updatedSection as DocumentSection).id
          );
          if (SectionIndex >= 0) {
            newSections[SectionIndex] = updatedSection as DocumentSection;
          }

          sectionSComponentTobeUpdated[(updatedSection as DocumentSection).id] =
            (updatedSection as DocumentSubsection).component_order;
        }

        const responseTemplate = yield call(
          apiUpdateSectionsComponentOrders,
          userState.default_organization_id,
          userState.default_workspace_id,
          documentTemplate,
          sectionSComponentTobeUpdated
        );
      }
      if (action.payload.createCopy) {
        const response = yield call(
          apiAttachCopySection,
          userState.default_organization_id,
          userState.default_workspace_id,
          documentTemplate,
          action.payload.sourceDocumentSectionId,
          action.payload.componentOrder,
          action.payload?.sourceDocumentId,
          action.payload.SectionswithSubSections,
          attchedSectionComponentOrder
        );
      } else {
        const response = yield call(
          apiAttachSection,
          userState.default_organization_id,
          userState.default_workspace_id,
          documentTemplate,
          action.payload.sourceDocumentSectionId,
          action.payload.componentOrder,
          action.payload?.sourceDocumentId,
          action.payload.SectionswithSubSections,
          attchedSectionComponentOrder
        );
      }
      // const newSubsection = Object.assign(
      //   {
      //     subsections: [],
      //     component_order: response.data.component_order,
      //   },
      //   response.data.document_section
      // );

      // console.log("in Else Condition")
      // yield put(addSectionLocal(newSubsection));
      // const response = yield call(
      //   apiAttachSection,
      //   userState.default_organization_id,
      //   userState.default_workspace_id,
      //   documentTemplate,
      //   action.payload.sourceDocumentSectionId,
      //   action.payload.componentOrder,
      //   action.payload?.sourceDocumentId,
      //   action.payload?.subSectionsId,
      //   action.payload?.destinationSectionId,
      // );
      yield put(actions.setEditingDocumentTemplate(documentTemplate));
    }
    // Pull the whole document to be safe.

    // yield delay(2000);

    if (action.payload.destinationSectionId) {
      const documentTemplateUpdate: DocumentTemplate = yield call(
        apiGetDocumentTemplate,
        userState.default_organization_id,
        userState.default_workspace_id,
        documentTemplate.id
      );
      const section = documentTemplateUpdate.sections?.find(
        (section) => section.id === action.payload.destinationSectionId
      ) as DocumentSection;
      yield put(setDocumentTemplate(documentTemplateUpdate));

      yield put(
        actions.updateSectionHeadingLevels({
          documentTemplate: documentTemplate,
          section: section,
          // refreshDocument: true,
        })
      );
    }
    yield put(
      setErrorMsg({
        status: "success",
        errorMsg: "Library content attached successfully!",
        errorType: "attachSection",
      })
    );

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(
      setErrorMsg({
        status: "failed",
        errorMsg: "Unable to attach library content!",
        errorType: "attachSection",
      })
    );
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* updateSectionContent(
  action: ReturnType<typeof actions.updateSectionContent>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    const newSection = { ...action.payload };

    yield put(updateSectionLocal(newSection));

    const response = yield call(
      apiUpdateSectionContent,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload
    );
    // check if the section heading requires the appproval?
    if (response.data.new_content_added) {
      yield put(setNewContentAdded(true));
    }
    if (response.data.needs_approval) {
      yield put(setNeedsApproval(true));
    }

    const responseSection = {
      ...action.payload,
      // pending_heading: response.data.document_section.pending_heading,
      // difference_content: response.data.document_section.difference_content,
      // heading: response.data.document_section.heading,
      // heading_level: response.data.document_section.heading_level,
    };
    yield put(updateSectionLocal(responseSection));

    //Added New
    // yield put(actions.setEditingDocumentTemplate(documentTemplate));
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    console.log("Unable to Update the Section Heading....", e);
    // yield put(setStatus({ status: "failed", error: String(e) }));
    yield put(
      setStatus({ status: "failed", error: "Heading Should Not be empty!" })
    );
  }
}

export function* updateSection(
  action: ReturnType<typeof actions.updateSection>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    const newSection = { ...action.payload };

    yield put(updateSectionLocal(newSection));

    const response = yield call(
      apiUpdateSection,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload
    );
    // check if the section heading requires the appproval?
    // if (response.data.document_section.pending_heading) {
    //   yield put(setNeedsApproval(true));
    //   yield put(setNewContentAdded(true));
    // }

    const responseSection = {
      ...action.payload,
      pending_heading: response.data.document_section.pending_heading,
      difference_content: response.data.document_section.difference_content,
      heading: response.data.document_section.heading,
      heading_level: response.data.document_section.heading_level,
    };
    yield put(updateSectionLocal(responseSection));

    // newSection.pending_heading = response.data.document_section.pending_heading;
    // newSection.pending_deleted_at =
    //   response.data.document_section.pending_deleted_at;
    // newSection.heading = response.data.document_section.heading;
    // newSection.heading_level = response.data.document_section.heading_level;
    // newSection.pending_heading_level =
    //   response.data.document_section.pending_heading_level;
    //   newSection.difference_content=  response.data.document_section.difference_content;

    //Added New
    if (response.data.new_content_added || response.data.needs_approval) {
      yield put(actions.refreshDocumentTemplate(documentTemplate));
    }
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    console.log("Unable to Update the Section Heading....", e);
    yield put(
      setStatus({ status: "failed", error: "Heading Should Not be empty!" })
    );
  }
}

export function* createSectionAboveSection(
  action: ReturnType<typeof actions.createSectionAboveSection>
) {
  yield put(setStatus({ status: "loading", error: undefined }));
  const belowSection = action.payload.belowSection,
    insertedSection = { ...action.payload.insertedSection };
  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";

    // The sections should always be ordered already.

    // Get the index of the above section
    const belowIndex = documentTemplate.sections!.findIndex(
      (s) => s.id === belowSection.id
    );
    if (belowIndex == null) throw new Error("Cannot find below section.");
    const uniqueID = Math.floor(Math.random() * 999);
    const documentMaster = (yield select(
      selectDocumentMasterState
    )) as DocumentMasterState;

    const documentMasterFormatting = JSON.stringify(
      Object.assign({
        followDTM: false,
        headerLine: false,
        footerLine: false,
        numberingSetting: "CN",
        previousSettings: true,
        is_header: false,
        is_footer: false,
        default_indent: false,
        headerLeft:
          '{"id":1,"title":"Header Left","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
        headerCenter:
          '{"id":2,"title":"Header Center","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
        headerRight:
          '{"id":3,"title":"Header Right","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
        footerLeft:
          '{"id":1,"title":"Footer Left","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
        footerCenter:
          '{"id":2,"title":"Footer Center","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
        footerRight:
          '{"id":3,"title":"Footer Right","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
        pageSettings:
          '{"id":1,"title":"Page Settings","pageTopMargin":"25.54","pageBottomMargin":"25.54","pageLeftMargin":25.54,"pageRightMargin":25.54,"pageWidth":"210","pageHeight":"297","pageColumn":1,"pageSize":"A4","pageColor":"FFFFFF","pageOrientation":"portrait","headerUnderline":false,"footerUnderline":false,"raggedColumn":true,"defaultIndent":false}',
        general_heading_settings:
          '{"id":1,"title":"General Heading Settings","topMargin":"0","bottomMargin":"12","showColorPicker":false,"fontColor":"020202","font":"arial","fontSize":"12","alignment":"raggedright","fontStyle":[],"indent":0,"format":"arabic","separator":".","prefix":"(","suffix":")","compound":true}',
        general_paragraph_settings:
          '{"id":1,"title":"General Paragraph Settings","topMargin":"0","bottomMargin":"4","showColorPicker":false,"fontColor":"070707","font":"arial","fontSize":"12","alignment":"raggedright","fontStyle":["bold",""],"indent":0,"format":"arabic","separator":".","prefix":"(","suffix":")","compound":true,"continueHeadingSettings":true,"linespacing":"1"}',
        heading1_formatting: documentMaster.documentMaster.heading1_formatting,
        heading2_formatting: documentMaster.documentMaster.heading2_formatting,
        heading3_formatting: documentMaster.documentMaster.heading3_formatting,
        heading4_formatting: documentMaster.documentMaster.heading4_formatting,
        heading5_formatting: documentMaster.documentMaster.heading5_formatting,
        heading6_formatting: documentMaster.documentMaster.heading6_formatting,

        paragraph1_formatting:
          documentMaster.documentMaster.paragraph1_formatting,
        paragraph2_formatting:
          documentMaster.documentMaster.paragraph2_formatting,
        paragraph3_formatting:
          documentMaster.documentMaster.paragraph3_formatting,
        paragraph4_formatting:
          documentMaster.documentMaster.paragraph4_formatting,
        paragraph5_formatting:
          documentMaster.documentMaster.paragraph5_formatting,
        paragraph6_formatting:
          documentMaster.documentMaster.paragraph6_formatting,
      })
    );
    // if (
    //   insertedSection.heading === "Section Heading" &&
    //   documentMaster.documentMaster
    // ) {
    //   if (insertedSection.heading_level === 1) {
    //     const markDownStyle = JSON.parse(
    //       documentMaster.documentMaster.heading1_formatting
    //     ).fontStyle;

    //     if (markDownStyle.includes("italic")) {
    //       insertedSection.heading = `_${insertedSection.heading}_`;
    //     }
    //     if (markDownStyle.includes("bold")) {
    //       insertedSection.heading = `**${insertedSection.heading}**`;
    //     }
    //     if (markDownStyle.includes("underline")) {
    //       insertedSection.heading = `++${action.payload.insertedSection.heading}++`;
    //     }
    //   }
    // }

    // If it's at the beginning, just append it.
    if (belowIndex === 0) {
      insertedSection.component_order = belowSection.component_order - 10;
      const response = yield call(
        apiCreateSection,
        userState.default_organization_id,
        userState.default_workspace_id,
        documentTemplate,
        insertedSection,
        uniqueID,
        documentMasterFormatting
      );
      const dummySection: DocumentSection = {
        component_order: 0,
        content_format: action.payload.insertedSection.content_format,
        edit: false,
        formatting: action.payload.insertedSection.formatting,
        heading: action.payload.insertedSection.heading,
        heading_level: action.payload.insertedSection.heading_level,
        id: uniqueID,
        pending_addition: false,
        pending_deleted_at: null,
        pending_heading: null,
        pending_heading_level: null,
        subsections: [],
        impact_section_include: true,
      };
      yield put(addSectionTopLocal(dummySection));
    } else {
      const newSections = [...documentTemplate.sections!];
      //Set as the same component order and just update below.
      insertedSection.component_order = belowSection.component_order;
      const response = yield call(
        apiCreateSection,
        userState.default_organization_id,
        userState.default_workspace_id,
        documentTemplate,
        insertedSection,
        uniqueID,
        documentMasterFormatting
      );
      //TODO: Refactor this in future, should happen in API : Done :)
      const insertedSectionResp = Object.assign(
        {},
        response.data.document_section,
        { component_order: response.data.component_order }
      );
      newSections.splice(belowIndex, 0, insertedSectionResp);
      // Fix everything below.
      const sectionsToUpdate = fixComponentOrderFromUpdatedElement(
        newSections,
        belowIndex,
        insertedSection.component_order
      );
      const sectionSComponentTobeUpdated: { [id: number]: number } = {};
      for (const updatedSection of sectionsToUpdate) {
        const SectionIndex = newSections.findIndex(
          (s) => s.id == (updatedSection as DocumentSection).id
        );
        if (SectionIndex >= 0) {
          newSections[SectionIndex] = updatedSection as DocumentSection;
        }

        sectionSComponentTobeUpdated[(updatedSection as DocumentSection).id] = (
          updatedSection as DocumentSubsection
        ).component_order;

        // yield call(
        //   apiUpdateSection,
        //   userState.default_organization_id,
        //   userState.default_workspace_id,
        //   documentTemplate,
        //   updatedSection as DocumentSection
        // );
      }

      const responseTemplate = yield call(
        apiUpdateSectionsComponentOrders,
        userState.default_organization_id,
        userState.default_workspace_id,
        documentTemplate,
        sectionSComponentTobeUpdated
      );

      // const updatedDocumentTemplate = Object.assign({}, documentTemplate, {
      //   sections: newSections,
      // });
      // yield put(setDocumentTemplate(updatedDocumentTemplate ))
    }
    // Pull the whole document to be safe.
    yield put(actions.setEditingDocumentTemplate(documentTemplate));
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

//Switch Section: reorder the section, this function called in TableofContents.tsx
export function* switchSections(
  action: ReturnType<typeof actions.switchSections>
) {
  const documentTemplate = (yield select(
    selectEditingDocumentTemplate
  )) as DocumentTemplate | null;
  yield put(setStatus({ status: "loading", error: undefined }));
  // Switch the component orders.
  const sectionAUpdated = Object.assign({}, action.payload.sourceSection, {
    component_order: action.payload.destinationSection.component_order,
  });

  try {
    const userState = (yield select(selectUser)) as User;
    if (documentTemplate == null) throw "No document is currently being edited";
    //Switch the sections...

    const newSections = [...(documentTemplate.sections || [])]; // Using default empty array if documentTemplate.sections is undefined

    const indexSrc = newSections.findIndex(
      (sec) => sec.id === action.payload.sourceSection.id
    );
    const indexDes = newSections.findIndex(
      (sec) => sec.id === action.payload.destinationSection.id
    );
    const splicedIndex = indexSrc < indexDes ? indexDes + 1 : indexDes;

    // indexSrc+1==indexDes? indexDes-1 :

    if (indexDes >= 0) {
      newSections.splice(splicedIndex, 0, sectionAUpdated);
    }
    // Remove the old sectionA if its id and component_order match

    const matchingOldSectionIndex = newSections.findIndex(
      (sec) =>
        sec.id === action.payload.sourceSection.id &&
        sec.component_order === action.payload.sourceSection.component_order
    );

    if (matchingOldSectionIndex >= 0) {
      newSections.splice(matchingOldSectionIndex, 1);
    }

    const sectionSComponentTobeUpdated: { [id: number]: number } = {};
    newSections.forEach((section, i) => {
      // Update component_order of sections by incrementing them by 10
      newSections[i] = { ...section, component_order: i * 10 };
      sectionSComponentTobeUpdated[newSections[i].id] = (
        newSections[i] as DocumentSection
      ).component_order;
    });

    yield put(updateAllSections(newSections));
    const responseTemplate = yield call(
      apiUpdateSectionsComponentOrders,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      sectionSComponentTobeUpdated
    );

    const _documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate;
    const documentTemplateMaster = yield select(selectDocumentMasterState);

    const fixedSectionFormating = fixSectionFormatMaster(
      _documentTemplate,
      documentTemplateMaster.sectionsDocumentMaster,
      documentTemplateMaster.documentMaster
    );
    if (Object.keys(fixedSectionFormating).length != 0) {
      yield put(setSortedSectionsDocumentMasterLocal(fixedSectionFormating));
    }

    // Pull the whole document to be safe.
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    if (documentTemplate)
      yield put(actions.setEditingDocumentTemplate(documentTemplate));

    yield put(setStatus({ status: "failed", error: String(e) }));
    console.log("switch Document catch block", e);
  }
}

// const responseA = yield call(
//   apiUpdateSectionContent,
//   userState.default_organization_id,
//   userState.default_workspace_id,
//   documentTemplate,
//   sectionAUpdated
// );
// const responseB = yield call(
//   apiUpdateSectionContent,
//   userState.default_organization_id,
//   userState.default_workspace_id,
//   documentTemplate,
//   sectionBUpdated
// );

// if(documentTemplate) yield put(actions.setEditingDocumentTemplate(documentTemplate));

export function* deleteSection(
  action: ReturnType<typeof actions.deleteSection>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    //   // Update all the document templates.
    if (!action.payload.edit) {
      yield put(deleteSectionLocal(action.payload));
    } else if (action.payload.edit) {
      //added new to delete add Library content section from Right Drawer
      yield put(deleteSectionLocal(action.payload));
    } else {
      yield put(markDeleteSectionLocal(action.payload));
    }

    const response = yield call(
      apiDeleteSection,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload
    );
    // //   // Update all the document templates.
    // if (!action.payload.edit) {
    //   yield put(deleteSectionLocal(action.payload));
    // }
    // else  if (action.payload.edit) {   //added new to delete add Library content section from Right Drawer
    //   yield put(deleteSectionLocal(action.payload));
    // }
    // else {
    //   yield put(markDeleteSectionLocal(action.payload))
    // }
    // const _documentTemplate = (yield select(
    //   selectEditingDocumentTemplate
    // )) as DocumentTemplate
    // const documentTemplateMaster = yield select(
    //   selectDocumentMasterState
    // )

    // const fixedSectionFormating=  fixSectionFormatMaster(_documentTemplate,documentTemplateMaster.sectionsDocumentMaster, documentTemplateMaster.documentMaster )
    // if( Object.keys(fixedSectionFormating).length!=0){
    //   yield put(setSortedSectionsDocumentMasterLocal(fixedSectionFormating));
    // }

    if (
      !(
        action.payload.heading === "Table of Contents Section" ||
        action.payload.heading === "List of Tables" ||
        action.payload.heading === "List of Figures"
      )
    )
      yield put(actions.refreshDocumentTemplate(documentTemplate));
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}
export function* deleteSectionAllDocuments(
  action: ReturnType<typeof actions.deleteSectionAllDocuments>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    //   // Update all the document templates.
    if (!action.payload.edit) {
      yield put(deleteSectionLocal(action.payload));
    } else if (action.payload.edit) {
      //added new to delete add Library content section from Right Drawer
      yield put(deleteSectionLocal(action.payload));
    } else {
      yield put(markDeleteSectionLocal(action.payload));
    }

    const response = yield call(
      apiDetachSectionFromCurrentDoc,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload
    );

    yield put(actions.refreshDocumentTemplate(documentTemplate));
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

// export function* createSubsectionWithElement(
//   action: ReturnType<typeof actions.createSubsectionWithElement>
// ) {
//   // const dispatch = useAppDispatch();
//   yield put(setStatus({ status: "loading", error: undefined }));
//   try {
//     const userState = (yield select(selectUser)) as User;
//     const documentTemplate = (yield select(
//       selectEditingDocumentTemplate
//     )) as DocumentTemplate | null;
//     if (documentTemplate == null) throw "No document is currently being edited";
//     // Get the section we are adding this to.
//     // NOTE: Don't use the payload section, it could be out of date.
//     if(action.payload.documentElement.content_type==="TABLEOFCONTENTS" && action.payload.documentElement.id===1 ){
//       action.payload.section=documentTemplate.sections![documentTemplate.sections!.length-1];
//     }

//     if(action.payload.documentElement.content_type==="TABLEOFCONTENTS" && action.payload.documentElement.id===10){
//       const newCreatedSectionID =
//       documentTemplate.sections!.length > 0
//         ? Math.max(
//             ...documentTemplate.sections!.map(function (o) {
//               return o.id;
//             })
//           )
//         : 0;
//         action.payload.section = documentTemplate.sections?.find(
//           (section) => Number(section.id) === newCreatedSectionID
//         ) as DocumentSection;
//     }

//     const section = documentTemplate.sections!.find(
//       (s) => s.id === action.payload.section.id
//     );

//     if (section == null) throw "Cannot find the section being updated";
//     // And the element being added (it's always component_order 0).
//     const documentElement = {
//       ...action.payload.documentElement,
//       content_order: 0,
//     };
//     const subsectionAboveIndex = action.payload.subsectionAbove
//       ? section.subsections?.findIndex(
//           (subSection) => subSection.id === action.payload.subsectionAbove!.id
//         )
//       : null;
//     // 1. Create a new subsection below the provided subsection,
//     // or above all subsections if none is provided.

//     let newComponentOrder = 0;
//     if (section.subsections!.length > 0) {
//       if (action.payload.subsectionAbove !== undefined) {
//         newComponentOrder =
//           action.payload.subsectionAbove!.component_order + 10;
//       } else {
//         // Drop at top.

//         newComponentOrder =
//           Math.min(
//             ...section.subsections!.map(function (o) {
//               return o.component_order;
//             })
//           ) - 10;
//       }
//     }
//     const uniqueID= Math.floor(Math.random() * 999);
//     const newSubsection = {
//       id: uniqueID,
//       heading: "notused",
//       component_order: newComponentOrder,
//       elements: [],
//       pending_deleted_at: null,
//       pending_heading: null,
//       pending_addition: false,
//     };

// yield put(addSubSectionWithElementLocal({  section :section , subSection:  newSubsection,documentElement: documentElement , subsectionAbove: action.payload.subsectionAbove }) )

//     const response = yield call(
//       apiCreateSubsectionWithElement,
//           userState.default_organization_id,
//           userState.default_workspace_id,
//           documentTemplate,
//           section,
//           newSubsection,
//           documentElement,
//           uniqueID
//         );
//     console.log("response of apiCreateSubsectionWithElement ", response , "unique_ID" , uniqueID)
//       yield put(updateSubsectionElementID({ section: section ,
//         uniqueID: response.data.uniqueID,
//         updatedSubsectionID :response.data.document_sub_section_id,
//         updatedElementID:response.data.element_id,
//       }))

//   // const responseTemplate = yield call(
//   //     apiGetDocumentTemplate,
//   //     userState.default_organization_id,
//   //     userState.default_workspace_id,
//   //     documentTemplate.id
//   //   );
//     // yield put(setDocumentTemplate(responseTemplate));
//     // Get the document master
//     // yield put(actions.getDocumentMaster());
//     // 2. Create a subsection.
//   //     const subSectionResponse = yield call(
//   //       apiCreateSubsection,
//   //       userState.default_organization_id,
//   //       userState.default_workspace_id,
//   //       documentTemplate,
//   //       action.payload.section,
//   //       newSubsection
//   //     );

//   //   //2. Create a subsection.
//   //   //2. Create a local subsection.
//   //   const subsection = subSectionResponse.data.document_sub_section;
//   //   subsection.component_order = subSectionResponse.data.component_order;
//   //   subsection.elements = [] as DocumentElement[];

//   //   // 3. Create the document element.
//   //   const response = yield call(
//   //     apiCreateDocumentElement,
//   //     userState.default_organization_id,
//   //     userState.default_workspace_id,
//   //     documentTemplate,
//   //     section,
//   //     subsection,
//   //     documentElement
//   //   );

//   //   subsection.elements.push({...response.data.document_element, pending_addition: section.edit});
//   //   const newSubsections = [...section.subsections!];
//   //   // Add the new subsection to the sections

//   //   let splicedIndex;
//   //   if (subsectionAboveIndex == null) {
//   //     splicedIndex = 0;
//   //     // Splice it in the top
//   //     newSubsections.splice(0, 0, subsection);
//   //   } else if (subsectionAboveIndex == section.subsections!.length - 1) {
//   //     // Append it to the end
//   //     newSubsections.push(subsection);
//   //     splicedIndex = section.subsections!.length;
//   //   } else {
//   //     //Splice in the new subsection and update the component order.
//   //     newSubsections.splice(subsectionAboveIndex + 1, 0, subsection);
//   //     splicedIndex = subsectionAboveIndex + 1;
//   //   }
//   //   // Fix everything below.
//   //   const subSectionsToUpdate = fixComponentOrderFromUpdatedElement(
//   //     newSubsections,
//   //     splicedIndex,
//   //     subsection.component_order
//   //   );
//   //    console.log("subSectionsToUpdate console" , subSectionsToUpdate)

//   //   for (const updatedSubsection of subSectionsToUpdate) {
//   //     // Push the update.
//   //     const resp = yield call(
//   //       apiUpdateSubsection,
//   //       userState.default_organization_id,
//   //       userState.default_workspace_id,
//   //       documentTemplate,
//   //       section,
//   //       updatedSubsection as DocumentSubsection
//   //     );

//   //     // Update locally
//   //     const subSectionIndex = newSubsections.findIndex(
//   //       (s) => s.id == (updatedSubsection as DocumentSubsection).id
//   //     );
//   //     if (subSectionIndex >= 0)
//   //       newSubsections[subSectionIndex] =
//   //         updatedSubsection as DocumentSubsection;
//   //   }
//   //   const updatedSection = Object.assign({}, section, {
//   //     subsections: newSubsections,
//   //   });

//   //   // Lastly update the local section's details
//   //   yield put(updateSectionLocal(updatedSection));

//   //   // const responseTemplate = yield call(
//   //   //   apiGetDocumentTemplate,
//   //   //   userState.default_organization_id,
//   //   //   userState.default_workspace_id,
//   //   //   documentTemplate.id
//   //   // );
//   // //  console.log("AAA", response);
//   //   // yield put(setDocumentTemplate(responseTemplate));
//   //   // Get the document master
//   //   yield put(actions.getDocumentMaster());
//   //   //New Added
//     yield put(setStatus({ status: "idle", error: undefined }));
//   } catch (e) {
//     console.error(e);
//     yield put(setStatus({ status: "failed", error: String(e) }));
//   }
// }

export function* createSubsectionWithElement(
  action: ReturnType<typeof actions.createSubsectionWithElement>
) {
  yield put(setStatus({ status: "loading", error: undefined }));
  yield put(setCreateElementBelow(false));
  const sectionPayLoadID = action.payload.section.id;

  try {
    //getting Current user Details.
    action.payload.subsectionAbove;
    const userState = (yield select(selectUser)) as User;
    //Select the Current Open Template
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    const documentMaster = (yield select(
      selectDocumentMasterState
    )) as DocumentMasterState;
    const documentImageMaster = (yield select(
      selectDocumentMasterState
    )) as DocumentMasterState;
    if (documentTemplate == null) throw "No document is currently being edited";
    // Get the section we are adding this to.
    // NOTE: Don't use the payload section, it could be out of date.
    // find the Section where subSection is to be created.
    const section = documentTemplate.sections!.find(
      (s) => s.id === sectionPayLoadID
    );

    if (section == null) throw "Cannot find the section being updated";
    // And the element being added (it's always component_order 0).
    // Create object of the Element Being Dropped.
    const isEmpty = section.subsections?.length;

    if (action.payload.documentElement.content_type === "IMAGE") {
      const updateddocumentTemplate = (yield select(
        selectEditingDocumentTemplate
      )) as DocumentTemplate;

      const newContentImage = JSON.parse(
        findAboveImageElement(
          updateddocumentTemplate,
          sectionPayLoadID,
          action.payload.subsectionAbove,
          true
        )
      );
      if (newContentImage && newContentImage.caption) {
        newContentImage.caption = "Image Caption"; // Change the caption to the desired value
      }
      // const newContentImage = documentTemplate.image_content_format!;
      action.payload.documentElement.content_format =
        JSON.stringify(newContentImage);
    } else if (action.payload.documentElement.content_type === "TABLE") {
      // const newContentTable = documentImageMaster.tabledocumentMaster;
      // action.payload.documentElement.content_format = newContentTable;
      action.payload.documentElement.formatting = DEFAULT_THEME_FORMATTING;
    }

    let documentElement;
    // if the text is dropped on text
    if (
      action.payload.subsectionAbove &&
      attachedSubsectionElement(action.payload.subsectionAbove).content_type ===
        "TEXT" &&
      action.payload.documentElement.content_type === "TEXT"
    ) {
      documentElement = {
        ...action.payload.documentElement,
        edit: false,
        heading_level:
          Number(
            attachedSubsectionElement(action.payload.subsectionAbove)
              .heading_level
          ) || 2,
        content_order: 0,
      };
      // if the above s heading and Element dropped is text .. 1 level deeper
    } else if (
      action.payload.subsectionAbove &&
      attachedSubsectionElement(action.payload.subsectionAbove).content_type ===
        "HEADING" &&
      action.payload.documentElement.content_type === "TEXT"
    ) {
      const level = Number(
        attachedSubsectionElement(action.payload.subsectionAbove).heading_level
      );
      documentElement = {
        ...action.payload.documentElement,
        edit: false,
        heading_level:
          level === 6
            ? 6
            : Number(
                attachedSubsectionElement(action.payload.subsectionAbove)
                  .heading_level
              ) + 1,
        content_order: 0,
      };
    } else if (
      // if heading is dropped on heading
      action.payload.subsectionAbove &&
      attachedSubsectionElement(action.payload.subsectionAbove).content_type ===
        "HEADING" &&
      action.payload.documentElement.content_type === "HEADING"
    ) {
      const level = Number(
        attachedSubsectionElement(action.payload.subsectionAbove).heading_level
      );
      documentElement = {
        ...action.payload.documentElement,
        edit: false,
        heading_level:
          level === 6
            ? 6
            : Number(
                attachedSubsectionElement(action.payload.subsectionAbove)
                  .heading_level
              ),
        content_order: 0,
      };
    } else if (
      // if the heading is dropped on text
      action.payload.subsectionAbove &&
      action.payload.subsectionAbove?.elements?.[0]?.content_type === "TEXT" &&
      action.payload.documentElement.content_type === "HEADING"
    ) {
      const sectionID = action.payload.section.id;
      const subsectionID = action.payload.subsectionAbove.id;
      let parentLevel = 2;
      let parentLevelFound = false;

      try {
        documentTemplate.sections?.forEach((section) => {
          if (section.id === sectionID && !parentLevelFound) {
            section.subsections?.forEach((subSection) => {
              if (
                subSection.id !== subsectionID &&
                subSection.elements &&
                subSection.elements[0] !== undefined &&
                subSection.elements[0].content_type === "HEADING" &&
                !parentLevelFound
              ) {
                parentLevel =
                  attachedSubsectionElement(subSection).heading_level;
                // added Component order here
              } else if (subSection.id === subsectionID && !parentLevelFound) {
                parentLevelFound = true;
              }
            });
          }
        });
      } catch (error) {
        // Handle the exception here
        console.error("An error occurred:", error);
        // You can throw the error or return a default value if needed
        throw error;
        // parentLevel = 1; // Example default value
      }

      documentElement = {
        ...action.payload.documentElement,
        edit: false,
        heading_level:
          parentLevel === 6 ? 6 : parentLevel === 1 ? 2 : parentLevel,
        content_order: 0,
      };
    }
    //  else if((action.payload.documentElement.content_type == "HEADING" || action.payload.documentElement.content_type == "TEXT" )   && action.payload.subsectionAbove?.elements?.[0]?.content_type != "TEXT" &&    action.payload.subsectionAbove?.elements?.[0]?.content_type != "HEADING"  ){

    //  }
    else {
      documentElement = {
        ...action.payload.documentElement,
        edit: false,
        heading_level:
          Number(
            action.payload.subsectionAbove?.elements?.[0]?.heading_level
          ) || 2,
        content_order: 0,
      };
    }
    // Assign the Component order to the Subsection In which Element is to be Cretaed.
    let newComponentOrder = 0;
    // 1. Create a new subsection below the provided subsection,
    // or above all subsections if none is provided.
    if (section.subsections!.length > 0) {
      if (action.payload.subsectionAbove !== undefined) {
        newComponentOrder =
          action.payload.subsectionAbove!.component_order + 10;
      } else {
        // Drop at top.
        newComponentOrder =
          Math.min(
            ...section.subsections!.map(function (o) {
              return o.component_order;
            })
          ) - 10;
      }
    }
    // if (
    //   documentMaster.documentMaster &&
    //   documentElement.content_type === "HEADING"
    // ) {
    //   if (documentElement.content.startsWith("##")) {
    //     documentElement.content = documentElement.content.slice(2).trim();
    //   }
    //   const markDownStyle = documentMasterFormat(
    //     documentMaster,
    //     "HEADING",
    //     documentElement.heading_level
    //   ).fontStyle;

    //   if (markDownStyle.includes("italic")) {
    //     documentElement.content = `_${documentElement.content}_`;
    //   }
    //   if (markDownStyle.includes("bold")) {
    //     documentElement.content = `**${documentElement.content}**`;
    //   }
    //   if (markDownStyle.includes("underline")) {
    //     documentElement.content = `++${documentElement.content}++`;
    //   }
    //   documentElement.content = "## " + documentElement.content;
    // }

    // Assign a Unique ID to the subSection untill the response is not arrived from Backend.
    // Generating random ID And Save it for Later use
    const uniqueID = Math.floor(Math.random() * 999);
    const newSubsection = {
      id: uniqueID,
      heading: "notused",
      component_order: newComponentOrder,
      elements: [
        {
          ...documentElement,
          pending_addition: section.edit,
          should_show:
            documentElement.content_type === ContentType.Heading ||
            documentElement.content_type === ContentType.Text ||
            documentElement.content_type == ContentType.Table ||
            documentElement.content_type === ContentType.Image
              ? true
              : false,
        },
      ],
      pending_deleted_at: null,
      pending_heading: null,
      pending_addition: false,
    };

    // Adding All the Existing Subsection to a Section to place the New Created SubSection to i to render it on Frontend.
    const newSubsections = [...section.subsections!];
    const subsectionAboveIndex = action.payload.subsectionAbove
      ? section.subsections?.findIndex(
          (subSection) => subSection.id === action.payload.subsectionAbove!.id
        )
      : null;
    // Add the new subsection to the sections
    let splicedIndex;
    if (subsectionAboveIndex == null) {
      splicedIndex = 0;
      if (isEmpty === 0) {
        newSubsections.push(newSubsection);
      }
      // if there is no Existing SubSection Add it in the top.
      else newSubsections.splice(splicedIndex, 0, newSubsection);
    } else if (subsectionAboveIndex == section.subsections!.length - 1) {
      // Append it to the end if it is Placed at last.
      newSubsections.push(newSubsection);
      splicedIndex = section.subsections!.length;
    } else {
      // if it is Placed IN-Between Splice the new subsection and update the component order of All Below Sub Sections.
      newSubsections.splice(subsectionAboveIndex + 1, 0, newSubsection);
      splicedIndex = subsectionAboveIndex + 1;
    }
    // Fix everything below Return Updated All Updated And Existing Subsection in that Specific Section.
    const subSectionsToUpdate = fixComponentOrderFromUpdatedElement(
      newSubsections,
      splicedIndex,
      newSubsection.component_order
    );
    // Array to Store the IDs of  the Subsection being updating.
    const SubsectionSComponentTobeUpdatedID: any = [];
    // Dictionary to Store the IDs and Component_Order of  the Subsection being updating.
    const SubsectionSComponentTobeUpdated: { [id: number]: number } = {};
    for (const updatedSubsection of subSectionsToUpdate) {
      // Push the update to the Frontend.
      // Update locally
      const subSectionIndex = newSubsections.findIndex(
        (s) => s.id == (updatedSubsection as DocumentSubsection).id
      );
      if (subSectionIndex >= 0) {
        newSubsections[subSectionIndex] =
          updatedSubsection as DocumentSubsection;
      }
      SubsectionSComponentTobeUpdated[
        (updatedSubsection as DocumentSubsection).id
      ] = (updatedSubsection as DocumentSubsection).component_order;
      SubsectionSComponentTobeUpdatedID.push(
        (updatedSubsection as DocumentSubsection).id
      );
    }

    const documentTemplatecopy = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    const sectionNew = documentTemplatecopy!.sections!.find(
      (s) => s.id === sectionPayLoadID
    );

    // Create Section Locally.
    const updatedSectionOrders = Object.assign({}, sectionNew, {
      subsections: newSubsections,
    });
    //Lastly update the locally created section to the Frontend using Slice.
    yield put(updateSubsectionComponentOrderLocal(updatedSectionOrders));
    yield put(setCreateElementBelow(true));
    // yield put(setStatus({ status: "idle", error: undefined }));
    const subSectionsToUpdateID = SubsectionSComponentTobeUpdatedID.slice(1);
    const lowestId = Math.min(
      ...Object.keys(SubsectionSComponentTobeUpdated).map(Number)
    );
    delete SubsectionSComponentTobeUpdated[lowestId];
    const subSectionsToUpdates = SubsectionSComponentTobeUpdated;
    let parentSubHeading = 0;
    if (action.payload.subsectionAbove) {
      parentSubHeading = findParentSubHeadingID(
        documentTemplate,
        action.payload.section,
        action.payload.subsectionAbove
      );
    }
    if (
      section.heading !== "Table of Contents Section" &&
      section.heading !== "List of Tables" &&
      section.heading !== "List of Figures"
    ) {
      const response = yield call(
        apiCreateSubsectionWithElement,
        userState.default_organization_id,
        userState.default_workspace_id,
        documentTemplate,
        section,
        newSubsection,
        documentElement,
        uniqueID,
        subSectionsToUpdates,
        subSectionsToUpdateID,
        parentSubHeading
      );
      // Update the Subsection ID on Frontend, Based on the UniqueID.
      yield put(
        updateSubsectionElementID({
          section: section,
          uniqueID: response.data.uniqueID,
          updatedSubsectionID: response.data.document_sub_section_id,
          updatedElementID: response.data.element_id,
        })
      );
    }

    // if(action.payload.isFocusNext){
    //   // Do nothing
    // }else{
    // // refresh the whole Document
    //   yield put(actions.setEditingDocumentTemplate(documentTemplate));
    // }

    if (action.payload.documentElement.content_type === "HEADING") {
      yield put(
        actions.updateSectionHeadingLevels({
          documentTemplate: documentTemplate,
          section: section,
        })
      );
    }

    yield put(setStatus({ status: "idle", error: undefined }));
    yield put(setCreateElementBelow(false));
  } catch (e) {
    console.error(e);
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* undoDeletedSection(
  action: ReturnType<typeof actions.undoDeletedSection>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    //   // Update all the document templates.
    if (!action.payload.edit) {
      yield put(deleteSectionLocal(action.payload));
    } else if (action.payload.edit) {
      //added new to delete add Library content section from Right Drawer
      yield put(deleteSectionLocal(action.payload));
    } else {
      yield put(markDeleteSectionLocal(action.payload));
    }

    const response = yield call(
      apiUndoDeletedSection,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload
    );

    yield put(actions.setOpenApprovalDocumentTemplate(documentTemplate));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* undoDeletedSubsection(
  action: ReturnType<typeof actions.undoDeletedSubsection>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    const section = action.payload.section;
    const subSection = action.payload.subSection;

    // 4. Delete the subsection.
    const response = yield call(
      apiUndoDeletedSubsection,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      section,
      subSection
    );

    yield put(actions.setOpenApprovalDocumentTemplate(documentTemplate));

    yield put(
      actions.updateSectionHeadingLevels({
        documentTemplate: documentTemplate,
        section: section,
      })
    );

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    console.error(e);
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* deleteSubsection(
  action: ReturnType<typeof actions.deleteSubsection>
) {
  yield put(setStatus({ status: "loading", error: undefined }));
  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    const section = action.payload.section;
    const subSection = action.payload.subSection;

    if ((subSection as any).publication_no !== null) {
      yield put(markDeleteSubSectionLocal(subSection));
    } else {
      yield put(
        deleteSubsectionLocal({ section: section, subSection: subSection })
      );
    }

    if (
      subSection.elements &&
      subSection.elements[0] &&
      (subSection.elements[0].content_type == ContentType.Spacer ||
        subSection.elements[0].content_type == ContentType.PageBreak ||
        subSection.elements[0].content_type == ContentType.columnStart ||
        subSection.elements[0].content_type == ContentType.columnEnd)
    ) {
      // 4. Delete the subsection.
      const response = yield call(
        apiDeleteSubsection,
        userState.default_organization_id,
        userState.default_workspace_id,
        // userState.user_id,
        documentTemplate,
        section,
        subSection
      );
    } else if (action.payload.currentDocOnly) {
      const response = yield call(
        apiDetachSubsection,
        userState.default_organization_id,
        userState.default_workspace_id,
        // userState.user_id,
        documentTemplate,
        section,
        subSection
      );
    } else {
      // 4. Delete the subsection.
      const response = yield call(
        apiDeleteSubsection,
        userState.default_organization_id,
        userState.default_workspace_id,
        // userState.user_id,
        documentTemplate,
        section,
        subSection
      );
    }

    // yield put(actions.setEditingDocumentTemplate(documentTemplate));

    // if ((subSection as any).publication_no !== null) {
    //     yield put(
    //       markDeleteSubSectionLocal(subSection)
    //     )
    // }
    // else {
    //   yield put(
    //     deleteSubsectionLocal({ section: section, subSection: subSection })
    //   );
    // }

    if (
      subSection.elements &&
      subSection.elements[0] &&
      (subSection.elements[0].content_type == ContentType.Heading ||
        subSection.elements[0].content_type == ContentType.Text)
    )
      yield put(
        actions.updateSectionHeadingLevels({
          documentTemplate: documentTemplate,
          section: section,
          refreshDocument: true,
        })
      );

    // yield put(actions.refreshDocumentTemplate(documentTemplate));

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    console.error(e);
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* switchSubsectionBlock(
  action: ReturnType<typeof actions.switchSubsectionBlock>
) {
  yield setStatus({ status: "loading", error: undefined });

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;

    if (documentTemplate == null)
      throw "No Documnet is currently being Editing";

    // 1. Find the section from the payload.
    const section = documentTemplate.sections!.find(
      (s) => s.id == action.payload.section.id
    );
    if (!section) return;

    // 2. Make a new list of subsections, we're going to move blocks of it around.
    const newSubsections = [...section.subsections!];

    // 3. Find the minimum index (we will need to repair component_index from here)
    const sourceIndex = newSubsections.findIndex(
      (subSection) => subSection.id === action.payload.sourceSubsection.id
    );
    const _destIndex = newSubsections.findIndex(
      (subSection) => subSection.id === action.payload.destinationSubsection.id
    );
    const sourceBlock = getSubheadingBlockSubSection(
      newSubsections,
      sourceIndex
    );
    const desBlock = getSubheadingBlockSubSection(newSubsections, _destIndex);

    newSubsections.splice(sourceIndex, sourceBlock.length);

    const destIndex = newSubsections.findIndex(
      (subSection) => subSection.id === action.payload.destinationSubsection.id
    );

    // console.log("sourceInde" , sourceIndex, "destIndex" , destIndex ,"condition" , sourceIndex<destIndex , destIndex+desBlock.length  , destIndex  )
    newSubsections.splice(
      sourceIndex <= destIndex ? destIndex + desBlock.length : destIndex,
      0,
      ...sourceBlock
    );
    // newSubsections.splice(secondIndex, secondBlock.length, ...firstBlock);
    // // 6. Splice the first part.
    // newSubsections.splice(firstIndex, firstBlock.length, ...secondBlock);

    const SubsectionSComponentTobeUpdated: { [id: number]: number } = {};
    for (let index = 0; index < newSubsections.length; index++) {
      const updatedSubsection = Object.assign({}, newSubsections[index], {
        component_order: index * 10,
      });
      SubsectionSComponentTobeUpdated[
        (updatedSubsection as DocumentSubsection).id
      ] = (updatedSubsection as DocumentSubsection).component_order;
      yield put(
        updateSubsectionComponentOrder({
          section: section,
          subsectionID: (updatedSubsection as DocumentSubsection).id,
          componentOrder: (updatedSubsection as DocumentSubsection)
            .component_order,
        })
      );
    }
    yield call(
      apiUpdateSubsectionsComponentOrder,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      section,
      SubsectionSComponentTobeUpdated
    );
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* switchSubsections(
  action: ReturnType<typeof actions.switchSubsections>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";

    // 1. Find the section from the payload.
    const section = documentTemplate.sections!.find(
      (s) => s.id == action.payload.section.id
    );
    if (!section) return;

    // 2. Make a new list of subsections, we're going to move blocks of it around.
    const newSubsections = [...section.subsections!];

    // 3. Find the minimum index (we will need to repair component_index from here)
    const sourceIndex = newSubsections.findIndex(
      (subSection) => subSection.id === action.payload.sourceSubsection.id
    );
    const destIndex = newSubsections.findIndex(
      (subSection) => subSection.id === action.payload.destinationSubsection.id
    );

    // console.log("sourceIndex", sourceIndex,destIndex ,"destIndex " , action.payload,"action.payload" )
    const firstIndex = Math.min(sourceIndex, destIndex),
      secondIndex = Math.max(sourceIndex, destIndex);

    if (action.payload.onlyHeading) {
      // 4. Find the blocks of subsections
      const firstBlock = getSubheadingBlockSubSection(
          newSubsections,
          firstIndex
        ),
        secondBlock = getSubheadingBlockSubSection(newSubsections, secondIndex);

      // Remove the first block from its original position
      // newSubsections.splice(firstIndex, firstBlock.length);
      // newSubsections.splice(destIndex-1<=0?0:destIndex-1, 0, ...firstBlock);
      // // 5. Splice the second part.
      newSubsections.splice(secondIndex, secondBlock.length, ...firstBlock);
      // 6. Splice the first part.
      newSubsections.splice(firstIndex, firstBlock.length, ...secondBlock);

      // 7. Update all subsection component IDs to make sure the order is correct
      const SubsectionSComponentTobeUpdated: { [id: number]: number } = {};
      for (let index = 0; index < newSubsections.length; index++) {
        const updatedSubsection = Object.assign({}, newSubsections[index], {
          component_order: index * 10,
        });
        SubsectionSComponentTobeUpdated[
          (updatedSubsection as DocumentSubsection).id
        ] = (updatedSubsection as DocumentSubsection).component_order;
        yield put(
          updateSubsectionComponentOrder({
            section: section,
            subsectionID: (updatedSubsection as DocumentSubsection).id,
            componentOrder: (updatedSubsection as DocumentSubsection)
              .component_order,
          })
        );
        // yield call(
        //   apiUpdateSubsection,
        //   userState.default_organization_id,
        //   userState.default_workspace_id,
        //   documentTemplate,
        //   action.payload.section,
        //   updatedSubsection
        // );
      }

      yield call(
        apiUpdateSubsectionsComponentOrder,
        userState.default_organization_id,
        userState.default_workspace_id,
        documentTemplate,
        section,
        SubsectionSComponentTobeUpdated
      );
      // Finally pull the whole document to be safe.
    } else {
      // 4. Find the blocks of subsections
      const firstBlock = getSubheadingBlock(newSubsections, firstIndex),
        secondBlock = getSubheadingBlock(newSubsections, secondIndex);
      // 5. Splice the second part.
      newSubsections.splice(secondIndex, secondBlock.length, ...firstBlock);
      // 6. Splice the first part.
      newSubsections.splice(firstIndex, firstBlock.length, ...secondBlock);
      // console.log("newSubsections",newSubsections)
      // 7. Update all subsection component IDs to make sure the order is correct
      const SubsectionSComponentTobeUpdated: { [id: number]: number } = {};

      for (let index = 0; index < newSubsections.length; index++) {
        const updatedSubsection = Object.assign({}, newSubsections[index], {
          component_order: index * 10,
        });

        SubsectionSComponentTobeUpdated[
          (updatedSubsection as DocumentSubsection).id
        ] = (updatedSubsection as DocumentSubsection).component_order;

        yield put(
          updateSubsectionComponentOrder({
            section: section,
            subsectionID: (updatedSubsection as DocumentSubsection).id,
            componentOrder: (updatedSubsection as DocumentSubsection)
              .component_order,
          })
        );

        // yield call(
        //   apiUpdateSubsection,
        //   userState.default_organization_id,
        //   userState.default_workspace_id,
        //   documentTemplate,
        //   action.payload.section,
        //   updatedSubsection
        // );
      }

      yield call(
        apiUpdateSubsectionsComponentOrder,
        userState.default_organization_id,
        userState.default_workspace_id,
        documentTemplate,
        section,
        SubsectionSComponentTobeUpdated
      );
      // Finally pull the whole document to be safe.

      yield put(
        actions.updateSectionHeadingLevels({
          documentTemplate: documentTemplate,
          section: section,
        })
      );
    }

    // const documentTemplateupdated = (yield select(
    //   selectEditingDocumentTemplate
    // )) as DocumentTemplate ;
    // yield put(setDocumentTemplate(documentTemplateupdated));
    // yield put(actions.setEditingDocumentTemplate(documentTemplate));

    yield put(setStatus({ status: "idle", error: undefined }));
    //  Updating the Heading Levels

    // yield put(
    //   actions.updateSectionHeadingLevels({
    //     documentTemplate: documentTemplate,
    //     section: section,
    //   })
    // );

    //We can probably just do a partial update, but being safe here.

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* updateDocumentElementContent(
  action: ReturnType<typeof actions.updateDocumentElementContent>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    const documentMaster = (yield select(
      selectDocumentMasterState
    )) as DocumentMasterState;
    if (documentTemplate == null) throw "No document is currently being edited";
    const section = action.payload.section;
    const subSection = action.payload.subSection;
    const documentElement = action.payload.documentElement;

    yield put(
      updateDocumentElementLocal({
        section: section,
        subSection: subSection,
        updatedDocumentElement: { ...documentElement },
      })
    );

    const response = yield call(
      apiUpdateDocumentElementContent,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      section,
      subSection,
      documentElement
    );
    // console.log("updateDocumentElemet",response);
    if (section.edit) {
      yield put(
        updateDocumentElementLocal({
          section: section,
          subSection: subSection,
          updatedDocumentElement: {
            ...documentElement,
            id: response.data.document_element_id,
          },
        })
      );

      // yield put(actions.refreshDocumentTemplate(documentTemplate));
      // Refresh the whole document
      // i commented this.
      // yield put(actions.setEditingDocumentTemplate(documentTemplate));
    } else {
      yield put(
        updateDocumentElementLocal({
          section: section,
          subSection: subSection,
          updatedDocumentElement: { ...documentElement },
        })
      );
    }

    if (action.payload.updateLevels) {
      const documentTemplateUpdate = (yield select(
        selectEditingDocumentTemplate
      )) as DocumentTemplate | null;

      if (documentTemplateUpdate != null) {
        const __section = documentTemplateUpdate.sections?.find(
          (_) => section.id === _.id
        ) as DocumentSection;

        yield put(
          actions.updateSectionHeadingLevels({
            documentTemplate: documentTemplateUpdate,
            section: __section,
            // refreshDocument: true,
          })
        );
      }
    }

    // yield put(setNeedsApproval(true));
    // yield put(setNewContentAdded(true));
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    console.error(e);
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* updateDocumentElement(
  action: ReturnType<typeof actions.updateDocumentElement>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    const documentMaster = (yield select(
      selectDocumentMasterState
    )) as DocumentMasterState;
    if (documentTemplate == null) throw "No document is currently being edited";
    const section = action.payload.section;
    const subSection = action.payload.subSection;
    const documentElement = action.payload.documentElement;
    // if(true){
    if (documentElement.content_type === "IMAGE") {
      yield put(updateDocumentImangeContent(documentElement.content_format));
    } else if (documentElement.content_type === "TABLE") {
      yield put(setTableDocumentMaster(documentElement.content_format));
    }

    yield put(
      updateDocumentElementLocal({
        section: section,
        subSection: subSection,
        updatedDocumentElement: { ...documentElement },
      })
    );

    const response = yield call(
      apiUpdateDocumentElement,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      section,
      subSection,
      documentElement
    );
    // console.log("updateDocumentElemet",response);
    if (section.edit) {
      yield put(
        updateDocumentElementLocal({
          section: section,
          subSection: subSection,
          updatedDocumentElement: {
            // ...documentElement,
            ...response.data.document_element,
            id: response.data.document_element_id,
          },
        })
      );
      // yield put(actions.refreshDocumentTemplate(documentTemplate));
      // Refresh the whole document
      // i commented this.
      // yield put(actions.setEditingDocumentTemplate(documentTemplate));
    } else {
      yield put(
        updateDocumentElementLocal({
          section: section,
          subSection: subSection,
          updatedDocumentElement: {
            ...response.data.document_element,
            //  ...documentElement
          },
        })
      );
    }

    // check if the section heading requires the appproval?
    if (response.data.new_content_added) {
      yield put(setNewContentAdded(true));
    }
    if (response.data.needs_approval) {
      yield put(setNeedsApproval(true));
    }
    if (
      // documentTemplate.needs_approval ||
      // documentTemplate.send_for_approvals ||
      // documentTemplate.publication_no ||
      action.payload.documentElement.content_type === "IMAGE"
    ) {
      yield put(actions.refreshDocumentTemplate(documentTemplate));
    }

    if (response.data.needs_approval) {
      yield put(setNeedsApproval(response.data.needs_approval));
    }

    // yield put(setNeedsApproval(true));
    // yield put(setNewContentAdded(true));
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    console.error(e);
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}
export function* updateSectionHeadingLevels(
  action: ReturnType<typeof actions.updateSectionHeadingLevels>
) {
  yield put(setStatus({ status: "loading", error: undefined }));
  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;

    const section = action.payload.section;
    if (documentTemplate == null) throw "No document is currently being edited";
    if (section == null) throw "No document Section is currently being edited";
    const elementsLevelsTobeUpdated: { [id: number]: number } = {};
    const ElementsToUpdate = fixHeadingLevelsOfCompleteSection(
      documentTemplate,
      section
    );

    for (const current of ElementsToUpdate) {
      // elementsLevelsTobeUpdated[current.ElementID] =
      elementsLevelsTobeUpdated[current.subSectionID] =
        current.newHeadingLevel > 6
          ? 6
          : current.newHeadingLevel < 2
          ? 2
          : current.newHeadingLevel;
      yield put(
        updateDocumentElementHeadingLocal({
          section: section,
          documentElementID: current.ElementID,
          headinglevel:
            current.newHeadingLevel > 6
              ? 6
              : current.newHeadingLevel < 2
              ? 2
              : current.newHeadingLevel,
        })
      );
    }
    const response = yield call(
      apiUpdateDocumentElementHeadingLevel,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      section,
      elementsLevelsTobeUpdated
    );
    if (action.payload.refreshDocument) {
      yield put(
        actions.fetchDocumentTemplate({
          documentTemplate: documentTemplate,
          fetchMaster: false,
          fetchSectionFormatting: false,
          fetchTemplate: true,
        })
      );

      // yield put(actions.refreshDocumentTemplate(documentTemplate));
    }

    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (error) {
    console.error(error);
  }
}

export function* setHeadingLevel(
  action: ReturnType<typeof actions.setHeadingLevel>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
   

    const userState = (yield select(selectUser)) as User;

    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    const section = action.payload.selected.section;
    const subSection = action.payload.selected.subSection;
    const documentElement = action.payload.selected.documentElement;

    if (documentElement !== null && section !== null && subSection !== null) {
      const newDocumentElement: DocumentElement = {
        ...documentElement,
        heading_level: action.payload.heading_level,
      };

      if (
        (documentElement.heading_level === 1 ||
          action.payload.heading_level === 1) &&
        documentElement?.content_type === "TEXT"
      ) {
        const responses = yield call(
          apiUpdateDocumentElement,
          userState.default_organization_id,
          userState.default_workspace_id,
          documentTemplate,
          section,
          subSection,
          newDocumentElement
        );
      } else {
        //console.log("AAA", newDocumentElement);
        // const LevelToBeUpdated = findChildSubSections(
        //   documentTemplate,
        //   section,
        //   subSection,
        //    action.payload.selected.documentElement?.content_type===ContentType.Text? "HEADING" :"TEXT"
        // );
        const LevelToBeUpdated =
          action.payload.selected.documentElement?.content_type ==
          ContentType.Text
            ? findChildSubSectionsToUpdateTextLevels(
                documentTemplate,
                section,
                subSection
              )
            : findChildSubSectionsToUpdateHeadingLevels(
                documentTemplate,
                section,
                subSection
              );
        // const childsOfH6Heading = findTextElementBelowSubHeading(

        //   documentTemplate,
        //   section,
        //   subSection
        // );

        const elementsLevelsTobeUpdated: { [id: number]: number } = {};
        elementsLevelsTobeUpdated[subSection.id] = Number(
          action.payload.heading_level
        );
        const decreasedLevel =
          Number(action.payload.heading_level) -
          Number(documentElement.heading_level);
        if (decreasedLevel < 0) {
          for (const current of LevelToBeUpdated) {
            elementsLevelsTobeUpdated[current.SubsectionID] =
              (current.heading_Level - -decreasedLevel) as number;
          }
        }

        if (decreasedLevel > 0) {
          for (const current of LevelToBeUpdated) {
            elementsLevelsTobeUpdated[current.SubsectionID] =
              ((current.heading_Level + decreasedLevel) as number) > 6
                ? 6
                : ((current.heading_Level + decreasedLevel) as number);
          }
        }

        yield put(
          updateDocumentElementLocal({
            section: section,
            subSection: subSection,
            updatedDocumentElement: {
              ...newDocumentElement,
              // ,
              // id: response.data.document_element_id,
            },
          })
        );

        updateDocumentsubSectionHeadingLocal({
          section: section,
          HeadingLevelstoUpdate: elementsLevelsTobeUpdated,
        });
        // for (const id in elementsLevelsTobeUpdated) {
        //   if (Object.prototype.hasOwnProperty.call(elementsLevelsTobeUpdated, id)) {
        //     const level = elementsLevelsTobeUpdated[id];
        //     updateDocumentsubSectionHeadingLocal({
        //       section:section,

        //       headinglevel:level,
        //     })
        //     console.log(`Element ID: ${id}, Level: ${level}`);
        //     // Perform operations with id and level here
        //   }
        // }

        // const responses = yield call(
        //   apiUpdateDocumentElement,
        //   userState.default_organization_id,
        //   userState.default_workspace_id,
        //   documentTemplate,
        //   section,
        //   subSection,
        //   newDocumentElement
        // );
        const response = yield call(
          apiUpdateDocumentElementHeadingLevel,
          userState.default_organization_id,
          userState.default_workspace_id,
          documentTemplate,
          section,
          elementsLevelsTobeUpdated
        );
      }
      // yield put(
      //   updateDocumentElementLocal({
      //     section: section,
      //     subSection: subSection,
      //     updatedDocumentElement: {
      //       ...newDocumentElement,
      //       // ,
      //       // id: response.data.document_element_id,
      //     },
      //   })
      // );
      // yield put(setLocalHeadingLevel(newDocumentElement.heading_level));
    } else if (
      documentElement === null &&
      section !== null &&
      subSection === null
    ) {
      const submitSection: DocumentSection = {
        ...section,
        heading_level: parseInt(action.payload.heading_level as any),
      };

      const response = yield call(
        apiUpdateSection,
        userState.default_organization_id,
        userState.default_workspace_id,
        documentTemplate,
        submitSection
      );

      const newSection = { ...submitSection };

      newSection.pending_heading =
        response.data.document_section.pending_heading;
      newSection.pending_deleted_at =
        response.data.document_section.pending_deleted_at;
      newSection.heading = response.data.document_section.heading;
      newSection.heading_level = response.data.document_section.heading_level;
      newSection.pending_heading_level =
        response.data.document_section.pending_heading_level;
      yield put(updateSectionLocal(newSection));
      yield put(setLocalHeadingLevel(newSection.heading_level));
    }
    yield put(actions.setEditingDocumentTemplate(documentTemplate));
    yield put(setStatus({ status: "idle", error: undefined }));
  } catch (e) {
    console.error(e);
    yield put(setStatus({ status: "failed", error: String(e) }));
  }
}

export function* getVariables(action: ReturnType<typeof actions.getVariables>) {
  // yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";

    const response = yield call(
      apiGetVariables,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate
    );
    const variables = response.data.document_variables;

    // Update all the document templates.
    yield put(setVariables(variables));
  } catch (e) {
    console.error(e);
    yield put(setError(String(e)));
    return;
  }
}

export function* getReferences(
  action: ReturnType<typeof actions.getReferences>
) {
  // yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";

    const response = yield call(
      apiGetReferences,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate
    );
    const references = response.data.document_references;

    // Update all the document templates.
    yield put(setReferences(references));
  } catch (e) {
    console.error(e);
    yield put(setError(String(e)));
    return;
  }
}

export function* createVariable(
  action: ReturnType<typeof actions.createVariable>
) {
  // yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    const response = yield call(
      apiCreateVariable,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload
    );
    // Update the variables
    yield put(actions.getVariables());
  } catch (e) {
    console.error(e);
    yield put(setError(String(e)));
    return;
  }
}

export function* createReference(
  action: ReturnType<typeof actions.createReference>
) {
  // yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));
  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    const response = yield call(
      apiCreateReference,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload
    );
    // Update the variables
    yield put(actions.getReferences());
  } catch (e) {
    console.error(e);
    yield put(setError(String(e)));
    return;
  }
}

export function* approveDocumentSection(
  action: ReturnType<typeof actions.approveDocumentSection>
) {
  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document currently being edited";
    const response = yield call(
      apiApproveDocumentSection,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload.approveSectionID,
      action.payload.declinedSectionID
    );
    yield put(actions.setOpenApprovalDocumentTemplate(documentTemplate));
  } catch (error) {
    yield put(setError(String(error)));
  }
}

export function* attachVersionDocumentElement(
  action: ReturnType<typeof actions.attachVersionDocumentElement>
) {
  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    const response = yield call(
      apiAttachVersionDocumentElement,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload.sectionID,
      action.payload.subSectionID,
      action.payload.documentElementID
    );
    yield put(actions.setOpenApprovalDocumentTemplate(documentTemplate));
  } catch (e) {
    console.error(e);
    yield put(setError(String(e)));
    return;
  }
}

export function* updateVariable(
  action: ReturnType<typeof actions.updateVariable>
) {
  // yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";

    const response = yield call(
      apiUpdateVariable,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload
    );

    // Update the variables
    yield put(actions.getVariables());
  } catch (e) {
    console.error(e);
    yield put(setError(String(e)));
    return;
  }
}

export function* updateReference(
  action: ReturnType<typeof actions.updateReference>
) {
  // yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";

    const response = yield call(
      apiUpdateReference,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload
    );

    // Update the variables
    yield put(actions.getReferences());
  } catch (e) {
    console.error(e);
    yield put(setError(String(e)));
    return;
  }
}

export function* deleteVariable(
  action: ReturnType<typeof actions.deleteVariable>
) {
  // yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";

    const response = yield call(
      apiDeleteVariable,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload
    );

    // Update the variables
    yield put(actions.getVariables());
  } catch (e) {
    console.error(e);
    yield put(setError(String(e)));
    return;
  }
}

export function* deleteReference(
  action: ReturnType<typeof actions.deleteReference>
) {
  // yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";

    const response = yield call(
      apiDeleteReference,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload
    );

    // Update the variables
    yield put(actions.getReferences());
  } catch (e) {
    console.error(e);
    yield put(setError(String(e)));
    return;
  }
}

export function* uploadDocumentElementImage(
  action: ReturnType<typeof actions.uploadDocumentElementImage>
) {
  yield put(setStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";

    const importResponse = yield call(
      apiUploadDocumentElementImage,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      action.payload.section,
      action.payload.subSection,
      action.payload.documentElement,
      action.payload.file
    );
    const newElement = Object.assign({}, action.payload.documentElement, {
      content: String(importResponse.data.document_image_id),
      versioned: false,
    });
    // Update the document element with the new ID.
    yield put(
      actions.updateDocumentElement({
        section: action.payload.section,
        subSection: action.payload.subSection,
        documentElement: newElement,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(setStatus({ status: "idle", error: String(e) }));
    return;
  }
}

export function* enableApprovalModeSaga(
  action: ReturnType<typeof actions.enableApprovalMode>
) {
  try {
    const userState = (yield select(selectUser)) as User;
    const documentTemplate = (yield select(
      selectEditingDocumentTemplate
    )) as DocumentTemplate | null;
    if (documentTemplate == null) throw "No document is currently being edited";
    // yield put(enableApprovalMode())
    actions.getDocumentMaster();
    const documentSections: any = [];
    documentTemplate.sections?.map((e) => {
      documentSections.push(e.id);
    });
    const response = yield call(
      apiGetImpact,
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      documentSections
    );

    const resultMap: { [key: number]: any[] } = {};
    response.data.impact.forEach(([key, documents]: [any, any[]]) => {
      const matches = key.match(/"(\d+)":(\d+)/);
      const subSectionID = parseInt(matches[1], 10);
      const elementId = parseInt(matches[2], 10);

      resultMap[subSectionID] = documents.map((doc: any) => ({
        sectionID: 0,
        documentID: doc.id,
        documentName: doc.name,
        elementId: elementId,
        status: "pending",
        isDeleted: false,
      }));
    });

    yield put(setImpactState(resultMap));
    //yield put(actions.getReferences());
  } catch (e) {
    console.error(e);
    yield put(setError(String(e)));
    return;
  }
}

import { DocumentTemplate, Variable } from "../../types/DocumentTemplate";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  withStyles,
  styled,
} from "@mui/material";
import { useRef, useState } from "react";
import React from "react";


export const  StyledButton = styled(Button)({
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '5px 10px',
    border: 'none',
    alignContent:'center',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
        backgroundColor: '#45a049',
    },
});

export default function DocumentInformationForm(props: {
  parentCloseHandle: any;
  documentTemplate: DocumentTemplate;
}) {

  const [formattedCode, setFormattedCode] = React.useState('');
  React.useEffect(() => {
    setFormattedCode(`
    {
      "template_id": ${props.documentTemplate.id},
      "name": "${props.documentTemplate.name}",
      "include_in_nomia": true,
      "variable_values": 
      "[${JSON.stringify(
        (props.documentTemplate.variables || []).map((v) => {
          if (v.fillable_field && JSON.parse(v.content_format).sendForEsign) {
              return {
          variable_name: v.variable_name,value: v.default_value,type: JSON.parse(v.content_format).type,signatory: JSON.parse(v.content_format).signatory};
          } else {
              return {variable_name: v.variable_name,value: v.default_value};
          }
        }),
        null
      ).replace(/"/g, '\\"')}]"
    }`
  );
  
  }, [props.documentTemplate.variables]);

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const handleCopyClick = () => {
        if (textAreaRef.current) {
            textAreaRef.current.select();
            document.execCommand('copy');
        }
    };
  return (
    <Dialog open={true} onClose={() => props.parentCloseHandle()}>
      <DialogTitle>Document Information</DialogTitle>
      <DialogContent>
    
        <div style={{ position: "relative" }}>
        <StyledButton onClick={handleCopyClick} sx={{ position: 'absolute', top: '5px', right: '30px' , fontSize:"9px" }}>Copy code</StyledButton>
          <textarea
           ref={textAreaRef}
            id="code"
            style={{
              minWidth: "550px",
              width:"auto",
              height: "auto",
              minHeight:"400px",
              backgroundColor: "#282c34",
              color: "#abb2bf",
              border: "none",
              padding: "20px",
              borderRadius: "5px",
              fontSize: "13pt",
              fontFamily: "Courier New, Courier, monospace",
            }}
          >
            {formattedCode}
          </textarea>
        </div>
      </DialogContent>
    </Dialog>
  );
}

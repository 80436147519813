import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { Box } from "@mui/material";
import {
  DocumentSection,
  DocumentSubsection,
} from "../../../../types/DocumentTemplate";
import { COLORS } from "../../../shared/Constants";
import { isIncludeImpact } from "../../drawers/right/Approval/ApprovalHelperFunctions/approvalHelper";

export default function ApprovalIcon(props: {
  isHeading: boolean;
  parentSection: DocumentSection;
  parentSubsection: DocumentSubsection;
  approvalState: any;
  style: any;
}) {
  return !props.isHeading &&
    isIncludeImpact(props.approvalState, props.parentSubsection.id) ? (
    <AutoAwesomeRoundedIcon
      style={{
        ...props.style,
        fontSize: 20,
        zIndex: 99,
           color:COLORS.NOMIA_PINK
      }}
    />
  ) : null;
}
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import { LeftDrawer } from "./drawers/left/LeftDrawer";
import { RightDrawer } from "./drawers/right/RightDrawer";
import Toolbar from "@mui/material/Toolbar";
import Editor from "./Editor";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectDocumentState, setError } from "./editorSlice";
import Alert from "@mui/material/Alert";

export default function EditorContainer() {
  const dispatch = useAppDispatch();
  const documentState = useAppSelector(selectDocumentState);
  function handleErrorClose() {
    dispatch(setError(undefined));
  }
  return (
    <Box sx={{ display: "flex"    }} > 
     
      <LeftDrawer></LeftDrawer>
    
      <Box sx={{ bgcolor: grey[400], flexGrow: 2, width: "100%"   }}>
        {/* Slightly awkward way to pad the top as per MUI guidelines */}
        <Toolbar />
        {/* {documentState.error ? (
          <Alert
            severity="error"
            onClose={() => {
              handleErrorClose();
            }}
          >
            {documentState.error}
          </Alert>
        ) : null} */}
        <Editor></Editor>

      </Box>
   
      <RightDrawer
        documentTemplate={documentState!.documentTemplate!}
      ></RightDrawer>
  
    </Box>
  );
}

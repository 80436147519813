import { getLocalUserToken } from "../services/userAPI";
import { decryptAES } from "./encryption";
import { host } from "./client";

export default async function getEncrypted(endpoint, customConfig = {}) {
  const token = getLocalUserToken();
  const headers = {};

  if (token) headers["Authorization"] = token;

  const config = {
    method: "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  try {
    const response = await window.fetch(host + endpoint, config);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const encryptedData = await response.text(); // assuming the response is TEXT
    const decryptedData = decryptAES(encryptedData);
    return {
      status: response.status,
      data: decryptedData,
      headers: response.headers,
      url: response.url,
    };
  } catch (error) {
    console.error("Error fetching data:", error);
    throw new Error("Failed to fetch data. Please try again later.");
  }
}

// export default async function postEncrypted(endpoint, body = {}, customConfig = {}) {
//   const token = getLocalUserToken();
//   const headers = {
//       "Content-Type": "application/json"
//   };
//   if (token) headers["Authorization"] = token;

//   const config = {
//       method: "POST",
//       ...customConfig,
//       headers: {
//           ...headers,
//           ...customConfig.headers,
//       },
//       body: JSON.stringify(body),
//   };

//   try {
//       const response = await window.fetch(host + endpoint, config);
//       if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//       }
//       const ciphertext = await response.text();
//       const decryptedData = decryptAES(ciphertext);
//       return {
//           status: response.status,
//           data: decryptedData,
//           headers: response.headers,
//           url: response.url,
//       };
//   } catch (error) {
//       console.error("Error fetching data:", error);
//       throw new Error("Failed to fetch data. Please try again later.");
//   }
// }
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import {
    ContentType,
    DocumentElement,
    DocumentSection,
    DocumentSectionSummary,
    DocumentSubsection,
    DocumentTemplate,
    DocumentTemplateSummary,
  } from "../../../types/DocumentTemplate";

  import {
    approveChangedElement,
    approveDeletedSubsection,
    clearSelectedComponent,
    declineChangedElement,
    declineDeletedSubsection,
    

    selectApprovedChangedElements,
    selectApprovedDeletedElements,
    selectApprovedDeletedSubsections,
    selectDeclinedChangedElements,
    selectDeclinedDeletedElements,
    selectDeclinedDeletedSubsections,
    selectSkippedChangedElements,
    selectSkippedDeletedElements,
    selectSkippedDeletedSubsections,

    skipChangedElement,
    skipDeletedSubsection,
  } from "../editorSlice";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import DeleteIcon from "@mui/icons-material/Delete";

import useActions, { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { secondarySilver } from "../../../globals";

export function Approvals(props: {
    element: DocumentElement | null;
    parentSubsection: DocumentSubsection | null;
    parentSection: DocumentSection | null;
    approvalsEnabled: boolean }) {

const approvedChangedElements = useAppSelector(selectApprovedChangedElements);
const approvedDeletedElements = useAppSelector(selectApprovedDeletedElements);
const approvedDeletedSubsections = useAppSelector(selectApprovedDeletedSubsections);
const declinedChangedElements = useAppSelector(selectDeclinedChangedElements);
const declinedDeletedElements = useAppSelector(selectDeclinedDeletedElements);
const declinedDeletedSubsections = useAppSelector(selectDeclinedDeletedSubsections);
const skippedChangedElements = useAppSelector(selectSkippedChangedElements);
const skippedDeletedElements = useAppSelector(selectSkippedDeletedElements);
const skippedDeletedSubsections = useAppSelector(selectSkippedDeletedSubsections);

const dispatch = useAppDispatch();

const actions = useActions();

return (
<Box sx={{width: "100%"}}>
  {/* SUB-SECTION (ACTUALLY ELEMENT) DELETE APPROVALS */}
  {/* {props.approvalsEnabled &&
    props.parentSubsection?.pending_deleted_at !== null  && (
      <Button
        startIcon={<DoneIcon fontSize="large" />}
        style={{
          background: approvedDeletedSubsections.includes(
            props.parentSubsection!.id
          )
            ? "#FF9999"
            : "00000000",
          float: "right",
          color: "#990000",
        }}
        onClick={() =>
          dispatch(
            approveDeletedSubsection({ subsectionId: props.parentSubsection!.id })
          )
        }
      ></Button>
    )} */}
  {/* {props.approvalsEnabled &&
    props.parentSubsection?.pending_deleted_at !== null && (
      <Button
        startIcon={<CloseIcon fontSize="large" />}
        style={{
          background: declinedDeletedSubsections.includes(
            props.parentSubsection!.id
          )
            ? "#FF9999"
            : "00000000",
          float: "right",
          color: "#990000",
        }}
        onClick={() =>
          dispatch(
            declineDeletedSubsection({ subsectionId: props.parentSubsection!.id })
          )
        }
      ></Button>
    )} */}
  {/* {props.approvalsEnabled &&
    props.parentSubsection?.pending_deleted_at !== null && (
      <Button
        startIcon={<SkipNextIcon fontSize="large" />}
        style={{
          background: skippedDeletedSubsections.includes(
            props.parentSubsection!.id
          )
            ? "#FF9999"
            : "00000000",
          float: "right",
          color: "#990000",
        }}
        onClick={() =>
          dispatch(
            skipDeletedSubsection({ subsectionId: props.parentSubsection!.id })
          )
        }
      ></Button>
    )} */}

  { /* ADDITION APPROVALS */ }
  {/* {props.approvalsEnabled &&
    props.element !== null && 
    props.element.pending_addition == true && (
      <Button
        startIcon={<DoneIcon fontSize="large" />}
        style={{
          background: approvedChangedElements.includes(
            props.element.id
          )
            ? "#99FF99"
            : "00000000",
          float: "right",
          color: "#009900",
        }}
        onClick={() =>
          dispatch(
            approveChangedElement({ elementId: props.element!.id })
          )
        }
      ></Button>
    )} */}
  {/* {props.approvalsEnabled &&
    props.element !== null && 
    props.element.pending_addition == true && (
      <Button
        startIcon={<CloseIcon fontSize="large" />}
        style={{
          background: declinedChangedElements.includes(
            props.element.id
          )
            ? "#99FF99"
            : "00000000",
          float: "right",
          color: "#009900",
        }}
        onClick={() =>
          dispatch(
            declineChangedElement({ elementId: props.element!.id })
          )
        }
      ></Button>
    )} */}
  {/* {props.approvalsEnabled &&
    props.element !== null && 
    props.element.pending_addition == true && (
      <Button
        startIcon={<SkipNextIcon fontSize="large" />}
        style={{
          background: skippedChangedElements.includes(
            props.element.id
          )
            ? "#99FF99"
            : "00000000",
          float: "right",
          color: "#009900",
        }}
        onClick={() =>
          dispatch(
            skipChangedElement({ elementId: props.element!.id })
          )
        }
      ></Button>
    )} */}
      {/* { props.parentSection !== null && props.parentSection.heading!=="Table of Contents Section"  &&
      <Button
        startIcon={<DeleteIcon fontSize="large" />}
        disableRipple
        style={{
          float: "right",
        
          color: secondarySilver,
          backgroundColor: "transparent", // Remove the grey color effect on hover
          // zIndex:999999
          
        }}
        onClick={() =>{
          actions.deleteSubsection({
            section: props.parentSection!,
            subSection: props.parentSubsection!,
          })
          dispatch(clearSelectedComponent());
        }
        }
      ></Button> } */}

      {/* {(props.element as any).publication_no === null && !props.element!.pending_addition && props.approvalsEnabled && (
        <Box
          sx={{
            borderRadius: "5px",
            border: "3px solid orange",
            padding: "5px",
          }}
        >
          <Button
            onClick={() =>
              dispatch(
                approveChangedElement({ elementId: props.element!.id })
              )
            }
            startIcon={<DoneIcon fontSize="large" />}
            style={{ float: "right", color: "orange", 
            background: approvedChangedElements.includes(
                props.element!.id
            )
            ? "#FFFF99"
            : "00000000", }}
          ></Button>
          <Button
            onClick={() =>
              dispatch(
                declineChangedElement({ elementId: props.element!.id })
              )
            }
            startIcon={<CloseIcon fontSize="large" />}
            style={{ float: "right", color: "orange", 
              background: declinedChangedElements.includes(
                props.element!.id
              )
              ? "#FFFF99"
              : "00000000", }}
          ></Button>
          <Button
            onClick={() =>
              dispatch(
                skipChangedElement({ elementId: props.element!.id })
              )
            }
            startIcon={<SkipNextIcon fontSize="large" />}
            style={{ float: "right", color: "orange", 
            background: skippedChangedElements.includes(
                props.element!.id
            )
            ? "#FFFF99"
            : "00000000", }}
          ></Button>
          <Typography
            sx={{ color: "orange", fontSize: "0.7", fontWeight: "bold" }}
          >
            NEW VERSION
          </Typography>
        </Box>
      )} */}
    </Box>
    
    );
}
import React, { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import {
  Autocomplete,
  Box,
  Collapse,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { findMatchingFont } from "../../HelperEditorFunctions";
import { font_Families } from "../../../../../globals";
import { ChromePicker, ColorResult } from "react-color";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import {
  TABLE_ALIGNMENT,
  textAlignOptions,
} from "../HelperFuctions/TableUtils";
import FontSizeInput from "../../../../shared/UtilitesComponents/FontSizeInput";
import SelectInput from "../../../../shared/UtilitesComponents/SelectInput";
import FontAutocomplete from "../../../../shared/UtilitesComponents/FontAutocomplete";
import BorderThicknessSelect from "../../../../shared/UtilitesComponents/BorderThicknessSelect";

interface ColorPickerProps {
  label: string;
  color: string;
  onChange: (newColor: string) => void;
  variant?: "outlined" | "filled"; // Define variant prop as optional
}

const StyledBox = styled(Box)({
  // position: "fixed",
  // position:  label === "Color" ? "fixed": "absolute",
  zIndex: 9999,
  backgroundColor: "white",
  border: "1px solid #ccc",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  borderRadius: "4px",
  padding: "10px",
  minWidth: "220px",
});

export const ColorPicker: React.FC<ColorPickerProps> = ({
  label,
  color,
  onChange,
  variant = "outlined" // Assign a default value to variant prop
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [tempColor, setTempColor] = useState<string>("#" + color);

useEffect(()=>{
setTempColor("#"+ color)
}, [color])

  const [position, setPosition] = useState({ top: 0, left: 0 });
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      const inputRect = inputRef.current.getBoundingClientRect();
      const newPosition = {
        top:
          label === "Color"
            ? inputRect.bottom + window.scrollY - 230
            : inputRect.bottom + window.scrollY,
        left: 
        Math.min(
          inputRect.left + window.scrollX,
          window.innerWidth - 240 // Adjust this value as needed
        ),
      };
      setPosition(newPosition);
    }
  }, [showPicker]);

  return (
    <div style={{ position: "relative" }}>
      <FormControl fullWidth>
        <InputLabel htmlFor={"color-picker-label" + label}>{label}</InputLabel>
        <OutlinedInput
     
          id={"color-picker-label" + label}
          label={label}
          value={tempColor}
          fullWidth
          onFocus={() => setShowPicker(true)}
          endAdornment={
            <InputAdornment position="end">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: `#${color}`,
                    border: "1px solid #ccc",
                    marginLeft: "5px",
                  }}
                />
              </Box>
            </InputAdornment>
          }
       
          inputProps={{ readOnly: true }}
           inputRef={inputRef}
          
        />
      </FormControl>

      {showPicker && (
        <StyledBox
         style={{  position:  label === "Color" ? "fixed": "absolute", top: label === "Color"? position.top :  "auto" ,
        //  position.top, 
         left: label === "Color"?position.left  :"auto" ,
        //  position.left
          }}
          >
          <ChromePicker
            color={tempColor}
            onChange={(newColor: ColorResult) => {
              setTempColor(newColor.hex);
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end", // Align items to the right
              paddingTop: 1,
            }}
          >
            <Button
              variant="outlined"
           sx={{marginRight:1}}
              onClick={() => {
              
                setTempColor(color);
                setShowPicker(false);
              }}
            >
              Close
            </Button>
            <Button
        
              variant="contained"
              color="primary"
              onClick={() => {
                onChange(tempColor.substring(1));
                setShowPicker(false);
              }}
            >
              Save
            </Button>
          </Box>
        </StyledBox>
      )}
    </div>
  );
};

export default function CustomThemeConfig(props: {
  open: boolean;
  onClose: any;
  onUpdate: any;
  currentDetails: any;
}) {
  const [updatedDetails, setUpdatedDetails] = useState({
    ...props.currentDetails,
  });


  useEffect(()=>{
setUpdatedDetails(props.currentDetails)
  },[props.currentDetails])

  const [isGridCollapsed, setIsGridCollapsed] = React.useState(true);
  const [isBorderCollapsed, setIsBorderCollapsed] = React.useState(true);
  const [isColorCollapsed, setIsColorCollapsed] = React.useState(true);
  const [isStripeCollapsed, setIsStripeCollapsed] = React.useState(true);

  // useEffect(() => {
  //   props.onUpdate(updatedDetails);
  // }, [updatedDetails]);

  const handleUpdate = () => {
    props.onUpdate(updatedDetails);
    props.onClose();
  };

  const gridStyle = {
    padding: 0,
    mariginBottom: 0,
    // alignItems: 'center', 
  };
  return (
    <Box
      marginTop={1}
      padding={1}
      border={1}
      borderColor="lightgray"
      borderRadius={1}
      sx={{ backgroundColor: "#faf9f5", width: "100%" }}
    >
      {/* fonts and Algnment */}
      <Paper elevation={1} sx={{ padding: 1, my: 1 }}>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" fontWeight={"bold"}>
              {/* Fonts and Alignment
               */}
              Formatting
            </Typography>
          </Grid>
          <Grid item xs={2} container justifyContent="flex-end">
            <IconButton
              onClick={() => setIsGridCollapsed((prev) => !prev)}
              aria-expanded={!isGridCollapsed}
              aria-label="toggle grid"
            >
              {isGridCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </Grid>
        </Grid>
        {/* ... (rest of your content) */}
      </Paper>
      <Collapse in={!isGridCollapsed} timeout="auto">
        <Grid container spacing={1}>
          <Grid item xs={6} sx={{gridStyle , margin:0}}>
            <FontSizeInput
              value={updatedDetails.THFontSize}
              label="Heading Font Size"
              onChange={(newValue) =>
                {setUpdatedDetails({
                  ...updatedDetails,
                  THFontSize: newValue,
                })
                props.onUpdate({
                  ...updatedDetails,
                  THFontSize: newValue,
                })}
              }
              min={8}
              max={72}
              step={1}
            />
          </Grid>
          <Grid item xs={6} sx={gridStyle}>
            <SelectInput
              label="Heading Text Align"
              value={updatedDetails.THTextAlign}
              onChange={(value) =>
                {setUpdatedDetails({
                  ...updatedDetails,
                  THTextAlign: value,
                })
              
                props.onUpdate({
                  ...updatedDetails,
                  THTextAlign: value,
                })
              }
              }
              options={textAlignOptions}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sx={gridStyle}>
            <FontSizeInput
              value={updatedDetails.CellFontSize}
              label="Cell Font Size"
              onChange={(newValue) =>
               { setUpdatedDetails({
                  ...updatedDetails,
                  CellFontSize: newValue,
                })
                props.onUpdate({
                  ...updatedDetails,
                  CellFontSize: newValue,
                })
              }
              }
              min={8}
              max={72}
              step={1}
            />
          </Grid>
          <Grid item xs={6} sx={gridStyle}>
            <SelectInput
              label="Cell Text Align"
              value={updatedDetails.CellTextAlign}
              onChange={(value) =>
               { setUpdatedDetails({
                  ...updatedDetails,
                  CellTextAlign: value,
                })
                props.onUpdate({
                  ...updatedDetails,
                  CellTextAlign: value,
                })
              
              }
              }
              options={textAlignOptions}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sx={gridStyle}>
            <Autocomplete
              value={font_Families.find(
                (option: any) => option.value === updatedDetails.tableFont
              )}
              disablePortal
              options={font_Families}
              onChange={(event: any, newValue: any) => {
                const selectedValue = newValue ? newValue.value : null;
                {setUpdatedDetails({
                  ...updatedDetails,
                  tableFont: selectedValue,
                });
                props.onUpdate({
                  ...updatedDetails,
                  tableFont: selectedValue,
                });
              
              }
              }}
              renderInput={(params: any) => (
                <TextField {...params} label="Font" />
              )}
              disableClearable
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ fontFamily: findMatchingFont(option.value) }}
                >
                  {option.label}
                </li>
              )}
            />
          </Grid>

          <Grid item xs={6} sx={gridStyle}>
            {/* <TextField
              select
              fullWidth
              label="Table Alignment"
              value={updatedDetails.tableAlignmnet}
              onChange={(e) =>
               { setUpdatedDetails({
                  ...updatedDetails,
                  tableAlignmnet: e.target.value,
                })
                props.onUpdate({
                  ...updatedDetails,
                  tableAlignmnet: e.target.value,
                });
              
              }

              }
              variant="outlined"
            >
              <MenuItem value="center">Center</MenuItem>
              <MenuItem value="left">Left</MenuItem>
              <MenuItem value="right">Right</MenuItem>
            </TextField> */}
          </Grid>

          <Grid item xs={6} sx={gridStyle}>
            <FormControl fullWidth variant="outlined" sx={{ margin: 0 }}>
              <TextField
                fullWidth
                margin="normal"
                type="number"
                value={updatedDetails.leftMargin}
                label="Table Left Margin"
                onChange={(e) =>
                 {
                  const inputValue = parseFloat(e.target.value);
                  if (!isNaN(inputValue) && inputValue >= 0) {
                  setUpdatedDetails({
                    ...updatedDetails,
                    leftMargin: e.target.value,
                  })
                  props.onUpdate({
                    ...updatedDetails,
                    leftMargin: e.target.value,
                  });
                
                }}
                }
             
                variant="outlined"
                InputProps={{
                  // endAdornment: (
                  //   <InputAdornment position="end">mm</InputAdornment>
                  // ),

                  endAdornment: (
                    <>
                    <InputAdornment position="end">mm</InputAdornment>
                    </>
                    
                  ),
              
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sx={gridStyle}>
            <FormControl fullWidth variant="outlined" sx={{ margin: 0 }}>
              <TextField
                fullWidth
                margin="normal"
                type="number"
                value={updatedDetails.rightMargin}
                label="Table Right Margin"
                onChange={(e) =>
                 { 
                  
                  const inputValue = parseFloat(e.target.value);
                  if (!isNaN(inputValue) && inputValue >= 0) {
                  setUpdatedDetails({
                    ...updatedDetails,
                    rightMargin: e.target.value,
                  })

                  props.onUpdate({
                    ...updatedDetails,
                    rightMargin: e.target.value,
                  });
                
                }}
                }
               
                variant="outlined"
                InputProps={{
                  // endAdornment: (
                  //   <InputAdornment position="end">mm</InputAdornment>
                  // ),

                  endAdornment: (
                    <>
                    <InputAdornment position="end">mm</InputAdornment>
                    </>
                  ),
              
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} sx={gridStyle}>
            <TextField
            sx={{ margin: 0 }}
              label="Column Width"
              select
              value={updatedDetails.colType}
              onChange={(e) =>
                {setUpdatedDetails({
                  ...updatedDetails,
                  colType: e.target.value,
                })
                props.onUpdate({
                  ...updatedDetails,
                  colType: e.target.value,
                });
              }

              }
              fullWidth
              margin="normal"
            >
              <MenuItem value="equal">Equal</MenuItem>
              <MenuItem value="automatic">Automatic</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={updatedDetails.rowType==="equal"?3:6} sx={gridStyle}>
            <TextField
            sx={{ margin: 0 }}
              label="Row Height"
              select
              value={updatedDetails.rowType}
              onChange={(e) =>
                {setUpdatedDetails({
                  ...updatedDetails,
                  rowType: e.target.value,
                })
                props.onUpdate({
                  ...updatedDetails,
                  rowType: e.target.value,
                });
              }

              }
              fullWidth
              margin="normal"
            >
              <MenuItem value="equal">Equal</MenuItem>
              <MenuItem value="automatic">Automatic</MenuItem>
            </TextField>
          </Grid>


{ updatedDetails.rowType==="equal" && (

<Grid item xs={3} sx={gridStyle}>


<FormControl fullWidth variant="outlined" sx={{padding:0, margin:0}}>
    <TextField
    sx={{padding:0, margin:0}}
      fullWidth
      margin="normal"
      type="number"
      value={updatedDetails.rowSize}
      label="Height Value"
      // title="Enter 0 to fit content"
      onChange={(e) =>
      {
        const inputValue = parseFloat(e.target.value);
        if (!isNaN(inputValue) && inputValue >= 0) {
        setUpdatedDetails({
          ...updatedDetails,
          rowSize: e.target.value,
        })
        props.onUpdate({
          ...updatedDetails,
          rowSize: e.target.value,
        });
      }}
      }
      variant="outlined"
      InputProps={{
        endAdornment: (
          <>
        
          <InputAdornment position="end">Pt</InputAdornment>
          </>
          
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  </FormControl>



</Grid>



)}




          {/* <Grid item xs={6} sx={gridStyle}>
            <TextField
              label="Table Size"
              select
              value={updatedDetails.tableSize}
              onChange={(e) =>
                {setUpdatedDetails({
                  ...updatedDetails,
                  tableSize: e.target.value,
                })
                props.onUpdate({
                  ...updatedDetails,
                  tableSize: e.target.value,
                });
              }

              }
              fullWidth
              margin="normal"
            >
              <MenuItem value="auto">Auto</MenuItem>
              <MenuItem value="custom">Custom</MenuItem>
            </TextField>
          </Grid> */}
    


{updatedDetails.tableSize==="custom" && (  
  <> 
     <Grid item xs={6} sx={gridStyle}>
            <FormControl fullWidth variant="outlined" sx={{ margin: 0 }}>
              <TextField
                fullWidth
                margin="normal"
                type="number"
                value={updatedDetails.tableWidth}
                label="Table Width"
                onChange={(e) =>
                {  setUpdatedDetails({
                    ...updatedDetails,
                    tableWidth: e.target.value,
                  })
                
                  props.onUpdate({
                    ...updatedDetails,
                    tableWidth: e.target.value,
                  });
                }
                }
                // inputProps={{
                //   style: {
                //     textAlign: "left",
                //     padding: "16px 16px",
                //     fontSize: "16px",
                //   },
                //   "aria-label": "Font Size",
                //   step: "1",
                //   min: "8",
                //   max: "",
                // }}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mm</InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} sx={gridStyle}>


          <FormControl fullWidth variant="outlined" sx={{ margin: 0 }}>
              <TextField
                fullWidth
                margin="normal"
                type="number"
                value={updatedDetails.rowSize}
                label="row height"
                title="Enter 0 to fit content"
                onChange={(e) =>
                {  setUpdatedDetails({
                    ...updatedDetails,
                    rowSize: e.target.value,
                  })
                  props.onUpdate({
                    ...updatedDetails,
                    rowSize: e.target.value,
                  });
                }
                }
        
                variant="outlined"
            
                InputProps={{
                  // startAdornment: (
                  //   <InputAdornment position="end">
                  //     {updatedDetails.rowSize ? "Fit-Content" : "Custom"}
                  //   </InputAdornment>
                  // ),
                  

                  endAdornment: (
                    <>
                     <InputAdornment position="end">
                      {updatedDetails.rowSize==0 ? "Fit-content" : "Fit-custom"}
                    </InputAdornment>
                    <InputAdornment position="end">mm</InputAdornment>
                    </>
                    
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} sx={gridStyle}>
            <FormControl fullWidth variant="outlined" sx={{ margin: 0 }}>
              <TextField
                fullWidth
                margin="normal"
                type="number"
                value={updatedDetails.columnSize}
                label="ColumnWidth"
                onChange={(e) =>
                 { setUpdatedDetails({
                    ...updatedDetails,
                    columnSize: e.target.value,
                  })
                  props.onUpdate({
                    ...updatedDetails,
                    columnSize: e.target.value,
                  });
                
                }
                }
              
                variant="outlined"
                InputProps={{
                 

                  endAdornment: (
                    <>
                
                    <InputAdornment position="end">mm</InputAdornment>
                    </>
                    
                  ),
              
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>

 
          </>    
          
          )}

        </Grid>
      </Collapse>

      {/* colors  */}

      <Paper elevation={1} sx={{ padding: 1, my: 1 }}>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" fontWeight={"bold"}>
              Colors
            </Typography>
          </Grid>
          <Grid item xs={2} container justifyContent="flex-end">
            <IconButton
              onClick={() => setIsColorCollapsed((prev) => !prev)}
              aria-expanded={!isColorCollapsed}
              aria-label="toggle grid"
            >
              {isColorCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </Grid>
        </Grid>
        {/* ... (rest of your content) */}
      </Paper>

      <Collapse in={!isColorCollapsed} timeout="auto">
        <Grid container spacing={1}>
          <Grid item xs={6} sx={{ marginTop: 1 }}>
            <ColorPicker
              label="Heading Background Color"
              color={updatedDetails.THBackgroundColor}
              onChange={(newColor) =>{
                setUpdatedDetails({
                  ...updatedDetails,
                  THBackgroundColor: newColor,
                })
                props.onUpdate({
                  ...updatedDetails,
                  THBackgroundColor: newColor,
                });
              }
              }
            />
          </Grid>
          <Grid item xs={6} sx={{ marginTop: 1 }}>
            <ColorPicker
              label="Heading Text Color"
              color={updatedDetails.THTextColor}
              onChange={(newColor) =>
              {  setUpdatedDetails({
                  ...updatedDetails,
                  THTextColor: newColor,
                })
                props.onUpdate({
                  ...updatedDetails,
                  THTextColor: newColor,
                });
              
              }
              }
            />
          </Grid>
          <Grid item xs={6} sx={{ marginTop: 2 }}>
            <ColorPicker
              label="Cell Background Color"
              color={updatedDetails.CellBackgroundColor}
              onChange={(newColor) =>
              {  setUpdatedDetails({
                  ...updatedDetails,
                  CellBackgroundColor: newColor,
                })
                props.onUpdate({
                  ...updatedDetails,
                  CellBackgroundColor: newColor,
                });
              
              
              }
              }
            />
          </Grid>
          <Grid item xs={6} sx={{ marginTop: 2 }}>
            <ColorPicker
              label="Cell Text Color"
              color={updatedDetails.CellTextColor}
              onChange={(newColor) =>
             {   setUpdatedDetails({
                  ...updatedDetails,
                  CellTextColor: newColor,
                })
                props.onUpdate({
                  ...updatedDetails,
                  CellTextColor: newColor,
                });
              
              }
              }
            />
          </Grid>
        </Grid>
      </Collapse>

      <Paper elevation={1} sx={{ padding: 1, my: 1 }}>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" fontWeight={"bold"}>
              Borders
            </Typography>
          </Grid>
          <Grid item xs={2} container justifyContent="flex-end">
            <IconButton
              onClick={() => setIsBorderCollapsed((prev) => !prev)}
              aria-expanded={!isBorderCollapsed}
              aria-label="toggle grid"
            >
              {isBorderCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </Grid>
        </Grid>
        {/* ... (rest of your content) */}
      </Paper>

      <Collapse in={!isBorderCollapsed} timeout="auto">
        <Grid container spacing={1} sx={gridStyle}>
          <Grid item xs={6} sx={gridStyle}>
            <TextField
              label="Border Style"
              select
              value={updatedDetails.CellBorderStyle}
              onChange={(e) =>
               { setUpdatedDetails({
                  ...updatedDetails,
                  CellBorderStyle: e.target.value,
                })
                props.onUpdate({
                  ...updatedDetails,
                  CellBorderStyle: e.target.value,
                });
              
              }
              }
              fullWidth
              margin="normal"
            >
              <MenuItem value="solid">Solid</MenuItem>
              {/* <MenuItem value="dashed">Dashed</MenuItem> */}
              {/* <MenuItem value="dotted">Dotted</MenuItem> */}
              <MenuItem value="none">None</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={6} sx={{ marginTop: 2 }}>
            {updatedDetails.CellBorderStyle != "none" && (
              <ColorPicker
                label="Border Color "
                color={updatedDetails.CellBorderColor}
                onChange={(newColor) =>
                {  setUpdatedDetails({
                    ...updatedDetails,
                    CellBorderColor: newColor,
                  })
                  props.onUpdate({
                    ...updatedDetails,
                    CellBorderColor: newColor,
                  });
                
                
                }
                }
              />
            )}
          </Grid>

          <Grid item xs={6} sx={{ gridStyle }}>
            {/* {updatedDetails.CellBorderStyle != "none" && (
              <FormControl fullWidth variant="outlined">
                <TextField
                  sx={{ padding: 0, mrgin: 0 }}
                  fullWidth
                  margin="normal"
                  type="number"
                  value={updatedDetails.CellBorderThickness}
                  label="Cell Border Thickness"
                  onChange={(e) =>
                    setUpdatedDetails({
                      ...updatedDetails,
                      CellBorderThickness: e.target.value,
                    })
                  }
                  inputProps={{
                    style: {
                      textAlign: "center",
                      // padding: "16px 16px",
                      fontSize: "16px",
                    },
                    "aria-label": "Font Size",
                    step: ".1",
                    min: "1",
                    max: "12",
                  }}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">mm</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            )} */}

            {/* <BorderThicknessSelect
       value={updatedDetails.CellBorderThickness}
        onChange={(value) =>
                   setUpdatedDetails({
                     ...updatedDetails,
                    CellBorderThickness:value,
                 })}
        options={[
          { value: '1px', label: 'Thin', thickness: '1px' },
          { value: '2px', label: 'Normal', thickness: '2px' },
          { value: '4px', label: 'Thick', thickness: '4px' },
          // Add more options as needed
        ]}
        label="Border Thickness"
      /> */}

{updatedDetails.CellBorderStyle != "none"  && ( 
<SelectInput
              label="Border Thickness"
              value={updatedDetails.CellBorderThickness}
              onChange={(value) =>
               { setUpdatedDetails({
                  ...updatedDetails,
                  CellBorderThickness: value,
                })
                props.onUpdate({
                  ...updatedDetails,
                  CellBorderThickness: value,
                });
              
                }
              }
              options={[
                // { value: "0.25", label: "1/4 pt" },
                // { value: "0.5", label: "1/2 pt" },
                // { value: "0.75", label: "3/4 pt" },
                // { value: "1", label: "1 pt" },
                // { value: "1.5", label: "1 1/2 pt" },
                // { value: "2.25", label: "2 1/4 pt" },
                // { value: "4.5", label: "4 1/2 pt" },
                // { value: "6", label: "6 pt" },
                { value: "0.088", label: "1/4 pt" },
                { value: "0.176", label: "1/2 pt" },
                { value: "0.264", label: "3/4 pt" },
                { value: "0.352", label: "1 pt" },
                { value: "0.529", label: "1 1/2 pt" },
                { value: "0.793", label: "2 1/4 pt" },
                { value: "1.587", label: "4 1/2 pt" },
                { value: "2.116", label: "6 pt" },
              ]}
              fullWidth
            />)}
          </Grid>
        </Grid>
      </Collapse>

      <Paper elevation={1} sx={{ padding: 1, my: 1 }}>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" fontWeight={"bold"}>
              Stripes
            </Typography>
          </Grid>
          <Grid item xs={2} container justifyContent="flex-end">
            <IconButton
              onClick={() => setIsStripeCollapsed((prev) => !prev)}
              aria-expanded={!isStripeCollapsed}
              aria-label="toggle grid"
            >
              {isStripeCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </Grid>
        </Grid>
        {/* ... (rest of your content) */}
      </Paper>

      <Collapse in={!isStripeCollapsed} timeout="auto">
        <Grid container spacing={1} sx={gridStyle}>
          <Grid item xs={6} sx={gridStyle}>
            <TextField
              label="Stripe"
              select
              value={updatedDetails.stripe}
              onChange={(e) =>
               { setUpdatedDetails({
                  ...updatedDetails,
                  stripe: e.target.value,
                })
                props.onUpdate({
                  ...updatedDetails,
                  stripe: e.target.value,
                });
              }
              }
              fullWidth
              margin="normal"
            >
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="column">Vertical</MenuItem>
              <MenuItem value="row">Horizontal</MenuItem>
            </TextField>
          </Grid>
          {updatedDetails.stripe != "none" && (
            <Grid item xs={6} sx={{ marginTop: 2 }}>
              <ColorPicker
                label="Stripe Color "
                color={updatedDetails.StripeBackgroundColor}
                onChange={(newColor) =>
                 { setUpdatedDetails({
                    ...updatedDetails,
                    StripeBackgroundColor: newColor,
                  })
                  
                  props.onUpdate({
                    ...updatedDetails,
                    StripeBackgroundColor: newColor,
                  });
                  }

                }
              />
            </Grid>
          )}
        </Grid>
      </Collapse>
    </Box>
  );
}

import React from "react";
import { Box, Grid, Typography, Switch } from "@mui/material";
import HeaderFooterConfigForm from "../../header/HeaderFooterConfigForm";
import ContentFormatCard from "./ContentFormatCard";
import ContentFormattingMaster from "../../header/DocumentFormatMaster/ContentFormattingMaster";
export const renderContentFormatCards = (startingIndex:number, settingsSlice: any, setter: any , defaultIndent:boolean) => {
  return settingsSlice.map((setting: any, index: number) => (
    <Grid item xs={4} key={setting.id}>
      <ContentFormattingMaster
        key={setting.id}
        title={setting.title}
        topMargin={setting.topMargin}
        setTopMargin={(newValue: any) =>
          updateSetting(index+startingIndex, "topMargin", newValue, setter)
        }
        bottomMargin={setting.bottomMargin}
        setBottomMargin={(newValue: any) =>
          updateSetting(index+startingIndex, "bottomMargin", newValue, setter)
        }
        fontColor={setting.fontColor}
        indent={setting.indent}
        setIndent={(newValue: any) =>
          updateSetting(index+startingIndex, "indent", newValue, setter)
        }
        setFontColor={(newValue: any) =>
          updateSetting(index+startingIndex, "fontColor", newValue, setter)
        }
        font={setting.font}
        setFont={(newValue: any) =>
          updateSetting(index+startingIndex, "font", newValue, setter)
        }
        fontSize={setting.fontSize}
        setFontSize={(newValue: any) =>
          updateSetting(index+startingIndex, "fontSize", newValue, setter)
        }
        fontStyle={setting.fontStyle}
        setFontStyle={(newValue: any) =>
          updateSetting(index+startingIndex, "fontStyle", newValue, setter)
        }
        alignment={setting.alignment}
        SetAlignment={(newValue: any) =>
          updateSetting(index+startingIndex, "alignment", newValue, setter)
        }
        format={setting.format}
        setFormat={(newValue: any) =>
          updateSetting(index+startingIndex, "format", newValue, setter)
        }
        separator={setting.separator}
        setSeparator={(newValue: any) =>
          updateSetting(index+startingIndex, "separator", newValue, setter)
        }
        compound={setting.compound}
        setCompound={(newValue: any) =>
          updateSetting(index+startingIndex, "compound", newValue, setter)
        }
        prefix={setting.prefix}
        setPrefix={(newValue: any) =>
          updateSetting(index+startingIndex, "prefix", newValue, setter)
        }
        suffix={setting.suffix}
        setSuffix={(newValue: any) =>
          updateSetting(index+startingIndex, "suffix", newValue, setter)
        }
        lineSpacing={setting.linespacing}
        setLineSpacing={(newValue: any) =>
          updateSetting(index+startingIndex, "linespacing", newValue, setter)
        }
        defaultIndent={defaultIndent}
      />
    </Grid>
  ));
};

export const updateSetting = (
  index: number,
  field: string,
  value: any,
  setter: React.Dispatch<React.SetStateAction<any[]>>
) => {
  setter((prevSettings) => {
    const updatedSettings = [...prevSettings];
    updatedSettings[index] = {
      ...updatedSettings[index],
      [field]: value,
    };
    return updatedSettings;
  });
  
};


export const headerFooterSection = ({
  title,
  checked,
  onCheckChange,
  lineCheck,
  onLineChange,
  settings,
  onSettingChange,
}: {
  title: string;
  checked: boolean;
  onCheckChange: () => void;
  lineCheck:boolean;
  onLineChange:(event: React.ChangeEvent<HTMLInputElement>) => void;
  settings:any,
  onSettingChange:any,
}) => (
  <Box>
  <Box
    display="flex"
    alignItems="center"
    sx={{
      backgroundColor: "#ebeaea",
      borderRadius: "3px",
      padding: "8px",
      marginBottom: "8px",
      marginTop: "8px",
      width: "33%",
    }}
    >
    <Typography variant="h5" fontWeight="bold" sx={{ marginRight: "3.5vh" }}>
      {title}
    </Typography>
    <Box>
      <Switch checked={checked} onChange={onCheckChange} />
    </Box>
  </Box>

{checked && (   
    <Box
    display="flex"
    alignItems="center"
    sx={{
      backgroundColor: "#ebeaea",
      borderRadius: "3px",
      padding: "5px",
      marginBottom: "5px",
      marginTop: "5px",
      width: "33%",
    }}
  >
    <Typography variant="h6" fontWeight="bold" sx={{ marginRight: "16px" }}>
      Display Line
    </Typography>
    <Switch checked={lineCheck} onChange={onLineChange} />
  </Box>
  )
}
{
    checked && (

<Grid container spacing={2}>
    {settings.map((item:any , index:number) => (
      <Grid item xs={4} key={item.id}>
        <HeaderFooterConfigForm
        id={item.id}
        title={item.title}
        type={title}
        font={item.font}
        setFont={(newValue: any) => onSettingChange(index, 'font', newValue)}
        fontSize={item.fontSize}
        setFontSize={(newValue: any) => onSettingChange(index, 'fontSize', newValue)}
        fontStyle={item.fontStyle}
        setFontStyle={(newValue: any) => onSettingChange(index, 'fontStyle', newValue)}
        fontColor={item.fontColor}
        setFontColor={(newValue: any) => onSettingChange(index, 'fontColor', newValue)}
        freeText={item.freeText}
        setFreeText={(newValue: any) => onSettingChange(index, 'freeText', newValue)}
        selectionChoice={item.selection}
        setSelectionChoice={(newValue: any) => onSettingChange(index, 'selection', newValue)}
        pageNumberStyle={item.pageNumberStyle}
        setPageNumberStyle={(newValue: any) => onSettingChange(index, 'pageNumberStyle', newValue)}
        isPageNumberOnFirst={item.isPageNumberOnFirst}
        setIsPageNumberOnFirst={(newValue: any) => onSettingChange(index, 'isPageNumberOnFirst', newValue)}
        imageID={item.image_Id}
        setImageID={(newValue: any) => onSettingChange(index, 'image_Id', newValue)}
        // imageFile={itemImage[index].image}
        // setImageFile={(id: number, newValue: any) => handleHeaderImage(index, id, newValue)}
        pageStyle={item.pageStyleText}
        setPageStyle={(newValue: any) => onSettingChange(index, 'pageStyleText', newValue)}
      />
      </Grid>
    ))}
  </Grid>
     )
}




    </Box>
);






export const ConfigGrid = ({
  settings,
  onSelectionChange,
}: {
  settings: any[]; // Adjust the type according to your data structure
  onSelectionChange: (id: number, newValue: any) => void;
}) => (
  <Grid container spacing={2}>
    {settings.map((item) => (
      <Grid item xs={4} key={item.id}>
        <HeaderFooterConfigForm
          {...item}
          type="footer" // Change this to "header" if used for header
          setSelectionChoice={(newValue: any) =>
            onSelectionChange(item.id, newValue)
          }
        />
      </Grid>
    ))}
  </Grid>
);

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import backgroundImageSignin from "./images/backgroundImageSignin.jpg";
import useActions, { useAppSelector } from "../../app/hooks";
import { AuthState, logout } from "./authSlice";
import { Warning } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import tempImg from "./images/backgroundImageSignin.jpg";

import logo from "../../nomia-logo2.png";

import { useSnackbar } from "notistack";
import { setLocalUserState, setRememberMeState } from "../../services/userAPI";
import SignupModal from "./SignupModal";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://nomia.co.za/">
        Nomia Pty. Ltd.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();
export default function SignInModal(props: {
  authState: AuthState;
  open: boolean;
}) {
  const actions = useActions();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isResetPassword, setResetPassword] = React.useState<boolean>(false);
  const [resetEmail, setResetEmail] = React.useState<string>("");
  const [rememberMe, setRememberMe] = React.useState<boolean>(true);
  const [isSignUp, setIsSignUp] = React.useState<boolean>(false);
  
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const lp = {
      email: data.get("email") as string,
      pass: data.get("password") as string,
      rememberMe: rememberMe,
    };
    actions.login(lp);
  };

  const handleReset = (event: any) => {
    event.preventDefault();
    const lp = {
      email: resetEmail,
    };
    actions.reset(lp);
  };



  return (
    <ThemeProvider theme={theme}>
      <Dialog
        style={{ backgroundImage: `url(${tempImg})`, backgroundSize: "cover" }}
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} width="200px" alt="nomiaLogo" />
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!isResetPassword && !isSignUp && (
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, width: 400 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setRememberMe(e.target.checked)}
                    checked={rememberMe}
                    color="primary"
                  />
                }
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              {/* If theres an error, show it */}
              {props.authState.error !== undefined ? (
                <Alert severity="error">
                  Unable to log in - {props.authState.error}
                </Alert>
              ) : null}
              <Grid container>
                <Grid item xs>
                  <Button onClick={() => setResetPassword(true)}>
                    Forgot password?
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <Button>Don't have an account?</Button>
                  <Button onClick={() => setIsSignUp(true)}>
           

              <span style = {{ textDecoration: "underline"}}>Sign Up</span>
             
                </Button>
                  {/* <Link href="https://nomiadocs.com/checkout" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link> */}
                </Grid>
              </Grid>
              {/*<Copyright sx={{ mt: 5 }} /> */}
            </Box>
          )}

          {isResetPassword && (
            <Box component="form" noValidate sx={{ mt: 1, width: 400 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="reset_email"
                label="Email Address"
                name="reset_email"
                autoComplete="email"
                autoFocus
                onChange={(event) => setResetEmail(event?.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={(event) => handleReset(event)}
                disabled={resetEmail == ""}
              >
                Send Password Reset Mail
              </Button>
              {/* If theres an error, show it */}
              {props.authState.error !== undefined ? (
                <Alert severity="error">{props.authState.error}.</Alert>
              ) : null}
              <Grid container>
                <Grid item xs>
                  <Button onClick={() => setResetPassword(false)}>
                    Return to Sign In
                  </Button>
                </Grid>
              </Grid>
              {/* <Copyright sx={{ mt: 5 }} /> */}
            </Box>
          )}
        </DialogContent>
      </Dialog>
      {isSignUp && (
        <SignupModal
          handleClose={() => setIsSignUp(false)}
          authState={props.authState}
        />
      )}
    </ThemeProvider>
  );
}

import {
  Alert,
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import useActions from "../../../../app/hooks";
import {
  ContentType,
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateSummary,
} from "../../../../types/DocumentTemplate";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
//   import useActions  from  "../../../../App/hooks"

export default function EditorUndoForm(props: {
  parentSection: DocumentSection;
  parentSubsection: DocumentSubsection;
  title?:string;
  isHeading: boolean;
  closeDialogHandler: any;
}) {
  const actions = useActions();
  const handleUndoDeleted = () => {
    if (props.isHeading) {
      actions.undoDeletedSection(props.parentSection as DocumentSection);
    } else {
      actions.undoDeletedSubsection({
        section: props.parentSection as DocumentSection,
        subSection: props.parentSubsection as DocumentSubsection,
      });
    }
    props.closeDialogHandler();
  };
  const handleClose = () => {
    props.closeDialogHandler();
  };

  return (
    <>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle sx={{ fontWeight: "bold" }}>
          Confirm Undo {props.isHeading ? "Section" : "Subsection"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1" color="textPrimary">
              Are you sure you want to undo this{" "}
              {props.isHeading &&  props.title ?  props.title  : props.isHeading? "Section Heding" : "Subsection"}?
            </Typography>
    
          </DialogContentText>
          {(  !props.isHeading &&  props.parentSubsection.elements && (props.parentSubsection!.elements[0]!.content_type===ContentType.Heading ||  props.parentSubsection!.elements[0]!.content_type===ContentType.Text) && <Alert sx={{my:1}} severity="warning" > You might have to adjust the Levels manually </Alert>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleUndoDeleted}
            variant="contained"
            color="primary"
            autoFocus
          >
            Confirm Undo
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

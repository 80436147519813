import { blue, grey, orange } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

import { ThemeOptions } from "@mui/material/styles";

// REF: https://mui.com/customization/theming/
// What you get by default: https://mui.com/customization/default-theme/

// TODO: Work with team to make a theme: https://bareynol.github.io/mui-theme-creator/
// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=006064&secondary.color=43A047

// APPLICATION: To use this in any component:
//  import { useTheme } from '@mui/styles';
//  function DeepChild() {
//    const theme = useTheme();
//    return <span>{`spacing ${theme.spacing}`}</span>;
//  }

// Additional theming properties.
declare module "@mui/material/styles" {
  // allow configuration using `createTheme`
  interface ThemeOptions {
    additional?: {
      highlighted?: string;
      modified?: string;
      logo?: string;
      lightBorder?: string;
      secondarySilver?: string;
    };
  }
}

/*
export const nomiaTheme = createTheme({
  palette: {
    primary: {
      main: blue[500]
    },
    warning: {
      main: orange[500]
    }
  },
  //Not used yet.
  additional: {
    highlighted: orange[500],
    modified: orange[500],
    logo: grey[800]
  },
});
*/
import { PaletteOptions } from '@mui/material/styles/createPalette';

declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    teal?: PaletteColorOptions;
    purple?: PaletteColorOptions;
    orange?: PaletteColorOptions;
    silver?: PaletteColorOptions;
  }
}

const additionalColors = {
  teal: '#008080',
  purple: '#800080',
  orange: '#FFA500',
  lightBorder: "dfdfdf",
  secondarySilver: "#7284A3",
};

export const nomiaTheme = createTheme({
  palette: {
    //type: 'light',
    primary: {
      main: "#4C33FF",
      light: "#29CFFF",
      dark: "#000000",
    },
    secondary: {
      main: "#000000",
    },
    background: {
      default: "#f5f5fb",
    },
    success: {
      main: "#00C482",
    },
    text: {
      primary: "#000000",
    },
    error: {
      main: "#FF0062",
    },
    warning: {
      main: "#FF9933",
    },
    info: {
      main: "#29CFFF",
    },
    teal: {
      main: additionalColors.lightBorder,
    },
    silver: {
      main: additionalColors.secondarySilver,
    },
    purple: {
      main: additionalColors.purple,
    },
    orange: {
      main: additionalColors.orange,
    },
    divider: "#D4CEFF",
  },
  typography: {
    fontFamily: 'MarkPro',
    // fontFamily: "DMSans-Regular.ttf",
  },
  additional: {
    lightBorder: "dfdfdf",
    secondarySilver: "#7284A3",
  },

  components: {
    MuiSlider: {
      styleOverrides: {
        root: {
          color: '#4C33FF', // Custom slider color
          '& .MuiSlider-thumb': {
            backgroundColor: '#4C33FF', // Thumb color
            border: '2px solid #4C33FF', // Thumb border color
          },
          '& .MuiSlider-track': {
            backgroundColor: '#4C33FF', // Track color
          },
          '& .MuiSlider-rail': {
            backgroundColor: '#4C33FF', // Rail color
          },
        },
      },
    },
  }

  /*
  props: {
    MuiAppBar: {
      color: 'secondary',
    },
  },*/
});

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { getLocalUserToken } from "../../../services/userAPI";
import * as crypto from "crypto";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import React, { RefObject, useRef } from "react";
import {
  apiGetWorkspaceApiKey,
  apiResetWorkspaceApiKey,
} from "../../../services/organizationAPI";
import { useAppSelector } from "../../../app/hooks";
import { selectAuth } from "../../auth/authSlice";
import RefreshIcon from "@mui/icons-material/Refresh";
import { StyledButton } from "../../library/DocumentInformation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { decryptAES, encryptAES } from "../../../services/encryption";
import { host } from "src/services/client";

// import encryptAES from "../../../services/encryption";
interface ApiSectionProps {
  title: string;
  textAreaRef: RefObject<HTMLTextAreaElement>;
  formattedApiCode: string;
  handleCopyLink: (textAreaRef: RefObject<HTMLTextAreaElement>) => void;
}

export default function APIInformationForm(props: { closeDialogHandler: any }) {
  const accessToken = getLocalUserToken();
  const [copied, setCopied] = React.useState(false);
  const [clickedButton, setClickedButton] = React.useState(null);
  const authState = useAppSelector(selectAuth);
  const [apiKey, setApiKey] = React.useState<string>("");
  const textAreaLinksRef = useRef<HTMLTextAreaElement>(null);
  const textAreaMergeRef = useRef<HTMLTextAreaElement>(null);
  const textAreaBodyRef = useRef<HTMLTextAreaElement>(null);
  const formattedAPIMerge = `${host}/organizations/${authState.user?.default_organization_id}/document_records/api/pdf/merged`;

  const formattedAPILink = `${host}/organizations/${authState.user?.default_organization_id}/document_records/api/pdf`;

  const formattedBody = `{"records": [
    {
      // ID of the template being used
      "template_id": 01,
  
      // Name assigned to the document
      "name": "Nomia_Docs", 
  
      // Include in Nomia (true/false)
      "include_in_nomia": false,
  
      // variables within the document
      "variable_values": "${'[[{"variable_name":"Nomia","value":"Doc"},{"variable_name":"fillable","value": "","type":"TEXT","signatory": 1}]]'}"
    },
    //You can add here more 
  ],
  // Workspace API key
  "api_key": "${apiKey}",
  "workspace_id": ${authState.user?.default_workspace_id}
  }`;
  // "api_key": "nomiaxx-x-xxxx-xxx-xxxx"
  const copyEmailToClipboard = (email: any, index: any) => {
    navigator.clipboard.writeText(email).then(() => {
      setCopied(true);
      setClickedButton(index);
      setTimeout(() => {
        setClickedButton(null);
      }, 3500);
    });
  };

  const handleCopyLink = (_ref: any) => {
    if (_ref.current) {
      _ref.current.select();
      document.execCommand("copy");
    }
  };

  const fetchApikey = async () => {
    try {
      const response = await apiGetWorkspaceApiKey(
        authState.user!.default_organization_id,
        authState.user!.default_workspace_id
      );
      console.log("🚀 ~ fetchApikey ~ response:", response.ws_api_key);

      setApiKey(response.ws_api_key);
    } catch (e) {
      console.log("error");
    }
  };

  const resetApikey = async () => {
    try {
      const response = await apiResetWorkspaceApiKey(
        authState.user!.default_organization_id,
        authState.user!.default_workspace_id
      );
      setApiKey(response.refresh_token);
    } catch (e) {
      console.log("error");
    }
  };

  React.useEffect(() => {
    fetchApikey();
  }, []);

  const handleClose = () => {
    props.closeDialogHandler();
  };

  const inputStyle = {
    // width: "400px",
    width: "100%",
    marginBottom: "16px",
    // display: "flex",
    // alignItems: "center",
  };

  const spanStyle = {
    display: "block",
    marginBottom: "5px",
    fontSize: "16px",
    fontWeight: "bold",
  };

  // Reusable component for API section
  const ApiSection: React.FC<ApiSectionProps> = ({
    title,
    textAreaRef,
    formattedApiCode,
    handleCopyLink,
  }) => {
    return (
      <div style={inputStyle}>
        <div
          style={{
            paddingBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <span style={spanStyle}>{title}</span>
          <StyledButton
            onClick={() => handleCopyLink(textAreaRef)}
            sx={{ fontSize: "9px" }}
          >
            Copy code
          </StyledButton>
        </div>
        <div>
          <textarea
            ref={textAreaRef}
            id="codeAPILinks"
            style={{
              // width: "410px",
              width: "100%",
              height: title == "JSON Body Format" ? "270px" : "fit-content",
              minHeight: "130px",
              backgroundColor: "#282c34",
              color: "#abb2bf",
              border: "none",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "5px",
              fontSize: "13pt",
              fontFamily: "Courier New, Courier, monospace",
            }}
          >
            {formattedApiCode}
          </textarea>
        </div>
      </div>
    );
  };

  const InstructionsAccordion = (
    <>
      <Box boxShadow={3} sx={{ marginTop: 0 }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "bold" }}>Instructions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <strong style={{ paddingTop: 0, marginTop: 0 }}>
                1. Obtain API Details:
              </strong>
              <br />
              <ul>
                <li>
                  <strong>API Endpoint:</strong> Retrieve the exact URL for the
                  document generation endpoint from the top-right menu.
                </li>
                <li>
                  <strong>Authorization Token:</strong> Acquire the AUTH Token
                  for authentication.
                </li>
                <li>
                  <strong>Workspace API Key:</strong> Obtain the workspace API
                  key for your account.
                </li>
              </ul>
              <strong>2. Navigate to Postman:</strong>
              <br />
              <ul>
                <li>
                  <strong>HTTP Method:</strong> Confirm that it's a POST
                  endpoint.
                </li>
                <li>
                  <strong>Headers:</strong> Add "Authorization" as a key and the
                  AUTH Token as its value. Ensure that the content-type header
                  is set to "application/json".
                </li>
                <li>
                  <strong>Body:</strong> Select "raw" and paste your JSON
                  payload.
                </li>
                <blockquote>
                  Please include the workspace API key in your payload.
                  <br />
                  Note: The "records" field in your JSON payload can contain a
                  single or multiple records.
                </blockquote>
              </ul>
              <strong>3. Send Request:</strong>
              <br />
              Click on "Send" to execute the request and obtain the API
              response.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle sx={{ fontWeight: "bold" }}>API Information</DialogTitle>
      <DialogContent>
        <div style={inputStyle}>
          <span style={spanStyle}>User Token</span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              value={accessToken}
              fullWidth
              readOnly
              style={{ flex: 1, marginRight: "5px" }}
            />
            <IconButton
              onClick={() => copyEmailToClipboard(accessToken, 1)}
              style={clickedButton === 1 ? { color: "blue" } : {}}
            >
              {clickedButton === 1 ? (
                <AssignmentTurnedInIcon sx={{ fontSize: "0.9em" }} />
              ) : (
                <NoteAltIcon sx={{ fontSize: "0.9em" }} />
              )}
            </IconButton>
          </div>
        </div>

        <div style={inputStyle}>
          <span style={spanStyle}>Workspace API Key</span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              value={apiKey}
              fullWidth
              readOnly
              style={{ flex: 1, marginRight: "5px" }}
            />
            <IconButton onClick={() => resetApikey()}>
              <RefreshIcon sx={{ fontSize: "0.9em" }} />
            </IconButton>
            <IconButton
              onClick={() => copyEmailToClipboard(apiKey, 2)}
              style={clickedButton === 2 ? { color: "blue" } : {}}
            >
              {clickedButton === 2 ? (
                <AssignmentTurnedInIcon sx={{ fontSize: "0.9em" }} />
              ) : (
                <NoteAltIcon sx={{ fontSize: "0.9em" }} />
              )}
            </IconButton>
          </div>
        </div>

        <div style={{ padding: "10px" }}>{InstructionsAccordion}</div>

        <div>
          <ApiSection
            title="API for PDFs Links"
            textAreaRef={textAreaLinksRef}
            formattedApiCode={formattedAPILink}
            handleCopyLink={handleCopyLink}
          />
          <ApiSection
            title="API for PDFs Stream"
            textAreaRef={textAreaMergeRef}
            formattedApiCode={formattedAPIMerge}
            handleCopyLink={handleCopyLink}
          />
          <ApiSection
            title="JSON Body Format"
            textAreaRef={textAreaBodyRef}
            formattedApiCode={formattedBody}
            handleCopyLink={handleCopyLink}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

import React, { Component, useState } from "react";
import Box from "@mui/material/Box";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import InfoIcon from "@mui/icons-material/Info";
import TwoColumnsIcon from "@mui/icons-material/ViewStream";
import ThreeColumnsIcon from "@mui/icons-material/ViewWeek";
import ErrorOutlineIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  ContentType,
  DocumentElement,
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateSummary,
} from "../../../types/DocumentTemplate";
import ExpandIcon from "@mui/icons-material/Expand";

import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from "@mui/icons-material/Undo";

import useActions, { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  clearSelectedComponent,
  selectApprovalState,
  selectSelected,
  setSelectedComponent,
} from "../editorSlice";
import Divider from "@mui/material/Divider";
import { ViewPreferences } from "../header/preferencesSlice";
import Typography from "@mui/material/Typography";
import { useDrop } from "react-dnd";
import { DebugID } from "./DebugID";
import { DropSkeleton } from "./DropSkeleton";
import {
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { Approvals } from "./Approvals";
import { SpaceBarRounded } from "@mui/icons-material";
import LibraryContentConfigFormSubSection from "./libraryContentSubSection/LibraryContentFormSubSection";
import SettingsIcon from "@mui/icons-material/Settings";
import { secondarySilver } from "../../../globals";
import EditorUndoForm from "./CustomEditorDecorators/EditorUndoForm";
import SwitchSubsection from "./CustomEditorDecorators/SwitchSubSection";
import LibraryContentIcon from "./CustomEditorDecorators/LibraryContentIcon";
import { isIncludeImpact } from "../drawers/right/Approval/ApprovalHelperFunctions/approvalHelper";
import { COLORS } from "../../shared/Constants";
import DeleteUnsharedConfirm from "./CustomEditorDecorators/DeleteUnsharedConfirm";
/**
 * Spacer
 *
 * @export
 * @param {({
 *   element: DocumentElement | null;
 *   parentSubsection: DocumentSubsection | null;
 *   parentSection: DocumentSection;
 *   documentTemplate: DocumentTemplate;
 *   organizationId: number;
 *   workspaceId: number;
 *   viewPreferences: ViewPreferences;
 * })} props
 * @return {*}
 */
export function ColumnElementEditor(props: {
  element: DocumentElement | null;
  parentSubsection: DocumentSubsection | null;
  parentSection: DocumentSection | DocumentSectionSummary;
  parentSection__: DocumentSection | null;
  documentTemplate: DocumentTemplate | DocumentTemplateSummary;
  organizationId: number;
  workspaceId: number;
  viewPreferences: ViewPreferences;
  readOnly?: boolean;
  approvalsEnabled: boolean;
}) {
  const approvalState = useAppSelector(selectApprovalState);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [IsLibraryOpen, SetIsLibarayOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const actions = useActions();
  const startElement = React.useMemo(() => {
    return JSON.parse(props.element!.content_format);
  }, []);

  const [sectionColumn, setSectionColumn] = React.useState<any>(
    startElement.columns
  );

  const [fillSpaces, setFillSpaces] = React.useState<boolean>(
    startElement.fillspacing ?? false
  );

  const [isDeleteUnsharedOpen, setIsDeleteUnsharedOpen] =
    useState<boolean>(false);

  // const isClosingColumn = () => {
  //   try {

  //     const template = props.documentTemplate as DocumentTemplate;
  //     const section = template.sections?.find((s) => s.id === props.parentSection.id);

  //     if (!section || !section.subsections) {
  //       console.warn("Section or subsections not found");
  //       return false;
  //     }

  //     const filteredColumns = section.subsections.filter(
  //       (sub) => sub?.elements?.[0]?.content_type === ContentType.columns
  //     );
  //     const currentElementIndex = filteredColumns.findIndex(
  //       (column) => column.id === props.parentSubsection?.id
  //     );

  //     return currentElementIndex !== -1 && currentElementIndex % 2 === 1;
  //   } catch (error) {
  //     console.warn("Unable to find the open/closing tag of the Column", error);
  //     return false;
  //   }
  // };

  const updateColumns = (_columns: number, _fillspacing: boolean) => {
    setSectionColumn(_columns);
    const newParams = JSON.stringify({
      columns: _columns,
      fillspacing: _fillspacing,
    });

    actions.updateDocumentElement({
      section: props.parentSection as DocumentSection,
      subSection: props.parentSubsection as DocumentSubsection,
      documentElement: {
        ...props.element,
        content_format: newParams,
      } as DocumentElement,
    });
  };

  // Only allow drag+drop if this is not read only.
  const [{ isOver }, drop] = props.readOnly
    ? [{ isOver: null }, null]
    : useDrop(() => ({
        accept: "right-drawer-item",
        drop: (dropItem: any) => addElementToSection({ ...dropItem.element }),
        collect: (monitor) => ({
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop(),
        }),
      }));
  function addElementToSection(element: DocumentElement) {
    if (element.content_type === "LIBRARYCONTENTSUBSECTION") {
      SetIsLibarayOpen(true);
    } else {
      actions.createSubsectionWithElement({
        section: props.parentSection as DocumentSection,
        documentElement: element,
        subsectionAbove: props.parentSubsection!,
      });
    }
  }

  /**
   * When the component is focused, update the state.
   * @param {*} e
   * @return {*}  {*}
   */

  function handleOnFocus(e: any): any {
    setIsFocused(true);
    dispatch(
      setSelectedComponent({
        selectedSection: props.parentSection as DocumentSection,
        selectedSubsection: props.parentSubsection,
        selectedComponent: props.element,
      })
    );
  }
  /**
   * Similarly track when focus is lost.
   * @param {*} e
   */
  function handleOnBlur(e: any) {
    setIsFocused(false);
    dispatch(clearSelectedComponent());
  }

  function handleDeleteClicked(e: any) {
    setIsDeleteUnsharedOpen(true);
    // actions.deleteSubsection({
    //   section: props.parentSection as DocumentSection,
    //   subSection: props.parentSubsection as DocumentSubsection,
    // });
    // dispatch(clearSelectedComponent());
  }
  const isDeleted = props.parentSubsection?.pending_deleted_at != null;

  const isSelected = isFocused;
  // props.element === null || selected.documentElement === null
  //   ? false
  //   : selected.documentElement!.id == props.element!.id;

  function border(): any {
    if (
      props.parentSubsection &&
      props.approvalsEnabled &&
      isIncludeImpact(approvalState, props.parentSubsection.id)
    ) {
      return {
        border: 2.5,
        borderColor: " #00C482 !important",
        borderStyle: isSelected ? "solid" : "dashed",
      };
    }

    if (
      (props.parentSection.pending_deleted_at != null || isDeleted) &&
      props.approvalsEnabled
    ) {
      return {
        border: COLORS.BORDER_SIZE,
        borderRadius: 0,
        borderStyle: isSelected ? "solid" : "dashed",
        borderColor: COLORS.NOMIA_RED,
      };
    }
    if (props.viewPreferences.showSections) {
      return {
        borderRadius: 0,
        border: isSelected
          ? `1px solid ${secondarySilver}`
          : `1px dashed ${secondarySilver}`, // Add border property
        borderColor: secondarySilver, // Add border color property
      };
    }
    return {};
  }

  const isClosingTag = props.element?.content_type == ContentType.columnEnd;

  const findCorrespondingTag = () => {
    const { sections } = props.documentTemplate as DocumentTemplate;
    const subsections: DocumentSubsection[] = sections?.reduce((acc, section) => {
      if (section.subsections) {
        return [...acc, ...section.subsections];
      }
      return acc;
    }, [] as DocumentSubsection[]) || [];
  
    const columnsElement = subsections.filter(
      (subsection: DocumentSubsection) =>
      subsection.elements &&  subsection.elements?.length > 0 &&  subsection.pending_deleted_at==null &&
        (subsection.elements[0].content_type === ContentType.columnStart ||
          subsection.elements[0].content_type === ContentType.columnEnd)
    );
  
    const currentElementIndex = columnsElement.findIndex(
      (subsection: DocumentSubsection) => subsection.id == props.parentSubsection?.id
    );

    if (currentElementIndex !== -1) {
      if (props.element?.content_type === ContentType.columnStart) {
        const nextElement = columnsElement[currentElementIndex + 1];
        if (nextElement && nextElement.elements?.[0]?.content_type === ContentType.columnEnd) {
          return true;
        }
      } else if (props.element?.content_type === ContentType.columnEnd) {
        const previousElement = columnsElement[currentElementIndex - 1];
        if (previousElement && previousElement.elements?.[0]?.content_type === ContentType.columnStart) {
          return true;
        }
      }
    }

    return false;
  };
  
  React.useEffect(() => {
    // Update tooltip open state based on the findCorrespondingTag result
    setIsTooltipOpen(!findCorrespondingTag());
  }, [props.documentTemplate, props.parentSubsection, props.element]);


  return (
    <>
      <ClickAwayListener
        onClickAway={(e) => {
          if (props.approvalsEnabled) {
            handleOnBlur(e);
          }
        }}
      >
        <Box
          id={`subsection-${props.parentSubsection!.id}`}
          ref={drop}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onClick={(e) => {
            if (props.approvalsEnabled) {
              handleOnFocus(e);
            }
          }}
          sx={Object.assign(
            {
              padding: 1,
              margin: 1,
              mx: 1,
              backgroundColor: isSelected ? "secondarySilver" : "white",
            },
            border()
          )}
          alignItems="center"
          justifyContent="center"
        >
          {props.viewPreferences.showIDs ? (
            <DebugID
              name="Element"
              id={props.element !== null ? props.element.id : -1}
              componentOrder={0}
            />
          ) : null}
          <Box
            sx={{
              position: "relative",
              zIndex: 99,
            }}
          >
            <SwitchSubsection
              parentSection={props.parentSection as DocumentSection}
              parentSubsection={props.parentSubsection as DocumentSubsection}
              direction={"up"}
              isDisabled={false}
              element={props.element as DocumentElement}
              style={{
                paddingBottom: 0,
              }}
              readonly={props.readOnly}
            />
            <LibraryContentIcon
              isHeading={false}
              parentSection={props.parentSection as DocumentSection}
              parentSubsection={props.parentSubsection as DocumentSubsection}
              documentTemplate={props.documentTemplate as DocumentTemplate}
              style={{
                outline: "none",
                color: "#7284A3",
                position: "absolute",
                top: 6,
                right: 52,
                transition: "none", // Remove transition on button press
                margin: 0,
                padding: 0,
                width: "fit-content",
              }}
            />

            {props.approvalsEnabled && isDeleted && (
              <IconButton
                onMouseDown={(e) => e.preventDefault()}
                onClick={(e) => {
                  e.preventDefault();
                  setIsDeleteOpen(true);
                  e.stopPropagation(); // Add this line to stop event propagation
                }}
                title="Undo Deletion"
                style={{
                  position: "absolute",
                  top: 6,
                  right: 3,
                  transition: "none", // Remove transition on button press
                  margin: 0,
                  padding: 0,
                  width: "fit-content",
                  zIndex: 99,
                }}
              >
                <UndoIcon
                  style={{ fontSize: 20, outline: "none", color: "#7284A3" }}
                />
              </IconButton>
            )}

            {!props.approvalsEnabled && !props.readOnly && (
              <>
                <IconButton
                  style={{
                    position: "absolute",
                    top: 6,
                    right: 25,
                    transition: "none", // Remove transition on button press
                    margin: 0,
                    padding: 0,
                    width: "fit-content",
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={handleDeleteClicked}
                >
                  
                  <DeleteIcon
                    style={{
                      fontSize: 20,
                      outline: "none",
                      color: "#7284A3",
                    }}
                  />
                </IconButton>

                <Tooltip
                
                open={isTooltipOpen}
            
                title={
                  isTooltipOpen
                    ? isClosingTag
                      ? "Add corresponding start column"
                      : "Add corresponding end column"
                    : ""
                } 
                placement="top"
                >
                  <IconButton
                    onMouseDown={(e) => e.preventDefault()}
                    style={{
                      position: "absolute",
                      top: 6,
                      right: 3,
                      transition: "none", // Remove transition on button press
                      margin: 0,
                      padding: 0,
                      width: "fit-content",
                      zIndex: 99,
                    }}
                    onClick={() => {if(isTooltipOpen){setIsTooltipOpen((prev) => !prev)}}} // Toggle Tooltip visibility on click
                  >
{
  !findCorrespondingTag() ?
  (
    < ErrorOutlineIcon
    style={{
      fontSize: 20,
      outline: "none",
      color: !findCorrespondingTag()?"red" :"#7284A3",
    }}
  />
   
  ):(
    <CheckCircleIcon
    style={{
      fontSize: 20,
      outline: "none",
      color: !findCorrespondingTag()?"red" :"#7284A3",
    }}
  />

  )
}

              
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
          <Box
            sx={{
              mx: 14,
            }}
          >
            <Divider sx={{ width: "100%", paddingBottom: "8px" }}>
              <IconButton
                title="Two Columns"
                disabled={isClosingTag}
                onMouseDown={(e) => e.preventDefault()}
                onClick={(e: any) => {
                  e.preventDefault();
                  if (sectionColumn != 2) {
                    updateColumns(2, fillSpaces);
                  }
                  e.stopPropagation(); // Add this line to stop event propagation
                }}
              >
                <TwoColumnsIcon
                  fontSize="large"
                  color={
                    !(!isDeleted && sectionColumn == 2) || isClosingTag ||  props.readOnly 
                      ? "disabled"
                      : "primary"
                  }
                  style={{ transform: "rotate(90deg)" }}
                ></TwoColumnsIcon>
              </IconButton>
              <IconButton
                title="Three Columns"
                disabled={isClosingTag}
                onMouseDown={(e) => e.preventDefault()}
                onClick={(e: any) => {
                  e.preventDefault();
                  if (sectionColumn != 3) {
                    updateColumns(3, fillSpaces);
                  }
                  e.stopPropagation(); // Add this line to stop event propagation
                }}
              >
                <ThreeColumnsIcon
                  fontSize="large"
                  color={
                    !(!isDeleted && sectionColumn == 3) || isClosingTag ||  props.readOnly 
                      ? "disabled"
                      : "primary"
                  }
                ></ThreeColumnsIcon>
              </IconButton>
              {!isClosingTag && (
                <IconButton
                  title="Fill spaces?"
                  disabled={isClosingTag}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={(e: any) => {
                    e.preventDefault();
                    updateColumns(sectionColumn, !fillSpaces);
                    setFillSpaces(!fillSpaces);
                    e.stopPropagation(); // Add this line to stop event propagation
                  }}
                >
                  <ExpandIcon
                    fontSize="large"
                    color={
                      !!isDeleted || isClosingTag || !fillSpaces ||  props.readOnly 
                        ? "disabled"
                        : "primary"
                    }
                    // style={{ transform: "rotate(90deg)" }}
                  ></ExpandIcon>
                </IconButton>
              )}
              <Typography>{`Columns ${
                isClosingTag ? "End" : "Start"
              }`}</Typography>
            </Divider>
          </Box>
          <SwitchSubsection
            parentSection={props.parentSection as DocumentSection}
            parentSubsection={props.parentSubsection as DocumentSubsection}
            direction={"down"}
            isDisabled={false}
            element={props.element as DocumentElement}
            style={{
              margin: 0,
              padding: 0,
              paddingTop: 4,
            }}
            readonly={props.readOnly}
          />
        </Box>
      </ClickAwayListener>
      {/* The drag+drop element showing that you are going to drop something here. */}
      {isOver ? <DropSkeleton /> : null}

      {IsLibraryOpen && (
        <LibraryContentConfigFormSubSection
          closeDialogHandler={() => SetIsLibarayOpen(false)}
          organizationId={props.organizationId}
          workspaceId={props.workspaceId}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          viewPreferences={props.viewPreferences}
        />
      )}

      {isDeleteUnsharedOpen === true ? (
        <DeleteUnsharedConfirm
          isHeading={false}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteUnsharedOpen(false)}
        />
      ) : null}

      {isDeleteOpen === true ? (
        <EditorUndoForm
          isHeading={false}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteOpen(false)}
        />
      ) : null}
    </>
  );
}

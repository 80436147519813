import { delay, select } from "redux-saga/effects";
import { call, put } from "redux-saga/effects";
import * as actions from "../actions";
import {
  AuthState,
  selectAuth,
  selectUser,
  setError as setAuthError,
  setStatus as setAuthStatus,
  setOrganizationOwner,
  setOrganizations,
  setUser,
  updateOrganizationUsers,
  setCreditStatus,
  updateOrganizationAdminUsers,
  setCreaterUserError,
  setErrorMsg,
  logout,
} from "../../features/auth/authSlice";
import { Organization, User } from "../../types/User";
import {
  apiCreateUserInOrg,
  apiCreateUserWithOrg,
  apiGetOrganizationUsers,
  apiGetOrganizations,
  apiCreateAdminUserInOrg,
  apiUpdateOrganizationCreditCount,
  apiDeleteOrganization,
  apiCreateSuperUserInOrg,
  apiDeleteSuperUser,
} from "../../services/organizationAPI";
import { clearDocumentTemplate } from "../../features/editor/editorSlice";

/**
 * Get all organizations, confirm that the user has selected
 * a valid organization, and then get all workspaces.
 *
 * @param {ReturnType<typeof actions.getOrganizations>} action
 * @return {*}
 */
export function* getOrganizations(
  action: ReturnType<typeof actions.getOrganizations>
) {
  yield put(setAuthStatus({ status: "loading", error: undefined }));

  try {
    const response = yield call(apiGetOrganizations);

    // Set the organizations.
    const organizations = response.data.organizations;
    // const default_org  = response.data.default_org
    yield put(setOrganizations(organizations));

    const userState = yield select(selectUser);
    const orgId = userState.default_organization_id;
    let defaultOrg = organizations.find((o: Organization) => o.id === orgId);

    if (organizations.length > 0) {
      if (!defaultOrg) {
        console.warn(
          `Cannot find user's default organization ${orgId}, resetting to first organization.`
        );
        // Select the first organization.
        defaultOrg = organizations[0];
        const updatedUser = Object.assign({}, userState, {
          default_organization_id: defaultOrg.id,
        });

        // Update the user
        yield put(setUser(updatedUser));
      }

      const link = (response.data.links as any[]).filter(
        (_) => _.organization_id == defaultOrg.id
      );
      if (link.length > 0) {
        yield put(
          setOrganizationOwner({ is_owner: link[0].org_role == "ADMIN" })
        );
      }
      // Get the users for this organization
      yield put(actions.getOrganizationUsers());
      // Get the workspaces for this organization
      yield put(actions.getWorkspaces());
    }

    yield put(setAuthStatus({ status: "logged-in", error: undefined }));

  } catch (e) {
    yield put(logout())
    yield put(setAuthStatus({ status: "logged-out", error: undefined }));
    console.error(String(e));
    yield put(setAuthError(String(e)));
    return;
  }
}

/**
 * Set the current organization and refresh downstream slices.
 *
 * @param {ReturnType<typeof actions.setOrganization>} action
 * @return {*}
 */
export function* setOrganization(
  action: ReturnType<typeof actions.setOrganization>
) {
  try {
    const organization = action.payload;

    const userState = (yield select(selectUser)) as User;
    const updatedUser = Object.assign({}, userState, {
      default_organization_id: organization!.id,
      default_workspace_id: -1,
    });
    // Clear any currently edited document
    yield put(clearDocumentTemplate());
    // Update the user
    yield put(setUser(updatedUser));
    // Get the users for this organization
    yield put(actions.getOrganizationUsers());
    // Get the workspaces for this organization
    yield put(actions.getWorkspaces());
  } catch (e) {
    yield put(setAuthError(String(e)));
    return;
  }
}

// export function* getOrgUsers(
//   action: ReturnType<typeof actions.getOrgUsers>
// ) {

// }

/**
 * Get all users in the current organization.
 *
 * @param {ReturnType<typeof actions.getOrganizationUsers>} action
 * @return {*}
 */
export function* getOrganizationUsers(
  action: ReturnType<typeof actions.getOrganizationUsers>
) {
  // yield put(setAuthStatus({ status: "loading", error: undefined }));

  yield put(  setErrorMsg({
    status:"idle",
    errorMsg:undefined,
    errorType:undefined
   }))

  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiGetOrganizationUsers,
      userState.default_organization_id
    );

    // check if the current user is Admin or not
    if (response.data.admin_users.length > 0) {
      yield put(
        setOrganizationOwner({
          is_owner:
            response.data.admin_users.some(
              (admin: any) => admin.user_id === userState.user_id
            ) || userState.super_user,
        })
      );
    }

    yield put(
      updateOrganizationUsers({
        organizationId: userState.default_organization_id,
        users: response.data?.users,
      })
    );
    const updatedusers: User[] = response.data?.users.map((du: User) => ({
      ...du,
      admin: response.data.admin_users.some(
        (admin: any) => admin.user_id === du.id
      ),
    }));
    yield put(
      updateOrganizationAdminUsers({
        organizationId: userState.default_organization_id,
        users: updatedusers,
      })
    );
    yield put(setAuthStatus({ status: "logged-in", error: undefined }));
  } catch (e) {
    console.error(String(e));
    yield put(setAuthError(String(e)));
    return;
  }
}

export function* createUserInOrg(
  action: ReturnType<typeof actions.createUserInOrg>
) {
  try {
    yield put(setCreaterUserError(undefined));
    const email = action.payload.email,
      password = action.payload.password,
      workspaces = action.payload.workspaces;

    const authState = (yield select(selectAuth)) as AuthState;

    const response = yield apiCreateUserInOrg(
      authState.user!.default_organization_id,
      email,
      password,
      workspaces
    );
    yield put(setCreaterUserError("success"));
    // Get new user list
    yield put(actions.getOrganizationUsers());
  } catch (e) {
    yield put(
      setCreaterUserError(
        "A user with this email already exists in this organization."
      )
    );
    // yield put(setAuthError(String(e)));
    return;
  }
}

export function* createUserWithOrg(
  action: ReturnType<typeof actions.createUserWithOrg>
) {
  try {
    const email = action.payload.email,
      organization_name = action.payload.organization_name;

    const authState = (yield select(selectAuth)) as AuthState;

    const response = yield apiCreateUserWithOrg(email, organization_name);

    // Get new user list
    yield put(actions.getOrganizationUsers());
  } catch (e) {
    yield put(setAuthError(String(e)));
    return;
  }
}

export function* updateOrganizationCredits(
  action: ReturnType<typeof actions.updateOrganizationCredits>
) {
  try {
    yield put(setCreditStatus("loading"));
    const userState = (yield select(selectUser)) as User;
    const response = yield;
    apiUpdateOrganizationCreditCount(
      action.payload.organizationID,
      action.payload.updatedCredit,
      action.payload.organizationName,
      action.payload.allowNegitiveCredits
    );

  yield put(setCreditStatus("success"));
   
    yield delay(5000);
    yield put(setCreditStatus("idle"));
    if(action.payload.organizationID===  userState.default_organization_id){
      yield put (actions.getOrganizations())
    }
    // Get new user list
  } catch (e) {
    yield put(setCreditStatus("failed"));
    yield put(setAuthError(String(e)));
    return;
  }
}
export function* deleteOrganization(
  action: ReturnType<typeof actions.deleteOrganization>
) {
  try {
    yield put(setCreditStatus("loading"));

    const response = yield;
    apiDeleteOrganization(action.payload.organizationID);

    yield put(setCreditStatus("success"));
    yield delay(3000);
    yield put(setCreditStatus("idle"));
    // Get new user list
  } catch (e) {
    yield put(setCreditStatus("failed"));
    yield put(setAuthError(String(e)));
    return;
  }
}

export function* createAdminUserInOrg(
  action: ReturnType<typeof actions.createAdminUserInOrg>
) {
  try {
    const email = action.payload.email;
    const authState = (yield select(selectAuth)) as AuthState;
    const userState = (yield select(selectUser)) as User;
    yield put(  setErrorMsg({
      status:"loading",
      errorMsg:undefined,
      errorType:"createAdminUserInOrg"
     }))

    const response = yield apiCreateAdminUserInOrg(
      email,
      userState.default_organization_id
    );

    yield put(  setErrorMsg({
      status:"success",
      errorMsg:"Email sent successfully!",
      errorType:"createAdminUserInOrg"
     }))
    // Get new user list
    yield put(actions.getOrganizationUsers());
  } catch (e) {
    yield put(  setErrorMsg({
      status:"failed",
      errorMsg:"User already exist!",
      errorType:"createAdminUserInOrg"
     }))
    yield put(setAuthError(String(e)));
    return;
  }
}
export function* createSuperUserInOrg(
  action: ReturnType<typeof actions.createSuperUserInOrg>
) {
  try {
    const email = action.payload.email;
    const authState = (yield select(selectAuth)) as AuthState;
    const userState = (yield select(selectUser)) as User;
 yield put(  setErrorMsg({
  status:"loading",
  errorMsg:undefined,
  errorType:"createSuperUserInOrg"
 }))
    const response = yield apiCreateSuperUserInOrg(
      email,
      userState.default_organization_id
    );

    yield put(  setErrorMsg({
      status:"success",
      errorMsg:"Email sent successfully!",
      errorType:"createSuperUserInOrg"
     }))

    // Get new user list
    yield put(actions.getOrganizationUsers());
  } catch (e) {
    yield put(  setErrorMsg({
      status:"failed",
      errorMsg:"User already exist!",
      errorType:"createSuperUserInOrg"
     }))
    yield put(setAuthError(String(e)));
    return;
  }
}
export function* deleteSuperUser (
  action: ReturnType<typeof actions.deleteSuperUser >
) {
  try {
    const authState = (yield select(selectAuth)) as AuthState;
    const userState = (yield select(selectUser)) as User;

    const response = yield  apiDeleteSuperUser(
     action.payload.userID
    );

    // Get new user list
    yield put(actions.getOrganizationUsers());
  } catch (e) {
    yield put(setAuthError(String(e)));
    return;
  }
}

import CryptoJS from "crypto-js";

export const encryptAES = (plaintext: string): string => {
  // Convert the key to a WordArray
  const key = process.env.REACT_APP_ENCRYPTION_KEY ?? "";
  const parsedKey = CryptoJS.enc.Utf8.parse(key);

  // Encrypt the plaintext using AES ECB mode with PKCS7 padding
  const encrypted = CryptoJS.AES.encrypt(plaintext, parsedKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  // Get the ciphertext as a Base64-encoded string
  const ciphertext = encrypted.toString();

  return ciphertext;
};

// working correctly

export const decryptAES = (ciphertext: string): any => {
  // Decode the Base64-encoded ciphertext first

  const key = process.env.REACT_APP_ENCRYPTION_KEY ?? "";
  const decodedCiphertext = CryptoJS.enc.Base64.parse(ciphertext);

  // Convert the decoded ciphertext to a format expected by CryptoJS
  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: decodedCiphertext,
  });

  // Decrypt the ciphertext
  const decrypted = CryptoJS.AES.decrypt(
    cipherParams,
    CryptoJS.enc.Utf8.parse(key), // Parse key to WordArray
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  // Convert the decrypted WordArray to a UTF-8 encoded string
  const plaintext = CryptoJS.enc.Utf8.stringify(decrypted);

  return JSON.parse(plaintext);
};
